import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { IReportingProps } from "../../../models/Interfaces/IMapSite";
import { ReportingTypeEnum } from "../../../models/Enum/EnumTypes";
import TabbedView from "./TabbedView";
import "./Style.css";

// eslint-disable-next-line no-empty-pattern
export default function ReportingTabs({}: IReportingProps) {
  const navigate = useNavigate();
  const { siteId, organisationId, sttId, defaultTab, clientId, selectedOrgId } =
    useParams();
  const [reportType, setReportType] = useState(
    defaultTab === ReportingTypeEnum.Progress
      ? ReportingTypeEnum.Progress
      : ReportingTypeEnum.Priorities,
  );

  return (
    <div className="report-menu">
      <div className="report-menu-bar">
        <div onClick={() => navigate(-1)} className="reports-nav-item">
          <p className="menu-title">BACK</p>
        </div>
        <div
          className="reports-nav-item"
          onClick={() => {
            setReportType(ReportingTypeEnum.Improvements);
          }}
        >
          <p
            className={`menu-title ${
              reportType === ReportingTypeEnum.Improvements
                ? "reports-menu-title-underlined"
                : ""
            }`}
          >
            IMPROVEMENTS
          </p>
        </div>
        <div
          className="reports-nav-item"
          onClick={() => {
            setReportType(ReportingTypeEnum.Progress);
          }}
        >
          <p
            className={`menu-title ${
              reportType === ReportingTypeEnum.Progress
                ? "reports-menu-title-underlined"
                : ""
            }`}
          >
            PROGRESS
          </p>
        </div>
        <div
          className="reports-nav-item"
          onClick={() => {
            setReportType(ReportingTypeEnum.Priorities);
          }}
        >
          <p
            className={`menu-title ${
              reportType === ReportingTypeEnum.Priorities
                ? "reports-menu-title-underlined"
                : ""
            }`}
          >
            PRIORITIES
          </p>
        </div>
        <div
          className="reports-nav-item"
          onClick={() => {
            setReportType(ReportingTypeEnum.Result);
          }}
        >
          <p
            className={`menu-title ${
              reportType === ReportingTypeEnum.Result
                ? "reports-menu-title-underlined"
                : ""
            }`}
          >
            RESULTS
          </p>
        </div>
        <div
          className="reports-nav-item"
          onClick={() => {
            setReportType(ReportingTypeEnum.Participation);
          }}
        >
          <p
            className={`menu-title ${
              reportType === ReportingTypeEnum.Participation
                ? "reports-menu-title-underlined"
                : ""
            }`}
          >
            PARTICIPATION
          </p>
        </div>
      </div>
      <div className="reports-section">
        <TabbedView
          TabName={reportType}
          siteId={siteId}
          organisationId={organisationId}
          surveyTemplateTypeId={sttId}
          clientId={clientId}
          selectedOrgId={selectedOrgId}
        />
      </div>
    </div>
  );
}
