import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useLoading } from "providers/loading";
import { DropzoneArea } from "material-ui-dropzone";
import { UseTable } from "hooks/useTable";
import Controls from "../../../components/Controls";
import { Form } from "../../../hooks/useForm";
import API from "../../../services/Api.Service";
import { API_URLS } from "../../../config/ApiUrls";
import routePaths from "../../../config/RoutePaths";
import { SuccessToast } from "../../../components/Toaster";
import "./Style.css";

export default function CreateMultipleDataCycles(this: any) {
  const { setAppLoading } = useLoading();
  const navigate = useNavigate();
  const [csvFile, setCSVFile] = useState([]);
  const [dataCycles, setDataCycles] = useState<any>([]);

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setAppLoading(true);
    API.post(API_URLS.CreateMultipleDataCycles, dataCycles)
      .then((response: any) => {
        SuccessToast(response?.data?.message);
        // Navigate to data cycles screen
        navigate(routePaths.DATACYCLES);
      })
      .catch(() => {
        setAppLoading(false);
      })
      .finally(() => {
        setAppLoading(false);
      });
  };

  const onDrageChange = async (file: any) => {
    setCSVFile(file);
  };

  useEffect(() => {
    setAppLoading(true);
    if (csvFile !== undefined && csvFile.length !== 0) {
      const formData = new FormData();
      formData.append("file", csvFile[0]);

      API.post(API_URLS.ReadDataCyclesCSV, formData)
        .then((response: any) => {
          setDataCycles(response?.data?.dataCycles);
          SuccessToast(response?.data?.message);
        })
        .catch(() => {
          setAppLoading(false);
        })
        .finally(() => {
          setAppLoading(false);
        });
    } else {
      setDataCycles([]);
      setAppLoading(false);
    }
  }, [csvFile]);

  const columns = [
    { title: "SURVEY CYCLE", field: "dataCycleName" },
    { title: "ORGANISATION", field: "organisationName" },
    { title: "ORGANISATION SITE", field: "organisationSiteName" },
    { title: "SURVEY TEMPLATE", field: "surveyTemplateName" },
    {
      title: "START DATE",
      field: "createdOn",
      render: (rowData: any) => (
        <div className="createdOn">
          {moment(rowData.startDate).format("DD/MM/YYYY")}
        </div>
      ),
    },
    { title: "LANGUAGES", field: "languageNames" },
    { title: "COLLECTION METHODS", field: "dataCollectionMethodNames" },
    { title: "SEASON", field: "dataCycleSeasonName" },
    { title: "WORK FORCE SIZE", field: "numberOfWorker" },
    { title: "TAGS", field: "tags" },
  ];

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container>
              <Grid item xs={12} className="grid-custom-spacing">
                <div className="page-heading-container">
                  <Controls.BackButton />
                  <h2 className="page-heading">Add Multiple Survey Cycle</h2>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div className="dropzone-area">
                  <DropzoneArea
                    acceptedFiles={[".csv"]}
                    useChipsForPreview
                    previewGridProps={{
                      container: { spacing: 1, direction: "row" },
                    }}
                    maxFileSize={80000000}
                    filesLimit={1}
                    onChange={(file) => onDrageChange(file)}
                  />
                </div>
              </Grid>
            </Grid>
            <UseTable
              columns={columns}
              data={dataCycles}
              title="Survey Cycle"
            />
            <div className="form-buttons custom-floatRight">
              <br />
              <Controls.Button
                className="button blue"
                type="submit"
                text="Create"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={() => {
                  navigate(routePaths.DATACYCLES);
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Form>
  );
}
