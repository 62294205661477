import { useEffect, useState } from "react";
import Controls from "components/Controls";
import { SuccessToast } from "components/Toaster";
import { API_URLS } from "config/ApiUrls";
import { UseTable } from "hooks/useTable";
import API from "services/Api.Service";
import { useLoading } from "providers/loading";
import routePaths from "config/RoutePaths";

const UserAdminTable = () => {
  const { setAppLoading } = useLoading();
  const [modal, setModal] = useState(false);
  const [adminUsers, setAdminUsers] = useState<any[]>([]);
  const [userToBeReset, setUserToBeReset] = useState({
    email: "",
  });
  const Url: any = window.location.origin + routePaths.RESETPASSWORD;

  // Populate the table with users from the DB
  useEffect(() => {
    setAppLoading(true);
    API.get(API_URLS.GetAdminUserList, {
      params: {
        OrderBy: "UserId",
        SearchValue: null,
      },
    })
      .then((response: any) => {
        const users = response.data.data;
        setAdminUsers(users);
      })
      .finally(() => setAppLoading(false));
  }, []);

  // This method updates the isApproved column on the user
  // in the DB.
  const approveUser = (e: any, userId: string) => {
    API.post(API_URLS.ApproveUser, {
      userId,
      isApproved: e.target.value,
    }).then((response: any) => {
      // After successfully updating then refresh the table elements
      // to reflect the change
      SuccessToast(response?.message);
      setAppLoading(true);
      API.get(API_URLS.GetAdminUserList, {
        params: {
          OrderBy: "UserId",
          SearchValue: null,
        },
      })
        .then((res: any) => {
          setAdminUsers(res.data.data);
        })
        .finally(() => setAppLoading(false));
    });
  };

  const resetPassword = () => {
    setAppLoading(true);
    API.post(API_URLS.ForgotPassword, null, {
      params: {
        Email: userToBeReset.email,
        url: Url,
      },
    })
      .then((response: any) => {
        SuccessToast(response?.data?.message);
        toggleModal();
      })
      .finally(() => {
        setAppLoading(false);
      });
  };

  const columns: any = [
    { title: "USER NAME", field: "userName" },
    // {
    //   title: "ORGANISATIONS",
    //   field: "userName",
    //   render: () => (
    //     <div>
    //       <p>NOT IMPLEMENTED</p>
    //     </div>
    //   ),
    // },
    // {
    //   title: "ROLES",
    //   field: "userName",
    //   render: () => (
    //     <div>
    //       <p>NOT IMPLEMENTED</p>
    //     </div>
    //   ),
    // },
    {
      title: "APPROVED",
      field: "isApproved",
      sorting: false,
      // Render a checkbox with value equal to isApproved column
      // on DB, run the 'approveUser' function whenever the
      // checkbox is clicked.
      render: (rowData: any) => (
        <div>
          <Controls.CheckBox
            id="approved"
            label=""
            name="approved"
            value={rowData.isApproved}
            onChange={(e) => approveUser(e, rowData.userId)}
            checked={rowData.isApproved}
          />
        </div>
      ),
    },
    {
      title: "MANAGE",
      field: "edit",
      sorting: false,
      render: (rowData: any) => {
        return (
          <div className="actions-container">
            {/* TODO: Whose password is being reset here */}
            {/* <p onClick={() => navigateToViewAdminUser(rowData?.userId)}>View</p> */}
            <p onClick={() => openModal(rowData)}>Reset Password</p>
            {/* |<p onClick={() => navigateToEdit(rowData?.userId)}>Edit</p> */}
          </div>
        );
      },
    },
  ];

  const toggleModal = () => {
    setModal(!modal);
  };

  const openModal = (rowData: any) => {
    setUserToBeReset({
      email: rowData.userName,
    });
    setModal(!modal);
  };

  return (
    <div>
      <UseTable columns={columns} data={adminUsers} title="Manage Users" />
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay" />
          <div className="modal-content">
            <h2>Reset Password</h2>
            <p>
              An email will be sent prompting the user to reset their password.
              Are you sure you want to proceed?
            </p>
            <br />
            <div className="form-buttons custom-floatRight">
              <Controls.Button
                className="button blue"
                onClick={resetPassword}
                text="Reset Password"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={toggleModal}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserAdminTable;
