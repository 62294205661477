import "./Style.css";
import SiteSummaryBox from "./SiteSummaryBox";

// eslint-disable-line @typescript-eslint/no-unused-vars
function SiteSummary({
  clientId,
  selectedSiteIds,
  surveyTemplateTypeId,
  removeSiteFromSummaries,
  organisationId,
}: any) {
  // eslint-disable-line @typescript-eslint/no-unused-vars

  // Retrieve sites from local storage
  const sites = selectedSiteIds;
  return (
    <div className="chooseSite">
      {sites?.map((selectedItem: any) => {
        return (
          <SiteSummaryBox
            clientId={clientId}
            site={selectedItem}
            surveyTemplateTypeId={surveyTemplateTypeId}
            removeSiteFromSummaries={removeSiteFromSummaries}
            organisationId={organisationId}
          />
        );
      })}
    </div>
  );
}

export default SiteSummary;
