import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import { Form, useForm } from "hooks/useForm";
import Controls from "components/Controls";
import { IQuestion } from "models/Interfaces/IQuestion";
import "./Style.css";
import API from "services/Api.Service";
import { API_URLS } from "config/ApiUrls";
import { SuccessToast } from "components/Toaster";
import routePaths from "config/RoutePaths";
import { useLoading } from "providers/loading";
import QuestionComponents from "../QuestionComponents";

export default function CreateQuestion() {
  const { setAppLoading } = useLoading();
  const navigate = useNavigate();
  // Pull ID of question from URL parameters
  // if no ID in parameters, it is a create question screen
  const { questionId } = useParams();
  const [entitiesLoaded, setEntitiesLoaded] = useState(false);
  const [questionTypes, setQuestionTypes] = useState<any>([]);
  const [indicatorFamilies, setIndicatorFamilies] = useState<any>([]);
  const [indicators, setIndicators] = useState<any>([]);
  const [questionType, setQuestionType] = useState("");
  const [isYesPositive, setIsYesPositive] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  let obj = {} as IQuestion;

  // Set heading of page according to whether the user
  // is creating or editing
  const pageHeading = questionId ? "Edit Question" : "Add Question";

  const initialFValues: IQuestion = {
    questionText: "",
    questionTypeId: "",
    questionTypeIdTextSearch: "",
    indicatorFamilyId: "",
    indicatorFamilyIdTextSearch: "",
    indicatorId: "",
    indicatorIdTextSearch: "",
    label: "",
    isYesAPositiveResponse: true,
    numberOfResponses: "",
    chartTitle: "",
    keypad1Description: "",
    keypad2Description: "",
    keypad3Description: "",
    keypad4Description: "",
    keypad5Description: "",
    keypad6Description: "",
    keypad7Description: "",
    keypad8Description: "",
    keypad9Description: "",
  };

  const validate = (fieldValues = values) => {
    if ("label" in fieldValues)
      obj.label = fieldValues.label ? "" : "Label is required.";
    if ("questionText" in fieldValues)
      obj.questionText = fieldValues.questionText
        ? ""
        : "Question text is required.";

    if ("questionTypeId" in fieldValues)
      obj.questionTypeId = fieldValues.questionTypeId
        ? ""
        : "Question type is required.";

    if (questionType === "Core") {
      if ("indicatorFamilyId" in fieldValues)
        obj.indicatorFamilyId = fieldValues.indicatorFamilyId
          ? ""
          : "Indicator family is required.";
      if ("indicatorId" in fieldValues)
        obj.indicatorId = fieldValues.indicatorId
          ? ""
          : "Indicator is required.";
    }

    if (questionType === "Segmentation") {
      if ("numberOfResponses" in fieldValues)
        obj.numberOfResponses = fieldValues.numberOfResponses
          ? ""
          : "Number of responses is required.";

      if ("chartTitle" in fieldValues)
        obj.chartTitle = fieldValues.chartTitle
          ? ""
          : "Chart title is required.";

      if ("keypad1Description" in fieldValues)
        obj.keypad1Description = fieldValues.keypad1Description
          ? ""
          : "Keypad 1 description is required.";

      if ("keypad2Description" in fieldValues)
        obj.keypad2Description = fieldValues.keypad2Description
          ? ""
          : "Keypad 2 description is required.";

      if (
        "keypad3Description" in fieldValues &&
        fieldValues.numberOfResponses &&
        Number(fieldValues.numberOfResponses) > 2
      )
        obj.keypad3Description = fieldValues.keypad3Description
          ? ""
          : "Keypad 3 description is required.";

      if (
        "keypad4Description" in fieldValues &&
        fieldValues.numberOfResponses &&
        Number(fieldValues.numberOfResponses) > 3
      )
        obj.keypad4Description = fieldValues.keypad4Description
          ? ""
          : "Keypad 4 description is required.";

      if (
        "keypad5Description" in fieldValues &&
        fieldValues.numberOfResponses &&
        Number(fieldValues.numberOfResponses) > 4
      )
        obj.keypad5Description = fieldValues.keypad5Description
          ? ""
          : "Keypad 5 description is required.";

      if (
        "keypad6Description" in fieldValues &&
        fieldValues.numberOfResponses &&
        Number(fieldValues.numberOfResponses) > 5
      )
        obj.keypad6Description = fieldValues.keypad6Description
          ? ""
          : "Keypad 6 description is required.";

      if (
        "keypad7Description" in fieldValues &&
        fieldValues.numberOfResponses &&
        Number(fieldValues.numberOfResponses) > 6
      )
        obj.keypad7Description = fieldValues.keypad7Description
          ? ""
          : "Keypad 7 description is required.";

      if (
        "keypad8Description" in fieldValues &&
        fieldValues.numberOfResponses &&
        Number(fieldValues.numberOfResponses) > 7
      )
        obj.keypad8Description = fieldValues.keypad8Description
          ? ""
          : "Keypad 8 description is required.";

      if (
        "keypad9Description" in fieldValues &&
        fieldValues.numberOfResponses &&
        Number(fieldValues.numberOfResponses) > 8
      )
        obj.keypad9Description = fieldValues.keypad9Description
          ? ""
          : "Keypad 9 description is required.";
    }

    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange, resetForm, setDataValues } =
    useForm(initialFValues, true, validate);

  useEffect(() => {
    setAppLoading(true);

    const fetchData = async () => {
      try {
        // Start all your GET requests asynchronously
        const indicatorFamilyRequest = API.get(
          API_URLS.GetIndicatorFamilies,
        ).then((response: any) => {
          const IndicatorFamiliesOption = response.data.data.map((x: any) => {
            return { id: x.indicatorFamilyId, label: x.indicatorFamilyLabel };
          });
          setIndicatorFamilies(IndicatorFamiliesOption);
        });

        const indicatorRequest = API.get(API_URLS.GetAllIndicators, {
          params: {},
        }).then((response: any) => {
          const IndicatorIdOption = response.data.data.map((x: any) => {
            return {
              id: x.indicatorId,
              label: x.indicatorLabel,
              indicatorFamilyId: x.indicatorFamilyId,
            };
          });
          setIndicators(IndicatorIdOption);
        });

        const questionTypesRequest = API.get(API_URLS.GetQuestionTypes, {
          params: {},
        }).then((response: any) => {
          const questionTypeOptions = response.data.data.map((x: any) => {
            return {
              id: x.questionTypeId,
              label: x.questionTypeName,
            };
          });
          setQuestionTypes(questionTypeOptions);
        });

        // Wait for all requests to complete
        await Promise.all([
          indicatorFamilyRequest,
          indicatorRequest,
          questionTypesRequest,
        ]);
      } finally {
        // All requests completed, set isLoading to false
        setEntitiesLoaded(true);

        if (!questionId) {
          setAppLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      // Remove all the field errors and reset the form
      obj = {} as IQuestion;
      resetForm();
    };
  }, []);

  // This useEffect checks whether QuestionID is present in parameters
  // if so the form is prepopulated with the details of the question to be edited
  useEffect(() => {
    if (questionId) {
      resetForm();
      if (entitiesLoaded) {
        setAppLoading(true);
        API.get(API_URLS.GetQuestionByQuestionId, {
          params: {
            QuestionId: questionId,
          },
        })
          .then((response: any) => {
            const result = response?.data?.data;

            values.questionText = result.questionText
              ? result.questionText
              : "";
            values.label = result.label ? result.label : "";

            values.questionTypeId = result.questionTypeId
              ? result.questionTypeId
              : "";

            values.questionTypeIdTextSearch = result.questionTypeName
              ? result.questionTypeName
              : "";

            values.indicatorFamilyId = result.indicatorFamilyId
              ? result.indicatorFamilyId
              : "";

            values.indicatorFamilyIdTextSearch = result.indicatorFamilyName
              ? result.indicatorFamilyName
              : "";

            values.indicatorId = result.indicatorId ? result.indicatorId : "";

            values.indicatorIdTextSearch = result.indicatorLabel
              ? result.indicatorLabel
              : "";

            values.numberOfResponses = 2;

            for (let i = 9; i > 1; i--) {
              if (result[`keypad${i}Description`] !== "") {
                values.numberOfResponses = i;
                break;
              }
            }

            values.chartTitle = result.chartTitle ? result.chartTitle : "";
            values.keypad1Description = result.keypad1Description
              ? result.keypad1Description
              : "";
            values.keypad2Description = result.keypad2Description
              ? result.keypad2Description
              : "";
            values.keypad3Description = result.keypad3Description
              ? result.keypad3Description
              : "";
            values.keypad4Description = result.keypad4Description
              ? result.keypad4Description
              : "";
            values.keypad5Description = result.keypad5Description
              ? result.keypad5Description
              : "";
            values.keypad6Description = result.keypad6Description
              ? result.keypad6Description
              : "";
            values.keypad7Description = result.keypad7Description
              ? result.keypad7Description
              : "";
            values.keypad8Description = result.keypad8Description
              ? result.keypad8Description
              : "";
            values.keypad9Description = result.keypad9Description
              ? result.keypad9Description
              : "";

            setIsYesPositive(result.isYesAPositiveResponse);
            setQuestionType(result.questionTypeName);
            setDataValues(values);
          })
          .finally(() => {
            setAppLoading(false);
          });
      }
    }
  }, [questionId, entitiesLoaded]);

  useEffect(() => {
    if (!questionId) {
      resetForm();
    }
  }, [questionId]);

  const updateIndicatorFamily = (selectedObject: any) => {
    const indicatorId = selectedObject.id;
    const index = indicators.findIndex((x: any) => x.id === indicatorId);
    if (index !== -1) {
      const { indicatorFamilyId } = indicators[index];
      // Retrieves the parent indicator family
      const indicatorFamilyIndex = indicatorFamilies.findIndex(
        (x: any) => x.id === indicatorFamilyId,
      );
      const { label } = indicatorFamilies[indicatorFamilyIndex];
      // Sets the values of the text search and selected indicator family ID
      values.indicatorFamilyId = indicatorFamilyId;
      values.indicatorFamilyIdTextSearch = label;
    }
    setDataValues(values);
  };

  const updateIndicator = (selectedObject: any) => {
    values.indicatorId = "";
    values.indicatorIdTextSearch = "";
    setDataValues(values);
    const objectId = selectedObject.id;
    let indicatorOptions: any[] = [];
    API.get(API_URLS.GetAllIndicators, {
      params: {},
    }).then((response: any) => {
      indicatorOptions = response.data.data.map((x: any) => {
        return {
          id: x.indicatorId,
          label: x.indicatorLabel,
          indicatorFamilyId: x.indicatorFamilyId,
        };
      });
      let result = indicatorOptions;
      // Filters the sub-sector options by sector ID
      if (objectId !== "" && objectId !== undefined) {
        result = indicatorOptions.filter((x) => {
          return x.indicatorFamilyId === objectId;
        });
      }
      setIndicators(result);
    });
  };

  // When selecting an object from any autocomplete form field,
  // this method runs to update the values to be submitted to the API
  const handleAutoCompleteChange = (selectedObject: any, fieldName: string) => {
    if (selectedObject) {
      values[fieldName] = selectedObject ? selectedObject.id : "";
      if (obj) (obj as any)[fieldName] = "";
      setDataValues(values);
      setErrors(obj);

      if (fieldName === "questionTypeId") {
        const localQuestionType = questionTypes.find(
          (x: any) => x.id === values.questionTypeId,
        );
        setQuestionType(localQuestionType?.label);
        const formValues = initialFValues;
        formValues.questionTypeId = values.questionTypeId;
        formValues.questionTypeIdTextSearch = localQuestionType?.label;
        setDataValues(formValues);
        obj = {} as IQuestion;
        setErrors(obj);
      }

      if (fieldName === "indicatorFamilyId") updateIndicator(selectedObject);

      if (fieldName === "indicatorId") updateIndicatorFamily(selectedObject);
    }
  };

  // This method is required to update the text search field in the
  // autocomplete components. It is also used to force the field to be blank.
  const handleAutoCompleteTextInputChange = (text: any, field: string) => {
    const fieldName = `${field}TextSearch`;
    values[fieldName] = text;
    setDataValues(values);
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Prevents the form from submitting twice
    if (!submitted) {
      setSubmitted(true);
      setAppLoading(true);
      e.preventDefault();
      if (validate()) {
        setAppLoading(true);
        let apiurl = API_URLS.CreateQuestions;

        if (values.indicatorFamilyId === "") {
          values.indicatorFamilyId = indicatorFamilies.find(
            (x: any) => x.label === "Default",
          ).id;
        }

        if (values.indicatorId === "") {
          values.indicatorId = indicators.find(
            (x: any) => x.label === "Default",
          ).id;
        }

        if (values.isArchived === undefined) {
          values.isArchived = false;
        }

        values.IsYesAPositiveResponse = isYesPositive;

        let data = { ...values };
        if (questionId) {
          apiurl = API_URLS.UpdateQuestions;
          data = { ...values, questionId };
        }
        API.post(apiurl, { ...data })
          .then((response: any) => {
            SuccessToast(response?.data?.message);
            navigate(routePaths.QUESTIONS);
            setDataValues(initialFValues);
            resetForm();
          })
          .finally(() => {
            setAppLoading(false);
          });
      }
      setAppLoading(false);
    } else {
      navigate(routePaths.QUESTIONS);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view">
        <Grid container>
          <Grid item xs={12} sm={2} md={2} />
          <Grid item xs={12} sm={9} md={9}>
            <Grid container>
              <Grid item xs={12}>
                <div className="page-heading-container">
                  <Controls.BackButton />
                  <h2 className="page-heading">{pageHeading}</h2>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Controls.AutoComplete
                  id="questionTypeId"
                  name="questionTypeId"
                  label="Question type*"
                  value={values.questionTypeId}
                  inputValue={values.questionTypeIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  options={questionTypes}
                  varient="standard"
                  error={obj.questionTypeId}
                />
              </Grid>
              {values.questionTypeId &&
                values.questionTypeIdTextSearch === "Core" && (
                  <>
                    <Grid item xs={12} sm={12} md={12}>
                      <Controls.AutoComplete
                        id="indicatorFamilyId"
                        name="indicatorFamilyId"
                        label="Indicator family*"
                        value={values.indicatorFamilyId}
                        inputValue={values.indicatorFamilyIdTextSearch}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteTextInputChange}
                        options={indicatorFamilies}
                        varient="standard"
                        error={obj.indicatorFamilyId}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Controls.AutoComplete
                        id="indicatorId"
                        name="indicatorId"
                        label="Indicator*"
                        value={values.indicatorId}
                        inputValue={values.indicatorIdTextSearch}
                        onChange={handleAutoCompleteChange}
                        onInputChange={handleAutoCompleteTextInputChange}
                        varient="standard"
                        options={indicators}
                        error={obj.indicatorId}
                      />
                    </Grid>
                  </>
                )}

              <Grid item xs={12} sm={12} md={12}>
                <Controls.Input
                  id="label"
                  name="label"
                  label="Label*"
                  value={values.label}
                  onChange={handleInputChange}
                  error={obj.label}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Controls.Input
                  id="questionText"
                  name="questionText"
                  label="Question text*"
                  value={values.questionText}
                  onChange={handleInputChange}
                  error={obj.questionText}
                />
              </Grid>
              {values.questionTypeId && (
                <QuestionComponents
                  questionTypes={questionTypes}
                  questionTypeId={values.questionTypeId}
                  values={values}
                  errors={obj}
                  onChange={handleInputChange}
                  isYesPositive={isYesPositive}
                  setIsYesPositive={setIsYesPositive}
                />
              )}
            </Grid>

            <div className="form-buttons custom-floatRight">
              <br />
              <Controls.Button
                className="button blue"
                type="submit"
                text="Save"
                disabled={!values.questionTypeId}
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={() => {
                  navigate(routePaths.QUESTIONS);
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Form>
  );
}
