import { Grid } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DropzoneArea } from "material-ui-dropzone";
import Controls from "components/Controls";
import routePaths from "config/RoutePaths";
import { Form, useForm } from "hooks/useForm";
import { API_URLS } from "config/ApiUrls";
import API from "services/Api.Service";
import { SuccessToast } from "components/Toaster";
import { getDropDownOptions } from "util/CommonFunctions";
import ResourceModal from "util/ResourceModal";
import "./Style.css";

import { IResource } from "models/Interfaces/IResource";
import { useLoading } from "providers/loading";

const initialFValues: IResource = {
  resourceDescription: "",
  resourceTitle: "",
  blobUrl: null,
  resourceTypeId: "",
  resourceGroupId: "",
};

const obj = {} as IResource;

const CreateResource = () => {
  const { setAppLoading } = useLoading();
  const navigate = useNavigate();
  const [resourceTypes, setResourceTypes] = useState<any>([]);
  const [resourceGroups, setResourceGroups] = useState<any>([]);
  const [open, setModelOpen] = useState(false);

  const validate = (fieldValues = values) => {
    if ("resourceTypeId" in fieldValues)
      obj.resourceTypeId = fieldValues.resourceTypeId
        ? ""
        : "Resource Type is required.";
    if ("resourceGroupId" in fieldValues)
      obj.resourceGroupId = fieldValues.resourceGroupId
        ? ""
        : "Resource Group is required.";
    if ("resourceTitle" in fieldValues)
      obj.resourceTitle = fieldValues.resourceTitle
        ? ""
        : "Resource Title is required.";
    if ("resourceDescription" in fieldValues)
      obj.resourceDescription = fieldValues.resourceDescription
        ? ""
        : "Resource Description is required.";
    if ("blobUrl" in fieldValues)
      obj.blobUrl = fieldValues.blobUrl ? "" : "Resource file is required.";
    setErrors({
      ...obj,
    });
    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange, resetForm } = useForm(
    initialFValues,
    true,
    validate,
  );

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (validate()) {
      setModelOpen(true);
    }
  };

  const handleClose = (isConfirmed: boolean) => {
    if (isConfirmed) {
      // setAppLoading(true);

      const bodyFormData = new FormData();
      bodyFormData.append("ResourceTitle", values.resourceTitle);
      bodyFormData.append("ResourceDescription", values.resourceDescription);
      bodyFormData.append("ResourceTypeId", values.resourceTypeId);
      bodyFormData.append("ResourceGroupId", values.resourceGroupId);
      bodyFormData.append("BlobUrl", values.blobUrl ?? null);
      setModelOpen(false);
      setAppLoading(true);
      API.post(API_URLS.CreateResource, bodyFormData)
        .then((response: any) => {
          navigate(`${routePaths.RESOURCES}`);
          SuccessToast(response?.data?.message);
          resetForm();
        })
        .finally(() => {
          setAppLoading(false);
        });
    } else {
      setModelOpen(false);
    }
  };

  useEffect(() => {
    API.get(API_URLS.GetResourceTypes).then((response: any) => {
      setResourceTypes(
        getDropDownOptions(
          response?.data?.data,
          "resourceTypeId",
          "resourceTypeName",
        ),
      );
    });
  }, []);

  useEffect(() => {
    API.get(API_URLS.GetResourceGroups).then((response: any) => {
      setResourceGroups(
        getDropDownOptions(
          response?.data?.data,
          "resourceGroupId",
          "resourceGroupName",
        ),
      );
    });
  }, []);

  const onDrageChange = async (file: any) => {
    if (file.length > 0) {
      const url = file[0];
      values.blobUrl = url;
    } else {
      values.blobUrl = "";
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view invie-org-form">
        <Grid container>
          <Grid item xs={12} sm={3} md={3} />
          <Grid item xs={12} sm={6} md={6}>
            <Grid container>
              <Grid item xs={12} className="grid-custom-spacing">
                <div className="page-heading-container">
                  <Controls.BackButton />
                  <h2 className="page-heading">Add Resource</h2>
                </div>
              </Grid>
            </Grid>

            <Grid container className="custom-marginTop30">
              <Grid item xs={12} sm={12} md={12}>
                <div className="grid-custom-spacing">
                  <Controls.Select
                    id="resourceTypeId"
                    name="resourceTypeId"
                    label="Resource Type*"
                    value={values.resourceTypeId}
                    onChange={handleInputChange}
                    options={resourceTypes}
                    error={obj.resourceTypeId}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div className="grid-custom-spacing">
                  <Controls.Select
                    id="resourceGroupId"
                    name="resourceGroupId"
                    label="Resource Group*"
                    value={values.resourceGroupId}
                    onChange={handleInputChange}
                    options={resourceGroups}
                    error={obj.resourceGroupId}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div className="grid-custom-spacing">
                  <Controls.Input
                    id="resourceTitle"
                    name="resourceTitle"
                    label="Resource Title*"
                    value={values.resourceTitle}
                    onChange={handleInputChange}
                    error={obj.resourceTitle}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div className="grid-custom-spacing">
                  <Controls.Input
                    id="resourceDescription"
                    name="resourceDescription"
                    label="Resource Description*"
                    value={values.resourceDescription}
                    onChange={handleInputChange}
                    error={obj.resourceDescription}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div className="dropzone-area">
                  <DropzoneArea
                    acceptedFiles={["video/*", "application/pdf"]}
                    useChipsForPreview
                    previewGridProps={{
                      container: { spacing: 1, direction: "row" },
                    }}
                    maxFileSize={80000000}
                    filesLimit={1}
                    onChange={(file) => onDrageChange(file)}
                  />
                  {obj.blobUrl && (
                    <span className="error-message">{obj.blobUrl}</span>
                  )}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                {open && <ResourceModal handleClose={handleClose} />}
                <div className="form-buttons custom-floatRight">
                  <br />
                  <Controls.Button
                    className="button blue"
                    type="submit"
                    text="Save"
                  />
                  <Controls.Button
                    text="Cancel"
                    className="cancel-button"
                    variant="outlined"
                    onClick={() => {
                      navigate(`${routePaths.RESOURCES}`);
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3} md={3} />
        </Grid>
      </div>
    </Form>
  );
};

export default CreateResource;
