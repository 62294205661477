import { useEffect, useState } from "react";
import { API_URLS } from "config/ApiUrls";
import { SmallSpinner } from "components/Spinner";
import { IChartProps } from "models/Interfaces/IMapSite";
import API from "services/Api.Service";
import "./Style.css";

function SiteScore({ siteId, surveyTemplateTypeId, dataCycleId }: IChartProps) {
  const [loading, setLoading] = useState<any>(true);
  const [siteScore, setSiteScore] = useState<any>("");

  useEffect(() => {
    if (dataCycleId && dataCycleId !== "") {
      API.get(API_URLS.GetSiteScoreByDataCycleId, {
        params: {
          dataCycleId,
        },
      })
        .then((response: any) => {
          setSiteScore(response?.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [siteId, surveyTemplateTypeId, dataCycleId]);

  return (
    <div className="siteScoreContainer">
      {loading ? (
        <SmallSpinner />
      ) : (
        <div
          className={`siteScoreCircle ${
            siteScore === -2
              ? "rating scoreNoCompleted"
              : siteScore === -1
              ? "rating scoreNoParticipation"
              : siteScore >= 0 && siteScore <= 5
              ? "rating scoreLow"
              : siteScore > 5 && siteScore <= 7.5
              ? "rating scoreMedium"
              : "rating scoreHigh"
          }`}
        >
          {siteScore < 0 ? "N/A" : siteScore}
        </div>
      )}
    </div>
  );
}

export default SiteScore;
