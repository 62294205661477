import React, { createRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_URLS } from "config/ApiUrls";
import { UseTable } from "hooks/useTable";
import API from "services/Api.Service";
import { SuccessToast } from "components/Toaster";
import Controls from "components/Controls";
import { useLoading } from "providers/loading";

const tableRef = createRef<any>();
const OrganisationUsersTable = () => {
  const { setAppLoading } = useLoading();
  const [modal, setModal] = React.useState(false);
  const { organisationId } = useParams();
  const [orgUserToBeDeleted, setOrgUserToBeDeleted] = React.useState({
    userId: "",
    fullName: "",
  });

  const [organisationUsers, setOrganisationUsers] = React.useState<any[]>([]);
  // Using Auth to determine the user role
  // and what should be rendered on the page

  // const navigateToEditUser = (userId: string) => {
  //   navigate(`/organisations/users/${organisationId}/${userId}/edit`);
  // };
  const navigate = useNavigate();

  const navigateToEditUser = (usersId: string) => {
    navigate(`/organisations/${usersId}/${organisationId}/edit-user`);
  };
  const columns = [
    { title: "FULL NAME", field: "fullName" },
    { title: "ROLE NAME", field: "roleName" },
    {
      title: "MANAGE",
      field: "edit",
      sorting: false,
      render: (rowData: any) => (
        <div className="actions-container">
          <p onClick={() => navigateToEditUser(rowData?.userId)}>Edit</p>|
          <p
            className="actions-container-delete"
            onClick={() => toggleModal(rowData)}
          >
            Remove
          </p>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setAppLoading(true);
    // const userOrgIds = auth?.user?.data?.userOrganisations.map((x: any) => {
    //   return x.userRole === "Organisation Administrator" && x.organisationId;
    // });

    // if (userOrgIds) {
    //   userOrgIds.forEach((x: any) => {

    if (organisationId) {
      API.get(API_URLS.GetOrganisationUsersList, {
        params: {
          organisationId,
        },
      })
        .then((response: any) => {
          setOrganisationUsers(response.data.data);
        })
        .finally(() => {
          setAppLoading(false);
        });
    }

    //});
    //}
  }, []);

  const toggleModal = (rowData: any) => {
    setOrgUserToBeDeleted({
      userId: rowData.userId,
      fullName: rowData.fullName,
    });
    setModal(!modal);
  };

  const closeModal = () => {
    setModal(false);
  };

  const deleteOrganisationUser = () => {
    setAppLoading(true);
    API.delete(API_URLS.RemoveOrganisationsUser, {
      params: {
        OrganisationId: organisationId,
        UserId: orgUserToBeDeleted.userId,
      },
    })
      .then((res: any) => {
        const filteredData = organisationUsers.filter((x: any) => {
          return x.userId !== orgUserToBeDeleted?.userId;
        });
        setOrganisationUsers([...filteredData]);
        SuccessToast(res?.data?.message);
        setModal(false);
      })
      .finally(() => {
        setAppLoading(false);
      });
  };

  return (
    <div>
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay" />
          <div className="modal-content">
            <h2>Delete Business User</h2>
            <p>
              You are about to delete the following business user. Are you sure
              you want to proceed?
            </p>
            <p>Name: {orgUserToBeDeleted.fullName}</p>
            <br />
            <div className="form-buttons custom-floatRight">
              <Controls.Button
                className="button blue"
                onClick={deleteOrganisationUser}
                text="Delete"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={closeModal}
              />
            </div>
          </div>
        </div>
      )}
      <UseTable
        refTable={tableRef}
        columns={columns}
        data={organisationUsers}
        title="Business Users"
      />
    </div>
  );
};

export default OrganisationUsersTable;
