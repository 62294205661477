import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, InputAdornment } from "@material-ui/core";
import "./Style.css";
import { Form, useForm } from "hooks/useForm";
import Controls from "components/Controls";
import { ICreateOrg } from "models/Interfaces/IOrganisation";
import API from "services/Api.Service";
import { API_URLS } from "config/ApiUrls";
import { SuccessToast } from "components/Toaster";
import routePaths from "config/RoutePaths";
import { useLoading } from "providers/loading";
import { phoneNumber, validateEmail } from "../../../util/validations";

export default function CreateOrganisation() {
  const { setAppLoading } = useLoading();
  const navigate = useNavigate();
  // Pull ID of organisation from URL parameters
  // if no ID in parameters, it is a create organisation screen
  const { id } = useParams();
  const [sectors, setSectors] = useState([]);
  const [subSectors, setSubSectors] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const [channelPartners, setChannelPartners] = useState<any[]>([]);
  const [obj, setObj] = useState<any>({} as ICreateOrg);

  // Set heading of page according to whether the user
  // is creating or editing
  const pageHeading = id ? "Edit Business" : "Add Business";

  // Initial form values.
  // For the autocomplete elements we require a separate property
  // for the text search and the selected item(s).
  const initialFValues: ICreateOrg = {
    organisationName: "",
    isSedexClient: false,
    sedexClientCode: "",
    benchmarksClient: false,
    sectorId: "",
    sectorIdTextSearch: "",
    subSectorId: "",
    subSectorIdTextSearch: "",
    tags: [],
    tagsTextSearch: "",
    tagsError: "",
    channelPartner: "",
    channelPartnerTextSearch: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  };

  // useEffect loads all the options into the stateful variables declared above
  useEffect(() => {
    API.get(API_URLS.GetSectors, {
      params: {},
    }).then((response: any) => {
      const sectorOptions = response.data.data.map((x: any) => {
        return { id: x.sectorId, label: x.sectorSection };
      });
      setSectors(sectorOptions);
    });

    API.get(API_URLS.GetSubSectors, {
      params: {},
    }).then((response: any) => {
      const subSectorOptions = response.data.data.map((x: any) => {
        return {
          id: x.subSectorId,
          label: `${x.divisionLabel} - ${x.groupLabel} - ${x.description}`,
          sectorId: x.sectorId,
        };
      });
      setSubSectors(subSectorOptions);
    });

    API.get(API_URLS.GetTags, {
      params: {},
    }).then((response: any) => {
      const tagOptions = response.data.data.map((x: any) => {
        return { id: x.tagId, label: x.tagName };
      });
      setTags(tagOptions);
    });

    API.get(API_URLS.GetChannelPartners, {
      params: {},
    }).then((response: any) => {
      const partnerOptions = response.data.data.map((x: any) => {
        return { id: x.partnerId, label: x.partnerName };
      });
      setChannelPartners(partnerOptions);
    });

    return () => {
      // Remove all the field errors and reset the form
      setObj({} as ICreateOrg);
      resetForm();
    };
  }, []);

  // This useEffect checks whether ID is present in parameters
  // if so the foorm is prepopulated with the details of the organisation to be edited
  useEffect(() => {
    if (id) {
      setAppLoading(true);
      API.get(API_URLS.GetOrganisation, {
        params: {
          organisationId: id,
        },
      })
        .then((response: any) => {
          const result = response?.data?.data;
          values.organisationName = result.organisationName;
          values.sectorId = result.sectorId ? result.sectorId : "";
          values.sectorIdTextSearch = result.sectorName
            ? result.sectorName
            : "";
          values.subSectorId = result.subSectorId ? result.subSectorId : "";
          values.subSectorIdTextSearch = result.subSectorName
            ? result.subSectorName
            : "";
          values.channelPartner = result.channelPartnerId
            ? result.channelPartnerId
            : "";
          values.channelPartnerTextSearch = result.channelPartnerName
            ? result.channelPartnerName
            : "";
          values.isSedexClient = !!result.sedexClientCode;
          values.sedexClientCode = result.sedexClientCode
            ? result.sedexClientCode
            : "";
          let tagsToSet = [];
          if (result.tags) {
            tagsToSet = result.tags.map((x: any) => {
              return { id: x.tagsId, label: x.tagsName };
            });
          }
          setDataValues(values);
          handleMultipleSelectAutoCompleteChange(tagsToSet, "tags");
        })
        .finally(() => {
          setAppLoading(false);
        });
    } else {
      // If no ID in parameters, reset the form and remove field errors
      resetForm();
      setObj({} as ICreateOrg);
    }
  }, [id]);

  // Validation check for all required fields
  // Also checks the format of certain fields
  const validate = (fieldValues = values) => {
    if ("organisationName" in fieldValues)
      obj.organisationName = fieldValues.organisationName
        ? ""
        : "Business name is required.";
    if (fieldValues.isSedexClient) {
      if (
        fieldValues.sedexClientCode === "" ||
        fieldValues.sedexClientCode === null
      ) {
        obj.sedexClientCode = fieldValues.sedexClientCode
          ? ""
          : "Sedex client code is required.";
      } else if (
        fieldValues.sedexClientCode !== undefined ||
        fieldValues.sedexClientCode !== null
      ) {
        obj.sedexClientCode =
          fieldValues.sedexClientCode.length <= 12 &&
          fieldValues.sedexClientCode.length >= 8
            ? ""
            : "Please enter between 8 and 12 digits.";
      }
    }
    if (!id) {
      if ("firstName" in fieldValues)
        obj.firstName = fieldValues.firstName ? "" : "First name is required.";

      if ("lastName" in fieldValues)
        obj.lastName = fieldValues.lastName ? "" : "Last name is required.";
      if ("email" in fieldValues)
        obj.email = fieldValues.email ? "" : "Email address is required.";
      // if ("phoneNumber" in fieldValues)
      //   obj.phoneNumber = fieldValues.phoneNumber
      //     ? ""
      //     : "Phone number is required.";
    }
    if ("sectorId" in fieldValues)
      obj.sectorId =
        fieldValues.sectorId.length !== 0 ? "" : "Sector is required.";
    if ("subSectorId" in fieldValues)
      obj.subSectorId =
        fieldValues.subSectorId.length !== 0 ? "" : "Sub-sector is required.";
    if ("channelPartner" in fieldValues)
      obj.channelPartner =
        fieldValues.channelPartner.length !== 0
          ? ""
          : "Channel partner is required.";
    if ("tags" in fieldValues)
      obj.tagsError = fieldValues.tags.length !== 0 ? "" : "Tags are required.";

    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  // useForm returns useful form components.
  // The 'true' being passed as second variable to the function is used
  // to specify that the form should run the validate function after every update
  const { values, setErrors, handleInputChange, resetForm, setDataValues } =
    useForm(initialFValues, true, validate);

  // This method updates the selected sector according to
  // which sub-sector you select.
  const updateSector = (selectedObject: any) => {
    const subSectorId = selectedObject.id;
    const index = subSectors.findIndex((x: any) => x.id === subSectorId);
    if (index !== -1) {
      const { sectorId } = subSectors[index];
      // Retrieves the parent sector
      const sectorIndex = sectors.findIndex((x: any) => x.id === sectorId);
      const { label } = sectors[sectorIndex];
      // Sets the values of the text search and selected sector ID
      values.sectorId = sectorId;
      values.sectorIdTextSearch = label;
    }
    setDataValues(values);
  };

  // This method filters the available sub-sector options according to which sector you select.
  const updateSubSector = (selectedObject: any) => {
    values.subSectorId = "";
    values.subSectorIdTextSearch = "";
    setDataValues(values);
    const objectId = selectedObject.id;
    let subSectorOptions: any[] = [];
    API.get(API_URLS.GetSubSectors, {
      params: {},
    }).then((response: any) => {
      subSectorOptions = response.data.data.map((x: any) => {
        return {
          id: x.subSectorId,
          label: `${x.divisionLabel} - ${x.groupLabel} - ${x.description}`,
          sectorId: x.sectorId,
        };
      });
      let result = subSectorOptions;
      // Filters the sub-sector options by sector ID
      if (objectId !== "" && objectId !== undefined) {
        result = subSectorOptions.filter((x) => {
          return x.sectorId === objectId;
        });
      }
      setSubSectors(result);
    });
  };

  // Runs whenever a user clicks the 'Sedex Client' checkbox
  // to hide and reveal the sedex client code form field.
  const updateSedexCode = () => {
    if (!values.isSedexClient) {
      values.sedexClientCode = null;
    }
  };

  // When selecting an object from any autocomplete form field,
  // this method runs to update the values to be submitted to the API
  const handleAutoCompleteChange = (selectedObject: any, fieldName: string) => {
    values[fieldName] = selectedObject ? selectedObject.id : "";
    if (obj) (obj as any)[fieldName] = "";
    setDataValues(values);
    setErrors(obj);

    if (fieldName === "sectorId") updateSubSector(selectedObject);

    if (fieldName === "subSectorId") updateSector(selectedObject);
  };

  // When selecting an object from any multi-select autocomplete form field,
  // this method runs to update the values to be submitted to the API
  const handleMultipleSelectAutoCompleteChange = (
    selectedObject: any,
    fieldName: string,
  ) => {
    if (selectedObject.length === 0) {
      values[fieldName] = [];
    } else {
      values[fieldName] = selectedObject;
    }
    if (obj) (obj as any)[fieldName] = "";
    setDataValues(values);
    setErrors(obj);
  };

  // This method is required to update the text search field in the
  // autocomplete components. It is also used to force the field to be blank.
  const handleAutoCompleteTextInputChange = (text: any, field: string) => {
    const fieldName = `${field}TextSearch`;
    values[fieldName] = text;
    setDataValues(values);
  };

  // The submit function which calls the API in order to save the organisation
  const handleSubmit = (e: any) => {
    e.preventDefault();
    updateSedexCode();
    const url = id ? API_URLS.EditOrganisation : API_URLS.CreateOrganisation;
    if (
      validate() &&
      (id ||
        ((values.phoneNumber === "" || phoneNumber(values, obj, setErrors)) &&
          validateEmail(values, obj, setErrors)))
    ) {
      setAppLoading(true);
      API.post(url, {
        OrganisationId: id,
        OrganisationName: values.organisationName,
        SedexClientCode: values.sedexClientCode,
        SectorId: values.sectorId,
        SubSectorId: values.subSectorId,
        ChannelPartnerId: values.channelPartner,
        TagsId: values.tags.map((x: any) => {
          return x.id;
        }),
        Email: values.email,
        FirstName: values.firstName,
        LastName: values.lastName,
        PhoneNumber: values.phoneNumber,
      })
        .then((res: any) => {
          // Inform the user the organisation was saved successfully
          SuccessToast(res?.data?.message);
          // Reset the form and error values
          setObj({} as ICreateOrg);
          resetForm();
          // Navigate to organisations screen
          navigate(routePaths.ORGANISATIONS);
        })
        .finally(() => {
          setAppLoading(false);
        });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view">
        <Grid container>
          <Grid item xs={12} sm={2} md={2} />
          <Grid item xs={12} sm={8} md={8}>
            <Grid container>
              <Grid item xs={12}>
                <div className="page-heading-container">
                  <Controls.BackButton />
                  <h2 className="page-heading">{pageHeading}</h2>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Controls.Input
                  id="organisationName"
                  name="organisationName"
                  label="Business name*"
                  value={values.organisationName}
                  onChange={handleInputChange}
                  error={obj.organisationName}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Controls.CheckBox
                  id="isSedexClient"
                  label="Sedex member"
                  name="isSedexClient"
                  value={values.isSedexClient}
                  onChange={handleInputChange}
                  error={obj.isSedexClient}
                  checked={values.isSedexClient}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={4}>
                <Controls.CheckBox
                  id="benchmarksClient"
                  name="benchmarksClient"
                  label="Benchmarks client"
                  value={values.benchmarksClient}
                  onChange={handleInputChange}
                  error={obj.benchmarksClient}
                  checked={values.benchmarksClient}
                />
              </Grid> */}
              {values.isSedexClient && (
                <Grid item xs={12}>
                  <Controls.Input
                    id="sedexClientCode"
                    name="sedexClientCode"
                    label="Sedex client code*"
                    value={values.sedexClientCode}
                    onChange={handleInputChange}
                    error={obj.sedexClientCode}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">ZC</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Controls.AutoComplete
                  id="sectorId"
                  name="sectorId"
                  label="Sector*"
                  inputValue={values.sectorIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  varient="standard"
                  options={sectors}
                  error={obj.sectorId}
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.AutoComplete
                  id="subSectorId"
                  name="subSectorId"
                  label="Sub-sector*"
                  inputValue={values.subSectorIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  varient="standard"
                  options={subSectors}
                  error={obj.subSectorId}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Controls.AutoComplete
                  id="tags"
                  name="tags"
                  label="Tags*"
                  multiple={true}
                  values={values.tags}
                  onChange={handleMultipleSelectAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  options={tags}
                  error={obj.tagsError}
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.AutoComplete
                  id="channelPartner"
                  name="channelPartner"
                  label="Channel partner*"
                  inputValue={values.channelPartnerTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  varient="standard"
                  options={channelPartners}
                  error={obj.channelPartner}
                />
              </Grid>
            </Grid>
            {!id && (
              <Grid container>
                <Grid item xs={12}>
                  <h2 className="page-heading">Invite Admin User</h2>
                </Grid>
                <Grid item xs={12}>
                  <Controls.Input
                    id="email"
                    name="email"
                    label="Email address*"
                    value={values.email}
                    onChange={handleInputChange}
                    error={obj.email}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Controls.Input
                    id="firstName"
                    name="firstName"
                    label="First name*"
                    value={values.firstName}
                    onChange={handleInputChange}
                    error={obj.firstName}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Controls.Input
                    id="lastName"
                    name="lastName"
                    label="Last name*"
                    value={values.lastName}
                    onChange={handleInputChange}
                    error={obj.lastName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controls.Input
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Phone number"
                    value={values.phoneNumber}
                    onChange={handleInputChange}
                    error={obj.phoneNumber}
                  />
                </Grid>
              </Grid>
            )}
            <div className="form-buttons custom-floatRight">
              <br />
              <Controls.Button
                className="button blue"
                type="submit"
                text="Save"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={() => {
                  navigate(routePaths.ORGANISATIONS);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={2} md={2} />
        </Grid>
      </div>
    </Form>
  );
}
