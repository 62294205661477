import { useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import SiteSelect from "../SiteSelect";
import "./Style.css";

function MapSites({
  organisationSiteIds,
  timeClicked,
  handleMultipleSelectAutoCompleteChange,
  handleAutoCompleteTextInputChange,
  hideAndShowModal,
  selectedSiteIds,
}: any) {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="mapSitesBox">
      <div
        className={`mapSitesBoxBanner ${
          expanded ? "mapSitesBoxBannerBorder" : ""
        }`}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ display: "inline-block" }}>Choose workplaces</p>
        </div>
        <div className="mapSitesBoxIcons">
          <div
            className="contentExpansion"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <div className={expanded ? "hiddenIcon" : "displayedIcon"}>
              <FaPlus />
            </div>
            <div className={expanded ? "displayedIcon" : "hiddenIcon"}>
              <FaMinus />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`mapSitesBoxContent ${expanded ? "visible" : "invisible"}`}
      >
        <div className="siteSelectMap">
          <SiteSelect
            id="sites"
            name="sites"
            label="Select workplaces*"
            multiple={true}
            timeClicked={timeClicked}
            onChange={handleMultipleSelectAutoCompleteChange}
            onInputChange={handleAutoCompleteTextInputChange}
            options={organisationSiteIds}
            values={selectedSiteIds}
          />
          <div className="mapDownloadButton" onClick={hideAndShowModal}>
            <p>Download workplace summaries</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapSites;
