import { Grid } from "@material-ui/core";
import Controls from "components/Controls";

const AntiCoaching = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Controls.Input
          id="antiCoachingYes"
          name="antiCoachingYes"
          label="Yes"
          value="Negative"
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <Controls.Input
          id="antiCoachingNo"
          name="antiCoachingNo"
          label="No"
          value="Positive"
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <Controls.Input
          id="antiCoachingDontKnow"
          name="antiCoachingDontKnow"
          label="Don't know"
          value="Neutral"
          disabled
        />
      </Grid>
    </Grid>
  );
};

export default AntiCoaching;
