import { useState } from "react";
import Controls from "components/Controls";
import { IManagePassword } from "models/Interfaces/IProfile";
import { Form, useForm } from "hooks/useForm";
import {
  validateEmail,
  validatePassword,
  validatePasswords,
} from "util/validations";
import API from "services/Api.Service";
import { API_URLS } from "config/ApiUrls";
import { SuccessToast } from "components/Toaster";
import { useLoading } from "providers/loading";

export const ChangePasswordForm = () => {
  const { setAppLoading } = useLoading();
  const [obj] = useState<any>({} as IManagePassword);

  const initialPasswordValues: IManagePassword = {
    currentPassword: "",
    password: "",
    passwordConfirm: "",
  };

  const validateUserPassword = (fieldValues = values) => {
    if ("currentPassword" in fieldValues)
      obj.currentPassword = fieldValues.currentPassword
        ? ""
        : "Current Password is required.";
    if ("password" in fieldValues)
      obj.password = fieldValues.password ? "" : "New Password is required.";
    if ("passwordConfirm" in fieldValues)
      obj.passwordConfirm = fieldValues.passwordConfirm
        ? ""
        : "New Password confirmation is required.";
    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange, resetForm } = useForm(
    initialPasswordValues,
    true,
    validateUserPassword,
  );

  const handleSubmitUserPassword = (e: any) => {
    e.preventDefault();
    if (
      validateUserPassword() &&
      validateEmail(values, obj, setErrors) &&
      validatePassword(values, obj, setErrors) &&
      validatePasswords(values, obj, setErrors)
    ) {
      setAppLoading(true);
      API.post(API_URLS.UpdatePassword, {
        currentPassword: values.currentPassword,
        newPassword: values.password,
        confirmPassword: values.passwordConfirm,
      })
        .then((response: any) => {
          SuccessToast(response?.data?.message);
          resetForm();
        })
        .finally(() => {
          setAppLoading(false);
        });
    }
  };

  return (
    <Form onSubmit={handleSubmitUserPassword}>
      <Controls.PasswordInput
        id="currentPassword"
        name="currentPassword"
        label="Current Password"
        value={values.currentPassword}
        onChange={handleInputChange}
        error={obj.currentPassword}
        cName="text-input"
      />
      <Controls.PasswordInput
        id="password"
        name="password"
        label="New Password"
        value={values.password}
        onChange={handleInputChange}
        error={obj.password}
        cName="text-input"
      />
      <Controls.PasswordInput
        id="passwordConfirm"
        name="passwordConfirm"
        label="Confirm New Password"
        value={values.passwordConfirm}
        onChange={handleInputChange}
        error={obj.passwordConfirm}
        cName="text-input"
      />
      <br />
      <br />
      <div>
        <Controls.Button
          className="button blue"
          type="submit"
          text="Change Password"
        />
      </div>
    </Form>
  );
};
