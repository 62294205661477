import React, { createRef, useEffect } from "react";
import { API_URLS } from "config/ApiUrls";
import { UseTable } from "hooks/useTable";
import API from "services/Api.Service";
import { useLoading } from "providers/loading";

import "./Style.css";
import moment from "moment";

const tableRef = createRef<any>();
const SubSectorsTable = () => {
  const { setAppLoading } = useLoading();
  const [subSectors, setSubSectors] = React.useState<any[]>([]);

  useEffect(() => {
    setAppLoading(true);
    API.get(API_URLS.GetSubSectors, {})
      .then((response: any) => {
        setSubSectors(response.data.data);
      })
      .finally(() => setAppLoading(false));
  }, []);

  const columns = [
    { title: "SECTOR", field: "sectionLabel" },
    { title: "SUBSECTOR", field: "divisionLabel" },
    { title: "GROUP", field: "groupLabel" },
    { title: "DESCRIPTION", field: "description" },
    {
      title: "CREATED ON",
      field: "createdOn",
      render: (rowData: any) => (
        <div className="createdOn">
          {moment(rowData.startDate).format("DD/MM/YYYY")}
        </div>
      ),
    },
  ];

  return (
    <div>
      <UseTable
        refTable={tableRef}
        columns={columns}
        data={subSectors}
        title="SubSectors"
      />
    </div>
  );
};

export default SubSectorsTable;
