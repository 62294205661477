import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "lib/auth";
import "./Dropdown.css";
import routePaths from "config/RoutePaths";
import { IAdminNavItem, INavItem } from "../../models/Interfaces/INavBar";

function Dropdown({ submenu }: IAdminNavItem) {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const auth = useAuth();

  return (
    <ul
      onClick={handleClick}
      className={click ? "dropdown-menu clicked" : "dropdown-menu"}
    >
      {submenu?.map((item: INavItem, index: number) => {
        const isSignOut = item.path === routePaths.SIGNOUT;
        return (
          <li key={index}>
            <Link
              className="dropdown-link"
              to={isSignOut ? "" : item.path}
              onClick={isSignOut ? () => auth.logout() : () => setClick(false)}
            >
              {item.title}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

export default Dropdown;
