export enum ReportingTypeEnum {
  Priorities = "Priorities",
  Progress = "Progress",
  Result = "Results",
  Participation = "Participation",
  Improvements = "Improvements",
  Test = "Test",
}

export enum ParticipantEnum {
  BarChart = "BarChart",
  PieChart = "PieChart",
}

export enum ProgressDirections {
  Up = "UP",
  Down = "DOWN",
  NoChange = "NO CHANGE",
}

export enum StatusBadges {
  Listening = "Listening",
  Responding = "Responding",
  Improving = "Improving",
  None = "None",
}

export enum MapPinColorsEnum {
  Positive = "#80ba3c",
  Negative = "#d81d40",
  Amber = "#ffad00",
  NoResults = "#423f3f",
}
