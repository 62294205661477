// create a helper function to check if the token is expired
export const isTokenExpired = (token: string) => {
  try {
    const decoded: any = parseJwt(token);
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired.
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};
