import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Controls from "components/Controls";
import routePaths from "config/RoutePaths";
import API from "services/Api.Service";
import { API_URLS } from "config/ApiUrls";
import { useLoading } from "providers/loading";
import "./Style.css";

function ViewPhoneNumbers() {
  const { setAppLoading } = useLoading();
  const navigate = useNavigate();
  const params = useParams();
  const { organisationSiteId } = params;

  const [phoneNumbers, setPhoneNumbers] = useState<any[]>([]);

  useEffect(() => {
    setAppLoading(true);
    API.get(API_URLS.GetWorkersPhoneNumber, {
      params: {
        organisationSiteId,
      },
    })
      .then((response: any) => {
        setPhoneNumbers(response.data.phoneNumbers);
      })
      .finally(() => {
        setAppLoading(false);
      });
  }, [organisationSiteId]);

  return (
    <div className="container-view">
      <Grid container>
        <Grid item xs={12} sm={3} />
        <Grid item xs={12} sm={6}>
          {phoneNumbers.length === 0 ? (
            <p style={{ textAlign: "center" }}>No phone numbers available.</p>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <table>
                <style>
                  {`
                    table {
                      border-collapse: collapse;
                      width: 100%;
                    }
        
                    th, td {
                      border: 1px solid #e0e0e0;
                      padding: 8px;
                      text-align: left;
                    }
                  `}
                </style>
                <thead>
                  <tr>
                    <th>Workers</th>
                    <th>Phone Numbers</th>
                  </tr>
                </thead>
                <tbody>
                  {phoneNumbers.map((x: any, index: number) => (
                    <tr key={index}>
                      <td>Worker {index}</td>{" "}
                      {/* Display "Worker" followed by index */}
                      <td>{x.phoneNumber}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div style={{ textAlign: "right", width: "100%" }}>
            <div className="form-buttons custom-floatRight">
              <br />
              <Controls.Button
                text="CLOSE"
                className="cancel-button"
                variant="outlined"
                onClick={() => {
                  navigate(routePaths.SITEMANAGEMENT);
                }}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={3} />
      </Grid>
    </div>
  );
}

export default ViewPhoneNumbers;
