import { RolesEnum } from "models/Enum/Roles";
import { useAuth } from "lib/auth";
import { useNavigate } from "react-router-dom";
import ClientDashboard from "../ClientDashboard";
import AndWiderDashboard from "../AndWiderDashboard";
import EmptyDashboard from "../EmptyDashboard";

const Dashboard = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const returnUserDashboard = () => {
    if (!auth?.user?.data?.roles) {
      return <div />;
    }

    if (
      auth?.user?.data?.roles?.find((role: any) => role === RolesEnum.Admin)
    ) {
      return <AndWiderDashboard />;
    }
    if (
      auth?.user?.data?.roles?.find(
        (role: any) =>
          role === RolesEnum.OrgAdmin ||
          role === RolesEnum.OrgGeneral ||
          role === RolesEnum.OrgGuest,
      )
    ) {
      if (auth?.user?.data?.hideMap === true) {
        const organisationIds = auth?.user?.data?.userOrganisations.map(
          (org: any) => {
            return org.organisationId;
          },
        );

        navigate(`reports/list`, {
          state: {
            clientId: null,
            clientName: null,
            clientOrgIds: organisationIds,
            organisationId: "all",
            orgName: "All Businesses",
          },
        });
      } else {
        return <ClientDashboard />;
      }
    }
    return <EmptyDashboard />;
  };
  return <div id="dashboardContainer">{returnUserDashboard()}</div>;
};

export default Dashboard;
