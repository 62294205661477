import { Grid } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Controls from "components/Controls";
import { IOrganisationUser } from "models/Interfaces/IOrganisationUser";
import { Form, useForm } from "hooks/useForm";
import { API_URLS } from "config/ApiUrls";
import API from "services/Api.Service";
import { ErrorToast, SuccessToast } from "components/Toaster";
import "./Style.css";
import { UseTable } from "hooks/useTable";
import { useLoading } from "providers/loading";
import { useAuth } from "lib/auth";
import { RolesEnum } from "models/Enum/Roles";
import { phoneNumber, validateEmail } from "util/validations";

const InviteUser = () => {
  const { setAppLoading } = useLoading();
  const navigate = useNavigate();
  const [organisationSites, setOrganisationSites] = useState<any>([]);
  const [roles, setRoles] = useState<any>([]);
  const [obj, setObj] = useState<any>({} as IOrganisationUser);
  const { usersId } = useParams();

  // Using Auth to determine the user role
  // and what should be rendered on the page
  const auth = useAuth();
  const isAdmin = auth?.user?.data?.roles?.includes(RolesEnum.Admin);

  const initialFValues: IOrganisationUser = {
    organisationId: "",
    organisationIdTextSearch: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    physicalAddress: "",
    organisationSiteIds: [],
    roleId: "",
    roleIdTextSearch: "",
  };

  const pageHeading = usersId ? "Edit Business User" : "Invite Business User";

  const columns = [
    { title: "WORKPLACE NAME", field: "organisationSiteName" },
    {
      title: "WORKPLACE VISIBILITY",
      field: "isOrganisationSiteIdChecked",
      sorting: false,
      type: "checkbox",
      render: (rowData: any) => (
        <div>
          <Controls.CheckBox
            id={rowData.organisationSiteId}
            label=""
            name="organisationSite"
            onChange={(e) => selectOrganisationSiteId(e, rowData)}
            checked={rowData.isOrganisationSiteIdChecked}
            value={rowData.isOrganisationSiteIdChecked}
          />
        </div>
      ),
    },
  ];

  const validate = (fieldValues = values) => {
    if ("email" in fieldValues)
      obj.email = fieldValues.email ? "" : "Email address is required.";
    // if ("phoneNumber" in fieldValues)
    //   obj.phoneNumber = fieldValues.phoneNumber
    //     ? ""
    //     : "Phone number is required.";
    if ("firstName" in fieldValues)
      obj.firstName = fieldValues.firstName ? "" : "First name is required.";
    if ("lastName" in fieldValues)
      obj.lastName = fieldValues.lastName ? "" : "Last name is required.";
    if ("roleId" in fieldValues)
      obj.roleId = fieldValues.roleId ? "" : "Role is required.";
    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, setDataValues, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  // Sets the default organisation value according to the
  // current user
  if (!isAdmin) {
    values.organisationId = auth?.user?.data?.userOrganisations.find(
      (organisation: any) => {
        return organisation.userRole === RolesEnum.OrgAdmin;
      },
    ).organisationId;
  }

  // This useEffect retrieves the sites for an organisation,
  // and marks them as checked if the user is an administrator or the user has access to them
  useEffect(() => {
    if (
      values.organisationId &&
      (values.organisationSiteIds.length > 0 || !usersId)
    ) {
      API.get(API_URLS.GetOrganisationSitesForReportsScreen, {
        params: {
          organisationId: values.organisationId,
          surveyTemplateTypeId: null,
        },
      }).then((response: any) => {
        const siteData = response?.data?.data.map((site: any) => {
          let objValue = {};
          if (
            values.organisationSiteIds.includes(site.organisationSiteId) ||
            values.isAdministrator
          )
            objValue = { ...site, isOrganisationSiteIdChecked: true };
          else objValue = { ...site, isOrganisationSiteIdChecked: false };
          return objValue;
        });
        const setData = siteData;
        setOrganisationSites(setData);
      });
    }
  }, [values.organisationId, values.organisationSiteIds.length]);

  // This useEffect is used to get the potential roles for the user
  useEffect(() => {
    API.get(API_URLS.GetClientRoles, {}).then((response: any) => {
      const roleOptions = response.data.data.map((x: any) => {
        return {
          id: x.roleId,
          label: x.name,
        };
      });
      setRoles(roleOptions);
    });

    return () => {
      // Remove all the field errors and reset the form
      setObj({} as IOrganisationUser);
      resetForm();
    };
  }, []);

  // This useEffect is used to set the values of the form
  // when the user is editing a user
  useEffect(() => {
    if (
      usersId &&
      usersId !== "" &&
      values.organisationId &&
      values.organisationId !== ""
    ) {
      setAppLoading(true);
      API.get(API_URLS.GetOrganisationUser, {
        params: {
          OrganisationId: values.organisationId,
          UserId: usersId,
        },
      })
        .then((response: any) => {
          const result = response?.data;
          values.organisationId = result.organisationId;
          values.organisationIdTextSearch = result.organisationName;
          values.firstName = result.firstName;
          values.lastName = result.lastName;
          values.email = result.email;
          values.phoneNumber = result.phoneNumber;
          values.physicalAddress = result.physicalAddress;
          values.roleId = result.roleId;
          values.roleIdTextSearch = result.roleName;
          values.organisationSiteIds = result.organisationSiteIds;
          setDataValues(values);
        })
        .finally(() => {
          setAppLoading(false);
        });
    } else {
      // If no ID in parameters, reset the form and remove field errors
      resetForm();
      setObj({} as IOrganisationUser);
    }
  }, [usersId, values.organisationId]);

  // This function runs when granting access to a workplace for a user
  const selectOrganisationSiteId = (event: any, rowData: any) => {
    if (!values.isAdministrator) {
      values.organisationSiteIds = [];
      setOrganisationSites((prevState: any[]) => {
        const newState = prevState.map((orgSite) => {
          if (orgSite.organisationSiteId === rowData.organisationSiteId) {
            return {
              ...orgSite,
              isOrganisationSiteIdChecked: event.target.value,
            };
          }
          return orgSite;
        });
        return newState;
      });
    }
  };

  // This function runs when selecting a role for the user
  // If the role is OrgAdmin, all the sites are selected by default
  const selectRole = (e: boolean) => {
    const neworganisationSites = organisationSites.map((item: any[]) => {
      return { ...item, isOrganisationSiteIdChecked: e };
    });
    setOrganisationSites(neworganisationSites);
  };

  // Method to submit the form
  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    values.organisationSiteIds = [];
    if (values.roleName !== RolesEnum.OrgAdmin) {
      for (let i = 0; i < organisationSites.length; i += 1) {
        if (organisationSites[i].isOrganisationSiteIdChecked) {
          values.organisationSiteIds.push(
            organisationSites[i].organisationSiteId,
          );
        }
      }
    }
    if (
      validate() &&
      (values.phoneNumber === "" || phoneNumber(values, obj, setErrors)) &&
      validateEmail(values, obj, setErrors)
    ) {
      if (
        values.roleName !== RolesEnum.OrgAdmin &&
        values.organisationSiteIds.length < 1
      ) {
        ErrorToast("Please select at least one workplace name");
        return;
      }
      const apiurl = usersId
        ? API_URLS.UpdateOrganisationUser
        : API_URLS.InviteOrganisationUser;
      setAppLoading(true);
      API.post(apiurl, {
        ...values,
        userId: usersId,
        roleName: values.roleIdTextSearch,
      })
        .then((response: any) => {
          SuccessToast(response?.data?.message);
          setObj({} as IOrganisationUser);
          resetForm();
          navigate(`/admin`);
        })
        .finally(() => {
          setAppLoading(false);
        });
    }
  };

  // When selecting an object from any autocomplete form field,
  // this method runs to update the values to be submitted to the API
  const handleAutoCompleteChange = (selectedObject: any, fieldName: string) => {
    values[fieldName] = selectedObject ? selectedObject.id : "";
    if (obj) (obj as any)[fieldName] = "";
    setDataValues(values);
    setErrors(obj);

    selectRole(selectedObject.label === RolesEnum.OrgAdmin);
  };

  // This method is required to update the text search field in the
  // autocomplete components. It is also used to force the field to be blank.
  const handleAutoCompleteTextInputChange = (text: any, field: string) => {
    const fieldName = `${field}TextSearch`;
    values[fieldName] = text;
    setDataValues(values);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view invie-org-form">
        <Grid container>
          <Grid item xs={12} sm={2} md={2} />
          <Grid item xs={12} sm={9}>
            <Grid container>
              <Grid item xs={12}>
                <div className="page-heading-container">
                  <Controls.BackButton />
                  <h2 className="page-heading">{pageHeading}</h2>
                </div>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={12}>
                <Controls.Input
                  id="email"
                  name="email"
                  label="Email address*"
                  value={values.email}
                  onChange={handleInputChange}
                  error={obj.email}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Controls.Input
                  id="firstName"
                  name="firstName"
                  label="First name*"
                  value={values.firstName}
                  onChange={handleInputChange}
                  error={obj.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controls.Input
                  id="lastName"
                  name="lastName"
                  label="Last name*"
                  value={values.lastName}
                  onChange={handleInputChange}
                  error={obj.lastName}
                />
              </Grid>

              <Grid item xs={12}>
                <Controls.Input
                  id="phoneNumber"
                  label="Phone number"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  onChange={handleInputChange}
                  error={obj.phoneNumber}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Controls.AutoComplete
                  id="roleId"
                  name="roleId"
                  label="Role*"
                  inputValue={values.roleIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  options={roles}
                  error={obj.roleId}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <div className="site-table">
                  <br />
                  <UseTable
                    columns={columns}
                    data={organisationSites}
                    title="Organisation Sites"
                  />
                </div>
                <div className="form-buttons custom-floatRight">
                  <br />
                  <Controls.Button
                    className="button blue"
                    type="submit"
                    text="Save"
                  />
                  <Controls.Button
                    text="Cancel"
                    className="cancel-button"
                    variant="outlined"
                    // onClick={() => organisationUsersById()}
                    onClick={() => navigate(-1)}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Form>
  );
};

export default InviteUser;
