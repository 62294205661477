import { StatusBadges } from "models/Enum/EnumTypes";
import { IImprovements } from "models/Interfaces/IReports";
import { StatusBadgeImagesEnum as StatusBadgeImages } from "util/Images";
import "./Style.css";

function Improvements({ setStatusBadge, renderDetails }: IImprovements) {
  const enterHover = (e: any, status: StatusBadges) => {
    e.preventDefault();
    setStatusBadge(status);
  };

  const exitHover = (e: any) => {
    e.preventDefault();
    setStatusBadge(StatusBadges.None);
  };

  return (
    <>
      <div className="statusBadgeMessage">
        {/* {!renderDetails.none && <h2>Congratulations! You have received a badge!</h2>} */}
      </div>
      <div className="statusBadgeTray">
        <div
          className="statusBadgeImageContainer"
          onMouseEnter={(e) => enterHover(e, StatusBadges.Listening)}
          onMouseLeave={exitHover}
        >
          <img
            src={StatusBadgeImages.Listening}
            alt="Listening Badge"
            className={`statusBadgeImage ${
              renderDetails.listening ? "" : "notQualified"
            }`}
          />
        </div>
        <div
          className="statusBadgeImageContainer"
          onMouseEnter={(e) => enterHover(e, StatusBadges.Responding)}
          onMouseLeave={exitHover}
        >
          <img
            src={StatusBadgeImages.Responding}
            alt="Responding Badge"
            className={`statusBadgeImage ${
              renderDetails.responding ? "" : "notQualified"
            }`}
          />
        </div>
        <div
          className="statusBadgeImageContainer"
          onMouseEnter={(e) => enterHover(e, StatusBadges.Improving)}
          onMouseLeave={exitHover}
        >
          <img
            src={StatusBadgeImages.Improving}
            alt="Improving Badge"
            className={`statusBadgeImage ${
              renderDetails.improving ? "" : "notQualified"
            }`}
          />
        </div>
      </div>
    </>
  );
}

export default Improvements;
