import { Grid } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import "./Style.css";
import { useEffect, useState } from "react";
import routePaths from "config/RoutePaths";
import Controls from "components/Controls";
import API from "services/Api.Service";
import { API_URLS } from "config/ApiUrls";
import { useLoading } from "providers/loading";

export default function ViewSurveyTemplate() {
  const { setAppLoading } = useLoading();
  const [surveyTemplateQuestions, setSurveyTemplateQuestions] = useState<any[]>(
    [],
  );
  const [surveyTemplate, setSurveyTemplate] = useState({
    surveyTemplateName: "",
    surveyTemplateType: "",
  });

  const navigate = useNavigate();
  const params = useParams();
  const { surveyTemplateId } = params;

  useEffect(() => {
    setAppLoading(true);
    API.get(API_URLS.GetSurveyTemplate, {
      params: {
        surveyTemplateId,
      },
    })
      .then((response: any) => {
        const survey = {
          surveyTemplateName: response.data.surveyTemplateName,
          surveyTemplateType: response.data.surveyTemplateTypeName,
        };

        setSurveyTemplate(survey);

        setSurveyTemplateQuestions(response.data.questions);
      })
      .finally(() => {
        setAppLoading(false);
      });
  }, [surveyTemplateId]);

  return (
    <div className="container-view">
      <Grid container>
        <Grid item xs={12} sm={2} md={2} />
        <Grid item xs={10} sm={8} md={8}>
          <div className="page-heading-container">
            <Controls.BackButton />
          </div>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
              <h3>Survey Template Name</h3>
              <p>{surveyTemplate.surveyTemplateName}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
              <h3>Survey Template Type</h3>
              <p>{surveyTemplate.surveyTemplateType}</p>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className="grid-custom-spacing">
              <h3>Questions & survey set up elements</h3>
            </Grid>
            <Grid item xs={12} className="grid-custom-spacing">
              <table>
                <tr>
                  <th>Order</th>
                  <th>Question</th>
                  <th>Type</th>
                </tr>
                {surveyTemplateQuestions.map((x: any) => {
                  return (
                    <tr>
                      <td>{x.orderNumber}</td>
                      <td>{x.questionText}</td>
                      <td>{x.questionTypeName}</td>
                    </tr>
                  );
                })}
              </table>
            </Grid>
          </Grid>
          <div className="form-buttons custom-floatRight">
            <br />
            <Controls.Button
              text="Back"
              className="cancel-button"
              variant="outlined"
              onClick={() => navigate(routePaths.SURVEYTEMPLATES)}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={2} md={2} />
      </Grid>
    </div>
  );
}
