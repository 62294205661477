import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Player, BigPlayButton } from "video-react";
import Controls from "components/Controls";
import routePaths from "config/RoutePaths";
import { Form, useForm } from "hooks/useForm";
import { API_URLS } from "config/ApiUrls";
import API from "services/Api.Service";
import { SuccessToast } from "components/Toaster";
import { getDropDownOptions } from "util/CommonFunctions";
import { IResource } from "models/Interfaces/IResource";
import { useLoading } from "providers/loading";
import "./Style.css";

const initialFValues: IResource = {
  resourceDescription: "",
  resourceTitle: "",
  blobUrl: "",
  resourceTypeId: "",
  resourceGroupId: "",
};

const obj = {} as IResource;
const UpdateResource = () => {
  const { setAppLoading } = useLoading();
  const navigate = useNavigate();
  const params = useParams();
  const { resourceId } = params;
  const [resourceGroups, setResourceGroups] = useState<any>([]);
  const [resourceTypes, setResourceTypes] = useState<any>([]);
  const [resourceTypeName, setResourceTypeName] = useState<any>();

  const validate = (fieldValues = values) => {
    if ("resourceGroupId" in fieldValues)
      obj.resourceGroupId = fieldValues.resourceGroupId
        ? ""
        : "Resource Group is required.";
    if ("resourceTitle" in fieldValues)
      obj.resourceTitle = fieldValues.resourceTitle
        ? ""
        : "Resource Title is required.";
    if ("resourceDescription" in fieldValues)
      obj.resourceDescription = fieldValues.resourceDescription
        ? ""
        : "Resource Description is required.";
    setErrors({
      ...obj,
    });
    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange, resetForm, setDataValues } =
    useForm(initialFValues, true, validate);

  useEffect(() => {
    API.get(API_URLS.GetResourceGroups).then((response: any) => {
      setResourceGroups(
        getDropDownOptions(
          response?.data?.data,
          "resourceGroupId",
          "resourceGroupName",
        ),
      );
    });
  }, []);

  useEffect(() => {
    API.get(API_URLS.GetResourceTypes).then((response: any) => {
      setResourceTypes(
        getDropDownOptions(
          response?.data?.data,
          "resourceTypeId",
          "resourceTypeName",
        ),
      );
    });
  }, []);

  useEffect(() => {
    API.get(API_URLS.GetResourceByResourceId, {
      params: {
        ResourceId: resourceId,
      },
    }).then((response: any) => {
      setDataValues({
        resourceId: response.data.data.resourceId,
        resourceTitle: response.data.data.resourceTitle,
        resourceDescription: response.data.data.resourceDescription,
        resourceUrl: response.data.data.resourceUrl,
        resourceTypeId: response.data.data.resourceTypeId,
        resourceTypeName: response.data.data.resourceTypeName,
        resourceGroupId: response.data.data.resourceGroupId,
        resourceGroupName: response.data.data.resourceGroupName,
      });
      setResourceTypeName(response.data.data.resourceTypeName);
    });
  }, []);

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (validate()) {
      const bodyFormData = new FormData();
      bodyFormData.append("ResourceId", values.resourceId);
      bodyFormData.append("ResourceTitle", values.resourceTitle);
      bodyFormData.append("ResourceDescription", values.resourceDescription);
      bodyFormData.append("ResourceTypeId", values.resourceTypeId);
      bodyFormData.append("ResourceGroupId", values.resourceGroupId);
      setAppLoading(true);
      API.post(API_URLS.UpdateResource, bodyFormData)
        .then((response: any) => {
          navigate(routePaths.RESOURCES);
          SuccessToast(response?.data?.message);
          resetForm();
        })
        .finally(() => {
          setAppLoading(false);
        });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view invie-org-form">
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container>
              <Grid item xs={12} className="grid-custom-spacing">
                <div className="page-heading-container">
                  <Controls.BackButton />
                  <h2 className="page-heading">Update Resource</h2>
                </div>
              </Grid>
            </Grid>

            <Grid container className=" grid-custom-spacing custom-marginTop30">
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className="grid-custom-spacing">
                      {resourceTypeName === "Video" ? (
                        <Player
                          onPlay={() => {}}
                          onEnded={() => {}}
                          onLoadStart={() => {}}
                          onPause={() => {}}
                          videoId="id"
                          src={values.resourceUrl}
                          poster="https://andwiderstaging.blob.core.windows.net/videoresources/&Wider_grey_logo.jpg"
                        >
                          <BigPlayButton position="center" />
                        </Player>
                      ) : (
                        <a
                          href={values.resourceUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            className="custom-pdfimage"
                            src="https://andwiderstaging.blob.core.windows.net/pdf-resources/Pdf_Logo.png"
                            alt="PDF"
                          />
                        </a>
                      )}
                    </div>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className="grid-custom-spacing">
                      <Controls.Select
                        id="resourceTypeId"
                        name="resourceTypeId"
                        label="Resource Type*"
                        value={values.resourceTypeId}
                        onChange={handleInputChange}
                        options={resourceTypes}
                        error={obj.resourceTypeId}
                        disabled
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <div className="grid-custom-spacing">
                      <Controls.Select
                        id="resourceGroupId"
                        name="resourceGroupId"
                        label="Resource Group*"
                        value={values.resourceGroupId}
                        onChange={handleInputChange}
                        options={resourceGroups}
                        error={obj.resourceGroupId}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <div className="grid-custom-spacing">
                      <Controls.Input
                        id="resourceTitle"
                        name="resourceTitle"
                        label="Resource Title*"
                        value={values.resourceTitle}
                        onChange={handleInputChange}
                        error={obj.resourceTitle}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <div className="grid-custom-spacing">
                      <Controls.Input
                        id="resourceDescription"
                        name="resourceDescription"
                        label="Resource Description*"
                        value={values.resourceDescription}
                        onChange={handleInputChange}
                        error={obj.resourceDescription}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <div className="form-buttons custom-floatRight">
                      <Controls.Button
                        className="button blue"
                        type="submit"
                        text="Update"
                      />
                      <Controls.Button
                        text="Cancel"
                        className="cancel-button"
                        variant="outlined"
                        onClick={() => {
                          navigate(routePaths.RESOURCES);
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Form>
  );
};

export default UpdateResource;
