import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, InputAdornment } from "@material-ui/core";
import { useLoading } from "providers/loading";
import "./Style.css";
import { useAuth } from "lib/auth";
import { RolesEnum } from "models/Enum/Roles";
import { Form, useForm } from "../../../hooks/useForm";
import Controls from "../../../components/Controls";
import { ICreateSite } from "../../../models/Interfaces/ISite";
import API from "../../../services/Api.Service";
import { API_URLS } from "../../../config/ApiUrls";
import { SuccessToast } from "../../../components/Toaster";
import routePaths from "../../../config/RoutePaths";

let obj = {} as ICreateSite;

const CreateSite = () => {
  const { setAppLoading } = useLoading();
  const navigate = useNavigate();
  // Pull ID of workplace from URL parameters
  // if no ID in parameters, it is a create workplace screen
  const { id } = useParams();
  const [sectors, setSectors] = useState([]);
  const [subSectors, setSubSectors] = useState<any[]>([]);
  const [organisations, setOrganisations] = useState<any[]>([]);
  const [isSedexClientCode, setIsSedexClientCode] = useState(false);

  // Using Auth to determine the user role
  // and what should be rendered on the page
  const auth = useAuth();
  const isAdmin = auth?.user?.data?.roles?.includes(RolesEnum.Admin);

  // Set heading of page according to whether the user
  // is creating or editing
  const pageHeading = id ? "Edit Workplace" : "Add Workplace";

  // Initial form values.
  // For the autocomplete elements we require a separate property
  // for the text search and the selected item(s).
  const initialFValues: ICreateSite = {
    organisationId: "",
    organisationIdTextSearch: "",
    organisationSiteName: "",
    address: "",
    sectorId: "",
    sectorIdTextSearch: "",
    subSectorId: "",
    subSectorIdTextSearch: "",
    workforceSize: "",
    latitude: "",
    longitude: "",
    sedexSiteCode: "",
    sedexAuditorCode: "",
    country: "",
  };

  // useEffect loads all the options into the stateful variables declared above
  useEffect(() => {
    API.get(API_URLS.GetSectors, {
      params: {},
    }).then((response: any) => {
      const sectorOptions = response.data.data.map((x: any) => {
        return { id: x.sectorId, label: x.sectorSection };
      });
      setSectors(sectorOptions);
    });

    API.get(API_URLS.GetSubSectors, {
      params: {},
    }).then((response: any) => {
      const subSectorOptions = response.data.data.map((x: any) => {
        return {
          id: x.subSectorId,
          label: `${x.divisionLabel} - ${x.groupLabel} - ${x.description}`,
          sectorId: x.sectorId,
        };
      });
      setSubSectors(subSectorOptions);
    });

    API.get(API_URLS.GetSelectOrganisationOptions, {
      params: {},
    }).then((response: any) => {
      let organisationOptions = response.data.map((x: any) => {
        return {
          id: x.organisationId,
          label: x.organisationName,
          isSedexClientCode: x.isSedexClientCode,
        };
      });

      if (!isAdmin) {
        const userOrgIds = auth?.user?.data?.userOrganisations.map((x: any) => {
          return (
            x.userRole === "Organisation Administrator" && x.organisationId
          );
        });
        organisationOptions = organisationOptions.filter((x: any) => {
          return userOrgIds?.includes(x.id);
        });
      }
      setOrganisations(organisationOptions);

      if (organisationOptions.length === 1) {
        values.organisationId = organisationOptions[0].id;
        values.organisationIdTextSearch = organisationOptions[0].label;
        setDataValues(values);
      }
    });

    return () => {
      // Remove all the field errors and reset the form
      obj = {} as ICreateSite;
      resetForm();
    };
  }, []);

  // This useEffect checks whether ID is present in parameters
  // if so the foorm is prepopulated with the details of the workplace to be edited
  useEffect(() => {
    if (id) {
      setAppLoading(true);
      API.get(API_URLS.GetSite, {
        params: {
          organisationSiteId: id,
        },
      })
        .then((response: any) => {
          const result = response?.data?.data;
          const coordinateArray = result.latLong.split(" ");
          const coordinates = {
            lat: coordinateArray[0],
            lng: coordinateArray[1],
          };
          result.address = result.address ? result.address : "";
          values.organisationSiteName = result.organisationSiteName;
          values.organisationId = result.organisationId;
          values.organisationIdTextSearch = result.organisationName;
          values.sectorId = result.sectorId;
          values.sectorIdTextSearch = result.sectorName;
          values.subSectorId = result.subSectorId;
          values.subSectorIdTextSearch = result.subSectorName;
          values.workforceSize = result.numberOfWorkers;
          setDataValues(values);
          setAddressAndCoordinates(result.address, coordinates);
        })
        .finally(() => {
          setAppLoading(false);
        });
    } else resetForm();
  }, [id]);

  // Validation check for all required fields
  // Also checks the format of certain fields
  const validate = (fieldValues = values) => {
    if ("organisationId" in fieldValues)
      obj.organisationId =
        fieldValues.organisationId.length !== 0 ? "" : "Business is required.";
    if ("organisationSiteName" in fieldValues)
      obj.organisationSiteName = fieldValues.organisationSiteName
        ? ""
        : "Workplace name is required.";
    if (isSedexClientCode) {
      if (
        fieldValues.sedexSiteCode === "" ||
        fieldValues.sedexSiteCode === null
      ) {
        obj.sedexSiteCode = fieldValues.sedexSiteCode
          ? ""
          : "Sedex workplace code is required.";
      } else if (
        fieldValues.sedexSiteCode !== undefined &&
        fieldValues.sedexSiteCode !== null
      ) {
        obj.sedexSiteCode =
          fieldValues.sedexSiteCode.length <= 12 &&
          fieldValues.sedexSiteCode.length >= 8
            ? ""
            : "Please enter between 8 and 12 digits.";
      }
    }
    if (isSedexClientCode) {
      if (
        fieldValues.sedexAuditorCode === "" ||
        fieldValues.sedexAuditorCode === null
      ) {
        obj.sedexAuditorCode = fieldValues.sedexAuditorCode
          ? ""
          : "Sedex auditor code is required.";
      } else if (
        fieldValues.sedexAuditorCode !== undefined &&
        fieldValues.sedexAuditorCode !== null
      ) {
        obj.sedexAuditorCode =
          fieldValues.sedexAuditorCode.length <= 12 &&
          fieldValues.sedexAuditorCode.length >= 8
            ? ""
            : "Please enter between 8 and 12 digits.";
      }
    }
    if (fieldValues.address === null || fieldValues.address === "")
      obj.address = fieldValues.address ? "" : "Address is required.";
    if ("workforceSize" in fieldValues)
      obj.workforceSize =
        fieldValues.workforceSize !== undefined
          ? ""
          : "Workforce size is required.";
    if ("workforceSize" in fieldValues)
      obj.workforceSize = !Number.isNaN(fieldValues.workforceSize)
        ? ""
        : "Workforce size must be a number.";
    if ("sectorId" in fieldValues)
      obj.sectorId =
        fieldValues.sectorId.length !== 0 ? "" : "Sector is required.";
    if ("subSectorId" in fieldValues)
      obj.subSectorId =
        fieldValues.subSectorId.length !== 0 ? "" : "Sub-sector is required.";
    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  // useForm returns useful form components.
  // The 'true' being passed as second variable to the function is used
  // to specify that the form should run the validate function after every update
  const { values, setErrors, handleInputChange, resetForm, setDataValues } =
    useForm(initialFValues, true, validate);

  // Function which sets the values of address and co-ordinates
  // according to what the user selects from the suggested addresses
  const setAddressAndCoordinates = (address: string, coordinates: any) => {
    values.address = address;
    values.latitude = coordinates.lat;
    values.longitude = coordinates.lng;
    setDataValues(values);
  };

  const setCountry = (country: string) => {
    values.country = country;
    setDataValues(values);
  };

  // This method updates the selected sector according to
  // which sub-sector you select.
  const updateSector = (selectedObject: any) => {
    const subSectorId = selectedObject.id;
    const index = subSectors.findIndex((x: any) => x.id === subSectorId);
    if (index !== -1) {
      const { sectorId } = subSectors[index];
      // Retrieves the parent sector
      const sectorIndex = sectors.findIndex((x: any) => x.id === sectorId);
      const { label } = sectors[sectorIndex];
      // Sets the values of the text search and selected sector ID
      values.sectorId = sectorId;
      values.sectorIdTextSearch = label;
    }
    setDataValues(values);
  };

  // This method filters the available sub-sector options according to which sector you select.
  const updateSubSector = (selectedObject: any) => {
    values.subSectorId = "";
    values.subSectorIdTextSearch = "";
    setDataValues(values);
    const objectId = selectedObject.id;
    let subSectorOptions: any[] = [];
    API.get(API_URLS.GetSubSectors, {
      params: {},
    }).then((response: any) => {
      subSectorOptions = response.data.data.map((x: any) => {
        return {
          id: x.subSectorId,
          label: `${x.divisionLabel} - ${x.groupLabel} - ${x.description}`,
          sectorId: x.sectorId,
        };
      });
      let result = subSectorOptions;
      // Filters the sub-sector options by sector ID
      if (objectId !== "" && objectId !== undefined) {
        result = subSectorOptions.filter((x) => {
          return x.sectorId === objectId;
        });
      }
      setSubSectors(result);
    });
  };

  // If the selected organisation is a Sedex organisation,
  // reveal the Sedex specific fields
  const updateIsSedexSite = (organisationId: string) => {
    if (organisationId) {
      const index = organisations.findIndex((x) => x.id === organisationId);
      setIsSedexClientCode(organisations[index].isSedexClientCode);
    }
  };

  // When selecting an object from any autocomplete form field,
  // this method runs to update the values to be submitted to the API
  const handleAutoCompleteChange = (selectedObject: any, fieldName: string) => {
    values[fieldName] = selectedObject ? selectedObject.id : "";
    if (obj) (obj as any)[fieldName] = "";
    setDataValues(values);
    setErrors(obj);

    if (fieldName === "sectorId") updateSubSector(selectedObject);

    if (fieldName === "subSectorId") updateSector(selectedObject);

    if (fieldName === "organisationId") updateIsSedexSite(selectedObject.id);
  };

  // This method is required to update the text search field in the
  // autocomplete components. It is also used to force the field to be blank.
  const handleAutoCompleteTextInputChange = (text: any, field: string) => {
    const fieldName = `${field}TextSearch`;
    values[fieldName] = text;
    setDataValues(values);
  };

  // Handles submission of the form
  // Depending on whether it is an Update or a Create
  // this method will hit a different API endpoint
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const url = id ? API_URLS.UpdateSite : API_URLS.CreateSite;
    if (validate()) {
      setAppLoading(true);
      API.post(url, {
        OrganisationSiteId: id,
        OrganisationId: values.organisationId,
        OrganisationSiteName: values.organisationSiteName,
        Address: values.address,
        Country: values.country,
        SectorId: values.sectorId,
        SubSectorId: values.subSectorId,
        NumberOfWorkers: Number(values.workforceSize),
        LatLong: `${values.latitude.toString()} ${values.longitude.toString()}`,
        SedexSiteCode: isSedexClientCode === true ? values.sedexSiteCode : null,
        SedexAuditorCode:
          isSedexClientCode === true ? values.sedexAuditorCode : null,
      }).then((res: any) => {
        setAppLoading(false);
        // Inform the user the workplace was saved successfully
        SuccessToast(res?.data?.message);
        // Reset the form and error values
        obj = {} as ICreateSite;
        resetForm();
        // Navigate to sites screen
        navigate(routePaths.SITEMANAGEMENT);
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view">
        <Grid container>
          <Grid item xs={12} sm={2} md={2} />
          <Grid item xs={12} sm={8} md={8}>
            <Grid container>
              <Grid item xs={12}>
                <div className="page-heading-container">
                  <Controls.BackButton />
                  <h2 className="page-heading">{pageHeading}</h2>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Controls.AutoComplete
                  id="organisationId"
                  name="organisationId"
                  label="Business*"
                  value={values.organisationId}
                  options={organisations}
                  inputValue={values.organisationIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  varient="standard"
                  error={obj.organisationId}
                  disabled={!!id}
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.Input
                  id="organisationSiteName"
                  name="organisationSiteName"
                  label="Workplace name*"
                  value={values.organisationSiteName}
                  onChange={handleInputChange}
                  error={obj.organisationSiteName}
                />
              </Grid>
            </Grid>
            {isSedexClientCode && (
              <Grid container>
                <Grid item xs={12}>
                  <Controls.Input
                    id="sedexSiteCode"
                    name="sedexSiteCode"
                    label="Sedex workplace Code*"
                    value={values.sedexSiteCode}
                    onChange={handleInputChange}
                    error={obj.sedexSiteCode}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">ZS</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controls.Input
                    id="sedexAuditorCode"
                    name="sedexAuditorCode"
                    label="Sedex Auditor Code*"
                    value={values.sedexAuditorCode}
                    onChange={handleInputChange}
                    error={obj.sedexAuditorCode}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">ZAA</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container>
              <Grid item xs={12}>
                <Controls.Address
                  value={values.address}
                  error={obj.address}
                  onSelect={handleInputChange}
                  setParentAddressAndCoordinates={setAddressAndCoordinates}
                  setCountry={setCountry}
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.Input
                  id="latitude"
                  name="latitude"
                  label="Latitude"
                  value={values.latitude}
                  onChange={handleInputChange}
                  readOnly
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.Input
                  id="longitude"
                  name="longitude"
                  label="Longitude"
                  value={values.longitude}
                  onChange={handleInputChange}
                  readOnly
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Controls.AutoComplete
                  id="sectorId"
                  name="sectorId"
                  label="Sector*"
                  value={values.sectorId}
                  inputValue={values.sectorIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  varient="standard"
                  options={sectors}
                  error={obj.sectorId}
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.AutoComplete
                  id="subSectorId"
                  name="subSectorId"
                  label="Sub-sector*"
                  value={values.subSectorId}
                  inputValue={values.subSectorIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  varient="standard"
                  options={subSectors}
                  error={obj.subSectorId}
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.Input
                  id="workforceSize"
                  name="workforceSize"
                  label="Workforce size at its largest?*"
                  type="number"
                  value={values.workforceSize}
                  onChange={handleInputChange}
                  error={obj.workforceSize}
                />
              </Grid>
            </Grid>

            <div className="form-buttons custom-floatRight">
              <br />
              <Controls.Button
                className="button blue"
                type="submit"
                text="Save"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={() => {
                  resetForm();
                  navigate(routePaths.SITEMANAGEMENT);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={2} md={2} />
        </Grid>
      </div>
    </Form>
  );
};

export default CreateSite;
