import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Controls from "components/Controls";

export interface ISegmentationQuestion {
  values: any;
  errors: any;
  onChange: (value: any) => void;
}

const options = [
  {
    id: 2,
    key: "2",
    label: "Two",
  },
  {
    id: 3,
    key: "3",
    label: "Three",
  },
  {
    id: 4,
    key: "4",
    label: "Four",
  },
  {
    id: 5,
    key: "5",
    label: "Five",
  },
  {
    id: 6,
    key: "6",
    label: "Six",
  },
  {
    id: 7,
    key: "7",
    label: "Seven",
  },
  {
    id: 8,
    key: "8",
    label: "Eight",
  },
  {
    id: 9,
    key: "9",
    label: "Nine",
  },
];

const SegmentationQuestion = ({
  values,
  errors,
  onChange,
}: ISegmentationQuestion) => {
  const [selectedNumberResponse, setSelectedNumberResponse] = useState<any>([]);

  const onResponsesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const numberOfResponses = Number(e.target.value);
    selectedNumberResponse.length = 0;

    for (let i = 1; i <= numberOfResponses; i++) {
      const segmentationOption = {
        number: i,
        description: `keypad${i}Description`,
      };

      selectedNumberResponse.push(segmentationOption);
    }

    setSelectedNumberResponse(selectedNumberResponse);
  };

  useEffect(() => {
    if (
      values?.numberOfResponses !== undefined &&
      values?.numberOfResponses > 1
    ) {
      selectedNumberResponse.length = 0;

      for (let i = 1; i <= values?.numberOfResponses; i++) {
        const segmentationOption = {
          number: i,
          description: `keypad${i}Description`,
        };

        selectedNumberResponse.push(segmentationOption);
      }

      setSelectedNumberResponse(selectedNumberResponse);
    }
  }, [values]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Controls.Select
          id="numberOfResponses"
          name="numberOfResponses"
          label="Number of responses*"
          options={options}
          value={values?.numberOfResponses}
          onChange={(e) => {
            onResponsesChange(e);
            onChange(e);
          }}
          error={errors?.numberOfResponses}
        />
      </Grid>
      <Grid item xs={12}>
        <Controls.Input
          name="chartTitle"
          id="chartTitle"
          label="Chart title*"
          onChange={onChange}
          value={values?.chartTitle}
          error={errors?.chartTitle}
        />
      </Grid>
      {selectedNumberResponse.map((x: any) => {
        return (
          <Grid item xs={12}>
            <Controls.Input
              id={x.description}
              name={x.description}
              value={values[x.description]}
              label={`Keypad ${x.number} description`}
              onChange={onChange}
              error={errors[x.description]}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default SegmentationQuestion;
