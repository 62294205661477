import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { FaFilter } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Controls from "components/Controls";
import { useLoading } from "providers/loading";
import { SuccessToast } from "components/Toaster";
import API from "services/Api.Service";
import { API_URLS } from "config/ApiUrls";
import DataCycleTable from "../DataCycleTable";
import FiltersModal from "../FiltersModal";
import { MultipleDataCyclesMarkAsComplete } from "../DataCycleTable/MultipleDataCyclesMarkAsComplete";
import { MultipleDataCyclesHideFilters } from "../DataCycleTable/MultipleDataCyclesHideFilters";
import { MultipleHideSegmentationCharts } from "../DataCycleTable/MultipleHideSegmentationCharts";
import "./Style.css";
import { MultipleSendEmails } from "../DataCycleTable/MultipleSendEmails";

function DataCycles() {
  const [filtersModal, setFiltersModal] = useState(false);
  const [filters, setFilters] = useState({
    organisations: [],
    sites: [],
    tags: [],
    date: null,
    filtersHidden: false,
    chartsHidden: false,
    isCompleted: false,
    statuses: true,
  });
  const navigate = useNavigate();
  const [dataCycleIds, setdataCycleIds] = useState<any[]>([]);
  const [refreshTable, setRefreshTable] = useState(false);
  const { setAppLoading } = useLoading();

  const [markAsCompleteModal, setMarkAsCompleteModal] = useState(false);
  const [hideFiltersModal, setHideFiltersModel] = useState(false);
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const [hideSegmentationChartsModal, setHideSegmentationChartsModel] =
    useState(false);

  const handleBulkButtonClick = (dataCycleTable: any) => {
    const checkedDataCycleIds = [];
    for (let i = 0; i < dataCycleTable.length; i += 1) {
      if (dataCycleTable[i].isDataCycleIdChecked) {
        checkedDataCycleIds.push({
          id: dataCycleTable[i].dataCycleId,
          name: dataCycleTable[i].dataCycleName,
        });
      }
    }
    setdataCycleIds(checkedDataCycleIds);
  };

  const handleHideFilters = () => {
    setAppLoading(true);
    try {
      API.post(
        API_URLS.BulkDisableSurveyCycleFilters,
        dataCycleIds.map((item) => item.id),
      )
        .then((res: any) => {
          SuccessToast(res?.data?.message);
          setRefreshTable((prev) => !prev);
        })
        .finally(() => {
          setAppLoading(false);
          setHideFiltersModel(false);
        });
    } catch (error) {
      setAppLoading(false);
    }
  };

  const handleHideSegmentationCharts = () => {
    setAppLoading(true);
    try {
      API.post(
        API_URLS.BulkHideSegmentationCharts,
        dataCycleIds.map((item) => item.id),
      )
        .then((res: any) => {
          SuccessToast(res?.data?.message);
          setRefreshTable((prev) => !prev);
        })
        .finally(() => {
          setAppLoading(false);
          setHideSegmentationChartsModel(false);
        });
    } catch (error) {
      setAppLoading(false);
    }
  };

  const handleMarkAsComplete = () => {
    setAppLoading(true);
    try {
      API.post(
        API_URLS.BulkDataCyclesAsComplete,
        dataCycleIds.map((item) => item.id),
      )
        .then((res: any) => {
          SuccessToast(res?.data?.message);
          setRefreshTable((prev) => !prev);
        })
        .finally(() => {
          setAppLoading(false);
          setMarkAsCompleteModal(false);
        });
    } catch (error) {
      setAppLoading(false);
    }
  };

  const handleSendEmails = () => {
    setAppLoading(true);
    try {
      API.post(API_URLS.SendEmails, {
        dataCycleIds: dataCycleIds.map((item) => item.id),
      })
        .then((res: any) => {
          SuccessToast(res?.data?.message);
          setRefreshTable((prev) => !prev);
        })
        .finally(() => {
          setAppLoading(false);
          setSendEmailModal(false);
        });
    } catch (error) {
      setAppLoading(false);
    }
  };

  const toggleMarkAsCompleteModal = () => {
    setMarkAsCompleteModal(!markAsCompleteModal);
  };

  const toggleHideFiltersModel = () => {
    setHideFiltersModel(!hideFiltersModal);
  };

  const toggleHideSegmentationChartsModal = () => {
    setHideSegmentationChartsModel(!hideSegmentationChartsModal);
  };

  const toggleEmailModal = () => {
    setSendEmailModal(!sendEmailModal);
  };

  return (
    <div className="container-view">
      {markAsCompleteModal && (
        <MultipleDataCyclesMarkAsComplete
          toggleModal={toggleMarkAsCompleteModal}
          data={dataCycleIds}
          setModal={setMarkAsCompleteModal}
          handle={handleMarkAsComplete}
        />
      )}
      {hideFiltersModal && (
        <MultipleDataCyclesHideFilters
          toggleModal={toggleHideFiltersModel}
          data={dataCycleIds}
          setModal={setHideFiltersModel}
          handle={handleHideFilters}
        />
      )}
      {hideSegmentationChartsModal && (
        <MultipleHideSegmentationCharts
          toggleModal={toggleHideSegmentationChartsModal}
          data={dataCycleIds}
          setModal={setHideSegmentationChartsModel}
          handle={handleHideSegmentationCharts}
        />
      )}
      {sendEmailModal && (
        <MultipleSendEmails
          toggleModal={toggleEmailModal}
          data={dataCycleIds}
          setModal={setSendEmailModal}
          handle={handleSendEmails}
        />
      )}
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <div className="page-heading-container">
              <Controls.BackButton />
              <h2 className="page-heading">Manage Survey Cycles</h2>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div
              className="filtersButton blue"
              onClick={() => setFiltersModal(!filtersModal)}
            >
              <FaFilter />
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className="table-button-tray">
              <Controls.Button
                text="Hide Filters"
                className="button maroon"
                onClick={() => toggleHideFiltersModel()}
              />
              <Controls.Button
                text="Hide Segmentation Charts"
                className="button yellow"
                onClick={() => toggleHideSegmentationChartsModal()}
              />
              <Controls.Button
                text="Mark as Complete"
                className="button turquoise"
                onClick={() => toggleMarkAsCompleteModal()}
              />
              <Controls.Button
                text="Send Emails"
                className="button green"
                onClick={() => toggleEmailModal()}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <DataCycleTable
        filters={filters}
        refreshTable={refreshTable}
        onBulkButtonClick={handleBulkButtonClick}
      />
      {filtersModal && (
        <FiltersModal
          setModal={() => setFiltersModal(!filtersModal)}
          setFilters={setFilters}
          filters={filters}
        />
      )}
    </div>
  );
}

export default DataCycles;
