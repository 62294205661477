import React from "react";
import Controls from "../../../components/Controls";
import { IMarkDataCycleComplete } from "../../../models/Interfaces/IDataCycle";
import "./Style.css";

type ShowSegmentationProps = {
  data: IMarkDataCycleComplete;
  toggleShowSegModal: (rowData: any) => void;
  setShowSegModal: React.Dispatch<React.SetStateAction<boolean>>;
  showSegmentationCharts: (values: IMarkDataCycleComplete) => void;
};

export const ShowSegmentationCharts = (props: ShowSegmentationProps) => {
  const { toggleShowSegModal, setShowSegModal, showSegmentationCharts, data } =
    props;

  return (
    <div className="modal">
      <div onClick={toggleShowSegModal} className="overlay" />
      <div className="modal-content">
        <p>
          You are going to show segmentation charts to the end user. Are you
          sure?
        </p>
        <br />
        <div className="form-buttons custom-floatRight">
          <Controls.Button
            className="button blue"
            text="Show"
            onClick={() => showSegmentationCharts(data)}
          />
          <Controls.Button
            text="Cancel"
            className="cancel-button"
            variant="outlined"
            onClick={() => setShowSegModal(false)}
          />
        </div>
      </div>
    </div>
  );
};
