import Axios from "axios";
import storage from "util/storage";
import { ErrorToast } from "../components/Toaster";
import { API_URLS } from "../config/ApiUrls";
import * as Env from "../config/Env";

const API = Axios.create({
  baseURL: Env.API_URL,
});
API.interceptors.request.use(
  (conf: any) => {
    if (storage.getUser()) {
      const tokenDetail = storage.getUser();
      conf.headers = {
        Authorization: `Bearer ${tokenDetail?.data?.accessToken}`,
      };
    }
    return conf;
  },
  (error) => {
    return Promise.reject(error);
  },
);

API.interceptors.response.use(
  (next) => {
    return Promise.resolve(next);
  },
  async (error) => {
    // console.log("🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥", error);
    const statusCode = error?.response?.status;
    if (statusCode === 401) {
      const originalRequest = error.config;
      const TokenDetail = storage.getUser();
      if (TokenDetail) {
        const newTokenResponse = await API.post(API_URLS.RefreshToken, {
          accessToken: TokenDetail?.data?.accessToken,
          refreshToken: TokenDetail?.data?.refreshToken,
        });
        TokenDetail.data.accessToken =
          newTokenResponse?.data?.data?.accessToken;
        TokenDetail.data.refreshToken =
          newTokenResponse?.data?.data?.refreshToken;
        storage.setUser(TokenDetail);
        originalRequest.headers.Authorization = `Bearer ${newTokenResponse.data?.data?.accessToken}`;
        const responseUpdated = await Axios(originalRequest);
        return Promise.resolve(responseUpdated);
      }
    }
    ErrorToast(
      error.response.data.message ||
        error.response.data.title ||
        error.response.data,
    );
    return Promise.reject(error.response.data.message || error.response.data);
  },
);
export default API;
