import { useEffect, useState } from "react";
import Charts from "components/Charts";
import { API_URLS } from "config/ApiUrls";
import { ReportingTypeEnum } from "models/Enum/EnumTypes";
import { IResultsChart } from "models/Interfaces/ICharts";
import API from "services/Api.Service";
import { SmallSpinner } from "components/Spinner";
import "./Style.css";

function PrioritiesAndDetailedComparison({
  tab,
  dataCycleId,
  filters,
  compareDataCycleId,
  showBenchmarks,
  dataCycleIds,
  showGroupBenchmarks,
  groupDataCycleIds,
}: IResultsChart) {
  const [responses, setResponses] = useState<any>({});
  const [benchmarks, setBenchmarks] = useState<any>([]);
  const [groupBenchmarks, setGroupBenchmarks] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [demographicBenchmarksLoading, setDemographicBenchmarksLoading] =
    useState(false);
  const [groupBenchmarksLoading, setGroupBenchmarksLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (tab && dataCycleId && filters && compareDataCycleId) {
      const url =
        tab === ReportingTypeEnum.Priorities
          ? API_URLS.GetPrioritiesComparison
          : API_URLS.GetResultsComparison;

      API.post(url, {
        dataCycleIds: [dataCycleId, compareDataCycleId],
        segmentationFilters: filters,
      })
        .then((response: any) => {
          setResponses(response?.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [tab, dataCycleId, JSON.stringify(filters), compareDataCycleId]);

  useEffect(() => {
    if (showBenchmarks && dataCycleIds) {
      setDemographicBenchmarksLoading(true);
      API.post(API_URLS.GetDetailedResultsBenchmarking, {
        dataCycleId,
        segmentationFilters: filters,
        dataCycleIds,
      })
        .then((response: any) => {
          const benchmarksReturned = response?.data?.map((x: any) => {
            return {
              question: x.description,
              questionId: x.questionId,
              positivePercentage: x.positivePercentage,
              neutralPercentage: x.neutralPercentage,
              negativePercentage: x.negativePercentage,
              surveyCycleName: "Your average",
            };
          });
          setBenchmarks(benchmarksReturned);
        })
        .finally(() => {
          setDemographicBenchmarksLoading(false);
        });
    }
    if (!showBenchmarks) {
      setBenchmarks([]);
    }
  }, [showBenchmarks, dataCycleIds]);

  useEffect(() => {
    if (showGroupBenchmarks && groupDataCycleIds) {
      setGroupBenchmarksLoading(true);
      API.post(API_URLS.GetDetailedResultsBenchmarking, {
        dataCycleId,
        segmentationFilters: filters,
        dataCycleIds: groupDataCycleIds,
      })
        .then((response: any) => {
          const benchmarksReturned = response?.data?.map((x: any) => {
            return {
              question: x.description,
              questionId: x.questionId,
              positivePercentage: x.positivePercentage,
              neutralPercentage: x.neutralPercentage,
              negativePercentage: x.negativePercentage,
              surveyCycleName: "Benchmark",
            };
          });
          setGroupBenchmarks(benchmarksReturned);
        })
        .finally(() => {
          setGroupBenchmarksLoading(false);
        });
    }
    if (!showGroupBenchmarks) {
      setGroupBenchmarks([]);
    }
  }, [showGroupBenchmarks, groupDataCycleIds]);

  return loading || demographicBenchmarksLoading || groupBenchmarksLoading ? (
    <div className="chartSpinner">
      <SmallSpinner />
    </div>
  ) : responses.length > 0 ? (
    <Charts.CompareBarChart
      responses={responses}
      tab={tab}
      benchmarks={benchmarks}
      groupBenchmarks={groupBenchmarks}
    />
  ) : (
    <h2 className="text-center">
      {tab === ReportingTypeEnum.Priorities
        ? "This workplace has no priorities for the currently selected survey cycle and filters."
        : "This workplace has no results for the currently selected survey cycle and filters."}
    </h2>
  );
}

export default PrioritiesAndDetailedComparison;
