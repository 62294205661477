import TextField from "@material-ui/core/TextField";
import { useEffect, useState } from "react";
import PlacesAutocomplete, {
  getLatLng,
  geocodeByAddress,
} from "react-places-autocomplete";
import { IAutoComplete } from "../../../models/Interfaces/IControls";
import "./Style.css";

export default function Address({
  error = null,
  value,
  setParentAddressAndCoordinates,
  setCountry,
}: IAutoComplete) {
  const [address, setAddress] = useState(value);

  const handleSelect = async (x: any) => {
    const results = await geocodeByAddress(x);
    const latLng = await getLatLng(results[0]);
    let country = "";
    results[0].address_components.forEach(async (element) => {
      if (element.types[0] === "country") {
        country = element.short_name;
      }
    });
    setAddress(x);
    setParentAddressAndCoordinates(x, latLng);
    setCountry(country);
  };

  const handleChange = (e: any) => {
    if (e === "" || e === undefined || e === null)
      setParentAddressAndCoordinates("", { lat: "", lng: "" });
    setCountry("");
    setAddress(e);
  };

  useEffect(() => {
    setAddress(value);
  }, [value]);

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <>
          <div>
            <TextField
              label="Address*"
              margin="dense"
              size="small"
              InputProps={{ type: "search" }}
              // name={name}
              variant="standard"
              {...getInputProps({ placeholder: "" })}
              {...(error && { error: true, helperText: error })}
            />
          </div>
          <div className="MuiInput-input MuiAutocomplete-input MuiAutocomplete-inputFocused MuiInputBase-inputAdornedEnd MuiInputBase-inputTypeSearch MuiInput-inputTypeSearch MuiInputBase-inputMarginDense MuiInput-inputMarginDense suggestions">
            {loading ? <div>Loading...</div> : null}
            {suggestions.map((suggestion) => {
              const style = {
                backgroundColor: suggestion.active ? "#f5f5f5" : "#fff",
                margin: "0.5em",
                cursor: "pointer",
              };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, { style })}
                  key={suggestion.placeId}
                >
                  {suggestion.description}
                </div>
              );
            })}
          </div>
        </>
      )}
    </PlacesAutocomplete>
  );
}
