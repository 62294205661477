import React, { createRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import WarningIcon from "@material-ui/icons/Warning";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { API_URLS } from "config/ApiUrls";
import routePaths from "config/RoutePaths";
import { UseTable } from "hooks/useTable";
import API from "services/Api.Service";
import { useLoading } from "providers/loading";
import ArchiveModal from "../ArchiveModal";
import "./Style.css";

const tableRef = createRef<any>();
const QuestionTable = () => {
  const { setAppLoading } = useLoading();
  const navigate = useNavigate();
  const [modal, setModal] = React.useState(false);
  const [questions, setQuestions] = React.useState<any[]>([]);
  const [question, setQuestion] = React.useState<any>({});

  useEffect(() => {
    setAppLoading(true);
    API.get(API_URLS.GetQuestionsList, {
      params: {
        OrderBy: "QuestionId",
        SearchValue: null,
      },
    })
      .then((response: any) => {
        setQuestions(response.data.data);
      })
      .finally(() => {
        setAppLoading(false);
      });
  }, [modal]);

  const navigateToEdit = (questionId: string) => {
    navigate(`/questions/${questionId}/edit`);
  };

  const navigateToLanguages = (questionId: string) => {
    navigate(`/questions/${questionId}/question-languages`);
  };

  const hideAndShowModal = () => {
    setModal(!modal);
  };

  const archiveQuestion = (questionObj: any) => {
    setQuestion(questionObj);
    hideAndShowModal();
  };

  const columns = [
    {
      title: "Question Text",
      field: "questionText",
      render: (rowData: any) => (
        <div>
          {rowData.questionText}{" "}
          {!rowData.hasAudio && (
            <Tooltip
              title={
                <>
                  <p style={{ color: "white" }}>SETUP INCOMPLETE</p>
                  <p>This audio is missing</p>
                </>
              }
              arrow
            >
              <Button className="warning-icon">
                <WarningIcon />
              </Button>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "MANAGE",
      field: "Edit",
      render: (rowData: any) => {
        return (
          <div className="actions-container">
            <p onClick={() => navigateToEdit(rowData?.questionId)}>Edit</p>|
            <p onClick={() => navigateToLanguages(rowData?.questionId)}>
              Languages
            </p>
            |
            <p
              className="actions-container-delete"
              onClick={() => archiveQuestion(rowData)}
            >
              Archive
            </p>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="question-table">
        <UseTable
          refTable={tableRef}
          columns={columns}
          data={questions}
          title="Questions"
        />
        {modal && (
          <ArchiveModal
            setModal={hideAndShowModal}
            selectedQuestion={question}
          />
        )}
      </div>
    </div>
  );
};

export default QuestionTable;
