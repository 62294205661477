import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Modal } from "@material-ui/core";
import { DropzoneDialog } from "material-ui-dropzone";
import { Stack } from "@mui/material";
import Input, { isValidPhoneNumber } from "react-phone-number-input/input";
import Controls from "components/Controls";
import API from "services/Api.Service";
import { API_URLS } from "config/ApiUrls";
import { SuccessToast } from "components/Toaster";
import routePaths from "config/RoutePaths";
import { useLoading } from "providers/loading";
import "./Style.css";

function UploadPhoneNumber() {
  const { setAppLoading } = useLoading();
  const [open, setOpen] = useState<boolean>(false);
  const [, setFiles] = useState<Array<File>>([]);
  const [phoneNumbers, setPhoneNumbers] = useState<any>([]);
  const [isChecked, setChecked] = useState(false);
  const [uploadNumbers, setuploadNumbers] = useState<boolean>(false);
  const [isManualUpload, setIsManualUpload] = useState<boolean>(false);
  const [isPhoneNumberEnteredIsValid, setIsPhoneNumberEnteredIsValid] =
    useState<boolean>(false);
  const [isUploadCsv, setisUploadCsv] = useState<boolean>(false);

  const params = useParams();
  const siteId = params.organisationSiteId;
  const navigate = useNavigate();

  const excelUpload = (acceptedFiles: Array<File>) => {
    let uploadCsvFile = false;
    setFiles(acceptedFiles);
    setOpen(false);
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();
      reader.onload = () => {
        const str = reader.result as string;
        const test = str.replace(/(\r\n|\n|\r)/gm, ",");
        const data = test.split(",");
        const finalArray = data.filter((text) => text.length);
        // const filteredPhoneNumber = finalArray?.filter(
        //   // eslint-disable-next-line array-callback-return
        //   (phoneNumberValue: string) => {
        //     if (isValidPhoneNumber(`+${phoneNumberValue}`))
        //       return `+${phoneNumberValue}`;
        //   },
        // );
        setPhoneNumbers(finalArray);
        uploadCsvFile = true;
        setisUploadCsv(uploadCsvFile);
      };
      reader.readAsText(file);
    });
  };

  const handlesOpen = () => {
    setuploadNumbers(true);
  };
  const handlesClose = () => {
    setuploadNumbers(false);
  };

  const onCheckboxClick = () => {
    setChecked(!isChecked);
  };

  const saveFile = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const WorkersPhoneNumber = phoneNumbers?.filter(
      // eslint-disable-next-line array-callback-return
      (phoneNumberValue: string) => {
        if (isValidPhoneNumber(`+${phoneNumberValue}`))
          return `+${phoneNumberValue}`;
      },
    );
    handlesClose();
    setAppLoading(true);
    API.post(API_URLS.UploadPhoneNumber, {
      organisationSiteId: siteId,
      WorkersPhoneNumber: phoneNumbers,
    })
      .then((response: any) => {
        SuccessToast(response?.data?.message);
        navigate(routePaths.SITEMANAGEMENT);
      })
      .finally(() => {
        setAppLoading(false);
      });
  };

  const handleChange = (value: any, index: number) => {
    const inputValue = value;
    if (isManualUpload) {
      phoneNumbers[index] = inputValue;
      setPhoneNumbers([...phoneNumbers]);
    } else {
      phoneNumbers[index] = inputValue;
      setPhoneNumbers([...phoneNumbers]);
    }
    let isPhoneValid = false;
    phoneNumbers.map((item: any) => {
      if (item?.length > 0) {
        isPhoneValid = true;
      }
      return item;
    });
    setIsPhoneNumberEnteredIsValid(isPhoneValid);
  };

  const handleOpen = () => {
    setPhoneNumbers([]);
    setIsManualUpload(false);
    setOpen(true);
  };

  const handlePaste = (e: any) => {
    const pastedData = e.clipboardData.getData("text/plain");
    if (pastedData) {
      pastedData.split("\n").map((splitNumber: any, index: number) => {
        if (splitNumber && isValidPhoneNumber(`+${splitNumber}`)) {
          phoneNumbers[index] = splitNumber;
        }
        return null;
      });
      setPhoneNumbers([...phoneNumbers]);
    }
    e.preventDefault();
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAddManaual = () => {
    setIsManualUpload(true);
    handleAddRow();
  };

  const handleAddRow = () => {
    setPhoneNumbers([...phoneNumbers, "", "", "", "", "", "", "", "", "", ""]);
  };

  return (
    <div className="container-view">
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div className="page-heading-container">
              <Controls.BackButton />
              <h2 className="page-heading">Upload Phone Numbers</h2>
            </div>
            <div>
              Copy and paste the phone number from a spreadsheet, or import as a
              csv. Each phone number must include the country code. E.g
              +27724506886 or +593991014563
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className="number-table-wrapper">
              <div className="header">
                <div className="serial-number"> Sr No. </div>
                <div className="phone-number" style={{ textAlign: "left" }}>
                  Phone Number
                </div>
              </div>
              <div className="table-body">
                {phoneNumbers?.map((phoneNumber: any, index: any) => {
                  //
                  return (
                    <div key={index} className="table-row">
                      <div className="serial-number">{index + 1}</div>
                      <div className="phone-number">
                        <Input
                          id={`PhoneNumber${-index}`}
                          name={`PhoneNumber${-index}`}
                          value={phoneNumber}
                          onChange={(e: any) => {
                            handleChange(e, index);
                          }}
                          onPaste={handlePaste}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className="button-wrapper">
              <Controls.Button onClick={handleOpen} text="Import csv " />
              {!isManualUpload ? (
                <Controls.Button onClick={handleAddManaual} text="Add Manual" />
              ) : (
                <Controls.Button onClick={handleAddRow} text="Add Row" />
              )}
              <div className="Dropzone-madal">
                <DropzoneDialog
                  dropzoneClass="dropzone"
                  open={open}
                  onSave={excelUpload}
                  acceptedFiles={[".csv"]}
                  // eslint-disable-next-line react/jsx-boolean-value
                  showPreviews={true}
                  useChipsForPreview
                  previewGridProps={{
                    container: { spacing: 1, direction: "row" },
                  }}
                  maxFileSize={5000000}
                  onClose={handleClose}
                  filesLimit={1}
                />
              </div>
            </div>
          </Grid>

          <Grid xs={12} sm={12} md={12}>
            <Controls.CheckBox
              onChange={onCheckboxClick}
              label="I received informed consent from every worker whose number is included in the list of numbers being shared with &Wider"
            />
          </Grid>
          <Modal open={uploadNumbers} onClose={handlesClose}>
            <Box className="modal-content">
              <div>
                <p>
                  Are you sure that the phone numbers are formatted correctly,
                  and that informed consent was received from the workers whose
                  phone numbers are included here?
                </p>
                <div className="modalFooter">
                  <Stack direction="row" spacing={2}>
                    <Controls.Button
                      text="Yes, i am"
                      size="large"
                      onClick={saveFile}
                      id="btn"
                    />
                    <Controls.Button
                      text="No,let me double check"
                      size="large"
                      onClick={() => {
                        setuploadNumbers(false);
                      }}
                      id="btn"
                    />
                  </Stack>
                </div>
              </div>
            </Box>
          </Modal>

          <div style={{ textAlign: "right", width: "100%" }}>
            <div className="form-buttons custom-floatRight">
              <br />
              <Controls.Button
                text="SAVE"
                className="button blue"
                onClick={handlesOpen}
                disabled={
                  isChecked === false
                    ? true
                    : !isPhoneNumberEnteredIsValid && !isUploadCsv
                }
              />
              <Controls.Button
                text="CLOSE"
                className="cancel-button"
                variant="outlined"
                onClick={() => {
                  navigate(routePaths.SITEMANAGEMENT);
                }}
              />
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
}

export default UploadPhoneNumber;
