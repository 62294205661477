import { useEffect, useState } from "react";
import Charts from "components/Charts";
import { API_URLS } from "config/ApiUrls";
import { IChartProps } from "models/Interfaces/IMapSite";
import API from "services/Api.Service";
import { SmallSpinner } from "components/Spinner";

function ResponseRates({ siteId, surveyTemplateTypeId }: IChartProps) {
  const [rates, setRates] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (siteId && surveyTemplateTypeId) {
      const url = API_URLS.GetResponseRates;

      API.post(url, {
        organisationSiteId: siteId,
        surveyTemplateTypeId,
      })
        .then((response: any) => {
          const resultsReturned = response?.data?.map((x: any) => {
            return {
              surveyCycleName: x.dataCycleName,
              complete: x.numberOfCompleteWorkers,
              incomplete: x.numberOfIncompleteWorkers,
              optedOut: x.numberOfOptedOutWorkers,
              unreachable: x.numberOfUnreachableWorkers,
              completePerc: x.numberOfCompleteWorkersPerc,
              incompletePerc: x.numberOfIncompleteWorkersPerc,
              optedOutPerc: x.numberOfOptedOutWorkersPerc,
              unreachablePerc: x.numberOfUnreachableWorkersPerc,
              totalWorkers: x.numberOfTotalWorkers,
            };
          });
          setRates(resultsReturned);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [siteId, surveyTemplateTypeId]);

  return loading ? (
    <div className="chartSpinner">
      <SmallSpinner />
    </div>
  ) : (
    <div>
      <Charts.BarChart items={rates} />
    </div>
  );
}

export default ResponseRates;
