import { Routes, Route } from "react-router-dom";
import routePaths from "config/RoutePaths";
import CreateSite from "./CreateSite";
import SiteManagement from "./Sites";
import UploadPhoneNumber from "./UploadPhoneNumber";
import ViewPhoneNumbers from "./ViewPhoneNummbers";

export const SitesRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<SiteManagement />} />
      <Route path="/create" element={<CreateSite />} />
      <Route path={routePaths.EDITSITE} element={<CreateSite />} />
      <Route
        path={routePaths.USERPHONENUMBER}
        element={<UploadPhoneNumber />}
      />
      <Route path={routePaths.VIEWPHONENUMBER} element={<ViewPhoneNumbers />} />
    </Routes>
  );
};
