import HorizontalBarChart from "./HorizontalBarChart";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import CompareBarChart from "./CompareBarChart";
import ProgressChart from "./ProgressChart";
import ComparePieChart from "./ComparePieChart";

const Charts = {
  HorizontalBarChart,
  BarChart,
  PieChart,
  ComparePieChart,
  CompareBarChart,
  ProgressChart,
};

export default Charts;
