import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleUp,
  faCircleDown,
  faCircleStop,
  faCircleMinus,
} from "@fortawesome/free-solid-svg-icons";
import { ProgressDirections } from "models/Enum/EnumTypes";
import "./Style.css";

function ProgressSummary({ progress }: any) {
  const renderProgressDirections = (config: any) => {
    switch (config) {
      case ProgressDirections.Up:
        return (
          <div className="progressIconContainer">
            <div className="progressIcon">
              <FontAwesomeIcon icon={faCircleUp} />
            </div>
            <h2>{config}</h2>
          </div>
        );
      case ProgressDirections.Down:
        return (
          <div className="progressIconContainer">
            <div className="progressIcon">
              <FontAwesomeIcon icon={faCircleDown} />
            </div>
            <h2>{config}</h2>
          </div>
        );
      case ProgressDirections.NoChange:
        return (
          <div className="progressIconContainer">
            <div className="progressIcon">
              <FontAwesomeIcon icon={faCircleStop} />
            </div>
            <h2>{config}</h2>
          </div>
        );
      default:
        return (
          <div className="progressIconContainer">
            <div className="progressIcon">
              <FontAwesomeIcon icon={faCircleMinus} />
            </div>
            <p>This workplace has only one survey cycle</p>
          </div>
        );
    }
  };

  return (
    <div className="progressContainer">
      {renderProgressDirections(progress[0])}
    </div>
  );
}

export default ProgressSummary;
