import { useNavigate, useParams } from "react-router-dom";
import { FaMinus, FaPlus, FaTimes } from "react-icons/fa";
import { useEffect, useState } from "react";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import HorizontalRuleRoundedIcon from "@mui/icons-material/HorizontalRuleRounded";
import BarChart from "@material-ui/icons/BarChart";
import { API_URLS } from "config/ApiUrls";
import API from "services/Api.Service";
import { ProgressDirections, ReportingTypeEnum } from "models/Enum/EnumTypes";
import { SmallSpinner } from "components/Spinner";
import ProgressSummary from "./ProgressSummary";
import PrioritiesSummary from "./PrioritiesSummary";
import "./Style.css";

function SiteSummaryBox({
  clientId,
  site,
  surveyTemplateTypeId,
  removeSiteFromSummaries,
  organisationId,
}: any) {
  clientId =
    clientId === undefined || clientId === ""
      ? "00000000-0000-0000-0000-000000000000"
      : clientId;
  const [expanded, setExpanded] = useState(true);
  const [hovering, setHover] = useState("");

  const navigate = useNavigate();

  const [loading, setLoading] = useState<any>(true);
  const [priorities, setPriorities] = useState<any[]>([]);
  const [prioritiesCount, setPrioritiesCount] = useState<any>();
  const [progress, setProgress] = useState<any>([]);

  const handleMouseOver = (hover: string) => {
    setHover(hover);
  };

  useEffect(() => {
    // Initialize loading state to true
    setLoading(true);

    // Make both API requests concurrently
    Promise.all([
      API.post(API_URLS.GetPrioritiesOverview, {
        organisationSiteId: site.key,
        surveyTemplateTypeId,
      }),
      API.post(API_URLS.GetProgressOverview, {
        organisationSiteId: site.key,
        surveyCycleTemplateTypeId: surveyTemplateTypeId,
      }),
    ])
      .then((responses) => {
        // Extract data from responses
        const prioritiesResponse = responses[0];
        const progressResponse = responses[1];

        const prioritiesData =
          prioritiesResponse?.data?.dataCycleQuestionResults.map((x: any) => {
            return {
              name: x.description,
              percentage: x.negativePercentage,
            };
          });

        // Update state with both data
        setPrioritiesCount(prioritiesData?.length);
        setPriorities(prioritiesData);
        setProgress(progressResponse?.data);
      })
      .catch((error) => {
        // Handle errors here
        // console.error("Error:", error);
      })
      .finally(() => {
        // Set loading state to false when both requests are complete
        setLoading(false);
      });
  }, [site]);

  return (
    <div className="siteSummary">
      <div
        className={`siteScoreCircle ${
          site.score < 0
            ? "scoreZero"
            : site.score >= 0 && site.score <= 5
            ? "scoreLow"
            : site.score > 5 && site.score <= 7.5
            ? "scoreMedium"
            : "scoreHigh"
        }`}
      >
        {site.score < 0 ? "N/A" : site.score}
      </div>
      <div className="siteSummaryBox">
        <div
          className={`siteSummaryBoxBanner ${
            expanded ? "siteSummaryBoxBannerBorder" : ""
          }`}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ display: "inline-block", paddingLeft: "5px" }}>
              {site.label}
            </p>
          </div>
          <div className="siteSummaryBoxIcons">
            {site.score !== null && site.score > -2 && (
              <div
                className="contentExpansion"
                onClick={() => {
                  setExpanded(!expanded);
                }}
              >
                <div className={expanded ? "hiddenIcon" : "displayedIcon"}>
                  <FaPlus />
                </div>
                <div className={expanded ? "displayedIcon" : "hiddenIcon"}>
                  <FaMinus />
                </div>
              </div>
            )}
            <div
              className="contentExpansion"
              onClick={() => {
                removeSiteFromSummaries(site.key);
              }}
            >
              <div className="displayedIcon">
                <FaTimes />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`siteSummaryBoxContent ${
            expanded ? "visible" : "invisible"
          }`}
        >
          {loading ? (
            <div className="spinnerContainer">
              <SmallSpinner />
            </div>
          ) : (
            <div className="hoverHolder">
              <div className="hoverItem">
                <div className="siteSummaryBoxContentCircle">
                  {prioritiesCount}
                </div>
                <a
                  onClick={() =>
                    navigate(
                      `/reports/tabs/${site.organisationId}/${site.key}/${surveyTemplateTypeId}/${ReportingTypeEnum.Priorities}/${clientId}/${organisationId}`,
                    )
                  }
                  onMouseOver={() => handleMouseOver("Priorities")}
                  onMouseOut={() => handleMouseOver("")}
                >
                  Priorities Overview
                </a>
              </div>
              <div className="hoverItem">
                <div className="siteSummaryBoxContentCircle">
                  {(() => {
                    switch (progress[0]) {
                      case ProgressDirections.Up:
                        return <ArrowUpwardRoundedIcon />;
                      case ProgressDirections.Down:
                        return <ArrowDownwardRoundedIcon />;
                      default:
                        return <HorizontalRuleRoundedIcon />;
                    }
                  })()}
                </div>
                <a
                  onClick={() =>
                    navigate(
                      `/reports/tabs/${site.organisationId}/${site.key}/${surveyTemplateTypeId}/${ReportingTypeEnum.Progress}/${clientId}/${organisationId}`,
                    )
                  }
                  onMouseOver={() => handleMouseOver("Progress")}
                  onMouseOut={() => handleMouseOver("")}
                >
                  Progress Direction
                </a>
              </div>
              <div className="hoverItem">
                <div className=" siteSummaryBoxContentCircle">
                  <BarChart />
                </div>
                <a
                  onClick={() =>
                    navigate(
                      `/reports/tabs/${site.organisationId}/${site.key}/${surveyTemplateTypeId}/${ReportingTypeEnum.Priorities}/${clientId}/${organisationId}`,
                    )
                  }
                >
                  Go to Results
                </a>
              </div>
            </div>
          )}
          {hovering !== "" && (
            <div className="siteSummaryPrioritiesHoverBox">
              {hovering === "Priorities" && (
                <PrioritiesSummary priorities={priorities} />
              )}
            </div>
          )}
          {hovering !== "" && (
            <div className="siteSummaryProgressHoverBox">
              {hovering === "Progress" && (
                <ProgressSummary progress={progress} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SiteSummaryBox;
