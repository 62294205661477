// A simple component to inform the user they
// do no have the required access permissions.

const Unauthorised = () => {
  return (
    <div className="container-view">
      <h2 className="page-heading">You are not authorised to view this page</h2>
    </div>
  );
};

export default Unauthorised;
