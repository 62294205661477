import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../../layout/Navbar";
import "./Style.css";

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout = (props: LayoutProps) => {
  const { children } = props;

  return (
    <main>
      <Navbar />
      <div className="app-window">
        {children && children}
        <Outlet />
      </div>
    </main>
  );
};
Layout.defaultProps = {
  children: null,
};
export default Layout;
