// import { useContext } from "react";
// import AuthContext from "../../../context/AuthProvider";
// import { IAuth } from "../../../models/Interfaces/IAuth";

const EmptyDashboard = () => {
  // const authentication: IAuth = useContext(AuthContext);

  return (
    <div className="container-view">
      <h2 className="page-heading">Empty Dashboard</h2>
    </div>
  );
};

export default EmptyDashboard;
