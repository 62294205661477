import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import routePaths from "config/RoutePaths";
import storage from "util/storage";
import { useAuth } from "lib/auth";
import { IRequireAuth } from "models/Interfaces/IAuth";

// This RequireAuth component wraps around all non-public routes
// and verifies user access to the route. If the user is not logged in
// or does not have required access permission, this component will
// redirect accordingly.

const RequireAuth = ({ allowedRoles }: IRequireAuth) => {
  const auth = useAuth();

  const currentUser = storage.getUser();

  React.useEffect(() => {
    if (currentUser) {
      // console.log("currentUser", currentUser);
      auth.setUser(currentUser);
    }
  }, []);

  const authRender = () => {
    // @ts-ignore
    if (
      currentUser?.data?.roles?.find((role: any) =>
        allowedRoles?.includes(role),
      )
    ) {
      return <Outlet />;
    }
    if (auth.user?.data?.email) {
      return <Navigate to={routePaths.UNAUTHORISED} />;
    }
    return <Navigate to={routePaths.SIGNIN} />;
  };
  return authRender();
};

export default RequireAuth;
