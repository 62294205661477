import { Grid } from "@material-ui/core";
import Controls from "components/Controls";
import UserAdminTable from "../UserAdminTable";

function AdminUsers() {
  return (
    <div className="container-view">
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div className="page-heading-container">
              <Controls.BackButton />
              <h2 className="page-heading">Manage AndWider Users</h2>
            </div>
          </Grid>
        </Grid>
      </div>
      <UserAdminTable />
    </div>
  );
}

export default AdminUsers;
