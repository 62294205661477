import React from "react";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { Form, useForm } from "hooks/useForm";
import Controls from "components/Controls";
import { ISector } from "models/Interfaces/IOrganisation";
import API from "services/Api.Service";
import routePaths from "config/RoutePaths";
import { API_URLS } from "config/ApiUrls";
import { SuccessToast } from "components/Toaster";
import { useLoading } from "providers/loading";
import "./Style.css";

const initialFValues: ISector = {
  sector: "",
};

const obj = {} as ISector;

export default function CreateSector() {
  const { setAppLoading } = useLoading();
  const navigate = useNavigate();

  const validate = (fieldValues = values) => {
    if ("sector" in fieldValues)
      obj.sector = fieldValues.sector ? "" : "Sector name is required.";
    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange, resetForm } = useForm(
    initialFValues,
    true,
    validate,
  );

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (validate()) {
      setAppLoading(true);
      const url = API_URLS.CreateSector;
      API.post(url, {
        sectorSection: values.sector,
      })
        .then((res: any) => {
          SuccessToast(res?.data.message);
          resetForm();
          // Navigate to sectors screen
          navigate(routePaths.SECTORS);
        })
        .finally(() => {
          setAppLoading(false);
        });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view">
        <Grid container>
          <Grid item xs={12} sm={2} md={2} />
          <Grid item xs={10} sm={8} md={8}>
            <Grid container>
              <Grid item xs={12} className="grid-custom-spacing">
                <div className="page-heading-container">
                  <Controls.BackButton />
                  <h2 className="page-heading">Add Sector</h2>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.Input
                  id="sector"
                  name="sector"
                  label="Sector*"
                  value={values.sector}
                  onChange={handleInputChange}
                  error={obj.sector}
                />
              </Grid>
            </Grid>

            <div className="form-buttons custom-floatRight">
              <br />
              <Controls.Button
                className="button blue"
                type="submit"
                text="Save"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={() => {
                  navigate(routePaths.DASHBOARD);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={2} md={2} />
        </Grid>
      </div>
    </Form>
  );
}
