import { Routes, Route, Navigate } from "react-router-dom";
import routePaths from "config/RoutePaths";
import CreateQuestion from "./CreateQuestion";
import QuestionLanguages from "./QuestionLanguages";
import Questions from ".";
import AddQuestionLanguage from "./AddQuestionLanguage";

export const QuestionsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Questions />} />
      <Route path="/create" element={<CreateQuestion />} />
      <Route path="/:questionId/edit" element={<CreateQuestion />} />
      <Route
        path={routePaths.ADDQUESTIONLANGUAGE}
        element={<AddQuestionLanguage />}
      />
      <Route
        path={routePaths.EDITQUESTIONLANGUAGE}
        element={<AddQuestionLanguage />}
      />
      <Route
        path={routePaths.QUESTIONLANGUAGES}
        element={<QuestionLanguages />}
      />
      <Route path="*" element={<Navigate to={routePaths.QUESTIONS} />} />
    </Routes>
  );
};
