import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useLoading } from "providers/loading";
import Controls from "components/Controls";
import { SuccessToast } from "components/Toaster";
import { API_URLS } from "config/ApiUrls";
import { UseTable } from "hooks/useTable";
import { IMarkDataCycleComplete } from "models/Interfaces/IDataCycle";
import API from "services/Api.Service";
import { MarkDataCycleAsComplete } from "./MarkDataCycleAsComplete";
import { HideSegmentationCharts } from "./HideSegmentationCharts";
import { ShowSegmentationCharts } from "./ShowSegmentationCharts";
import { NukeSurveyCycle } from "./NukeSurveyCycle";
import { ToggleFilters } from "./ToggleFilters";
import "./Style.css";

const DataCycleTable = (props: any) => {
  const { setAppLoading } = useLoading();
  const [modal, setModal] = useState(false);
  const [markAsCompleteModal, setMarkAsCompleteModal] = useState(false);
  const [hideSegModal, setHideSegModal] = useState(false);
  const [showSegModal, setShowSegModal] = useState(false);
  const [nukeModal, setNukeModal] = useState(false);
  const [hideFiltersModal, setHideFiltersModal] = useState(false);
  const [isDataCyclesChecked, setDataCyclesChecked] = useState(false);
  const [dataCycleToBeDeleted, setDataCycleToBeDeleted] = useState({
    dataCycleId: "",
    dataCycleName: "",
  });
  const [dataCycleTable, setdataCycleTable] = useState<any[]>([]);

  const navigate = useNavigate();
  const [selectedDataCycle, setSelectedDataCycle] =
    useState<IMarkDataCycleComplete>({
      dataCycleId: "",
      dataCycleNote: "",
      organisationSiteId: "",
      organisationCoOperation: 0,
      hideFilters: false,
    });

  const navigateToNotes = (dataCycleId: string) => {
    navigate(`${dataCycleId}/notes`);
  };

  const navigateToEdit = (dataCycleId: string) => {
    navigate(`${dataCycleId}/edit`);
  };

  const navigateToView = (dataCycleId: string) => {
    navigate(`${dataCycleId}/view`);
  };

  const navigateToTranslations = (dataCycleId: string) => {
    navigate(`${dataCycleId}/translations`);
  };

  const selectAllWorkplaceVisibility = (e: boolean) => {
    const newOrganisations = dataCycleTable.map((item: any) => ({
      ...item,
      isDataCycleIdChecked: e,
    }));

    setdataCycleTable(newOrganisations);
    props.onBulkButtonClick(newOrganisations);
    setDataCyclesChecked(e);
  };

  const selectOrganisationSiteId = (event: any, rowData: any) => {
    setdataCycleTable((prevState: any[]) => {
      const newState = prevState.map((organisation) => {
        if (organisation.dataCycleId === rowData.dataCycleId) {
          return {
            ...organisation,
            isDataCycleIdChecked: event.target.value,
          };
        }
        return organisation;
      });
      props.onBulkButtonClick(newState);
      return newState;
    });
  };

  const columns = [
    {
      title: (
        <div>
          <Controls.CheckBox
            id="workplaceVisibilityAll"
            label=""
            name="workplaceVisibilityAll"
            onChange={(e) => selectAllWorkplaceVisibility(e.target.value)}
            checked={isDataCyclesChecked}
          />
        </div>
      ),
      field: "isDataCycleIdChecked",
      sorting: false,
      render: (rowData: any) => (
        <div>
          <Controls.CheckBox
            id={rowData.organisationId}
            label=""
            name="organisationSite"
            onChange={(e) => selectOrganisationSiteId(e, rowData)}
            checked={rowData.isDataCycleIdChecked}
            value={rowData.isDataCycleIdChecked}
          />
        </div>
      ),
    },
    { title: "BUSINESS", field: "organisationName" },
    { title: "SITE", field: "organisationSiteName" },
    { title: "SURVEY CYCLE", field: "dataCycleName" },
    {
      title: "SURVEY TEMPLATE",
      field: "surveyTemplateName",
    },
    {
      title: "START DATE",
      field: "createdOn",
      render: (rowData: any) => (
        <div className="createdOn">
          {moment(rowData.startDate).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      title: "MANAGE",
      field: "edit",
      render: (rowData: any) => {
        return (
          <div className="actions-container">
            <p onClick={() => navigateToNotes(rowData?.dataCycleId)}>Notes</p>
            {rowData.isEditable && !rowData.isCompleted && (
              <>
                |
                <p onClick={() => navigateToEdit(rowData?.dataCycleId)}>Edit</p>
              </>
            )}
            {rowData.isCompleted && (
              <>
                |
                <p onClick={() => navigateToView(rowData?.dataCycleId)}>View</p>
              </>
            )}
            {rowData.isCompleted && (
              <>
                |<p onClick={() => toggleNukeModal(rowData)}>Nuke</p>
              </>
            )}
            {!rowData.isCompleted && (
              <>
                |
                <p onClick={() => toggleMarkAsCompleteModal(rowData)}>
                  Mark As Complete
                </p>
                |
                <p onClick={() => navigateToTranslations(rowData?.dataCycleId)}>
                  View Translations
                </p>
              </>
            )}
            {rowData.isCompleted && !rowData.hideSegmentationChart && (
              <>
                |
                <p
                  className="actions-container"
                  onClick={() => toggleHideSegModal(rowData)}
                >
                  Hide Segmentation
                </p>
              </>
            )}
            {rowData.isCompleted && rowData.hideSegmentationChart && (
              <>
                |
                <p
                  className="actions-container"
                  onClick={() => toggleShowSegModal(rowData)}
                >
                  Show Segmentation
                </p>
              </>
            )}
            {!rowData.hideFilters && (
              <>
                |
                <p
                  className="actions-container"
                  onClick={() => toggleHideFiltersModal(rowData)}
                >
                  Disable Filters
                </p>
              </>
            )}
            {rowData.hideFilters && (
              <>
                |
                <p
                  className="actions-container"
                  onClick={() => toggleHideFiltersModal(rowData)}
                >
                  Enable Filters
                </p>
              </>
            )}
            {!rowData.isCompleted && (
              <>
                |
                <p
                  className="actions-container-delete"
                  onClick={() => toggleModal(rowData)}
                >
                  Delete
                </p>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const toggleModal = (rowData: any) => {
    setDataCycleToBeDeleted({
      dataCycleId: rowData.dataCycleId,
      dataCycleName: rowData.dataCycleName,
    });
    setModal(!modal);
  };

  const toggleHideFiltersModal = (rowData: any) => {
    setSelectedDataCycle({
      dataCycleId: rowData.dataCycleId,
      dataCycleNote: "",
      organisationSiteId: rowData.organisationSiteId,
      organisationCoOperation: 0,
      hideFilters: rowData.hideFilters,
    });
    setHideFiltersModal(!hideFiltersModal);
  };

  const toggleMarkAsCompleteModal = (rowData: any) => {
    setSelectedDataCycle({
      dataCycleId: rowData.dataCycleId,
      dataCycleNote: "",
      organisationSiteId: rowData.organisationSiteId,
      organisationCoOperation: 0,
      hideFilters: rowData.hideFilters,
    });
    setMarkAsCompleteModal(!markAsCompleteModal);
  };

  const toggleHideSegModal = (rowData: any) => {
    setSelectedDataCycle({
      dataCycleId: rowData.dataCycleId,
      dataCycleNote: "",
      organisationSiteId: rowData.organisationSiteId,
      organisationCoOperation: 0,
      hideFilters: rowData.hideFilters,
    });
    setHideSegModal(!hideSegModal);
  };

  const toggleShowSegModal = (rowData: any) => {
    setSelectedDataCycle({
      dataCycleId: rowData.dataCycleId,
      dataCycleNote: "",
      organisationSiteId: rowData.organisationSiteId,
      organisationCoOperation: 0,
      hideFilters: rowData.hideFilters,
    });
    setShowSegModal(!showSegModal);
  };

  const toggleNukeModal = (rowData: any) => {
    setSelectedDataCycle({
      dataCycleId: rowData.dataCycleId,
      dataCycleNote: "",
      organisationSiteId: rowData.organisationSiteId,
      organisationCoOperation: 0,
      hideFilters: rowData.hideFilters,
    });
    setNukeModal(!nukeModal);
  };

  const closeModal = () => {
    setModal(false);
  };

  const deleteDataCycle = () => {
    setAppLoading(true);
    API.delete(API_URLS.DeleteDataCycle, {
      params: {
        dataCycleId: dataCycleToBeDeleted.dataCycleId,
      },
    })
      .then((res: any) => {
        SuccessToast(res?.data?.message);
        setdataCycleTable(
          dataCycleTable.filter(
            (dc) => dc.dataCycleId !== dataCycleToBeDeleted.dataCycleId,
          ),
        );
        setModal(false);
      })
      .finally(() => {
        setAppLoading(false);
      });
  };

  const markDataCycleAsComplete = (values: IMarkDataCycleComplete) => {
    setAppLoading(true);
    try {
      API.post(API_URLS.MarkDataCycleAsComplete, values)
        .then((res: any) => {
          SuccessToast(res?.data?.message);
          API.get(API_URLS.GetDataCycles, {
            params: {
              OrderBy: "dataCycleId",
              SearchValue: "null",
            },
          })
            .then((response: any) => {
              setdataCycleTable(response.data.data);
            })
            .finally(() => {
              setAppLoading(false);
            });
          setMarkAsCompleteModal(false);
        })
        .finally(() => {
          setAppLoading(false);
        });
    } catch (error) {
      setAppLoading(false);
    }
  };

  const hideSegmentationCharts = (values: IMarkDataCycleComplete) => {
    setAppLoading(true);
    try {
      API.post(API_URLS.HideSegmentationCharts, {
        segmentationChartRequest: {
          dataCycleId: values.dataCycleId,
          hideSegmentationChart: true,
        },
      })
        .then((res: any) => {
          SuccessToast(res?.data?.message);
          API.get(API_URLS.GetDataCycles, {
            params: {
              OrderBy: "dataCycleId",
              SearchValue: "null",
            },
          }).then((response: any) => {
            setdataCycleTable(response.data.data);
          });
          setHideSegModal(false);
        })
        .finally(() => {
          setAppLoading(false);
        });
    } catch (error) {
      setAppLoading(false);
    }
  };

  const showSegmentationCharts = (values: IMarkDataCycleComplete) => {
    setAppLoading(true);
    try {
      API.post(API_URLS.HideSegmentationCharts, {
        segmentationChartRequest: {
          dataCycleId: values.dataCycleId,
          hideSegmentationChart: false,
        },
      })
        .then((res: any) => {
          SuccessToast(res?.data?.message);
          API.get(API_URLS.GetDataCycles, {
            params: {
              OrderBy: "dataCycleId",
              SearchValue: "null",
            },
          }).then((response: any) => {
            setdataCycleTable(response.data.data);
          });
          setShowSegModal(false);
        })
        .finally(() => {
          setAppLoading(false);
        });
    } catch (error) {
      setAppLoading(false);
    }
  };

  const nukeSurveyCycle = (values: IMarkDataCycleComplete) => {
    setAppLoading(true);
    try {
      API.delete(API_URLS.NukeSurveyCycle, {
        params: {
          surveyCycleId: values.dataCycleId,
        },
      })
        .then((res: any) => {
          SuccessToast(res?.data?.message);
          API.get(API_URLS.GetDataCycles, {
            params: {
              OrderBy: "dataCycleId",
              SearchValue: "null",
            },
          }).then((response: any) => {
            setdataCycleTable(response.data.data);
          });
          setNukeModal(false);
        })
        .finally(() => {
          setAppLoading(false);
        });
    } catch (error) {
      setAppLoading(false);
    }
  };

  // Added method which sends a request to the API
  // to toggle showing filters for a survey cycle,
  // dependent on what value is currently saved for the survey cycle
  const toggleFilters = (values: IMarkDataCycleComplete) => {
    setAppLoading(true);
    try {
      API.post(API_URLS.ToggleFilters, {
        filtersRequest: {
          dataCycleId: values.dataCycleId,
          toggleFilters: !values.hideFilters,
        },
      })
        .then((res: any) => {
          SuccessToast(res?.data?.message);
          API.get(API_URLS.GetDataCycles, {
            params: {
              OrderBy: "dataCycleId",
              SearchValue: "null",
            },
          }).then((response: any) => {
            setdataCycleTable(response.data.data);
          });
          setHideFiltersModal(false);
        })
        .finally(() => {
          setAppLoading(false);
        });
    } catch (error) {
      setAppLoading(false);
    }
  };

  useEffect(() => {
    setAppLoading(true);

    API.post(API_URLS.GetFilteredDataCycles, {
      OrganisationIds: props.filters.organisations.map((x: any) => x.id),
      SiteIds: props.filters.sites.map((x: any) => x.id),
      TagIds: props.filters.tags.map((x: any) => x.id),
      StartDate: props.filters.date,
      IsCompleted: props.filters.statuses ? null : props.filters.isCompleted,
      SegmentationChartsHidden: props.filters.statuses
        ? null
        : props.filters.chartsHidden,
      FiltersHidden: props.filters.statuses
        ? null
        : props.filters.filtersHidden,
    })
      .then((response: any) => {
        setdataCycleTable(response.data.data);
        props.onBulkButtonClick(response.data.data);
      })
      .finally(() => {
        setAppLoading(false);
      });
  }, [JSON.stringify(props.filters), props.refreshTable]);

  return (
    <div>
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay" />
          <div className="modal-content">
            <h2>Delete Survey cycle</h2>
            <p>
              You are about to delete the following survey cycle. Are you sure
              you want to proceed?
            </p>
            <p>Survey cycle: {dataCycleToBeDeleted.dataCycleName}</p>
            <br />
            <div className="form-buttons custom-floatRight">
              <Controls.Button
                className="button blue"
                onClick={deleteDataCycle}
                text="Delete"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={closeModal}
              />
            </div>
          </div>
        </div>
      )}
      {markAsCompleteModal && (
        <MarkDataCycleAsComplete
          data={selectedDataCycle}
          toggleMarkAsCompleteModal={toggleMarkAsCompleteModal}
          setMarkAsCompleteModal={setMarkAsCompleteModal}
          markDataCycleAsComplete={markDataCycleAsComplete}
        />
      )}
      {hideSegModal && (
        <HideSegmentationCharts
          data={selectedDataCycle}
          toggleHideSegModal={toggleHideSegModal}
          setHideSegModal={setHideSegModal}
          hideSegmentationCharts={hideSegmentationCharts}
        />
      )}
      {showSegModal && (
        <ShowSegmentationCharts
          data={selectedDataCycle}
          toggleShowSegModal={toggleShowSegModal}
          setShowSegModal={setShowSegModal}
          showSegmentationCharts={showSegmentationCharts}
        />
      )}
      {nukeModal && (
        <NukeSurveyCycle
          data={selectedDataCycle}
          toggleNukeModal={toggleNukeModal}
          setNukeModal={setNukeModal}
          nukeSurveyCycle={nukeSurveyCycle}
        />
      )}
      {hideFiltersModal && (
        <ToggleFilters
          data={selectedDataCycle}
          toggleHideFiltersModal={toggleHideFiltersModal}
          setHideFiltersModal={setHideFiltersModal}
          toggleFilters={toggleFilters}
        />
      )}
      <UseTable
        columns={columns}
        data={dataCycleTable}
        title="Survey Cycle"
        filtering={false}
      />
    </div>
  );
};

export default DataCycleTable;
