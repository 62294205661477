import React from "react";
import Controls from "../../../components/Controls";
import { Form, useForm } from "../../../hooks/useForm";
import { IMarkDataCycleComplete } from "../../../models/Interfaces/IDataCycle";
import "./Style.css";

type MarkDataCycleAsCompleteProps = {
  data: IMarkDataCycleComplete;
  toggleMarkAsCompleteModal: (rowData: any) => void;
  setMarkAsCompleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  markDataCycleAsComplete: (values: IMarkDataCycleComplete) => void;
};

const fieldError = {} as IMarkDataCycleComplete;

export const MarkDataCycleAsComplete = (
  props: MarkDataCycleAsCompleteProps,
) => {
  const {
    toggleMarkAsCompleteModal,
    setMarkAsCompleteModal,
    markDataCycleAsComplete,
    data,
  } = props;

  const initialFormValues: IMarkDataCycleComplete = {
    dataCycleId: data.dataCycleId,
    dataCycleNote: data.dataCycleNote,
    organisationSiteId: data.organisationSiteId,
    organisationCoOperation: data.organisationCoOperation,
    hideFilters: data.hideFilters,
  };

  const validate = (fieldValues = values) => {
    if ("dataCycleId" in fieldValues)
      fieldError.dataCycleId = fieldValues.dataCycleId
        ? ""
        : "Survey cycle is required.";

    // if ("dataCycleNote" in fieldValues)
    //   fieldError.dataCycleNote =
    //     fieldValues.dataCycleNote.length !== 0
    //       ? ""
    //       : "Survey cycle note is required.";

    if ("organisationSiteId" in fieldValues)
      fieldError.organisationSiteId =
        fieldValues.organisationSiteId.length !== 0
          ? ""
          : "Organisation workplace name is required.";

    // if ("organisationCoOperation" in fieldValues)
    //   fieldError.organisationCoOperation =
    //     fieldValues.organisationCoOperation.length !== 0
    //       ? ""
    //       : "Organisation CoOperation is required.";

    setErrors({ ...fieldError });
    if (fieldValues === values)
      return Object.values(fieldError).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange, handleRatingChange } = useForm(
    initialFormValues,
    true,
    validate,
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validate()) {
      markDataCycleAsComplete(values);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="modal">
        <div onClick={toggleMarkAsCompleteModal} className="overlay" />
        <div className="modal-content">
          <p>Please rate the business's cooperation during this rollout.</p>
          <Controls.Rating
            name="organisationCoOperation"
            value={values.organisationCoOperation}
            onChange={handleRatingChange}
          />
          <br />
          <Controls.Input
            id="dataCycleNote"
            name="dataCycleNote"
            label="Survey cycle note"
            value={values.dataCycleNote}
            onChange={handleInputChange}
            error={fieldError.dataCycleNote}
            multiline
            variant="outlined"
          />
          <div className="form-buttons custom-floatRight">
            <Controls.Button
              className="button blue"
              type="submit"
              text="Mark As Complete"
            />
            <Controls.Button
              text="Cancel"
              className="cancel-button"
              variant="outlined"
              onClick={() => setMarkAsCompleteModal(false)}
            />
          </div>
        </div>
      </div>
    </Form>
  );
};
