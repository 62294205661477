import { IAdminNavItem } from "../models/Interfaces/INavBar";
import routePaths from "./RoutePaths";

// Created three different menu configurations:
// 1. &Wider Admin Menu
// 2. Organisation Admin Menu
// 3. Organisation Basic Menu
// 4. Blank Menu
//
// We can add more configurations as needed

export const AdminMenuConfig: IAdminNavItem[] = [
  {
    title: "RESULTS",
    id: "reports",
    path: `${routePaths.REPORTS}`,
    submenu: null,
  },
  {
    title: "BUSINESSES",
    id: "organisations",
    path: "",
    submenu: [
      {
        title: "Manage Businesses",
        path: `${routePaths.ORGANISATIONS}`,
        cName: "dropdown-link",
      },
      {
        title: "Add Business",
        path: `${routePaths.CREATEORGANISATIONS}`,
      },
      {
        title: "Manage Tags",
        path: `${routePaths.TAGS}`,
      },
      // {
      //   title: "Manage Sectors",
      //   path: `${routePaths.SECTORS}`,
      // },
      // {
      //   title: "Manage Sub Sectors",
      //   path: `${routePaths.SUBSECTORS}`,
      // },
    ],
  },
  {
    title: "WORKPLACES",
    id: "sites",
    path: "",
    submenu: [
      {
        title: "Manage Workplaces",
        path: `${routePaths.SITEMANAGEMENT}`,
      },
      {
        title: "Add Workplace",
        path: `${routePaths.CREATESITE}`,
      },
    ],
  },
  {
    title: "QUESTIONS",
    id: "questions",
    path: "",
    submenu: [
      {
        title: "Manage Questions",
        path: `${routePaths.QUESTIONS}`,
      },
      {
        title: "Manage Languages",
        path: `${routePaths.LANGUAGES}`,
      },
      {
        title: "Add Question",
        path: `${routePaths.CREATEQUESTION}`,
      },
      {
        title: "Add Language",
        path: `${routePaths.ADDLANGUAGE}`,
      },
    ],
  },
  {
    title: "SURVEY TEMPLATES",
    id: "surveyTemplates",
    path: "",
    submenu: [
      {
        title: "Manage Templates",
        path: `${routePaths.SURVEYTEMPLATES}`,
      },
      {
        title: "Add Template",
        path: `${routePaths.CREATETEMPLATE}`,
      },
      {
        title: "Add Template Type",
        path: `${routePaths.CREATETEMPLATETYPE}`,
      },
    ],
  },
  {
    title: "SURVEY CYCLES",
    id: "datacycles",
    path: "",
    submenu: [
      {
        title: "Manage Survey Cycles",
        path: `${routePaths.DATACYCLES}`,
      },
      {
        title: "Add Survey Cycle",
        path: `${routePaths.CREATEDATACYCLE}`,
      },
      {
        title: "Add Multiple Survey Cycles",
        path: `${routePaths.CREATEMULTIPLEDATACYCLES}`,
      },
    ],
  },
  {
    title: "RESOURCES",
    id: "resources",
    path: "",
    submenu: [
      {
        title: "Manage Resources",
        path: `${routePaths.RESOURCES}`,
      },
      {
        title: "Add Resource",
        path: `${routePaths.CREATERESOURCE}`,
      },
      {
        title: "View Resources",
        path: `${routePaths.VIEWRESOURCES}`,
      },
    ],
  },
  {
    title: "USERS",
    id: "users",
    path: "",
    submenu: [
      {
        title: "Manage &Wider Users",
        path: `${routePaths.USERS}`,
      },
      {
        title: "Manage Client Users",
        path: `${routePaths.CLIENTUSERS}`,
      },
      {
        title: "Register &Wider Admin",
        path: `/users${routePaths.REGISTERADMINISTRATOR}`,
      },
    ],
  },
  {
    title: "PROFILE",
    path: "my-profile",
    icon: true,
    iconPath: "fa fa-user-circle",
    submenu: [
      {
        title: "Manage password & email",
        path: `${routePaths.MANAGEPROFILE}`,
      },
      {
        title: "Sign out",
        path: `${routePaths.SIGNOUT}`,
      },
    ],
  },
];

export const OrgAdminMenuConfig = [
  {
    title: "RESULTS",
    id: "reports",
    path: `${routePaths.REPORTS}`,
    submenu: null,
  },
  {
    title: "USERS",
    id: "users",
    path: "",
    submenu: [
      {
        title: "Manage Users",
        path: `${routePaths.ORGANISATIONADMIN}`,
        cName: "dropdown-link",
      },
      {
        title: "Invite User",
        path: `/admin${routePaths.ORGANISATIONINVITEUSER}`,
        cName: "dropdown-link",
      },
    ],
  },
  {
    title: "HELP",
    id: "help",
    path: "",
    submenu: [
      {
        title: "View Resources",
        path: `${routePaths.VIEWRESOURCES}`,
      },
    ],
  },
  {
    title: "PROFILE",
    path: "my-profile",
    icon: true,
    iconPath: "fa fa-user-circle",
    submenu: [
      {
        title: "Manage password & email",
        path: `${routePaths.MANAGEPROFILE}`,
      },
      {
        title: "Developer API credentials",
        path: `${routePaths.DEVELOPERAPICREDENTIALS}`,
      },
      {
        title: "Sign out",
        path: `${routePaths.SIGNOUT}`,
      },
    ],
  },
];

export const OrgGeneralMenuConfig = [
  {
    title: "RESULTS",
    id: "reports",
    path: `${routePaths.REPORTS}`,
    submenu: null,
  },
  {
    title: "HELP",
    id: "help",
    path: "",
    submenu: [
      {
        title: "View Resources",
        path: `${routePaths.VIEWRESOURCES}`,
      },
    ],
  },
  {
    title: "PROFILE",
    path: "my-profile",
    icon: true,
    iconPath: "fa fa-user-circle",
    submenu: [
      {
        title: "Manage password & email",
        path: `${routePaths.MANAGEPROFILE}`,
      },
      {
        title: "Developer API credentials",
        path: `${routePaths.DEVELOPERAPICREDENTIALS}`,
      },
      {
        title: "Sign out",
        path: `${routePaths.SIGNOUT}`,
      },
    ],
  },
];

export const BlankMenuConfig = [];
