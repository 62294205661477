import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { ISelectAutoComplete } from "../../../models/Interfaces/IControls";

export default function AutoComplete({
  id,
  name,
  label,
  error = null,
  options,
  onChange,
  onInputChange,
  varient,
  inputValue,
  multiple = false,
  disabled = false,
  values,
  margin = "dense",
}: ISelectAutoComplete) {
  return (
    <FormControl size="small" margin="dense" {...(error && { error: true })}>
      <Autocomplete
        multiple={multiple}
        disabled={disabled}
        disableClearable
        autoHighlight
        freeSolo
        // defaultValue={value}
        value={values}
        inputValue={inputValue}
        id={id}
        onChange={(event, selectObj) => {
          onChange(selectObj, name);
        }}
        onInputChange={(event, newInputValue) => {
          if (event != null) {
            if (newInputValue === "") {
              onChange("", name);
              onInputChange(newInputValue, name);
            } else {
              onInputChange(newInputValue, name);
            }
          }
        }}
        options={options}
        getOptionLabel={(option: any) => option.label || ""}
        renderInput={(params: any) => {
          return (
            <TextField
              value={inputValue}
              disabled
              {...params}
              label={label}
              margin={margin}
              size="small"
              name={name}
              InputProps={{ ...params.InputProps, type: "search" }}
              varient={varient}
              {...(error && { error: true })}
            />
          );
        }}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
