import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { API_URLS } from "config/ApiUrls";
import API from "services/Api.Service";
import "./Style.css";
import { useLoading } from "providers/loading";
import { useAuth } from "lib/auth";
import { RolesEnum } from "models/Enum/Roles";
import { MapPinColorsEnum } from "../../../models/Enum/EnumTypes";
import WhatToLookFor from "./WhatToLookFor";
import SiteSummary from "./SiteSummary";
import MapFilters from "./MapFilters";
import MapSites from "./MapSites";
import AvgSiteScore from "./AvgSiteScore";
import MapDownloadModal from "./MapDownloadModal";

function SiteMaps({ organisationId, orgName, clientOrgIds }: any) {
  const location: any = useLocation();
  organisationId =
    organisationId !== null &&
    organisationId !== undefined &&
    organisationId !== ""
      ? organisationId
      : location.state.organisationId
      ? location.state.organisationId
      : "";
  const clientId =
    location.state && location.state.clientId ? location.state.clientId : "";
  orgName =
    orgName !== null && orgName !== undefined
      ? orgName
      : location.state.orgName
      ? location.state.orgName
      : "";
  const clientName =
    location.state && location.state.clientName
      ? location.state.clientName
      : "";
  clientOrgIds =
    clientOrgIds !== null &&
    clientOrgIds !== undefined &&
    clientOrgIds.length > 0
      ? clientOrgIds
      : location.state.clientOrgIds && location.state.clientOrgIds.length > 0
      ? location.state.clientOrgIds
      : [];
  const [surveyTemplateTypes, setSurveyTemplateTypes] = useState<any>([]);
  const [markers, setMarkers] = useState<any>([]);
  const [groups, setGroups] = useState<any>([]);
  const [selectedSurveyTemplateTypeId, setSelectedSurveyTemplateTypeId] =
    useState("");
  const [surveyTemplateTypeName, setSurveyTemplateTypeName] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(clientId);
  const [selectedUserName, setSelectedUserName] = useState(clientName);
  const [selectedOrgId, setSelectedOrgId] = useState(organisationId);
  const [selectedOrgName, setSelectedOrgName] = useState(orgName);
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [selectedGroupName, setSelectedGroupName] = useState("");
  const [groupScore, setGroupScore] = useState(-1);
  const [modal, setModal] = useState(false);
  const { setAppLoading } = useLoading();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey:
      "AIzaSyC1y3eMCmRmphlZ52OsC_O8acxYIWeRbJc&callback=Function.prototype",
  });

  const [organisationSiteIds, setOrganisationSiteIds] = useState<any>([]);
  const [selectedSiteIds, setSelectedSiteIds] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [organisations, setOrganisations] = useState<any>([]);
  const [filteredOrganisations, setFilteredOrganisations] = useState<any>([]);
  const [, setSiteTextSearch] = useState<any>("");
  const [timeClicked, setTimeClicked] = useState<any>(null);
  const [avgSiteScore, setAvgSiteScore] = useState<any>(null);

  const auth = useAuth();
  const isAdmin = auth?.user?.data?.roles?.includes(RolesEnum.Admin);
  const mapRef = useRef<google.maps.Map | null>(null);

  const options = {
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM,
    },
    mapTypeControlOptions: {
      style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: google.maps.ControlPosition.BOTTOM_CENTER,
    },
    disableDefaultUI: true,
    fullscreenControl: true,
    styles: [
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      // {
      //   "featureType": "administrative",
      //   "elementType": "geometry",
      //   "stylers": [
      //     {
      //       "visibility": "off"
      //     }
      //   ]
      // },
      // {
      //   "featureType": "administrative.land_parcel",
      //   "stylers": [
      //     {
      //       "visibility": "off"
      //     }
      //   ]
      // },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#bdbdbd",
          },
        ],
      },
      {
        featureType: "administrative.neighborhood",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "administrative.country",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#4d4d4fff",
          },
        ],
      },
      {
        featureType: "poi",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      // {
      //   "featureType": "road",
      //   "elementType": "geometry",
      //   "stylers": [
      //     {
      //       "visibility": "off"
      //     }
      //   ]
      // },
      {
        featureType: "road",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road.arterial",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#dadada",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        featureType: "road.local",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "transit",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#add8e6",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
    ],
  };

  const handleActiveMarker = (selectedLocation: any) => {
    if (
      selectedSiteIds.findIndex(
        (object: any) => object.key === selectedLocation?.key,
      ) === -1
    ) {
      selectedSiteIds.push({
        key: selectedLocation?.key,
        label: selectedLocation?.label,
        score: selectedLocation?.score,
        organisationId: selectedLocation?.organisationId,
        isExpanded: true,
      });

      setSelectedSiteIds([...selectedSiteIds]);
    }
  };

  useEffect(() => {
    // setAppLoading(true);
    const fetchData = async () => {
      try {
        // Start all your GET requests asynchronously
        const getTypes = API.get(API_URLS.GetSurveyTemplateTypes, {
          params: {
            organisationId,
            userId: clientId != null ? clientId : "",
          },
        }).then((response: any) => {
          const templateTypeOptions = response.data.data.map((x: any) => {
            return {
              id: x.surveyTemplateTypeId,
              label: x.surveyTemplateTypeName,
              code: x.surveyTemplateTypeCode,
            };
          });
          setSurveyTemplateTypes(templateTypeOptions);
          const currentTemplateType = templateTypeOptions[0];
          if (currentTemplateType) {
            setSelectedSurveyTemplateTypeId(currentTemplateType.id);
            setSurveyTemplateTypeName(currentTemplateType.label);
          }
        });

        const getOrgs = API.get(API_URLS.GetOrganisationsWithSites, {
          params: {
            OrderBy: "OrganisationId",
            SearchValue: null,
          },
        }).then((response: any) => {
          const orgs = response.data.data.map((x: any) => {
            return { id: x.organisationId, label: x.organisationName };
          });

          let organisationOptions: any = [];

          if (!isAdmin) {
            if (auth?.user?.data?.userOrganisations) {
              if (auth?.user?.data?.userOrganisations?.length > 1) {
                const userOrgIds = auth?.user?.data?.userOrganisations.map(
                  (x: any) => {
                    return x.organisationId;
                  },
                );
                organisationOptions = orgs.filter((x: any) => {
                  return userOrgIds.includes(x.id);
                });
              } else {
                const userOrgIds = auth?.user?.data?.userOrganisations.map(
                  (x: any) => {
                    return x.organisationId;
                  },
                );
                organisationOptions = orgs.filter((x: any) => {
                  return userOrgIds.includes(x.id);
                });
              }
            }

            organisationOptions = [
              {
                id: "all",
                label: "All Businesses",
              },
              ...organisationOptions,
            ];
          } else if (selectedUserId !== "") {
            organisationOptions = orgs.filter((x: any) => {
              return clientOrgIds.includes(x.id);
            });

            organisationOptions = [
              {
                id: "all",
                label: "All Businesses",
              },
              ...organisationOptions,
            ];
          } else {
            organisationOptions = orgs;
          }
          setFilteredOrganisations(organisationOptions);
          setOrganisations(orgs);
        });

        const getUsers = API.get(API_URLS.GetUserList, {
          params: {},
        }).then((response: any) => {
          const userList = response.data.data
            .filter((x: any) => {
              return x.userOrganisationDetails.length > 0;
            })
            .map((x: any) => {
              return {
                id: x.userId,
                label: x.userName,
                orgs: x.userOrganisationDetails.map((y: any) => {
                  return y.organisationId;
                }),
              };
            });
          setUsers(userList);
        });

        // Wait for all requests to complete
        await Promise.all([getOrgs, getUsers, getTypes]);
      } finally {
        // All requests completed, set isLoading to false
        // setAppLoading(false);
      }
    };

    fetchData();
    return () => {
      // storage.clearSites();
      // setAppLoading(false);
    };
  }, []);

  useEffect(() => {
    if (isAdmin) {
      API.get(API_URLS.GetTags, {
        params: {},
      }).then((response: any) => {
        const groupOptions = response.data.data.map((x: any) => {
          return { id: x.tagId, label: x.tagName };
        });
        setGroups(groupOptions);
      });
    } else {
      const groupOptions = auth?.user?.data?.userTags.map((x: any) => {
        return {
          id: x.tagId,
          label: x.tagName,
        };
      });
      setGroups(groupOptions);
    }
  }, [isAdmin]);

  useEffect(() => {
    if (selectedSurveyTemplateTypeId && selectedOrgId) {
      setAppLoading(true);
      API.get(API_URLS.GetSitesForMap, {
        params: {
          organisationId: selectedOrgId,
          surveyTemplateTypeId: selectedSurveyTemplateTypeId,
          clientId: selectedUserId,
        },
      })
        .then((response: any) => {
          if (response.data.data.length > 0) {
            const sites = response.data.data.map((x: any) => {
              return {
                key: x.organisationSiteId,
                label: x.organisationSiteName,
                score: x.siteRating,
                latitude: x.latitude,
                longitude: x.longitude,
                organisationId: x.organisationId,
              };
            });
            setSelectedSiteIds([]);
            setOrganisationSiteIds(sites);

            const currentTemplateType = surveyTemplateTypes.find(
              (x: any) => x.id === response.data.data[0].surveyTemplateTypeId,
            );
            if (currentTemplateType) {
              setSelectedSurveyTemplateTypeId(currentTemplateType.id);
              setSurveyTemplateTypeName(currentTemplateType.label);
            }

            const markerList = response.data.data.map((x: any) => {
              let mapIconColor = MapPinColorsEnum.NoResults;
              if (x.siteRating < 0) {
                mapIconColor = MapPinColorsEnum.NoResults;
              } else if (x.siteRating >= 0 && x.siteRating <= 5) {
                mapIconColor = MapPinColorsEnum.Negative;
              } else if (x.siteRating > 5 && x.siteRating <= 7.5) {
                mapIconColor = MapPinColorsEnum.Amber;
              } else if (x.siteRating > 7.5) {
                mapIconColor = MapPinColorsEnum.Positive;
              }

              const lat = parseFloat(x.latitude);
              const lng = parseFloat(x.longitude);

              let label = " ";

              label =
                x.siteRating > 0
                  ? x.siteRating.toString()
                  : x.siteRating === -1
                  ? "N/A"
                  : " ";

              const svgMarker = {
                path: "M 384 192 c 0 87.4 -118 240 -168.3 307.2 c -34.7 48.8 -10.7 48.8 -47.4 0 C 119 433 0 279.4 0 192 C 0 86 86 0 192 0 S 384 86 384 192 z",
                fillColor: mapIconColor,
                fillOpacity: 1,
                strokeColor: "#000000",
                strokeWeight: 1.5,
                rotation: 0,
                scale: 0.09,
                anchor: new google.maps.Point(225, 425),
                labelOrigin: new google.maps.Point(192, 192),
              };

              const marker: any = {
                position: { lat, lng },
                icon: svgMarker,
                label: {
                  text: label,
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "bold",
                },
                onClickParameters: {},
              };

              if (x.siteRating !== -2) {
                marker.onClickParameters = {
                  key: x.organisationSiteId,
                  label: x.organisationSiteName,
                  score: x.siteRating,
                  organisationId: x.organisationId,
                };
              }

              return marker;
            });

            setMarkers(markerList);
            setAvgSiteScore(response.data.averageSiteScore);
          } else {
            setSelectedSiteIds([]);
            setOrganisationSiteIds([]);
            setMarkers([]);
          }
        })
        .finally(() => {
          setAppLoading(false);
        });
    }

    return () => {
      setAppLoading(false);
    };
  }, [selectedSurveyTemplateTypeId, selectedOrgId]);

  useEffect(() => {
    if (organisationSiteIds.length > 0) {
      // Calculate the bounds of the markers
      const bounds = new window.google.maps.LatLngBounds();
      organisationSiteIds.forEach((x: any) => {
        bounds.extend({
          lat: parseFloat(x.latitude),
          lng: parseFloat(x.longitude),
        });
      });

      if (mapRef.current) {
        mapRef.current.fitBounds(bounds);

        // Retrieve the current zoom level
        const currentZoom = mapRef.current?.getZoom();

        // Set the minimum zoom level
        const minimumZoom = 10; // Adjust as needed

        // Adjust the zoom level if below the minimum
        if (currentZoom && currentZoom > minimumZoom) {
          mapRef.current.setZoom(minimumZoom);
        }
      }
    }

    return () => {
      setAppLoading(false);
    };
  }, [JSON.stringify(organisationSiteIds)]);

  useEffect(() => {
    if (selectedGroupId !== "" && selectedGroupName !== "") {
      setAppLoading(true);
      API.get(API_URLS.GetTaggedSites, {
        params: {
          tagId: selectedGroupId,
          surveyTemplateTypeId: selectedSurveyTemplateTypeId,
        },
      })
        .then((response: any) => {
          // if (response.data.averageSiteScore > 0) {
          //   setGroupScore(response.data.averageSiteScore);
          // }
          setGroupScore(response.data.averageSiteScore);
        })
        .finally(() => {
          setAppLoading(false);
        });
    } else {
      setGroupScore(-1);
    }
  }, [selectedGroupId, selectedGroupName, selectedSurveyTemplateTypeId]);

  const handleSelectSurveyTemplate = (selectedObject: any) => {
    setSelectedSurveyTemplateTypeId(selectedObject.id);
    setSurveyTemplateTypeName(selectedObject.label);
  };

  const handleSelectSurveyTemplateTextInputChange = (text: any) => {
    setSurveyTemplateTypeName(text);
  };

  const handleSelectUser = (selectedObject: any) => {
    setSelectedUserId(selectedObject.id);
    setSelectedUserName(selectedObject.label);
    setSelectedOrgId("");
    setSelectedOrgName("");
    updateFilteredOrganisations(selectedObject);
  };

  const handleSelectUserTextInputChange = (text: any) => {
    setSelectedUserName(text);
  };

  const handleSelectOrg = (selectedObject: any) => {
    setSelectedOrgId(selectedObject.id);
    setSelectedOrgName(selectedObject.label);
  };

  const handleSelectOrgTextInputChange = (text: any) => {
    setSelectedOrgName(text);
  };

  const handleSelectGroup = (selectedObject: any) => {
    setSelectedGroupId(selectedObject.id);
    setSelectedGroupName(selectedObject.label);
  };

  const handleSelectGroupTextInputChange = (text: any) => {
    setSelectedGroupName(text);
  };

  // Filter the organisations when the user is selected
  const updateFilteredOrganisations = (user: any) => {
    if (!user) {
      setFilteredOrganisations(organisations);
      return;
    }

    let organisationOptions = organisations.filter((x: any) => {
      return user.orgs.includes(x.id);
    });

    organisationOptions = [
      {
        id: "all",
        label: "All Businesses",
      },
      ...organisationOptions,
    ];

    setFilteredOrganisations(organisationOptions);
  };

  const hideAndShowModal = () => {
    setModal(!modal);
  };

  const removeSiteFromSummaries = (siteId: any) => {
    const updatedSites = selectedSiteIds.filter(
      (object: any) => object.key !== siteId,
    );
    setSelectedSiteIds([...updatedSites]);
  };

  // When selecting an object from any multi-select autocomplete form field,
  // this method runs to update the values to be submitted to the API
  const handleMultipleSelectAutoCompleteChange = (selectedObject: any) => {
    const sites = selectedSiteIds;
    if (selectedObject) {
      if (selectedObject.length < sites.length) {
        const currentSiteIds = sites.map((x: any) => x.key);
        const selectedIds = selectedObject.map((x: any) => x.key);
        const siteIdToRemove = currentSiteIds.filter(
          (x: any) => selectedIds.indexOf(x) === -1,
        );
        removeSiteFromSummaries(siteIdToRemove[0]);
      } else if (selectedObject.length > 0) {
        handleActiveMarker(selectedObject[selectedObject.length - 1]);
      }
    }
  };

  const handleAutoCompleteTextInputChange = (text: any) => {
    setSelectedSiteIds(selectedSiteIds);
    setSiteTextSearch(text);
  };

  const onLoad = useCallback((map: any) => {
    mapRef.current = map;
  }, []);

  return isLoaded ? (
    <GoogleMap
      id="mapData"
      options={options}
      zoom={organisationSiteIds.length === 0 ? 3 : 2}
      onLoad={onLoad}
      center={
        organisationSiteIds.length === 0
          ? new google.maps.LatLng(34.397, 60.644)
          : undefined
      }
    >
      {markers.map((marker: any, index: any) => {
        return (
          <Marker
            key={index}
            // eslint-disable-next-line object-shorthand
            position={marker.position}
            onClick={() =>
              marker.onClickParameters.key
                ? handleActiveMarker(marker.onClickParameters)
                : null
            }
            icon={marker.icon}
            label={marker.label}
          />
        );
      })}
      <div className="customPannel">
        <div className="infoHolder">
          {(auth?.user?.data.benchmarksEnabled || isAdmin) && (
            <AvgSiteScore
              avgSiteScore={avgSiteScore}
              groupScore={groupScore}
              selectedGroupId={selectedGroupId}
            />
          )}
          <MapFilters
            isAdmin={isAdmin}
            selectedUserId={selectedUserId}
            selectedUserName={selectedUserName}
            selectedOrgId={selectedOrgId}
            selectedOrgName={selectedOrgName}
            selectedSurveyTemplateTypeId={selectedSurveyTemplateTypeId}
            surveyTemplateTypeName={surveyTemplateTypeName}
            handleSelectUser={handleSelectUser}
            handleSelectUserTextInputChange={handleSelectUserTextInputChange}
            handleSelectOrg={handleSelectOrg}
            handleSelectOrgTextInputChange={handleSelectOrgTextInputChange}
            handleSelectSurveyTemplate={handleSelectSurveyTemplate}
            handleSelectSurveyTemplateTextInputChange={
              handleSelectSurveyTemplateTextInputChange
            }
            filteredOrganisations={filteredOrganisations}
            surveyTemplateTypes={surveyTemplateTypes}
            users={users}
            handleSelectGroup={handleSelectGroup}
            handleSelectGroupTextInputChange={handleSelectGroupTextInputChange}
            selectedGroupId={selectedGroupId}
            selectedGroupName={selectedGroupName}
            groups={groups}
          />
          <MapSites
            organisationSiteIds={organisationSiteIds}
            timeClicked={timeClicked}
            handleMultipleSelectAutoCompleteChange={
              handleMultipleSelectAutoCompleteChange
            }
            handleAutoCompleteTextInputChange={
              handleAutoCompleteTextInputChange
            }
            hideAndShowModal={hideAndShowModal}
            selectedSiteIds={selectedSiteIds}
          />
          <WhatToLookFor />
        </div>
        <div className="summaryHolder">
          <SiteSummary
            clientId={selectedUserId}
            selectedSiteIds={selectedSiteIds}
            surveyTemplateTypeId={selectedSurveyTemplateTypeId}
            removeSiteFromSummaries={removeSiteFromSummaries}
            organisationId={selectedOrgId}
          />
        </div>
        {modal && (
          <MapDownloadModal
            setModal={hideAndShowModal}
            items={organisationSiteIds}
          />
        )}
      </div>
    </GoogleMap>
  ) : (
    <div />
  );
}

export default SiteMaps;
