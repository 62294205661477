import React, { createRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import WarningIcon from "@material-ui/icons/Warning";
import Tooltip from "@material-ui/core/Tooltip";
import Controls from "components/Controls";
import { SuccessToast } from "components/Toaster";
import { API_URLS } from "config/ApiUrls";
import { UseTable } from "hooks/useTable";
import API from "services/Api.Service";
import { useLoading } from "providers/loading";
import "./Style.css";

const tableRef = createRef<any>();

const OrganisationTable = () => {
  const { setAppLoading } = useLoading();
  const navigate = useNavigate();
  const [organisations, setOrganisations] = React.useState<any[]>([]);
  const [modal, setModal] = React.useState(false);
  const [orgToBeDeleted, setOrgToBeDeleted] = React.useState({
    organisationId: "",
    organisationName: "",
    sectorName: "",
  });

  useEffect(() => {
    setAppLoading(true);
    API.get(API_URLS.GetIncompleteOrganizations, {
      params: {
        OrderBy: "OrganisationId",
        SearchValue: null,
      },
    })
      .then((response: any) => {
        setOrganisations(response.data.data);
      })
      .finally(() => setAppLoading(false));
  }, []);

  const editOrganisation = (organisationId: string) => {
    navigate(`/organisations/${organisationId}/edit`);
  };

  const columns = [
    {
      title: "BUSINESS NAME",
      field: "organisationName",
    },
    {
      title: "SECTOR",
      field: "sectorName",
      render: (rowData: any) => (
        <div>
          {rowData?.sectorName}{" "}
          {!rowData.sectorName && (
            <Tooltip
              title={
                <>
                  <p style={{ color: "white" }}>SETUP INCOMPLETE</p>
                  <p>This organisation was self-registered</p>
                </>
              }
              arrow
            >
              <div className="warning-icon">
                <WarningIcon />
              </div>
            </Tooltip>
          )}
        </div>
      ),
    },
    { title: "NUMBER OF WORKPLACESS", field: "numberOfSites" },
    {
      title: "MANAGE",
      field: "edit",
      render: (rowData: any) => (
        <div className="actions-container">
          <p onClick={() => editOrganisation(rowData?.organisationId)}>Edit</p>|
          <p
            className="actions-container-delete"
            onClick={() => toggleModal(rowData)}
          >
            Delete
          </p>
        </div>
      ),
    },
  ];

  const toggleModal = (rowData: any) => {
    setOrgToBeDeleted({
      organisationId: rowData.organisationId,
      organisationName: rowData.organisationName,
      sectorName: rowData.sectorName,
    });
    setModal(!modal);
  };

  const closeModal = () => {
    setModal(false);
  };

  const deleteOrganisation = () => {
    setAppLoading(true);
    API.delete(API_URLS.DeleteOrganisation, {
      params: {
        organisationId: orgToBeDeleted.organisationId,
      },
    })
      .then((res: any) => {
        SuccessToast(res?.data?.message);
        setOrganisations(
          organisations.filter(
            (u) => u.organisationId !== orgToBeDeleted.organisationId,
          ),
        );
        setModal(false);
      })
      .finally(() => {
        setAppLoading(false);
      });
  };

  return (
    <div>
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay" />
          <div className="modal-content">
            <h2>Delete Business</h2>
            <p>
              You are about to delete the following business. Are you sure you
              want to proceed?
            </p>
            <p>Business: {orgToBeDeleted.organisationName}</p>
            <br />
            <div className="form-buttons custom-floatRight">
              <Controls.Button
                className="button blue"
                onClick={deleteOrganisation}
                text="Delete"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={closeModal}
              />
            </div>
          </div>
        </div>
      )}
      <UseTable
        refTable={tableRef}
        columns={columns}
        data={organisations}
        title="Organisation"
      />
    </div>
  );
};

export default OrganisationTable;
