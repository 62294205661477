import ReactAudioPlayer from "react-audio-player";
import { IAudioPlayer } from "models/Interfaces/IAudioPlayer";
import Controls from "../../../../components/Controls";

import "./Style.css";

// Audio player component used to play audio translations

const AudioPlayer = ({ closeAudio, audio }: IAudioPlayer) => {
  return (
    <div className="modal">
      <div onClick={closeAudio} className="overlay" />
      <div className="modal-content">
        <h2 className="page-heading">Play Audio</h2>
        <ReactAudioPlayer src={audio} autoPlay controls />
        <br />
        <div className="form-buttons custom-floatRight">
          <Controls.Button
            text="Close"
            className="cancel-button"
            variant="outlined"
            onClick={closeAudio}
          />
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
