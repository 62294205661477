import { Routes, Route, Navigate } from "react-router-dom";
import { ManageProfile } from ".";
import { DeveloperAPICredentials } from "./DeveloperAPICredentials";

export const ProfileRoutes = () => {
  return (
    <Routes>
      <Route path="/manage-profile" element={<ManageProfile />} />
      <Route
        path="/developer-api-credentials"
        element={<DeveloperAPICredentials />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
