import { Routes, Route, Navigate } from "react-router-dom";
import routePaths from "config/RoutePaths";
import CreateOrganisation from "./CreateOrganisations";
import Organisations from "./Organisations";
import OrganisationUsers from "./OrganisationUsers";
import InviteOrgnisationUser from "./InviteOrganisationUser";
import CreateTag from "./CreateTag";

export const OrganisationRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Organisations />} />
      <Route path="/create" element={<CreateOrganisation />} />
      <Route
        path={routePaths.EDITORGANISATIONS}
        element={<CreateOrganisation />}
      />
      <Route path="/:organisationId/users/" element={<OrganisationUsers />} />
      <Route
        path="/:usersId/:organisationUserId/invite-user"
        element={<InviteOrgnisationUser />}
      />
      <Route
        path="/:usersId/:organisationUserId/edit-user"
        element={<InviteOrgnisationUser />}
      />
      <Route path="/invite-user" element={<InviteOrgnisationUser />} />
      <Route path="/tags" element={<CreateTag />} />

      <Route
        path="*"
        element={<Navigate to={routePaths.ORGANISATIONS} replace={true} />}
      />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
