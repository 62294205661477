import Controls from "../../../components/Controls";
import "./Style.css";

export const MultipleDataCyclesMarkAsComplete = (props: any) => {
  return (
    <div className="modal">
      <div onClick={props.toggleModal} className="overlay" />
      <div className="modal-content">
        {props.data.length > 0 && (
          <>
            <p>
              You are going to mark the following{" "}
              <strong>{props.data.length}</strong> survey cycles as complete:
            </p>
            <div className="surveyCycleList">
              {props.data.map((item: any) => {
                return (
                  <>
                    <b>{item.name}</b>
                    <br />
                  </>
                );
              })}
            </div>
            <p>Are you sure?</p>
          </>
        )}
        {props.data.length === 0 && <p>No survey cycles selected</p>}
        <br />
        <div className="form-buttons custom-floatRight">
          {props.data.length > 0 && (
            <Controls.Button
              className="button blue"
              text="Confirm"
              onClick={() => props.handle(props.data)}
            />
          )}
          <Controls.Button
            text="Cancel"
            className="cancel-button"
            variant="outlined"
            onClick={() => props.setModal(false)}
          />
        </div>
      </div>
    </div>
  );
};
