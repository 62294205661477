import Tooltip from "@material-ui/core/Tooltip";
import Controls from "../../../components/Controls";
import { UseTable } from "../../../hooks/useTable";

type IType =
  | "string"
  | "boolean"
  | "numeric"
  | "date"
  | "datetime"
  | "time"
  | "currency";

const string: IType = "string";

const PotentialQuestionTable = (props: any) => {
  const columns = [
    {
      title: "TEXT",
      field: "questionText",
      type: string,
      render: (rowData: any) => (
        <div>
          {rowData.questionText && (
            <Tooltip title={<p>{rowData.label}</p>} arrow>
              <p>{rowData.questionText}</p>
            </Tooltip>
          )}
        </div>
      ),
    },
    { title: "TYPE", field: "type", type: string },
    { title: "INDICATOR", field: "indicator", type: string },
    { title: "INDICATOR FAMILY", field: "indicatorFamily", type: string },
    {
      title: "INCLUDE",
      field: "isIncluded",
      sorting: false,
      render: (rowData: any) => (
        <div>
          <Controls.CheckBox
            id={`${rowData.id}_isIncluded`}
            label=""
            name="isIncluded"
            value={rowData.isIncluded}
            checked={rowData.isIncluded}
            onChange={(e) => {
              if (e.target.value) {
                props.includeQuestion(e, rowData.id);
              } else {
                props.excludeQuestion(e, rowData.id);
              }
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <UseTable columns={columns} data={props.items} />
    </div>
  );
};

export default PotentialQuestionTable;
