import { useNavigate, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Controls from "components/Controls";
import DataCycleNotesTable from "../DataCycleNotesTable";

function DataCycleNotes() {
  const { id } = useParams();
  const navigate = useNavigate();

  const navigateToCreateDataCycleNote = () => {
    navigate(`/surveycycles/${id}/createnote`);
  };

  return (
    <div className="container-view">
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div className="page-heading-container">
              <Controls.BackButton />
              <h2 className="page-heading">Manage Survey Cycle Notes</h2>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="custom-floatRight">
              <Controls.Button
                text="Add Survey Cycle Note"
                className="button maroon"
                onClick={() => {
                  navigateToCreateDataCycleNote();
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <DataCycleNotesTable />
    </div>
  );
}

export default DataCycleNotes;
