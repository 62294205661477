import { ParticipantEnum } from "models/Enum/EnumTypes";
import ResponseRates from "./ResponseRates";
import Segmentation from "./Segmentation";
import SegmentationCompare from "./SegmentationCompare";
import "./Style.css";

function Participation({
  selectedQuestion,
  siteId,
  surveyTemplateTypeId,
  dataCycleId,
  dataCycleIds,
  groupDataCycleIds,
  compareDataCycleId,
  showBenchmarks,
  showGroupBenchmarks,
  dataCycleName,
}: any) {
  return selectedQuestion.responseChartType === ParticipantEnum.BarChart ? (
    <ResponseRates
      siteId={siteId}
      surveyTemplateTypeId={surveyTemplateTypeId}
    />
  ) : compareDataCycleId === "" ? (
    <Segmentation
      selectedQuestion={selectedQuestion}
      dataCycleId={dataCycleId}
      dataCycleIds={dataCycleIds}
      showBenchmarks={showBenchmarks}
      groupDataCycleIds={groupDataCycleIds}
      showGroupBenchmarks={showGroupBenchmarks}
      dataCycleName={dataCycleName}
    />
  ) : (
    <SegmentationCompare
      selectedQuestion={selectedQuestion}
      dataCycleId={dataCycleId}
      compareDataCycleId={compareDataCycleId}
      dataCycleIds={dataCycleIds}
      showBenchmarks={showBenchmarks}
      groupDataCycleIds={groupDataCycleIds}
      showGroupBenchmarks={showGroupBenchmarks}
    />
  );
}

export default Participation;
