import { SyntheticEvent } from "react";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useAuth as useNewAuth } from "lib/auth";
import Controls from "components/Controls";
import { Form, useForm } from "hooks/useForm";
import { ISignIn } from "models/Interfaces/IAuth";
import "./Style.css";
import API from "services/Api.Service";
import { API_URLS } from "config/ApiUrls";
import routePaths from "config/RoutePaths";
import { ErrorToast, SuccessToast } from "components/Toaster";
import { validateEmail } from "util/validations";
import { UserResponse } from "lib/auth/helpers";
import { useLoading } from "providers/loading";
import PasswordEye from "../../../components/Controls/PaaswordEye";

export default function SignIn() {
  const { login }: any = useNewAuth();
  const { setAppLoading } = useLoading();
  const navigate: any = useNavigate();

  const initialFValues: ISignIn = {
    email: "",
    password: "",
  };

  const obj = {} as ISignIn;

  const validate = (fieldValues = values) => {
    if ("email" in fieldValues)
      obj.email = fieldValues.email ? "" : "Email is required.";
    if ("password" in fieldValues)
      obj.password = fieldValues.password ? "" : "Password is required.";
    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate,
  );

  const handleSubmitNew = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (validate() && validateEmail(values, obj, setErrors)) {
      setAppLoading(true);
      await API.post(API_URLS.SignIn, {
        Email: values.email,
        Password: values.password,
      })
        .then(async (response: any) => {
          if (
            response.data.result.succeeded &&
            !response.data.isVerificationRequired
          ) {
            try {
              const { email, password } = values;
              if (validate() && validateEmail(values, obj, setErrors)) {
                await login({ email, password }).then(
                  (loginResponse: UserResponse) => {
                    setAppLoading(false);
                    SuccessToast(loginResponse.message);
                    navigate(routePaths.DASHBOARD, { replace: true });
                  },
                );
              }
            } catch (error: any) {
              ErrorToast(error.response.data.message || error.response.data);
              setAppLoading(false);
              navigate(routePaths.SIGNIN, { replace: true });
            }
          }

          if (response.data.isVerificationRequired) {
            setAppLoading(false);
            SuccessToast(response.data.message);
            navigate(routePaths.TWOFACTORCODE, {
              replace: true,
              state: { email: values.email, password: values.password },
            });
          }
        })
        .catch((error: any) => {
          setAppLoading(false);
        });
    }
  };

  return (
    <Form onSubmit={handleSubmitNew}>
      {/* {loader ? <Loader /> : ""} */}
      <div className="container-view">
        <Grid container>
          <Grid item xs={12} sm={3} md={4} />
          <Grid item xs={12} sm={6} md={4}>
            <div>
              <h1>LOGIN</h1>
            </div>
            <Controls.Input
              id="email"
              name="email"
              label="Email address"
              value={values.email}
              onChange={handleInputChange}
              error={obj.email}
              cName="text-input"
              variant="outlined"
            />
            <PasswordEye
              id="password"
              label="Password"
              name="password"
              value={values.password}
              onChange={handleInputChange}
              cName="text-input"
              variant="outlined"
              error={obj.password}
            />

            <br />
            <br />
            <div>
              <a href={routePaths.FORGOTPASSWORD} id="forgot-link">
                Forgot password?
              </a>
            </div>
            <div>
              <Controls.Button
                className="button blue"
                type="submit"
                text="Log in"
              />
            </div>
            <br />
            <div>
              <p>Still need an account?</p>
              <a href={routePaths.SIGNUP} id="signup">
                Register your organisation
              </a>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={4} />
        </Grid>
      </div>
    </Form>
  );
}
