export const phoneNumber = (values: any, obj: any, setErrors: any) => {
  const fieldValues = values;
  const re =
    /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;

  if ("phoneNumber" in fieldValues)
    if (!re.test(fieldValues.phoneNumber)) {
      obj.phoneNumber = "Please enter a valid phone number.";
    }
  setErrors({
    ...obj,
  });
  return Object.values(obj).every((x) => x === "");
};

export const validateEmail = (values: any, obj: any, setErrors: any) => {
  const fieldValues = values;
  const re = /\S+@\S+\.\S+/;

  if ("email" in fieldValues)
    if (!re.test(fieldValues.email)) {
      obj.email = "Please enter a valid email address.";
    }
  setErrors({
    ...obj,
  });
  return Object.values(obj).every((x) => x === "");
};

export const validatePassword = (values: any, obj: any, setErrors: any) => {
  const fieldValues = values;
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;

  if ("password" in fieldValues)
    if (!re.test(fieldValues.password)) {
      obj.password =
        "Your password must be at least 8 characters long, contain at least one number and one special character, and have a mixture of uppercase and lowercase letters.";
    }
  setErrors({
    ...obj,
  });
  return Object.values(obj).every((x) => x === "");
};

export const validatePasswords = (values: any, obj: any, setErrors: any) => {
  const fieldValues = values;

  if ("password" in fieldValues)
    if ("password" in fieldValues && "passwordConfirm" in fieldValues)
      if (fieldValues.password !== fieldValues.passwordConfirm) {
        obj.passwordConfirm = "Passwords do not match.";
      }
  setErrors({
    ...obj,
  });
  return Object.values(obj).every((x) => x === "");
};
