import { useEffect, useState } from "react";
import { StatusBadges } from "models/Enum/EnumTypes";
import { IStatusBadgeInfo } from "models/Interfaces/IReports";
import "./Style.css";

function StatusBadgeInfo({ status }: IStatusBadgeInfo) {
  const [boxInfo, setBoxInfo] = useState<any>({});

  useEffect(() => {
    const info: any = {};
    switch (status) {
      case StatusBadges.Listening:
        info.title = "Listening";
        info.message =
          "This means that you have logged in to view the results generated by your workers within two weeks of receiving your results.\nWant to become a Responding Employer?\nMove your mouse over the Responding Employer badge to see what comes next.";
        break;
      case StatusBadges.Improving:
        info.title = "Improving";
        info.message =
          "This means that your latest survey cycle has at least 2 indicators that have increased the Green by a minimum of 10% each, and/or at least 1 less indicator on the Priorities chart.\nKeep on improving in order to retain your status!";
        break;
      case StatusBadges.Responding:
        info.title = "Responding";
        info.message =
          "This means that you have decreased the Red in your progress chart for the last survey cycle.\nWant to become an Improving Employer?\nMove your mouse over to the Improving Employer badge to see what comes next.";
        break;
      case StatusBadges.None:
        info.title = "";
        info.message = "";
        break;
      default:
        info.title = "";
        info.message = "";
        break;
    }
    setBoxInfo(info);
  }, [status]);

  return (
    <div className="statusBadgeInfoBox">
      {status !== StatusBadges.None && (
        <>
          <div className="statusBadgeInfoBoxBanner statusBadgeInfoBoxBannerBorder">
            <p>{boxInfo.title}</p>
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <p style={{ display: "inline-block" }}>Filters</p>
            </div> */}
          </div>
          <div className="statusBadgeInfoBoxContent">
            <p>{boxInfo.message}</p>
          </div>
        </>
      )}
    </div>
  );
}

export default StatusBadgeInfo;
