import React from "react";
import Controls from "../../../components/Controls";
import { IMarkDataCycleComplete } from "../../../models/Interfaces/IDataCycle";
import "./Style.css";

type HideSegmentationProps = {
  data: IMarkDataCycleComplete;
  toggleHideSegModal: (rowData: any) => void;
  setHideSegModal: React.Dispatch<React.SetStateAction<boolean>>;
  hideSegmentationCharts: (values: IMarkDataCycleComplete) => void;
};

export const HideSegmentationCharts = (props: HideSegmentationProps) => {
  const { toggleHideSegModal, setHideSegModal, hideSegmentationCharts, data } =
    props;

  return (
    <div className="modal">
      <div onClick={toggleHideSegModal} className="overlay" />
      <div className="modal-content">
        <p>
          You are going to hide segmentation charts from the end user. Are you
          sure?
        </p>
        <br />
        <div className="form-buttons custom-floatRight">
          <Controls.Button
            className="button blue"
            text="Hide"
            onClick={() => hideSegmentationCharts(data)}
          />
          <Controls.Button
            text="Cancel"
            className="cancel-button"
            variant="outlined"
            onClick={() => setHideSegModal(false)}
          />
        </div>
      </div>
    </div>
  );
};
