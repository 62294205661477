import { Button as MuiButton } from "@material-ui/core";
import { IButtonProp } from "../../../models/Interfaces/IControls";

export default function Button({
  id,
  className,
  text,
  size,
  color,
  variant,
  onClick,
  disabled,
  ...other
}: IButtonProp) {
  return (
    <MuiButton
      id={id}
      variant={variant || "contained"}
      size={size || "large"}
      color={color || "primary"}
      onClick={onClick}
      disabled={disabled}
      className={className}
      {...other}
    >
      {text}
    </MuiButton>
  );
}
