import Controls from "components/Controls";
import { IResourceModal } from "../models/Interfaces/IAertModal";

export default function ResourceModal({ handleClose }: IResourceModal) {
  return (
    <div>
      <div className="modal">
        <div onClick={() => handleClose(false)} className="overlay" />
        <div className="modal-content">
          <h2>Add Resource</h2>
          <p>
            Are you sure that the <b>Resource Type</b> and{" "}
            <b>Uploaded File Format</b> are correct?
          </p>
          <br />
          <div className="form-buttons custom-floatRight">
            <Controls.Button
              className="button blue"
              onClick={() => handleClose(true)}
              text="Yes"
            />
            <Controls.Button
              text="No"
              className="cancel-button"
              variant="outlined"
              onClick={() => handleClose(false)}
            />
          </div>
        </div>
      </div>
      {/* <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure that the <b>Resource Type</b> and{" "}
          <b>Uploaded File Format</b> are correct?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)}>Yes,I am</Button>
          <Button onClick={() => handleClose(false)} autoFocus>
            No,let me double check
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
}
