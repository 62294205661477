import Grid from "@material-ui/core/Grid";
import Controls from "components/Controls";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import routePaths from "config/RoutePaths";
import LanguagesTable from "./LanguagesTable";

export default function Languages() {
  const navigate = useNavigate();
  return (
    <div className="container-view">
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div className="page-heading-container">
              <Controls.BackButton />
              <h2 className="page-heading">Manage Languages</h2>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="table-button-tray">
              <Button
                variant="contained"
                onClick={() => navigate(routePaths.ADDLANGUAGE)}
                className="button green"
              >
                Add Language
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <LanguagesTable />
    </div>
  );
}
