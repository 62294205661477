import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { ISelect, ISelectOptions } from "../../../models/Interfaces/IControls";
import "./Style.css";

export default function SelectField({
  id,
  name,
  label,
  value,
  error = null,
  onChange,
  options,
  disabled,
  variant,
}: ISelect) {
  // Removed the makeStyles call as it was causing a null reference exception
  return (
    <FormControl
      size="small"
      className={`Select-dropdown`}
      {...(error && { error: true })}
    >
      <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        variant={variant}
        label={label}
        disabled={disabled}
      >
        {options?.map((item: ISelectOptions) => (
          <MenuItem key={item.key} value={item.key}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
