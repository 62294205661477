import { IPieChart } from "models/Interfaces/ICharts";
import { colours } from "../Colours";
import "./Style.css";

function PieChart({
  config,
  demographicBenchmarks,
  dataCycleName,
  groupDemographicBenchmarks,
}: IPieChart) {
  const degrees = [];
  let counter = 0;

  for (let i = 0; i < config.responses.length; i++) {
    let colourConfig = "";
    const slice = (config.responses[i].percentage / 100) * 360 + counter;

    counter = slice;

    colourConfig = `${colours[i]} 0 ${slice}deg`;

    degrees.push(colourConfig);
  }

  const imgConfigString = `conic-gradient(${degrees.toString()})`;
  let benchmarkImgConfigString = ``;

  if (
    demographicBenchmarks.responses != null &&
    demographicBenchmarks.responses.length > 0
  ) {
    const benchmarkDegrees = [];
    let benchmarkCounter = 0;

    for (let i = 0; i < demographicBenchmarks.responses.length; i++) {
      let colourConfig = "";
      const slice =
        (demographicBenchmarks.responses[i].percentage / 100) * 360 +
        benchmarkCounter;

      benchmarkCounter = slice;

      colourConfig = `${colours[i]} 0 ${slice}deg`;

      benchmarkDegrees.push(colourConfig);
    }

    benchmarkImgConfigString = `conic-gradient(${benchmarkDegrees.toString()})`;
  }

  let groupBenchmarkImgConfigString = ``;

  if (
    groupDemographicBenchmarks.responses != null &&
    groupDemographicBenchmarks.responses.length > 0
  ) {
    const benchmarkDegrees = [];
    let benchmarkCounter = 0;

    for (let i = 0; i < groupDemographicBenchmarks.responses.length; i++) {
      let colourConfig = "";
      const slice =
        (groupDemographicBenchmarks.responses[i].percentage / 100) * 360 +
        benchmarkCounter;

      benchmarkCounter = slice;

      colourConfig = `${colours[i]} 0 ${slice}deg`;

      benchmarkDegrees.push(colourConfig);
    }

    groupBenchmarkImgConfigString = `conic-gradient(${benchmarkDegrees.toString()})`;
  }

  return (
    <>
      <div className="pieChartContainer">
        <div className="compareHeadingAndChart">
          <h2 className="questionText">{dataCycleName}</h2>
          <div
            className="pieChart tooltip"
            style={{
              backgroundImage: imgConfigString,
            }}
          >
            <span className="hover-key">
              {config.responses.map((item: any, index: any) => {
                return (
                  <div className="segmentationKeyLine">
                    <div
                      className="segmentationKeyColour"
                      style={{ backgroundColor: colours[index] }}
                    />
                    <div className="segmentationKeyText">{`${item.responseOption} : ${item.participantsCount} (${item.percentage}%)`}</div>
                  </div>
                );
              })}
            </span>
          </div>
        </div>
      </div>
      <div className="pieChartContainer demographicPieChartContainer">
        {demographicBenchmarks.responses != null &&
          demographicBenchmarks.responses.length > 0 && (
            <div className="compareHeadingAndChart">
              <h2 className="questionText">Your average</h2>
              <div
                className="pieChart demographicPieChart tooltip"
                style={{
                  backgroundImage: benchmarkImgConfigString,
                }}
              >
                <span className="hover-key">
                  {demographicBenchmarks.responses.map(
                    (item: any, index: any) => {
                      return (
                        <div className="segmentationKeyLine">
                          <div
                            className="segmentationKeyColour"
                            style={{ backgroundColor: colours[index] }}
                          />
                          <div className="segmentationKeyText">{`${item.responseOption} : ${item.participantsCount} (${item.percentage}%)`}</div>
                        </div>
                      );
                    },
                  )}
                </span>
              </div>
            </div>
          )}
        {groupDemographicBenchmarks.responses != null &&
          groupDemographicBenchmarks.responses.length > 0 && (
            <div className="compareHeadingAndChart">
              <h2 className="questionText">Group average</h2>
              <div
                className="pieChart demographicPieChart tooltip"
                style={{
                  backgroundImage: groupBenchmarkImgConfigString,
                }}
              >
                <span className="hover-key">
                  {groupDemographicBenchmarks.responses.map(
                    (item: any, index: any) => {
                      return (
                        <div className="segmentationKeyLine">
                          <div
                            className="segmentationKeyColour"
                            style={{ backgroundColor: colours[index] }}
                          />
                          <div className="segmentationKeyText">{`${item.responseOption} : ${item.participantsCount} (${item.percentage}%)`}</div>
                        </div>
                      );
                    },
                  )}
                </span>
              </div>
            </div>
          )}
      </div>
    </>
  );
}

export default PieChart;
