import * as React from "react";
import {
  LoginCredentialsDTO,
  loginWithEmailAndPassword,
  RegisterCredentialsDTO,
  registerWithEmailAndPassword,
  UserResponse,
} from "lib/auth/helpers";
import storage from "util/storage";
import { FullPageSpinner } from "components/Spinner";
import * as Env from "config/Env";
import { isTokenExpired } from "./helpers/utils";

interface AuthContextProps {
  user: UserResponse | null;
  setUser: React.Dispatch<React.SetStateAction<UserResponse | null>>;
  login: (data: LoginCredentialsDTO) => Promise<void>;
  register: (data: RegisterCredentialsDTO) => Promise<void>;
  logout: () => void;
  isLoading: boolean;
  error: any;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = React.createContext<AuthContextProps | undefined>({
  user: null,
  setUser: () => {},
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => {},
  isLoading: false,
  error: null,
});

const handleUserResponse = (response: UserResponse) => {
  if (response.data.accessToken) {
    storage.setUser(response);
  }
  return response;
};

function AuthProvider(props: AuthProviderProps) {
  const [user, setUser] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  // Pulling the session timeout in minutes from the environment variable
  const sessionTimeOut: number = parseInt(
    Env.REACT_APP_USER_SESSION_TIMEOUT_MINUTES ?? "5",
    10,
  );

  // Function to check for inactivty and log out
  const checkForInactivty = () => {
    // Get expire time from local storage
    const expireTime = localStorage.getItem("expireTime");

    // If expire time is earlier than now, log out
    if (expireTime !== null && parseInt(expireTime, 10) < Date.now()) {
      logoutFn();
    }
  };

  // Function to update expire time in local storage
  const updateExpireTime = () => {
    // set expire time to sessionTimeOut minutes from now
    const expireTime = Date.now() + sessionTimeOut * 60 * 1000;

    // Set expire time in local storage
    localStorage.setItem("expireTime", expireTime.toString());
  };

  // Update expire time on page load and any user activity
  React.useEffect(() => {
    // Set initial expire time
    updateExpireTime();

    // set event listeners
    window.addEventListener("mousemove", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);

    return () => {
      // remove event listeners
      window.removeEventListener("mousemove", updateExpireTime);
      window.removeEventListener("keypress", updateExpireTime);
      window.removeEventListener("scroll", updateExpireTime);
      window.removeEventListener("mousemove", updateExpireTime);
    };
  }, []);

  // use effect to check for inactivity
  React.useEffect(() => {
    // check for inactivity every 1 minute
    const interval = setInterval(checkForInactivty, 60 * 1000);

    // return function to clear interval
    return () => clearInterval(interval);
  }, []);

  // make a login request
  const loginFn = React.useCallback(
    (data: LoginCredentialsDTO) => {
      setIsLoading(true);
      try {
        return loginWithEmailAndPassword(data).then((authedUser) => {
          const userResponse = handleUserResponse(authedUser);
          setUser(userResponse.data);
          // navigate to dashboard
          return userResponse;
        });
      } catch (err: Error | any) {
        setError(err);
      } finally {
        //console.log(user);
        // session.start();
        setIsLoading(false);
      }
    },
    [setIsLoading, setError, setUser],
  );

  // make a register request
  const registerFn = React.useCallback(
    (data: RegisterCredentialsDTO) => {
      setIsLoading(true);
      try {
        return registerWithEmailAndPassword(data).then((authedUser) => {
          const userResponse = handleUserResponse(authedUser);
          setUser(userResponse.data);
        });
      } catch (err: any) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    },
    [setUser, setIsLoading, setError],
  );

  // make a logout request
  const logoutFn = React.useCallback(() => {
    storage.clearUser();
    // window.location.assign((window.location.href = routePaths.SIGNIN));
    window.location.assign(window.location.origin as unknown as string);
  }, [user]);

  // logout user is the token is expired
  React.useEffect(() => {
    const loggedInUser = storage.getUser();
    if (loggedInUser) {
      const { accessToken } = loggedInUser.data;
      const isExpired = isTokenExpired(accessToken);
      // console.log("isExpired", isExpired);
      if (isExpired) {
        logoutFn();
      }
    }
  }, [logoutFn]);

  const value: any = React.useMemo(() => {
    return {
      user,
      setUser,
      login: loginFn,
      register: registerFn,
      logout: logoutFn,
      isLoading,
      error,
    };
  }, [user, setUser, loginFn, registerFn, logoutFn, isLoading, error]);

  // check if isLoading is true and return a spinner
  if (isLoading) {
    return <FullPageSpinner />;
  }

  return <AuthContext.Provider value={value} {...props} />;
}

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export { AuthProvider, useAuth };
