import { useEffect, useState } from "react";
import Controls from "components/Controls";
import { SuccessToast } from "components/Toaster";
import { API_URLS } from "config/ApiUrls";
import { UseTable } from "hooks/useTable";
import API from "services/Api.Service";
import { useLoading } from "providers/loading";
import routePaths from "config/RoutePaths";
import moment from "moment";
import { Form, useForm } from "hooks/useForm";
import { validatePassword, validatePasswords } from "util/validations";
import { IManagePassword } from "models/Interfaces/IProfile";

const ClientUserTable = () => {
  const { setAppLoading } = useLoading();
  const [modal, setModal] = useState(false);
  const [adminUsers, setAdminUsers] = useState<any[]>([]);
  const [userToBeReset, setUserToBeReset] = useState({
    userId: "",
  });
  const [obj, setObj] = useState<any>({} as IManagePassword);

  const initialPasswordValues: IManagePassword = {
    currentPassword: "",
    password: "",
    passwordConfirm: "",
  };

  // Populate the table with users from the DB
  useEffect(() => {
    setAppLoading(true);
    API.get(API_URLS.GetClientUsers, {
      params: {
        OrderBy: "UserId",
        SearchValue: null,
      },
    })
      .then((response: any) => {
        const users = response.data.data;
        setAdminUsers(users);
      })
      .finally(() => setAppLoading(false));
  }, []);

  const validateUserPassword = (fieldValues = values) => {
    if ("password" in fieldValues)
      obj.password = fieldValues.password ? "" : "New Password is required.";
    if ("passwordConfirm" in fieldValues)
      obj.passwordConfirm = fieldValues.passwordConfirm
        ? ""
        : "New Password confirmation is required.";
    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange, resetForm } = useForm(
    initialPasswordValues,
    true,
    validateUserPassword,
  );

  const handleSubmitUserPassword = (e: any) => {
    e.preventDefault();
    if (
      validateUserPassword() &&
      validatePassword(values, obj, setErrors) &&
      validatePasswords(values, obj, setErrors)
    ) {
      setAppLoading(true);
      API.post(API_URLS.UpdateClientPassword, {
        newPassword: values.password,
        confirmPassword: values.passwordConfirm,
        userId: userToBeReset.userId,
      })
        .then((response: any) => {
          SuccessToast(response?.data?.message);
          setErrors(obj);
          resetForm();
        })
        .finally(() => {
          setUserToBeReset({ userId: "" });
          setAppLoading(false);
          toggleModal();
        });
    }
  };

  const columns: any = [
    { title: "EMAIL", field: "email" },
    {
      title: "LAST LOGGED IN DATE",
      field: "lastLoggedInDate",
      render: (rowData: any) => (
        <div className="lastLoggedInDate">
          {rowData.lastLoggedInDate === null
            ? "-"
            : moment(rowData.lastLoggedInDate).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      title: "MANAGE",
      field: "edit",
      sorting: false,
      render: (rowData: any) => {
        return (
          <div className="actions-container">
            {/* TODO: Whose password is being reset here */}
            {/* <p onClick={() => navigateToViewAdminUser(rowData?.userId)}>View</p> */}
            <p onClick={() => openModal(rowData.userId)}>Reset Password</p>
            {/* |<p onClick={() => navigateToEdit(rowData?.userId)}>Edit</p> */}
          </div>
        );
      },
    },
  ];

  const toggleModal = () => {
    setObj({} as IManagePassword);
    resetForm();
    setModal(!modal);
  };

  const openModal = (userId: any) => {
    setObj({} as IManagePassword);
    resetForm();
    setUserToBeReset({
      userId,
    });
    setModal(!modal);
  };

  return (
    <div>
      <UseTable columns={columns} data={adminUsers} title="Manage Users" />
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay" />
          <div className="modal-content">
            <h2>Reset Client Password</h2>
            <p>
              You are about to reset this client's password. Are you sure you
              want to proceed?
            </p>
            <br />
            <Form onSubmit={handleSubmitUserPassword}>
              <Controls.PasswordInput
                id="password"
                name="password"
                label="New Password"
                value={values.password}
                onChange={handleInputChange}
                error={obj.password}
                cName="text-input"
              />
              <Controls.PasswordInput
                id="passwordConfirm"
                name="passwordConfirm"
                label="Confirm New Password"
                value={values.passwordConfirm}
                onChange={handleInputChange}
                error={obj.passwordConfirm}
                cName="text-input"
              />
              <br />
              <br />
              <div className="form-buttons custom-floatRight">
                <Controls.Button
                  className="button blue"
                  type="submit"
                  text="Change Password"
                />
                <Controls.Button
                  text="Cancel"
                  className="cancel-button"
                  variant="outlined"
                  onClick={toggleModal}
                />
              </div>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientUserTable;
