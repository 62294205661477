import { useState } from "react";
import { Link } from "react-router-dom";
import "./MobileMenuItem.css";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import { IMobileNavItem } from "../../models/Interfaces/INavBar";

function MobileMenuItem({
  title,
  id,
  keyId,
  path,
  submenu,
  setClick,
}: IMobileNavItem) {
  const [clicked, setClicked] = useState(false);

  const onClick = () => {
    setClick(!clicked);
    setClicked(!clicked);
  };

  const renderItem = () => {
    if (submenu != null) {
      return (
        <li
          className="mobilemenu-item"
          onClick={() => onClick()}
          key={keyId}
          id={`${id}-mobile`}
        >
          <div>
            {title}{" "}
            {clicked ? (
              <FaChevronDown className="mobilemenu-icon" />
            ) : (
              <FaChevronRight className="mobilemenu-icon" />
            )}{" "}
          </div>
          <ul>
            {submenu.map((item: any, index: number) => {
              if (clicked) {
                return (
                  <li className="mobilemenu-subitem" key={index}>
                    <Link
                      to={item.path}
                      className="mobilemenu-subitem"
                      onClick={() => setClicked(false)}
                    >
                      {item.title}
                    </Link>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </li>
      );
    }
    return (
      <li className="mobilemenu-item" key={keyId} id={`${id}-mobile`}>
        <Link
          to={path}
          onClick={() => setClick(false)}
          className="mobilemenu-subitem"
        >
          {title}
        </Link>
      </li>
    );
  };

  return <>{renderItem()}</>;
}

export default MobileMenuItem;
