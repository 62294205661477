import { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Controls from "components/Controls";
import { Form, useForm } from "hooks/useForm";
import { ISelectOrg } from "models/Interfaces/IReports";
import API from "services/Api.Service";
import { API_URLS } from "config/ApiUrls";
import { useLoading } from "providers/loading";
import { useAuth } from "lib/auth";
import { RolesEnum } from "models/Enum/Roles";
import "./Style.css";

function ReportingRoleFilter() {
  const { setAppLoading } = useLoading();
  const [organisations, setOrganisations] = useState<any>([]);
  const [filteredOrganisations, setFilteredOrganisations] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [viewPreference, setViewPreference] = useState<any>("");
  const [userOrgIds, setUserOrgIds] = useState<any>([]);
  const [obj, setObj] = useState<any>({} as ISelectOrg);

  const navigate = useNavigate();
  const auth = useAuth();

  const isAdmin = auth?.user?.data?.roles?.includes(RolesEnum.Admin);

  const initialValues = {
    organisationId: "",
    organisationIdTextSearch: "",
    clientId: "",
    clientIdTextSearch: "",
  };

  const validate = (fieldValues = values) => {
    if (isAdmin) {
      if ("clientId" in fieldValues && fieldValues.organisationId === "all")
        obj.clientId = fieldValues.clientId
          ? ""
          : "Client selection is required if no organisation is selected.";
    }

    if ("organisationId" in fieldValues)
      obj.organisationId = fieldValues.organisationId
        ? ""
        : "Business field is required.";

    if (viewPreference === "")
      obj.viewPreference = "View preference is required.";

    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, setDataValues, resetForm } = useForm(
    initialValues,
    true,
    validate,
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validate()) {
      if (viewPreference === "Map") {
        navigate(`map`, {
          state: {
            clientId: values.clientId,
            clientName: values.clientIdTextSearch,
            clientOrgIds: userOrgIds,
            organisationId: values.organisationId,
            orgName: values.organisationIdTextSearch,
          },
        });
      } else {
        navigate(`list`, {
          state: {
            clientId: values.clientId,
            clientName: values.clientIdTextSearch,
            clientOrgIds: userOrgIds,
            organisationId: values.organisationId,
            orgName: values.organisationIdTextSearch,
          },
        });
      }
    }
  };

  useEffect(() => {
    setAppLoading(true);

    const fetchData = async () => {
      try {
        // Start all your GET requests asynchronously
        const getOrgs = API.get(API_URLS.GetOrganisationsWithSites, {
          params: {
            OrderBy: "OrganisationId",
            SearchValue: null,
          },
        }).then((response: any) => {
          let organisationOptions = response.data.data.map((x: any) => {
            return { id: x.organisationId, label: x.organisationName };
          });

          if (!isAdmin) {
            if (auth?.user?.data?.userOrganisations) {
              if (auth?.user?.data?.userOrganisations?.length > 1) {
                const userOrganisationIds =
                  auth?.user?.data?.userOrganisations.map((x: any) => {
                    return x.organisationId;
                  });
                organisationOptions = organisationOptions.filter((x: any) => {
                  return userOrganisationIds.includes(x.id);
                });
              } else {
                const userOrganisationIds =
                  auth?.user?.data?.userOrganisations.map((x: any) => {
                    return x.organisationId;
                  });
                organisationOptions = organisationOptions.filter((x: any) => {
                  return userOrganisationIds.includes(x.id);
                });
                values.organisationId =
                  auth?.user?.data?.userOrganisations[0].organisationId;
                values.organisatonIdTextSearch =
                  auth?.user?.data?.userOrganisations[0].organisationName;
                setDataValues(values);
              }
            }

            organisationOptions = [
              {
                id: "all",
                label: "All Businesses",
              },
              ...organisationOptions,
            ];
          }
          setFilteredOrganisations(organisationOptions);
          setOrganisations(organisationOptions);
        });

        const getUsers = API.get(API_URLS.GetUserList, {
          params: {},
        }).then((response: any) => {
          const userList = response.data.data
            .filter((x: any) => {
              return x.userOrganisationDetails.length > 0;
            })
            .map((x: any) => {
              return {
                id: x.userId,
                label: x.userName,
                orgs: x.userOrganisationDetails.map((y: any) => {
                  return y.organisationId;
                }),
              };
            });
          setUsers(userList);
        });

        // Wait for all requests to complete
        await Promise.all([getOrgs, getUsers]);
      } finally {
        // All requests completed, set isLoading to false
        values.organisationId = "";
        values.organisationIdTextSearch = "";
        setDataValues(values);
        setAppLoading(false);
      }
    };

    fetchData();

    return () => {
      // Remove all the field errors and reset the form
      setObj({} as ISelectOrg);
      setDataValues(initialValues);
      resetForm();
    };
  }, []);

  // Filter the organisations when the user is selected
  const updateFilteredOrganisations = (user: any) => {
    if (!user) {
      setFilteredOrganisations(organisations);
      return;
    }

    let organisationOptions = organisations.filter((x: any) => {
      return user.orgs.includes(x.id);
    });

    const userOrganisationIds = organisationOptions.map((x: any) => {
      return x.id;
    });

    setUserOrgIds(userOrganisationIds);

    organisationOptions = [
      {
        id: "all",
        label: "All Businesses",
      },
      ...organisationOptions,
    ];

    setFilteredOrganisations(organisationOptions);
  };

  // When selecting an object from any autocomplete form field,
  // this method runs to update the values to be submitted to the API
  const handleAutoCompleteChange = (selectedObject: any, fieldName: string) => {
    values[fieldName] = selectedObject ? selectedObject.id : "";
    if (obj) (obj as any)[fieldName] = "";
    setDataValues(values);
    setErrors(obj);

    if (fieldName === "clientId") updateFilteredOrganisations(selectedObject);
  };

  // This method is required to update the text search field in the
  // autocomplete components. It is also used to force the field to be blank.
  const handleAutoCompleteTextInputChange = (text: any, field: string) => {
    const fieldName = `${field}TextSearch`;
    values[fieldName] = text;
    setDataValues(values);
  };

  const handleViewPreferenceSelect = (value: string) => {
    obj.viewPreference = "";
    setErrors({
      ...obj,
    });
    setViewPreference(value);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view">
        <Grid container>
          <Grid item xs={12} sm={2} md={2} />
          <Grid item xs={12} sm={8} md={8}>
            <Grid container>
              <Grid item xs={12} className="grid-custom-spacing">
                <div className="page-heading-container">
                  <Controls.BackButton />
                  <h2 className="orgSelectHeading">
                    Which business do you want to look at first?
                  </h2>
                </div>
              </Grid>
              {isAdmin && (
                <Grid item xs={12}>
                  <Controls.AutoComplete
                    id="clientId"
                    name="clientId"
                    label="Select a user"
                    value={values.clientId}
                    inputValue={values.clientIdTextSearch}
                    onChange={handleAutoCompleteChange}
                    onInputChange={handleAutoCompleteTextInputChange}
                    varient="standard"
                    options={users}
                    error={obj.clientId}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Controls.AutoComplete
                  id="organisationId"
                  name="organisationId"
                  label="Select a business*"
                  value={values.organisationId}
                  inputValue={values.organisationIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  varient="standard"
                  options={filteredOrganisations}
                  error={obj.organisationId}
                />
              </Grid>
              <Grid item xs={12}>
                <p>Please select your view preference.</p>
                {google && (
                  <label htmlFor="map">
                    <input
                      type="radio"
                      id="map"
                      name="view_preference"
                      value="Map"
                      onChange={(e) =>
                        handleViewPreferenceSelect(e.target.value)
                      }
                      disabled={auth?.user?.data?.hideMap}
                    />
                    Map {auth?.user?.data?.hideMap ? "(Disabled)" : ""}
                  </label>
                )}
                <br />
                <label htmlFor="list">
                  <input
                    type="radio"
                    id="list"
                    name="view_preference"
                    value="List"
                    onChange={(e) => handleViewPreferenceSelect(e.target.value)}
                  />
                  List
                </label>
                <br />
                <p style={{ color: "#f44336" }}>{obj.viewPreference}</p>
              </Grid>
            </Grid>
            <div className="form-buttons custom-floatRight">
              <br />
              <Controls.Button
                className="button blue"
                type="submit"
                text="View Results"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Form>
  );
}

export default ReportingRoleFilter;
