import { Routes, Route, Navigate } from "react-router-dom";
import routePaths from "config/RoutePaths";
import AdminUsers from "pages/Administrator/AdminUsers";
import ClientUsers from "pages/Administrator/ClientUsers";
import RegisterAdministrator from "pages/Administrator/RegisterAdministrator";

export const UsersRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<AdminUsers />} />
      <Route
        path={routePaths.REGISTERADMINISTRATOR}
        element={<RegisterAdministrator />}
      />
      <Route path="/clients" element={<ClientUsers />} />
      <Route path="*" element={<Navigate to={routePaths.USERS} />} />
    </Routes>
  );
};
//
