import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import { Form, useForm } from "hooks/useForm";
import Controls from "components/Controls";
import { ICreateDataCycleNote } from "models/Interfaces/IDataCycle";
import API from "services/Api.Service";
import { API_URLS } from "config/ApiUrls";
import { SuccessToast } from "components/Toaster";
import { useLoading } from "providers/loading";

import "./Style.css";

const initialFValues: ICreateDataCycleNote = {
  dataCycleNote: "",
  dataCycleId: "",
};

let obj = {} as ICreateDataCycleNote;

export default function CreateSurveyTemplate() {
  const navigate = useNavigate();
  const { setAppLoading } = useLoading();
  // Pull ID of data cycle note from URL parameters
  // if no ID in parameters, it is a create data cycle note screen
  const { dataCycleId, dataCycleNoteId } = useParams();

  // Set heading of page according to whether the user
  // is creating or editing
  const pageHeading = dataCycleNoteId
    ? "Edit Survey Cycle Note"
    : "Add Survey Cycle Note";

  useEffect(() => {
    return () => {
      // Remove all the field errors and reset the form
      obj = {} as ICreateDataCycleNote;
      resetForm();
    };
  }, []);

  // This useEffect checks whether ID is present in parameters
  // if so the form is prepopulated with the details of the data cycle note to be edited
  useEffect(() => {
    if (dataCycleNoteId) {
      setAppLoading(true);
      API.get(API_URLS.GetDataCycleNoteById, {
        params: {
          dataCycleNoteId,
        },
      })
        .then((response: any) => {
          const result = response?.data;
          values.dataCycleNote = result.dataCycleNote;
          values.dataCycleId = result.dataCycleId;
          values.dataCycleNoteId = result.dataCycleNoteId;
          setDataValues(values);
        })
        .finally(() => {
          setAppLoading(false);
        });
    } else {
      values.dataCycleNote = "";
      setDataValues(values);
    }
  }, [dataCycleNoteId]);

  const validate = (fieldValues = values) => {
    if ("dataCycleNote" in fieldValues)
      obj.dataCycleNote = fieldValues.dataCycleNote
        ? ""
        : "Survey cycle note is required.";
    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange, resetForm, setDataValues } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const url = dataCycleNoteId
      ? API_URLS.EditDataCycleNote
      : API_URLS.CreateDataCycleNote;

    if (validate()) {
      setAppLoading(true);
      API.post(url, {
        dataCycleNote: values.dataCycleNote,
        dataCycleId: dataCycleId || values.dataCycleId,
        dataCycleNoteId: dataCycleNoteId || "",
      })
        .then((res: any) => {
          SuccessToast(res?.data?.message);
          navigateToNotes();
          resetForm();
        })
        .finally(() => {
          setAppLoading(false);
        });
    }
  };

  const navigateToNotes = () => {
    const navId = dataCycleId || values.dataCycleId;
    navigate(`/surveycycles/${navId}/notes`);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view">
        <Grid container>
          <Grid item xs={12} sm={2} md={2} />
          <Grid item xs={10} sm={8} md={8}>
            <Grid container>
              <Grid item xs={12} className="grid-custom-spacing">
                <div className="page-heading-container">
                  <Controls.BackButton />
                  <h2 className="page-heading">{pageHeading}</h2>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <textarea
                  id="dataCycleNote"
                  name="dataCycleNote"
                  value={values.dataCycleNote}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <div className="form-buttons custom-floatRight">
              <br />
              <Controls.Button
                className="button blue"
                type="submit"
                text="Save"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={navigateToNotes}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Form>
  );
}
