export const colours = [
  "#737cbb",
  "#b6298a",
  "#455180",
  "#009d81",
  "#e0e0e0",
  "#323135",
  "pink",
  "turquoise",
  "black",
  "#423f3f", //All text across the application
];
