import moment from "moment";
import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Controls from "../../../components/Controls";
import { SuccessToast } from "../../../components/Toaster";
import { API_URLS } from "../../../config/ApiUrls";
import { UseTable } from "../../../hooks/useTable";
import API from "../../../services/Api.Service";

import "./Style.css";

const DataCycleNotesTable = () => {
  const [datacyclenotes, setDataCycleNotes] = React.useState<any[]>([]);
  const [modal, setModal] = React.useState(false);
  const [modalHeading, setModalHeading] = React.useState("");
  const [tempInContext, setTempInContext] = React.useState({
    dataCycleNoteId: "",
    dataCycleNote: "",
  });

  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    API.get(API_URLS.GetDataCycleNotes, {
      params: {
        dataCycleId: id,
      },
    }).then((response: any) => {
      setDataCycleNotes(response.data.data);
    });
  }, []);

  const navigateToEdit = (dataCycleNoteId: string) => {
    navigate(`/surveycycles/${dataCycleNoteId}/editnote`);
  };

  const columns = [
    { title: "SURVEY CYCLE NOTE", field: "dataCycleNote" },
    {
      title: "CREATED ON",
      field: "createdOn",
      render: (rowData: any) => (
        <div className="createdOn">
          {moment(rowData.createdOn).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      title: "MANAGE",
      field: "edit",
      render: (rowData: any) => (
        <div className="actions-container">
          <p onClick={() => navigateToEdit(rowData?.dataCycleNoteId)}>Edit</p>|
          <p
            className="actions-container-delete"
            onClick={() => toggleModal(rowData, "Delete")}
          >
            Delete
          </p>
        </div>
      ),
    },
  ];

  const toggleModal = (rowData: any, type: string) => {
    setModalHeading(type);
    setTempInContext({
      dataCycleNoteId: rowData.dataCycleNoteId,
      dataCycleNote: rowData.dataCycleNote,
    });
    setModal(!modal);
  };

  const closeModal = () => {
    setModal(false);
  };

  const deleteDataCycleNote = () => {
    API.delete(API_URLS.DeleteDataCycleNote, {
      params: {
        dataCycleNoteId: tempInContext.dataCycleNoteId,
      },
    }).then((res: any) => {
      SuccessToast(res?.data.message);
      setModal(false);
      API.get(API_URLS.GetDataCycleNotes, {
        params: {
          dataCycleId: id,
        },
      }).then((response: any) => {
        setDataCycleNotes(response.data.data);
      });
    });
  };

  return (
    <>
      <div>
        <UseTable
          columns={columns}
          data={datacyclenotes}
          title="Survey Cycle Notes"
        />
      </div>
      {modal && (
        <div className="modal">
          <div onClick={closeModal} className="overlay" />
          <div className="modal-content">
            <h2 className="page-heading">{modalHeading} Survey Cycle Note</h2>
            <p>
              You are about to {modalHeading.toLowerCase()} the survey cycle
              note. Are you sure you want to proceed?
            </p>
            <br />
            <div className="form-buttons custom-floatRight">
              <Controls.Button
                className="button blue"
                onClick={deleteDataCycleNote}
                text={modalHeading}
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={closeModal}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DataCycleNotesTable;
