import InCompleteOrganisations from "./InCompleteOrganisations";
import "./Style.css";

const AndWiderDashboard = () => {
  return (
    <div className="container-view">
      <InCompleteOrganisations />
    </div>
  );
};

export default AndWiderDashboard;
