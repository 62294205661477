import { useEffect } from "react";
import Controls from "components/Controls";
import { IManageUserEmail } from "models/Interfaces/IProfile";
import { Form, useForm } from "hooks/useForm";
import { useLoading } from "providers/loading";
import { validateEmail } from "util/validations";
import API from "services/Api.Service";
import { API_URLS } from "config/ApiUrls";
import { SuccessToast } from "components/Toaster";

const initialEmailValues: IManageUserEmail = {
  email: "",
};
let obj = {} as IManageUserEmail;

export const ChangeEmailForm = () => {
  const { setAppLoading } = useLoading();

  const validateUserEmail = (fieldValues = values) => {
    if ("email" in fieldValues)
      obj.email = fieldValues.email ? "" : "email is required.";
    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };
  useEffect(() => {
    obj = {};
  });
  const { values, setErrors, handleInputChange, resetForm } = useForm(
    initialEmailValues,
    true,
    validateUserEmail,
  );

  const handleSubmitUserEmail = (e: any) => {
    e.preventDefault();
    if (validateUserEmail() && validateEmail(values, obj, setErrors)) {
      setAppLoading(true);
      API.post(API_URLS.UpdateEmail, {
        email: values.email,
      })
        .then((response: any) => {
          SuccessToast(response?.data?.message);
          resetForm();
        })
        .finally(() => {
          setAppLoading(false);
        })
        .catch(() => {
          setAppLoading(false);
        });
    }
  };

  return (
    <Form onSubmit={handleSubmitUserEmail}>
      <Controls.Input
        id="email"
        name="email"
        label="Email address"
        value={values.email}
        onChange={handleInputChange}
        error={obj.email}
        cName="text-input"
      />
      <br />
      <br />
      <div>
        <Controls.Button
          className="button blue"
          type="submit"
          text="Change email"
        />
      </div>
    </Form>
  );
};
