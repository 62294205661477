import Grid from "@material-ui/core/Grid";
import { useNavigate } from "react-router-dom";
import Controls from "components/Controls";
import OrganisationUsersTable from "../OrganisationUsersTable";

const OrganisationUsers = () => {
  const navigate = useNavigate();

  const inviteuser = () => {
    navigate(`/organisations/invite-user`);
  };

  return (
    <div className="container-view">
      <Grid container>
        <Grid item xs={12} sm={6}>
          <div className="page-heading-container">
            <Controls.BackButton />
            <h2 className="page-heading">BUsiness Users</h2>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="table-button-tray">
            <Controls.Button
              text="Invite User"
              className="button maroon"
              onClick={inviteuser}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <OrganisationUsersTable />
        </Grid>
      </Grid>
    </div>
  );
};

export default OrganisationUsers;
