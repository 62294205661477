import Grid from "@material-ui/core/Grid";
import { useNavigate } from "react-router-dom";
import Controls from "components/Controls";
import routePaths from "config/RoutePaths";
import SectorsTable from "./SectorsTable";

const Sectors = () => {
  const navigate = useNavigate();

  const createSector = () => {
    navigate(`${routePaths.CREATESECTOR}`);
  };

  return (
    <div className="container-view">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <div className="page-heading-container">
            <Controls.BackButton />
            <h2 className="page-heading">Manage Sectors</h2>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="table-button-tray">
            <Controls.Button
              text="Add Sector"
              className="button maroon"
              onClick={() => createSector()}
            />
          </div>
        </Grid>
      </Grid>
      <SectorsTable />
    </div>
  );
};

export default Sectors;
