import React, { createRef, useEffect } from "react";
import { API_URLS } from "config/ApiUrls";
import { UseTable } from "hooks/useTable";
import API from "services/Api.Service";
import { useLoading } from "providers/loading";

import "./Style.css";
import moment from "moment";

const tableRef = createRef<any>();
const SectorsTable = () => {
  const { setAppLoading } = useLoading();
  const [sectors, setSectors] = React.useState<any[]>([]);

  useEffect(() => {
    setAppLoading(true);
    API.get(API_URLS.GetSectors, {})
      .then((response: any) => {
        setSectors(response.data.data);
      })
      .finally(() => setAppLoading(false));
  }, []);

  const columns = [
    { title: "SECTOR", field: "sectorSection" },
    {
      title: "CREATED ON",
      field: "createdOn",
      render: (rowData: any) => (
        <div className="createdOn">
          {moment(rowData.startDate).format("DD/MM/YYYY")}
        </div>
      ),
    },
  ];

  return (
    <div>
      <UseTable
        refTable={tableRef}
        columns={columns}
        data={sectors}
        title="Sectors"
      />
    </div>
  );
};

export default SectorsTable;
