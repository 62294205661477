import { colours } from "components/Charts/Colours";
import "./Style.css";

function ResponseRatesKey() {
  return (
    <div className="responseRatesKeyContainer">
      <div className="responseRatesKeyLine">
        <div
          className="responseRatesKeyColour"
          style={{ backgroundColor: colours[0] }}
        />
        <div className="responseRatesKeyText">Complete</div>
      </div>
      <div className="responseRatesKeyLine">
        <div
          className="responseRatesKeyColour"
          style={{ backgroundColor: colours[1] }}
        />
        <div className="responseRatesKeyText">Incomplete</div>
      </div>
      <div className="responseRatesKeyLine">
        <div
          className="responseRatesKeyColour"
          style={{ backgroundColor: colours[2] }}
        />
        <div className="responseRatesKeyText">Opted Out</div>
      </div>
      <div className="responseRatesKeyLine">
        <div
          className="responseRatesKeyColour"
          style={{ backgroundColor: colours[3] }}
        />
        <div className="responseRatesKeyText">Unengaged</div>
      </div>
    </div>
  );
}

export default ResponseRatesKey;
