import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faPlay } from "@fortawesome/free-solid-svg-icons";
import "./Style.css";
import { useEffect, useState } from "react";
import { API_URLS } from "config/ApiUrls";
import API from "services/Api.Service";
import { useLoading } from "providers/loading";
import Controls from "components/Controls";
import AudioPlayer from "./AudioPlayer";

function Translations() {
  const [modal, setModal] = useState(false);
  const [audio, setAudio] = useState("");
  const [surveyCycle, setSurveyCycle] = useState("");
  const [surveyTemplate, setSurveyTemplate] = useState("");
  const [languages, setLanguages] = useState<any>([]);

  const { setAppLoading } = useLoading();

  // Survey cycle ID from parameters
  const { id } = useParams();

  // This useEffect pulls the survey cycle details
  useEffect(() => {
    setAppLoading(true);
    API.get(API_URLS.GetDataCycleById, {
      params: {
        dataCycleId: id,
      },
    })
      .then((response: any) => {
        setSurveyCycle(response?.data?.dataCycleName);
        setSurveyTemplate(response?.data?.surveyTemplateName);
      })
      .finally(() => {
        setAppLoading(false);
      });

    API.get(API_URLS.GetMissingTranslations, {
      params: {
        dataCycleId: id,
      },
    })
      .then((response: any) => {
        setLanguages(response?.data);
      })
      .finally(() => {});
  }, []);

  // Function used to close the audio player
  const closeAudioPlayer = () => {
    setModal(false);
  };

  return (
    <div className="container-view">
      <Grid container>
        <Grid item xs={12} sm={2} md={2} />
        <Grid item xs={10} sm={8} md={8}>
          <Grid container>
            <Grid item>
              <div className="page-heading-container">
                <Controls.BackButton />
                <h2 className="page-heading">Translations</h2>
              </div>
              <p>{surveyCycle}</p>
              <p>{surveyTemplate}</p>
            </Grid>
          </Grid>
          <div style={{ width: "100%" }}>
            {languages.map((language: any) => {
              // This function maps each language required for the survey cycle
              // rendering a table with each question in the survey template
              // and the corresponding written and audio translations
              return (
                <div>
                  <div className="language-heading">
                    <h3 className="language-heading-text">
                      {language.languageName}
                    </h3>
                    {/* {language.missing && (
                      <Controls.Button
                        text="Download Missing Translations CSV"
                        className="button maroon"
                        size="small"
                        //onClick={() => navigate(routePaths.CREATEORGANISATIONS)}
                      />
                    )} */}
                  </div>
                  <table className="translations-table">
                    <tr>
                      <th>Type</th>
                      <th>Indicator</th>
                      <th>Question</th>
                      <th>Translation</th>
                      <th>Audio</th>
                    </tr>
                    {language.questions.map((question: any) => {
                      return (
                        <tr>
                          <td>{question.questionType}</td>
                          <td>{question.indicatorLabel}</td>
                          <td>{question.questionText}</td>
                          <td>
                            <div className="written-translation-cell">
                              <p>{question.translation}</p>
                              {question.translation && (
                                <div
                                  className="icon-holder"
                                  onClick={() =>
                                    navigator.clipboard.writeText(
                                      question.translation,
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon={faCopy} />
                                </div>
                              )}
                            </div>
                          </td>
                          <td>
                            {question.audioUrl && (
                              <div className="audio-translation-cell">
                                <div
                                  className="icon-holder green"
                                  onClick={() => {
                                    setAudio(question.audioUrl);
                                    setModal(true);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPlay} />
                                </div>
                                <div
                                  className="icon-holder"
                                  onClick={() =>
                                    navigator.clipboard.writeText(
                                      question.audioUrl,
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon={faCopy} />
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                  <br />
                  <br />
                </div>
              );
            })}
          </div>
        </Grid>
      </Grid>
      {modal && <AudioPlayer closeAudio={closeAudioPlayer} audio={audio} />}
    </div>
  );
}

export default Translations;
