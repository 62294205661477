import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { useAuth as useNewAuth } from "lib/auth";
import { IAdminNavItem } from "models/Interfaces/INavBar";
import { RolesEnum } from "models/Enum/Roles";
import {
  AdminMenuConfig,
  BlankMenuConfig,
  OrgAdminMenuConfig,
  OrgGeneralMenuConfig,
} from "config/MenuConfig";
import "./Navbar.css";
import MenuItem from "./MenuItem";
import MobileMenuItem from "./MobileMenuItem";
import { IconEnum as Icons } from "../../util/Icons";

// function Navbar({ user }: INavBar) {
function Navbar() {
  const auth = useNewAuth();
  // console.log("authentication", authentication);

  let user = {};
  if (auth.user?.data?.roles) {
    user = auth.user?.data.roles[0];
  } else {
    user = RolesEnum.Anonymous;
  }

  const [click, setClick] = useState(false);

  const navigate = useNavigate();

  const handleClick = () => setClick(!click);

  const clickLogo = () => {
    const path = "/";

    if (auth?.user?.data?.hideMap === true) {
      const organisationIds = auth?.user?.data?.userOrganisations.map(
        (org: any) => {
          return org.organisationId;
        },
      );

      navigate(`reports/list`, {
        state: {
          clientId: null,
          clientName: null,
          clientOrgIds: organisationIds,
          organisationId: "all",
          orgName: "All Businesses",
        },
      });
    } else {
      navigate(path);
    }
  };

  let menuConfig: IAdminNavItem[];
  // check if user is not logged in
  if (auth?.user?.data?.roles?.find((role: any) => role === RolesEnum.Admin)) {
    menuConfig = AdminMenuConfig;
  } else if (
    auth?.user?.data?.roles?.find((role: any) => role === RolesEnum.OrgAdmin)
  ) {
    menuConfig = OrgAdminMenuConfig;
  } else if (
    auth?.user?.data?.roles?.find((role: any) => role === RolesEnum.OrgGeneral)
  ) {
    menuConfig = OrgGeneralMenuConfig;
  } else if (
    auth?.user?.data?.roles?.find((role: any) => role === RolesEnum.OrgGuest)
  ) {
    menuConfig = OrgGeneralMenuConfig;
  } else {
    menuConfig = BlankMenuConfig;
  }

  const renderIcon = () => {
    if (!click) {
      return <FaBars className="icon" />;
    }
    return <FaTimes className="icon" />;
  };

  // The rendering and configuration of the menu is dependent
  // on whether a user is logged in and what their role is

  const renderMenu = () => {
    // check if user is not logged in
    if (user !== RolesEnum.Anonymous) {
      return (
        <>
          <ul className="nav-menu">
            {menuConfig.map((item: IAdminNavItem, index: any) => {
              return (
                <MenuItem
                  title={item.title}
                  icon={item.icon}
                  iconPath={item.iconPath}
                  key={index}
                  keyId={index}
                  path={item.path}
                  submenu={item.submenu}
                  id={item.id}
                />
              );
            })}
          </ul>
          <div className="navbar-icon" onClick={handleClick}>
            {renderIcon()}
          </div>
        </>
      );
    }
    return <div />;
  };

  return (
    <>
      <div className="nav-wrapper">
        <nav className="navbar">
          <div className="navbar-logo" onClick={clickLogo}>
            <img src={Icons.WhiteLogo} alt="&Wider" />
          </div>
          {renderMenu()}
        </nav>
        <div className="nav-thick-banner">
          <img src={Icons.ThickBanner} alt="Thick Banner" />
        </div>
      </div>
      <div>
        <nav className={click ? "navmobile-active" : "navmobile-hidden"}>
          <ul className="mobile">
            {menuConfig.map((item: any, index: number) => {
              return (
                <MobileMenuItem
                  title={item.title}
                  key={index}
                  keyId={index}
                  path={item.path}
                  submenu={item.submenu}
                  id={item.id}
                  setClick={setClick}
                />
              );
            })}
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Navbar;
