import { useEffect, useState } from "react";
import Charts from "components/Charts";
import { API_URLS } from "config/ApiUrls";
import { SmallSpinner } from "components/Spinner";
import API from "services/Api.Service";

function Segmentation({
  dataCycleId,
  selectedQuestion,
  dataCycleIds,
  showBenchmarks,
  groupDataCycleIds,
  showGroupBenchmarks,
  dataCycleName,
}: any) {
  const [demographics, setDemographics] = useState<any>({});
  const [demographicsLoaded, setDemographicsLoaded] = useState(false);
  const [demographicBenchmarks, setDemographicBenchmarks] = useState<any>({});
  const [demographicBenchmarksLoaded, setDemographicBenchmarksLoaded] =
    useState(true);
  const [groupBenchmarks, setGroupBenchmarks] = useState<any>({});
  const [groupBenchmarksLoaded, setGroupBenchmarksLoaded] = useState(true);

  useEffect(() => {
    setDemographicsLoaded(false);
    if (dataCycleId && selectedQuestion) {
      const url = API_URLS.GetSegmentationResults;

      API.post(url, {
        dataCycleId,
        questionId: selectedQuestion.questionId,
      })
        .then((response: any) => {
          demographics.segmentationQuestion = response?.data?.chartTitle;
          demographics.responses = response?.data?.codedResponses?.map(
            (x: any) => {
              return {
                responseOption: x.keypadDesription,
                percentage: Math.round(x.participantsPercentage),
                participantsCount: x.participantsCount,
              };
            },
          );
          setDemographics(demographics);
          setDemographicsLoaded(true);
        })
        .finally(() => {});
    }
  }, [dataCycleId, selectedQuestion]);

  useEffect(() => {
    if (showBenchmarks) {
      setDemographicBenchmarksLoaded(false);
      API.post(API_URLS.GetSegmentationChartBenchmarks, {
        dataCycleId,
        questionId: selectedQuestion.questionId,
        dataCycleIds,
      })
        .then((response: any) => {
          demographicBenchmarks.segmentationQuestion =
            response?.data?.chartTitle;
          demographicBenchmarks.responses = response?.data?.codedResponses?.map(
            (x: any) => {
              return {
                responseOption: x.keypadDesription,
                percentage: Math.round(x.participantsPercentage),
                participantsCount: x.participantsCount,
              };
            },
          );
          setDemographicBenchmarks(demographicBenchmarks);
          setDemographicBenchmarksLoaded(true);
        })
        .finally(() => {});
    }
    if (!showBenchmarks) {
      setDemographicBenchmarks({});
    }
  }, [showBenchmarks, selectedQuestion]);

  useEffect(() => {
    if (showGroupBenchmarks) {
      setGroupBenchmarksLoaded(false);
      API.post(API_URLS.GetSegmentationChartBenchmarks, {
        dataCycleId,
        questionId: selectedQuestion.questionId,
        dataCycleIds: groupDataCycleIds,
      })
        .then((response: any) => {
          groupBenchmarks.segmentationQuestion = response?.data?.chartTitle;
          groupBenchmarks.responses = response?.data?.codedResponses?.map(
            (x: any) => {
              return {
                responseOption: x.keypadDesription,
                percentage: Math.round(x.participantsPercentage),
                participantsCount: x.participantsCount,
              };
            },
          );
          setGroupBenchmarks(groupBenchmarks);
          setGroupBenchmarksLoaded(true);
        })
        .finally(() => {});
    }
    if (!showGroupBenchmarks) {
      setGroupBenchmarks({});
    }
  }, [showGroupBenchmarks, selectedQuestion]);

  return demographicsLoaded &&
    demographicBenchmarksLoaded &&
    groupBenchmarksLoaded ? (
    <Charts.PieChart
      config={demographics}
      demographicBenchmarks={demographicBenchmarks}
      groupDemographicBenchmarks={groupBenchmarks}
      dataCycleName={dataCycleName}
    />
  ) : (
    <div className="chartSpinner">
      <SmallSpinner />
    </div>
  );
}

export default Segmentation;
