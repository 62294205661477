import { Routes, Route, Navigate } from "react-router-dom";
import routePaths from "config/RoutePaths";
import CreateDataCycle from "./CreateDataCycle";
import ViewDataCycle from "./ViewDataCycle";
import CreateMultipleDataCycles from "./CreateMultipleDataCycles";
import CreateDataCycleNote from "./CreateDataCycleNote";
import DataCycleNotes from "./DataCycleNotes";
import DataCycles from "./DataCycles";
import Translations from "./Translations";

export const DataCycleRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<DataCycles />} />
      <Route path="/create" element={<CreateDataCycle />} />
      <Route
        path="/create-multiple-datacycles"
        element={<CreateMultipleDataCycles />}
      />
      <Route path={routePaths.EDITDATACYCLE} element={<CreateDataCycle />} />
      <Route path={routePaths.VIEWDATACYCLE} element={<ViewDataCycle />} />
      <Route path={routePaths.DATACYCLENOTES} element={<DataCycleNotes />} />
      <Route
        path={routePaths.CREATEDATACYCLENOTE}
        element={<CreateDataCycleNote />}
      />
      <Route
        path={routePaths.EDITDATACYCLENOTE}
        element={<CreateDataCycleNote />}
      />
      <Route path={routePaths.TRANSLATIONS} element={<Translations />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
