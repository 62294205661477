import Controls from "components/Controls";
import { IArchiveModal } from "models/Interfaces/IReports";
import API from "services/Api.Service";
import { API_URLS } from "config/ApiUrls";
import { useLoading } from "providers/loading";

const ArchiveModal = ({ setModal, selectedQuestion }: IArchiveModal) => {
  const { setAppLoading } = useLoading();

  const archiveQuestion = () => {
    setAppLoading(true);
    API.post(API_URLS.ArchivedQuestions, null, {
      params: {
        QuestionId: selectedQuestion.questionId,
      },
    })
      .then(() => {
        setModal();
      })
      .finally(() => {
        setAppLoading(false);
      });
  };

  return (
    <div className="modal">
      <div onClick={setModal} className="overlay" />
      <div className="modal-content">
        <h2>Archive Question</h2>
        <p>
          You are about to archive a question. Are you sure you want to proceed?
        </p>
        <p>Question: {selectedQuestion.questionText}</p>
        <br />
        <div className="form-buttons custom-floatRight">
          <Controls.Button
            className="button blue"
            onClick={archiveQuestion}
            text="Archive"
          />
          <Controls.Button
            text="Cancel"
            className="cancel-button"
            variant="outlined"
            onClick={setModal}
          />
        </div>
      </div>
    </div>
  );
};

export default ArchiveModal;
