import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@material-ui/core";
import { IRadioGroup } from "../../../models/Interfaces/IControls";
import { Items } from "../../../models/Interfaces/IQuestion";

export default function RadioGroup({
  id,
  name,
  label,
  value,
  onChange,
  items,
  error,
  values,
}: IRadioGroup) {
  return (
    <FormControl {...(error && { error: true })}>
      <FormLabel>{label}</FormLabel>
      <MuiRadioGroup
        style={{ display: "flex", flexDirection: "column" }}
        row
        id={id}
        name={name}
        value={value}
        onChange={onChange}
      >
        {items?.map((item: Items) => {
          const isChecked = values[item.name];
          return (
            <FormControlLabel
              key={item.id}
              value={item.id}
              name={item.name}
              control={<Radio />}
              label={item.label}
              checked={isChecked}
            />
          );
        })}
      </MuiRadioGroup>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
