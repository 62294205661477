import { useEffect, useState } from "react";
import Charts from "components/Charts";
import { API_URLS } from "config/ApiUrls";
import { SmallSpinner } from "components/Spinner";
import API from "services/Api.Service";

function SegmentationCompare({
  dataCycleId,
  selectedQuestion,
  compareDataCycleId,
  dataCycleIds,
  showBenchmarks,
  groupDataCycleIds,
  showGroupBenchmarks,
}: any) {
  const [demographics, setDemographics] = useState<any>({});
  const [demographicsLoaded, setDemographicsLoaded] = useState(true);
  const [demographicBenchmarks, setDemographicBenchmarks] = useState<any>({});
  const [demographicBenchmarksLoaded, setDemographicBenchmarksLoaded] =
    useState(true);
  const [groupBenchmarks, setGroupBenchmarks] = useState<any>({});
  const [groupBenchmarksLoaded, setGroupBenchmarksLoaded] = useState(true);

  useEffect(() => {
    setDemographicsLoaded(false);
    if (dataCycleId && selectedQuestion && compareDataCycleId) {
      const url = API_URLS.GetSegmentationComparison;

      API.post(url, {
        dataCycleIds: [dataCycleId, compareDataCycleId],
        questionId: selectedQuestion.questionId,
      })
        .then((response: any) => {
          setDemographics(response?.data);
        })
        .finally(() => {
          setDemographicsLoaded(true);
        });
    } else if (dataCycleId && selectedQuestion) {
      if (dataCycleId && selectedQuestion) {
        const url = API_URLS.GetSegmentationComparison;

        API.post(url, {
          dataCycleIds: [dataCycleId, compareDataCycleId],
          questionId: selectedQuestion.questionId,
        })
          .then((response: any) => {
            setDemographics(response?.data);
          })
          .finally(() => {
            setDemographicsLoaded(true);
          });
      }
    }
  }, [dataCycleId, compareDataCycleId, selectedQuestion]);

  useEffect(() => {
    if (showBenchmarks) {
      setDemographicBenchmarksLoaded(false);
      API.post(API_URLS.GetSegmentationChartBenchmarks, {
        dataCycleId,
        questionId: selectedQuestion.questionId,
        dataCycleIds,
      })
        .then((response: any) => {
          demographicBenchmarks.segmentationQuestion =
            response?.data?.chartTitle;
          demographicBenchmarks.responses = response?.data?.codedResponses?.map(
            (x: any) => {
              return {
                responseOption: x.keypadDesription,
                percentage: Math.round(x.participantsPercentage),
                participantsCount: x.participantsCount,
              };
            },
          );
          setDemographicBenchmarks(demographicBenchmarks);
          setDemographicBenchmarksLoaded(true);
        })
        .finally(() => {});
    }
    if (!showBenchmarks) {
      setDemographicBenchmarks({});
    }
  }, [showBenchmarks, selectedQuestion]);

  useEffect(() => {
    if (showGroupBenchmarks) {
      setGroupBenchmarksLoaded(false);
      API.post(API_URLS.GetSegmentationChartBenchmarks, {
        dataCycleId,
        questionId: selectedQuestion.questionId,
        dataCycleIds: groupDataCycleIds,
      })
        .then((response: any) => {
          groupBenchmarks.segmentationQuestion = response?.data?.chartTitle;
          groupBenchmarks.responses = response?.data?.codedResponses?.map(
            (x: any) => {
              return {
                responseOption: x.keypadDesription,
                percentage: Math.round(x.participantsPercentage),
                participantsCount: x.participantsCount,
              };
            },
          );
          setGroupBenchmarks(groupBenchmarks);
          setGroupBenchmarksLoaded(true);
        })
        .finally(() => {});
    }
    if (!showGroupBenchmarks) {
      setGroupBenchmarks({});
    }
  }, [showGroupBenchmarks, selectedQuestion]);

  return demographicsLoaded &&
    demographicBenchmarksLoaded &&
    groupBenchmarksLoaded ? (
    <Charts.ComparePieChart
      config={demographics}
      demographicBenchmarks={demographicBenchmarks}
      groupDemographicBenchmarks={groupBenchmarks}
    />
  ) : (
    <div className="chartSpinner">
      <SmallSpinner />
    </div>
  );
}

export default SegmentationCompare;
