import { useState } from "react";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { IAdmin } from "models/Interfaces/IAdmin";
import { Form, useForm } from "hooks/useForm";
import Controls from "components/Controls";
import { API_URLS } from "config/ApiUrls";
import API from "services/Api.Service";
import { SuccessToast } from "components/Toaster";
import routePaths from "config/RoutePaths";

import {
  validateEmail,
  validatePassword,
  validatePasswords,
} from "util/validations";
import { useLoading } from "providers/loading";

export default function RegisterAdministrator() {
  const navigate = useNavigate();
  const { setAppLoading } = useLoading();
  const [obj] = useState<any>({} as IAdmin);

  const initialFValues: IAdmin = {
    email: "",
    password: "",
    passwordConfirm: "",
  };

  const validate = (fieldValues = values) => {
    if ("email" in fieldValues)
      obj.email = fieldValues.email ? "" : "Email is required.";
    if ("password" in fieldValues)
      obj.password = fieldValues.password ? "" : "Password is required.";
    if ("passwordConfirm" in fieldValues)
      obj.passwordConfirm = fieldValues.passwordConfirm
        ? ""
        : "Password confirmation is required.";
    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange, resetForm } = useForm(
    initialFValues,
    true,
    validate,
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (
      validate() &&
      validateEmail(values, obj, setErrors) &&
      validatePassword(values, obj, setErrors) &&
      validatePasswords(values, obj, setErrors)
    ) {
      setAppLoading(true);
      API.post(API_URLS.RegisterAndWiderAdmin, {
        email: values.email,
        password: values.password,
      })
        .then((res: any) => {
          SuccessToast(res?.message);
          navigate(routePaths.USERS);
          resetForm();
        })
        .finally(() => {
          setAppLoading(false);
        });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view">
        <Grid container>
          <Grid item xs={12} sm={3} md={3} />
          <Grid item xs={12} sm={6} md={6}>
            <div className="page-heading-container">
              <Controls.BackButton />
              <h2 className="page-heading">Register Administrator</h2>
            </div>
            <Controls.Input
              id="email"
              name="email"
              label=" Email address"
              value={values.email}
              onChange={handleInputChange}
              error={obj.email}
              cName="text-input"
            />
            <Controls.PasswordInput
              id="password"
              label="Password"
              name="password"
              value={values.password}
              onChange={handleInputChange}
              error={obj.password}
              cName="text-input"
            />
            <Controls.PasswordInput
              id="passwordConfirm"
              label="Confirm password"
              name="passwordConfirm"
              value={values.passwordConfirm}
              onChange={handleInputChange}
              error={obj.passwordConfirm}
              cName="text-input"
            />
            <br />
            <br />
            <div className="form-buttons custom-floatRight">
              <br />
              <Controls.Button
                className="button blue"
                type="submit"
                text="Save"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={() => navigate(routePaths.USERS)}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3} />
        </Grid>
      </div>
    </Form>
  );
}
