import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { Form, useForm } from "hooks/useForm";
import Controls from "components/Controls";
import { ITag } from "models/Interfaces/IOrganisation";
import API from "services/Api.Service";
import routePaths from "config/RoutePaths";
import { API_URLS } from "config/ApiUrls";
import { UseTable } from "hooks/useTable";
import { SuccessToast } from "components/Toaster";
import { useLoading } from "providers/loading";
import "./Style.css";

const initialFValues: ITag = {
  tagName: "",
  tagId: "",
};

const obj = {} as ITag;

export default function CreateTag() {
  const { setAppLoading } = useLoading();
  const [tags, setTags] = useState<any[]>([]);
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [tagToBeDeleted, setTagToBeDeleted] = useState({
    tagId: "",
    tagName: "",
  });
  const [tagToBeEdited, setTagToBeEdited] = useState({
    tagId: "",
    tagName: "",
  });
  const navigate = useNavigate();

  const columns = [
    { title: "NAME", field: "tagName" },
    {
      title: "MANAGE",
      field: "edit",
      render: (rowData: any) => (
        <div className="actions-container">
          <p onClick={() => editTag(rowData)}>Edit</p>|
          <p
            className="actions-container-delete"
            onClick={() => toggleModal(rowData)}
          >
            Delete
          </p>
        </div>
      ),
    },
  ];

  const validate = (fieldValues = values) => {
    if ("tagName" in fieldValues)
      obj.tagName = fieldValues.tagName ? "" : "Tag name is required.";
    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange, resetForm } = useForm(
    initialFValues,
    true,
    validate,
  );

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (validate()) {
      setAppLoading(true);
      const url = editModal ? API_URLS.UpdateTag : API_URLS.CreateTag;
      API.post(url, {
        tagName: values.tagName,
        tagId: tagToBeEdited.tagId,
      })
        .then((res: any) => {
          SuccessToast(res?.data.message);
          resetForm();
          API.get(API_URLS.GetTags, {
            params: {},
          }).then((response: any) => {
            const tagOptions = response.data.data;
            setTags(tagOptions);
          });
        })
        .finally(() => {
          setModal(false);
          setEditModal(false);
          setAppLoading(false);
        });
    }
  };

  useEffect(() => {
    API.get(API_URLS.GetTags, {
      params: {},
    }).then((response: any) => {
      const tagOptions = response.data.data;
      setTags(tagOptions);
    });
  }, []);

  const toggleModal = (rowData: any) => {
    setTagToBeDeleted({
      tagId: rowData.tagId,
      tagName: rowData.tagName,
    });
    setModal(!modal);
  };

  const closeModal = () => {
    setModal(false);
  };

  const closeEditModal = () => {
    setEditModal(false);
  };

  const editTag = (rowData: any) => {
    setTagToBeEdited({
      tagId: rowData.tagId,
      tagName: rowData.tagName,
    });
    setEditModal(!editModal);
  };

  const deleteTag = () => {
    setAppLoading(true);
    API.delete(API_URLS.DeleteTag, {
      params: {
        tagId: tagToBeDeleted.tagId,
      },
    })
      .then((res: any) => {
        SuccessToast(res?.data.message);
        resetForm();
        API.get(API_URLS.GetTags, {
          params: {},
        }).then((response: any) => {
          const tagOptions = response.data.data;
          setTags(tagOptions);
        });
      })
      .finally(() => {
        closeModal();
        setAppLoading(false);
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view">
        <Grid container>
          <Grid item xs={12} sm={2} md={2} />
          <Grid item xs={10} sm={8} md={8}>
            <Grid container>
              <Grid item xs={12} className="grid-custom-spacing">
                <div className="page-heading-container">
                  <Controls.BackButton />
                  <h2 className="page-heading">Add Tag</h2>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.Input
                  id="tagName"
                  name="tagName"
                  label="Tag Name*"
                  value={values.tagName}
                  onChange={handleInputChange}
                  error={obj.tagName}
                />
              </Grid>
            </Grid>

            <div className="form-buttons custom-floatRight">
              <br />
              <Controls.Button
                className="button blue"
                type="submit"
                text="Save"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={() => {
                  navigate(routePaths.DASHBOARD);
                }}
              />
            </div>

            {modal && (
              <div className="modal">
                <div onClick={toggleModal} className="overlay" />
                <div className="modal-content">
                  <h2>Delete Tag</h2>
                  <p>
                    You are about to delete the following tag. Are you sure you
                    want to proceed?
                  </p>
                  <p>Tag: {tagToBeDeleted.tagName}</p>
                  <br />
                  <div className="form-buttons custom-floatRight">
                    <Controls.Button
                      className="button blue"
                      onClick={deleteTag}
                      text="Delete"
                    />
                    <Controls.Button
                      text="Cancel"
                      className="cancel-button"
                      variant="outlined"
                      onClick={closeModal}
                    />
                  </div>
                </div>
              </div>
            )}

            {editModal && (
              <div className="modal">
                <div onClick={toggleModal} className="overlay" />
                <div className="modal-content">
                  <h2>Edit Tag</h2>
                  <p>
                    You are about to edit the following tag. Are you sure you
                    want to proceed?
                  </p>
                  <p>Tag: {tagToBeEdited.tagName}</p>

                  <Form onSubmit={handleSubmit}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      className="grid-custom-spacing"
                    >
                      <Controls.Input
                        id="tagName"
                        name="tagName"
                        label="Enter updated tag name*"
                        value={values.tagName}
                        onChange={handleInputChange}
                        error={obj.tagName}
                      />
                    </Grid>
                  </Form>
                  <br />
                  <div className="form-buttons custom-floatRight">
                    <Controls.Button
                      className="button blue"
                      type="submit"
                      text="Edit"
                    />
                    <Controls.Button
                      text="Cancel"
                      className="cancel-button"
                      variant="outlined"
                      onClick={closeEditModal}
                    />
                  </div>
                </div>
              </div>
            )}

            <Grid container>
              <Grid item xs={12} className="grid-custom-spacing">
                <br />
                <h3>Tags</h3>
                <br />
                <UseTable columns={columns} title="Tags" data={tags} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={2} md={2} />
        </Grid>
      </div>
    </Form>
  );
}
