import "./Style.css";

function PrioritiesSummary({ priorities }: any) {
  return (
    <div className="prioritiesContainer">
      <div>
        {priorities.length > 0 ? (
          priorities.map((x: any) => {
            return (
              <div className="prioritiesOverviewLine">
                <div>{x.name}</div>
                <div className="percentageCell">
                  <div
                    className="percentageCircle"
                    style={{ width: `${x.percentage}%` }}
                  >{`${x.percentage}%`}</div>
                </div>
              </div>
            );
          })
        ) : (
          <p className="prioritiesOverviewLine">
            There are no current priorities for this site.
          </p>
        )}
      </div>
    </div>
  );
}

export default PrioritiesSummary;
