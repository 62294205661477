import { Route, Routes, Navigate } from "react-router-dom";
import RequireAuth from "pages/Auth/RequireAuth/RequireAuth";
import { RolesEnum } from "models/Enum/Roles";
import routePaths from "config/RoutePaths";
import Dashboard from "pages/DashBoard/Dashboard";
import Unauthorised from "pages/DashBoard/Unauthorised";
import { UsersRoutes } from "pages/Administrator/routes";
import { OrganisationRoutes } from "pages/Organisations/routes";
import { QuestionsRoutes } from "pages/Questions/routes";
import { DataCycleRoutes } from "pages/DataCycles/routes";
import { LanguageRoutes } from "pages/Languages/routes";
import { ResourcesRoutes } from "pages/Resources/routes";
import { SurveyRoutes } from "pages/SurveyTemplates/routes";
import { SitesRoutes } from "pages/Sites/routes";
import { ReportsRoutes } from "pages/Reports/routes";
import { ProfileRoutes } from "pages/Profile/routes";
import { OrgAdminRoutes } from "pages/OrgAdmin/routes";
import { SectorsRoutes } from "pages/Sectors/routes";

export const AuthenticatedApp = () => {
  return (
    <Routes>
      {/*PUBLIC ROUTES*/}
      <Route
        element={
          <RequireAuth
            allowedRoles={[
              RolesEnum.Admin,
              RolesEnum.OrgAdmin,
              RolesEnum.OrgGeneral,
              RolesEnum.OrgGuest,
            ]}
          />
        }
      >
        <Route path={routePaths.DASHBOARD} element={<Dashboard />} />
        <Route path={`${routePaths.UNAUTHORISED}`} element={<Unauthorised />} />
        <Route
          path={`/${routePaths.RESOURCES}/*`}
          element={<ResourcesRoutes />}
        />
        <Route path={`/${routePaths.SECTORS}/*`} element={<SectorsRoutes />} />
        <Route path={`/${routePaths.PROFILE}/*`} element={<ProfileRoutes />} />
        <Route path={`/${routePaths.REPORTS}/*`} element={<ReportsRoutes />} />
      </Route>
      {/*SHARED ROUTES*/}
      <Route
        element={
          <RequireAuth allowedRoles={[RolesEnum.Admin, RolesEnum.OrgAdmin]} />
        }
      >
        <Route
          path={`/${routePaths.SITEMANAGEMENT}/*`}
          element={<SitesRoutes />}
        />
        <Route
          path={`/${routePaths.ORGANISATIONS}/*`}
          element={<OrganisationRoutes />}
        />
      </Route>
      {/*&WIDER ADMIN ROUTES*/}
      <Route element={<RequireAuth allowedRoles={[RolesEnum.Admin]} />}>
        <Route path={`/${routePaths.USERS}/*`} element={<UsersRoutes />} />
        <Route
          path={`/${routePaths.QUESTIONS}/*`}
          element={<QuestionsRoutes />}
        />
        <Route
          path={`/${routePaths.DATACYCLES}/*`}
          element={<DataCycleRoutes />}
        />
        <Route
          path={`/${routePaths.LANGUAGES}/*`}
          element={<LanguageRoutes />}
        />
        <Route
          path={`/${routePaths.SURVEYTEMPLATES}/*`}
          element={<SurveyRoutes />}
        />
        <Route
          path={`/${routePaths.SITEMANAGEMENT}/*`}
          element={<SitesRoutes />}
        />
      </Route>
      {/*ORGANISATION ADMIN ROUTES*/}
      <Route element={<RequireAuth allowedRoles={[RolesEnum.OrgAdmin]} />}>
        <Route
          path={`/${routePaths.ORGANISATIONADMIN}/*`}
          element={<OrgAdminRoutes />}
        />
      </Route>
      {/*ORGANISATION USER ROUTES*/}
      <Route
        element={
          <RequireAuth
            allowedRoles={[RolesEnum.OrgGeneral, RolesEnum.OrgGuest]}
          />
        }
      >
        {/*Nothing specific here*/}
      </Route>
      <Route
        path="*"
        element={<Navigate to={routePaths.DASHBOARD} replace={true} />}
      />
    </Routes>
  );
};
