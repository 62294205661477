import React, { createRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_URLS } from "config/ApiUrls";
import { UseTable } from "hooks/useTable";
import API from "services/Api.Service";
import { SuccessToast } from "components/Toaster";
import Controls from "components/Controls";
import { useLoading } from "providers/loading";

import "./Style.css";

const tableRef = createRef<any>();
const ResourcesTable = () => {
  const { setAppLoading } = useLoading();
  const navigate = useNavigate();
  const [resources, setResources] = React.useState<any[]>([]);
  const [modal, setModal] = React.useState(false);
  const [resToBeDeleted, setResToBeDeleted] = React.useState({
    resourceId: "",
    resourceTitle: "",
  });

  useEffect(() => {
    setAppLoading(true);
    API.get(API_URLS.GetAllResourcesList, {
      params: {
        OrderBy: "ResourceId",
        SearchValue: null,
      },
    })
      .then((response: any) => {
        setResources(response.data.data);
      })
      .finally(() => setAppLoading(false));
  }, []);

  const navigateToEdit = (resourceId: string) => {
    navigate(`/resources/${resourceId}/edit`);
  };

  const columns = [
    { title: "RESOURCE TITLE", field: "resourceTitle" },
    { title: "TYPE", field: "resourceTypeName" },
    { title: "GROUP", field: "resourceGroupName" },
    {
      title: "MANAGE",
      field: "edit",
      sorting: false,
      render: (rowData: any) => (
        <div className="actions-container">
          <p onClick={() => navigateToEdit(rowData?.resourceId)}>Edit</p>|
          <p
            className="actions-container-delete"
            onClick={() => toggleModal(rowData)}
          >
            Delete
          </p>
        </div>
      ),
    },
  ];

  const toggleModal = (rowData: any) => {
    setResToBeDeleted({
      resourceId: rowData.resourceId,
      resourceTitle: rowData.resourceTitle,
    });
    setModal(!modal);
  };

  const closeModal = () => {
    setModal(false);
  };

  const deleteResource = () => {
    setAppLoading(true);
    API.delete(API_URLS.DeleteResource, {
      params: {
        resourceId: resToBeDeleted.resourceId,
      },
    })
      .then((res: any) => {
        setAppLoading(true);
        API.get(API_URLS.GetAllResourcesList, {
          params: {
            OrderBy: "ResourceId",
            SearchValue: null,
          },
        })
          .then((response: any) => {
            setResources(response.data.data);
          })
          .finally(() => setAppLoading(false));
        SuccessToast(res?.data?.message);
        setModal(false);
      })
      .finally(() => {
        setAppLoading(false);
      });
  };

  return (
    <div>
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay" />
          <div className="modal-content">
            <h2>Delete Resource</h2>
            <p>
              You are about to delete the following Resource. Are you sure you
              want to proceed?
            </p>
            <p>Resource Title: {resToBeDeleted.resourceTitle}</p>
            <br />
            <div className="form-buttons custom-floatRight">
              <Controls.Button
                className="button blue"
                onClick={deleteResource}
                text="Delete"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={closeModal}
              />
            </div>
          </div>
        </div>
      )}
      <UseTable
        refTable={tableRef}
        columns={columns}
        data={resources}
        title="Resources"
      />
    </div>
  );
};

export default ResourcesTable;
