import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./Style.css";
import { useLoading } from "providers/loading";
import { Form, useForm } from "hooks/useForm";
import Controls from "components/Controls";
import API from "services/Api.Service";
import { validatePassword, validatePasswords } from "util/validations";
import { API_URLS } from "config/ApiUrls";
import { SuccessToast } from "components/Toaster";
import routePaths from "config/RoutePaths";
import { IManagePassword } from "models/Interfaces/IProfile";

export default function ResetPassword() {
  const { setAppLoading } = useLoading();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [obj] = useState<any>({} as IManagePassword);

  const initialPasswordValues: IManagePassword = {
    password: "",
    passwordConfirm: "",
  };

  const validate = (fieldValues = values) => {
    if ("password" in fieldValues)
      obj.password = fieldValues.password ? "" : "New Password is required.";
    if ("passwordConfirm" in fieldValues)
      obj.passwordConfirm = fieldValues.passwordConfirm
        ? ""
        : "New Password confirmation is required.";
    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange, resetForm } = useForm(
    initialPasswordValues,
    true,
    validate,
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (
      validate() &&
      validatePassword(values, obj, setErrors) &&
      validatePasswords(values, obj, setErrors)
    ) {
      setAppLoading(true);
      API.post(API_URLS.ResetPassword, {
        password: values.password,
        confirmPassword: values.passwordConfirm,
        userId: searchParams.get("userId"),
        token: searchParams.get("code"),
      })
        .then((response: any) => {
          SuccessToast(response?.data?.message);
          navigate(routePaths.SIGNIN);
          resetForm();
        })
        .finally(() => {
          setAppLoading(false);
        });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view">
        <Grid container>
          <Grid item xs={12} sm={3} md={3} />
          <Grid item xs={12} sm={6} md={6}>
            <div>
              <h2 className="page-heading">Reset Password</h2>
              <p>Please enter your new password below.</p>
            </div>
            <Controls.PasswordInput
              id="password"
              name="password"
              label="New Password"
              value={values.password}
              onChange={handleInputChange}
              error={obj.password}
              cName="text-input"
            />
            <Controls.PasswordInput
              id="passwordConfirm"
              name="passwordConfirm"
              label="Confirm New Password"
              value={values.passwordConfirm}
              onChange={handleInputChange}
              error={obj.passwordConfirm}
              cName="text-input"
            />
            <br />
            <br />
            <div className="form-buttons custom-floatRight">
              <Controls.Button
                className="button blue"
                type="submit"
                text="Reset"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={() => navigate(routePaths.SIGNIN)}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3} />
        </Grid>
      </div>
    </Form>
  );
}
