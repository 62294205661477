import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { Form, useForm } from "hooks/useForm";
import Controls from "components/Controls";
import { ISurveyTemplateType } from "models/Interfaces/ISurveyTemplate";
import API from "services/Api.Service";
import routePaths from "config/RoutePaths";
import { API_URLS } from "config/ApiUrls";
import { UseTable } from "hooks/useTable";
import { SuccessToast } from "components/Toaster";
import { useLoading } from "providers/loading";
import "./Style.css";

const initialFValues: ISurveyTemplateType = {
  surveyTemplateTypeName: "",
  surveyTemplateTypeId: "",
  surveyTemplateTypeCode: 0,
};

const obj = {} as ISurveyTemplateType;

const columns = [
  { title: "NAME", field: "surveyTemplateTypeName" },
  { title: "CODE", field: "surveyTemplateTypeCode" },
];

export default function CreateSurveyTemplateType() {
  const { setAppLoading } = useLoading();
  const [surveyTemplateTypes, setSurveyTemplateTypes] = useState<any[]>([]);
  const navigate = useNavigate();

  const validate = (fieldValues = values) => {
    if ("surveyTemplateTypeName" in fieldValues)
      obj.surveyTemplateTypeName = fieldValues.surveyTemplateTypeName
        ? ""
        : "Survey template type name is required.";
    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange, resetForm } = useForm(
    initialFValues,
    true,
    validate,
  );

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (validate()) {
      setAppLoading(true);
      API.post(API_URLS.CreateSurveyTemplateType, {
        surveyTemplateTypeName: values.surveyTemplateTypeName,
      })
        .then((res: any) => {
          SuccessToast(res?.data.message);
          resetForm();
          API.get(API_URLS.GetSurveyTemplateTypes, {
            params: {},
          }).then((response: any) => {
            const templateTypeOptions = response.data.data;
            setSurveyTemplateTypes(templateTypeOptions);
          });
        })
        .finally(() => {
          setAppLoading(false);
        });
    }
  };

  useEffect(() => {
    API.get(API_URLS.GetSurveyTemplateTypes, {
      params: {},
    }).then((response: any) => {
      const templateTypeOptions = response.data.data;
      setSurveyTemplateTypes(templateTypeOptions);
    });
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view">
        <Grid container>
          <Grid item xs={12} sm={2} md={2} />
          <Grid item xs={10} sm={8} md={8}>
            <Grid container>
              <Grid item xs={12} className="grid-custom-spacing">
                <div className="page-heading-container">
                  <Controls.BackButton />
                  <h2 className="page-heading">Add Survey Template Type</h2>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.Input
                  id="surveyTemplateTypeName"
                  name="surveyTemplateTypeName"
                  label="Survey Template Type Name*"
                  value={values.surveyTemplateTypeName}
                  onChange={handleInputChange}
                  error={obj.surveyTemplateTypeName}
                />
              </Grid>
            </Grid>

            <div className="form-buttons custom-floatRight">
              <br />
              <Controls.Button
                className="button blue"
                type="submit"
                text="Save"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={() => {
                  navigate(routePaths.SURVEYTEMPLATES);
                }}
              />
            </div>

            <Grid container>
              <Grid item xs={12} className="grid-custom-spacing">
                <br />
                <h3>Survey Template Types</h3>
                <br />
                <UseTable
                  columns={columns}
                  title="Template Types"
                  data={surveyTemplateTypes}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={2} md={2} />
        </Grid>
      </div>
    </Form>
  );
}
