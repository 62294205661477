import { Routes, Route, Navigate } from "react-router-dom";
import routePaths from "config/RoutePaths";
import InviteUser from "./InviteUser";
import OrgAdmin from ".";

export const OrgAdminRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<OrgAdmin />} />
      <Route
        path={`${routePaths.ORGANISATIONINVITEUSER}`}
        element={<InviteUser />}
      />
      <Route
        path={`${routePaths.ORGANISATIONEDITUSER}`}
        element={<InviteUser />}
      />
      <Route path="*" element={<Navigate to={routePaths.USERS} />} />
    </Routes>
  );
};
