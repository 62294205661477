import { useNavigate } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Controls from "components/Controls";
import routePaths from "config/RoutePaths";
import OrganisationTable from "../OrganisationTable";

function Organisations() {
  const navigate = useNavigate();
  const navigateToInvite = () => {
    navigate(routePaths.INVITEORGANISATIONUSER);
    // window.location.reload();
  };
  return (
    <div className="container-view">
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div className="page-heading-container">
              <Controls.BackButton />
              <h2 className="page-heading">Manage Businesses</h2>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="table-button-tray">
              <Controls.Button
                text="Add Business"
                className="button maroon"
                onClick={() => navigate(routePaths.CREATEORGANISATIONS)}
              />
              <Controls.Button
                text="Add Workplace"
                className="button blue"
                onClick={() => navigate(routePaths.CREATESITE)}
              />
              <Controls.Button
                text="Invite User"
                className="button turquoise"
                onClick={navigateToInvite}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <OrganisationTable />
    </div>
  );
}

export default Organisations;
