import React from "react";
import Controls from "../../../components/Controls";
import { IMarkDataCycleComplete } from "../../../models/Interfaces/IDataCycle";
import "./Style.css";

type NukeSurveyCycleProps = {
  data: IMarkDataCycleComplete;
  toggleNukeModal: (rowData: any) => void;
  setNukeModal: React.Dispatch<React.SetStateAction<boolean>>;
  nukeSurveyCycle: (values: IMarkDataCycleComplete) => void;
};

export const NukeSurveyCycle = (props: NukeSurveyCycleProps) => {
  const { toggleNukeModal, setNukeModal, nukeSurveyCycle, data } = props;

  return (
    <div className="modal">
      <div onClick={toggleNukeModal} className="overlay" />
      <div className="modal-content">
        <p>
          You are going to remove all results from this survey cycle. Are you
          sure?
        </p>
        <br />
        <div className="form-buttons custom-floatRight">
          <Controls.Button
            className="button blue"
            text="Nuke"
            onClick={() => nukeSurveyCycle(data)}
          />
          <Controls.Button
            text="Cancel"
            className="cancel-button"
            variant="outlined"
            onClick={() => setNukeModal(false)}
          />
        </div>
      </div>
    </div>
  );
};
