import React, { useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import FileCopy from "@material-ui/icons/FileCopyOutlined";
import Controls from "components/Controls";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import { SuccessToast } from "components/Toaster";
import API from "services/Api.Service";
import { API_URLS } from "config/ApiUrls";
import { UseTable } from "hooks/useTable";
import { useLoading } from "providers/loading";

export const DeveloperAPICredentials = () => {
  const { setAppLoading } = useLoading();
  const [clientId, setClientId] = React.useState<string>("");
  const [apiAuthToken, setAPIAuthToken] = React.useState<string>("");
  const [dataCycleDetails, setDataCycleDetails] = React.useState([]);
  const copyToClipboard = useCopyToClipboard();

  useEffect(() => {
    setAppLoading(true);
    API.get(API_URLS.GetAPICredentials)
      .then((response: any) => {
        setClientId(response.data.clientId);
        setAPIAuthToken(response.data.authToken);
        setDataCycleDetails(response.data.dataCycleDetails);
      })
      .finally(() => {
        setAppLoading(false);
      });
  }, []);

  const handleCopy = (value: string) => {
    copyToClipboard(value);
    SuccessToast("Value copied to clipboard");
  };

  const columns = [
    { title: "Survey Cycle Id", field: "dataCycleId" },
    { title: "Survey Cycle Name", field: "dataCycleName" },
    { title: "Workplace Name", field: "organisationSiteName" },
    { title: "End Date", field: "completedDate" },
  ];

  return (
    <div className="container-view">
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={6} md={6} className="grid-custom-spacing">
              <h2 className="page-heading">API Credentials</h2>
              <p>
                Visit the{" "}
                <a
                  href="https://developer.andwider.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Developer API Documentation
                </a>{" "}
                to learn more. <br />
                Use the credentials below to get started making API requests to
                &WIDER.
              </p>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Controls.Input
                  id="clientId"
                  name="clientId"
                  label="Client Id"
                  value={clientId}
                  cName="text-input"
                  disabled
                />
                <FileCopy onClick={() => handleCopy(clientId)} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Controls.PasswordEye
                  id="authToken"
                  name="authToken"
                  label="Auth Token"
                  value={apiAuthToken}
                  cName="text-input"
                  disabled
                />
                <FileCopy onClick={() => handleCopy(apiAuthToken)} />
              </Box>
            </Grid>
          </Grid>
          <br />
          <br />
          {dataCycleDetails.length > 0 && (
            <div>
              <UseTable
                columns={columns}
                data={dataCycleDetails}
                title="Survey Cycle Details"
              />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
