import { FaMinus, FaPlus } from "react-icons/fa";
import InfoIcon from "@mui/icons-material/Info";
import "./Style.css";
import { useState } from "react";

function WhatToLookFor() {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="mapBox">
      <div className={`mapBoxBanner ${expanded ? "mapBoxBannerBorder" : ""}`}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <InfoIcon style={{ display: "inline-block" }} />
          <p style={{ display: "inline-block", paddingLeft: "5px" }}>
            What to look for
          </p>
        </div>
        <div
          className="contentExpansion"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <div className={expanded ? "hiddenIcon" : "displayedIcon"}>
            <FaPlus />
          </div>
          <div className={expanded ? "displayedIcon" : "hiddenIcon"}>
            <FaMinus />
          </div>
        </div>
      </div>
      <div className={`mapBoxContent ${expanded ? "visible" : "invisible"}`}>
        <div>
          Let’s start with the bigger picture. Our map provides a quick overview
          of your work sites—green for good working conditions, amber for areas
          needing attention, and red for urgent improvements. A black pin means
          data collection is still in progress or has not yet been collected for
          that site.
        </div>
      </div>
    </div>
  );
}

export default WhatToLookFor;
