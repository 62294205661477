import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useNavigate } from "react-router-dom";
import routePaths from "config/RoutePaths";
import Controls from "components/Controls";
import QuestionTable from "./QuestionTable";

const Questions = () => {
  const navigate = useNavigate();
  return (
    <div className="container-view">
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div className="page-heading-container">
              <Controls.BackButton />
              <h2 className="page-heading">Manage Questions</h2>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="table-button-tray">
              <Button
                variant="contained"
                onClick={() => navigate(routePaths.CREATEQUESTION)}
                className="button maroon"
              >
                Add Question
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <QuestionTable />
    </div>
  );
};

export default Questions;
