import { SyntheticEvent, useState } from "react";
import Controls from "../../../components/Controls";
import { ErrorToast } from "../../../components/Toaster";
import { useForm } from "../../../hooks/useForm";
import { UseTable } from "../../../hooks/useTable";
import { IChangeQuestionOrder } from "../../../models/Interfaces/ISurveyTemplate";
import "./Style.css";

type IType =
  | "string"
  | "boolean"
  | "numeric"
  | "date"
  | "datetime"
  | "time"
  | "currency";
const string: IType = "string";

const SurveyTemplateQuestionTable = (props: any) => {
  const [orderModal, setOrderModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [question, setQuestion] = useState({
    id: "",
    order: "",
  });

  const columns = [
    { title: "ORDER", field: "order", type: string },
    { title: "INTERNAL ID", field: "internalId", type: string },
    { title: "LABEL", field: "label", type: string },
    { title: "TEXT", field: "questionText", type: string },
    {
      title: "MANAGE",
      field: "manage",
      render: (rowData: any) => (
        <div>
          {rowData.isSurveySetup !== 1 && (
            <div className="actions-container">
              <p onClick={() => toggleOrderModal(rowData)}>Reorder</p>|
              <p
                className="actions-container-delete"
                onClick={() => toggleModal(rowData)}
              >
                Remove
              </p>
            </div>
          )}
        </div>
      ),
    },
  ];

  const initialFValues: IChangeQuestionOrder = {
    order: question.order,
  };

  const obj = {} as IChangeQuestionOrder;

  const validate = (fieldValues = values) => {
    if ("order" in fieldValues)
      obj.order = fieldValues.order ? "" : "Order number is required.";
    if (
      fieldValues.order >
      props.items.filter((x: any) => !x.isSurveySetup).length + 1
    )
      obj.order = fieldValues.order
        ? ""
        : "Order number exceeds the amount of questions in survey.";
    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange, resetForm } = useForm(
    initialFValues,
    true,
    validate,
  );

  const toggleModal = (rowData: any) => {
    setQuestion({
      id: rowData.id,
      order: rowData.order,
    });
    setModal(!modal);
  };

  const toggleOrderModal = (rowData: any) => {
    setQuestion({
      id: rowData.id,
      order: rowData.order,
    });
    values.order = rowData.order;
    setOrderModal(!modal);
  };

  const closeOrderModal = () => {
    setOrderModal(false);
  };

  const closeModal = () => {
    setModal(false);
  };

  const removeQuestion = (e: SyntheticEvent) => {
    props.removeQuestion(e, question.id);
    setModal(false);
  };

  const reorderQuestion = () => {
    const orderedQuestions = props.items.filter((x: any) => x.order !== 0);
    if (values.order < 1) {
      ErrorToast("Order number cannot be less than 1.");
    } else if (values.order > orderedQuestions.length) {
      ErrorToast("Order number cannot be greater than length of survey.");
    } else {
      props.reorderQuestion(values.order, question.id);
      resetForm();
      setOrderModal(false);
    }
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const handleKeyboardEvent = (e: any) => {
    if (e.code === "Enter") {
      reorderQuestion();
    }
  };

  return (
    <>
      <div>
        <UseTable
          columns={columns}
          data={props.items.filter((x: any) => x.order !== 0)}
        />
      </div>
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay" />
          <div className="modal-content">
            <h2>Remove Question</h2>
            <p>Are you sure you want to remove the question?</p>
            <br />
            <div className="form-buttons custom-floatRight">
              <Controls.Button
                className="button blue"
                onClick={(e) => removeQuestion(e)}
                text="Remove"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={closeModal}
              />
            </div>
          </div>
        </div>
      )}
      {orderModal && (
        <div className="modal">
          <div onClick={toggleOrderModal} className="overlay" />
          <div className="modal-content">
            <h2>Change Order</h2>
            <p>Please enter the new position for the question</p>
            <Controls.Input
              id="order"
              name="order"
              label="Position in survey?"
              type="number"
              value={values.order}
              onChange={handleInputChange}
              onKeyDown={handleKeyboardEvent}
              error={obj.order}
            />
            <br />
            <div className="form-buttons custom-floatRight">
              <Controls.Button
                className="button blue"
                onClick={() => reorderQuestion()}
                text="Reorder"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={closeOrderModal}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SurveyTemplateQuestionTable;
