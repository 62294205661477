import { useEffect, useState } from "react";
import { API_URLS } from "config/ApiUrls";
import API from "services/Api.Service";
import { ParticipantEnum } from "models/Enum/EnumTypes";
import { SmallSpinner } from "components/Spinner";
import { FaMinus, FaPlus } from "react-icons/fa";
import "./Style.css";

function ParticipationChartSelect({
  dataCycleId,
  compareDataCycleId,
  setSelectedQuestion,
  hideSegmentation,
}: any) {
  const [expanded, setExpanded] = useState(true);
  const [loading, setLoading] = useState(true);
  const [participantsResponseBox, setParticipantsResponseBox] = useState<any>(
    [],
  );

  useEffect(() => {
    setLoading(true);
    if (dataCycleId && compareDataCycleId) {
      API.get(API_URLS.GetSegmentationQuestionsComparison, {
        params: {
          dataCycleId,
          compareDataCycleId,
        },
      })
        .then((response: any) => {
          const data = response?.data?.data;
          setParticipantsResponseBox(data);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (dataCycleId) {
      API.get(API_URLS.GetSegmentationQuestions, {
        params: {
          dataCycleId,
        },
      })
        .then((response: any) => {
          const data = response?.data?.data;
          setParticipantsResponseBox(data);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [dataCycleId, compareDataCycleId]);

  const handleClick = (selectedObj: any, type: ParticipantEnum) => {
    setSelectedQuestion({
      questionId: selectedObj?.questionId,
      questionText: selectedObj?.chartTitle,
      responseChartType: type,
    });
  };

  return loading ? (
    <div className="chartSpinner">
      <SmallSpinner />
    </div>
  ) : (
    <div className="chartSelectBox">
      <div
        className={`chartSelectBoxBanner ${
          expanded ? "chartSelectBoxBannerBorder" : ""
        }`}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ display: "inline-block" }}>Who participated</p>
        </div>
        <div className="chartSelectBoxIcons">
          <div
            className="contentExpansion"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <div className={expanded ? "hiddenIcon" : "displayedIcon"}>
              <FaPlus />
            </div>
            <div className={expanded ? "displayedIcon" : "hiddenIcon"}>
              <FaMinus />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`chartSelectBoxContent ${
          expanded ? "visible" : "invisible"
        }`}
      >
        <p
          className="chartSelectBoxList"
          onClick={() => handleClick("", ParticipantEnum.BarChart)}
        >
          Response Rates
        </p>
        {!hideSegmentation &&
          participantsResponseBox.map((responseOption: any) => {
            return (
              <p
                className="chartSelectBoxList"
                onClick={() =>
                  handleClick(responseOption, ParticipantEnum.PieChart)
                }
              >
                {responseOption.chartTitle}
              </p>
            );
          })}
      </div>
    </div>
  );
}
export default ParticipationChartSelect;
