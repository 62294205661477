import { useEffect, useState } from "react";
import { IPieChart } from "models/Interfaces/ICharts";
import { colours } from "../Colours";
import "./Style.css";

function ComparePieChart({
  config,
  demographicBenchmarks,
  groupDemographicBenchmarks,
}: IPieChart) {
  const [firstConfig, setFirstConfig] = useState("");
  const [secondConfig, setSecondConfig] = useState("");
  const [benchmarkConfig, setBenchmarkConfig] = useState("");
  const [groupBenchmarkConfig, setGroupBenchmarkConfig] = useState("");

  useEffect(() => {
    if (config.length > 0) {
      config.map((item: any, index: any) => {
        if (item.codedResponses !== null) {
          const degrees = [];
          let counter = 0;

          for (let i = 0; i < item.codedResponses.length; i++) {
            let colourConfig = "";
            const slice =
              (item.codedResponses[i].participantsPercentage / 100) * 360 +
              counter;
            counter = slice;

            colourConfig = `${colours[i]} 0 ${slice}deg`;

            degrees.push(colourConfig);
          }

          const imgConfigString = `conic-gradient(${degrees.toString()})`;

          if (index === 0) {
            setFirstConfig(imgConfigString);
          } else {
            setSecondConfig(imgConfigString);
          }
          return true;
        }
        return null;
      });
    }
  }, [JSON.stringify(config)]);

  useEffect(() => {
    if (
      demographicBenchmarks.responses != null &&
      demographicBenchmarks.responses.length > 0
    ) {
      const degrees = [];
      let counter = 0;

      for (let i = 0; i < demographicBenchmarks.responses.length; i++) {
        let colourConfig = "";
        const slice =
          (demographicBenchmarks.responses[i].percentage / 100) * 360 + counter;
        counter = slice;

        colourConfig = `${colours[i]} 0 ${slice}deg`;

        degrees.push(colourConfig);
      }

      const imgConfigString = `conic-gradient(${degrees.toString()})`;
      setBenchmarkConfig(imgConfigString);
    }
  }, [JSON.stringify(demographicBenchmarks.responses)]);

  useEffect(() => {
    if (
      groupDemographicBenchmarks.responses != null &&
      groupDemographicBenchmarks.responses.length > 0
    ) {
      const degrees = [];
      let counter = 0;

      for (let i = 0; i < groupDemographicBenchmarks.responses.length; i++) {
        let colourConfig = "";
        const slice =
          (groupDemographicBenchmarks.responses[i].percentage / 100) * 360 +
          counter;
        counter = slice;

        colourConfig = `${colours[i]} 0 ${slice}deg`;

        degrees.push(colourConfig);
      }

      const imgConfigString = `conic-gradient(${degrees.toString()})`;
      setGroupBenchmarkConfig(imgConfigString);
    }
  }, [JSON.stringify(groupDemographicBenchmarks.responses)]);

  return (
    <div className="comparePieChartContainer">
      {config.length > 0 &&
        config.map((item: any, index: any) => {
          if (item.codedResponses !== null) {
            return (
              <div className={`${index === 1 ? "compareAndBenchmark" : ""}`}>
                <div className={`compareHeadingAndChart`}>
                  <h2 className="questionText">{item.dataCycleName}</h2>
                  <div
                    className="comparePieChart"
                    style={{
                      backgroundImage: `${
                        index === 0 ? firstConfig : secondConfig
                      }`,
                      opacity: `${index === 0 ? "1" : "0.6"}`,
                    }}
                  >
                    <span className="hover-key">
                      {config[index].codedResponses.map((x: any, y: any) => {
                        return (
                          <div className="segmentationKeyLine">
                            <div
                              className="segmentationKeyColour"
                              style={{ backgroundColor: colours[y] }}
                            />
                            <div className="segmentationKeyText">{`${x.keypadDesription}: ${x.participantsCount} (${x.participantsPercentage}%)`}</div>
                          </div>
                        );
                      })}
                    </span>
                  </div>
                </div>
                {index === 1 &&
                  demographicBenchmarks.responses != null &&
                  demographicBenchmarks.responses.length > 0 && (
                    <div className="compareHeadingAndChart">
                      <h2 className="questionText">Your average</h2>
                      <div
                        className="comparePieChart"
                        style={{
                          backgroundImage: benchmarkConfig,
                          opacity: `0.6`,
                        }}
                      >
                        <span className="hover-key">
                          {demographicBenchmarks.responses.map(
                            (x: any, y: any) => {
                              return (
                                <div className="segmentationKeyLine">
                                  <div
                                    className="segmentationKeyColour"
                                    style={{ backgroundColor: colours[y] }}
                                  />
                                  <div className="segmentationKeyText">{`${x.responseOption}: ${x.participantsCount} (${x.percentage}%)`}</div>
                                </div>
                              );
                            },
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                {index === 1 &&
                  groupDemographicBenchmarks.responses != null &&
                  groupDemographicBenchmarks.responses.length > 0 && (
                    <div className="compareHeadingAndChart">
                      <h2 className="questionText">Group average</h2>
                      <div
                        className="comparePieChart"
                        style={{
                          backgroundImage: groupBenchmarkConfig,
                          opacity: `0.6`,
                        }}
                      >
                        <span className="hover-key">
                          {groupDemographicBenchmarks.responses.map(
                            (x: any, y: any) => {
                              return (
                                <div className="segmentationKeyLine">
                                  <div
                                    className="segmentationKeyColour"
                                    style={{ backgroundColor: colours[y] }}
                                  />
                                  <div className="segmentationKeyText">{`${x.responseOption}: ${x.participantsCount} (${x.percentage}%)`}</div>
                                </div>
                              );
                            },
                          )}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            );
          }
          return (
            <div className="comparePieChartEmpty">
              <h2 className="questionText">{item.dataCycleName}</h2>
              <div className="comparePieChartEmptyText">
                <p>{item.noChartMessage}</p>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default ComparePieChart;
