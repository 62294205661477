import { useEffect, useState } from "react";
import Controls from "components/Controls";
import { IDownloadModal } from "models/Interfaces/IReports";
import { useLoading } from "providers/loading";
import { API_URLS } from "config/ApiUrls";
import API from "services/Api.Service";
import "./Style.css";

const MapDownloadModal = ({ setModal, items }: IDownloadModal) => {
  const { setAppLoading } = useLoading();
  const [options, setOptions] = useState<any>([]);
  const [consentGiven, setConsentGiven] = useState(false);

  useEffect(() => {
    const optionArray = items.map((site: any) => {
      return {
        checked: false,
        description: site.label,
        option: site.key,
      };
    });

    optionArray.unshift({
      checked: false,
      description: "All sites",
      option: "ALL",
    });

    setOptions(optionArray);
  }, [items]);

  const handleCheckBoxChange = (e: any, optionIndex: number) => {
    if (optionIndex === 0) {
      options.forEach((option: any) => (option.checked = !e.target.checked));
      setOptions([...options]);
    } else {
      options[optionIndex].checked = !e.target.checked;
      setOptions([...options]);
    }
  };

  const downloadData = () => {
    const IDs: string[] = [];

    options.forEach((option: any) => {
      if (option.option && option.checked && option.option !== "ALL") {
        IDs.push(option.option);
      }
    });

    if (IDs.length > 0) {
      setAppLoading(true);
      API.post(API_URLS.DownloadSiteSummaries, {
        SiteIds: IDs,
      })
        .then((res: any) => {
          const mineType = res.headers["content-type"];
          let name = res.headers["content-disposition"].replace(
            "attachment; filename=",
            "",
          );
          name = name.substring(1, name.indexOf(".csv"));
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          const blob = new Blob([res.data], { type: mineType });
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .finally(() => {
          setAppLoading(false);
          setModal();
        });
    }
  };

  return (
    <div className="modal">
      <div onClick={setModal} className="overlay" />
      <div className="modal-content">
        <h2>Download Workplace Summaries</h2>
        {!consentGiven ? (
          <div className="consent-checkbox">
            <Controls.CheckBox
              checked={consentGiven}
              label="Responses provided by survey participants should not be altered. By downloading this data you agree to using it ethically and in ways that contribute to improving the wellbeing of the participants. Please check the box to proceed."
              key="consent"
              value={consentGiven}
              onChange={() => setConsentGiven(!consentGiven)}
            />
          </div>
        ) : (
          <div className="callCycle-options">
            {options.length > 0 &&
              options.map((dataCycle: any, optionIndex: any) => {
                return (
                  <Controls.CheckBox
                    checked={dataCycle.checked}
                    label={dataCycle.description}
                    key={dataCycle.option}
                    value={dataCycle.checked}
                    onChange={(e: any) => handleCheckBoxChange(e, optionIndex)}
                  />
                );
              })}
          </div>
        )}
        <div className="form-buttons custom-floatRight">
          <Controls.Button
            className="button blue"
            onClick={downloadData}
            text="Download"
          />
          <Controls.Button
            text="Cancel"
            className="cancel-button"
            variant="outlined"
            onClick={setModal}
          />
        </div>
      </div>
    </div>
  );
};

export default MapDownloadModal;
