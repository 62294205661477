// Png Image
import WhiteLogo from "../assets/Images/WhiteLogo.png";
import PositiveMarkerPin from "../assets/Images/LocationPins/reports-positive-pin-48.png";
import NegativeMarkerPin from "../assets/Images/LocationPins/reports-negative-pin-48.png";
import NeutralMarkerPin from "../assets/Images/LocationPins/reports-amber-pin-48.png";
import NoResultsMarkerPin from "../assets/Images/LocationPins/reports-no-results-pin-48.png";
import NoCycleMarkerPin from "../assets/Images/LocationPins/reports-no-cycles-pin-48.png";

// Svg Image
import ProfileIcon from "../assets/Images/ProfileIcon.svg";
import ThickBanner from "../assets/Images/ThickBanner.svg";

export const IconEnum = {
  WhiteLogo,
  ProfileIcon,
  ThickBanner,
  PositiveMarkerPin,
  NegativeMarkerPin,
  NeutralMarkerPin,
  NoResultsMarkerPin,
  NoCycleMarkerPin,
};
