import { useNavigate } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import routePaths from "config/RoutePaths";
import Controls from "components/Controls";
import SurveyTemplateTable from "../SurveyTemplateTable";

function SurveyTemplates() {
  const navigate = useNavigate();

  return (
    <div className="container-view">
      <div className="headingDiv">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div className="page-heading-container">
              <Controls.BackButton />
              <h2 className="page-heading">Manage Survey Templates</h2>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="table-button-tray">
              <Controls.Button
                text="Add Survey Template"
                className="button maroon"
                onClick={() => navigate(routePaths.CREATETEMPLATE)}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <SurveyTemplateTable />
    </div>
  );
}

export default SurveyTemplates;
