import React from "react";

interface LoadingContextProps {
  appLoading: boolean;
  setAppLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface LoadingProviderProps {
  children: React.ReactNode;
}

const LoadingContext = React.createContext<LoadingContextProps>({
  appLoading: false,
  setAppLoading: () => {},
});

function LoadingProvider(props: LoadingProviderProps) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const value: any = React.useMemo(() => {
    return { appLoading: loading, setAppLoading: setLoading };
  }, [loading, setLoading]);

  return <LoadingContext.Provider value={value} {...props} />;
}

function useLoading() {
  const context = React.useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within LoadingProvider");
  }
  return context;
}

export { LoadingProvider, useLoading };
