import { useState, useEffect } from "react";
import { Player, BigPlayButton } from "video-react";
import { Grid } from "@material-ui/core";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import SearchBar from "material-ui-search-bar";
import { IResourceResponse } from "models/Interfaces/IResource";
import { API_URLS } from "config/ApiUrls";
import API from "services/Api.Service";
import { useLoading } from "providers/loading";
import "./Style.css";
import Controls from "components/Controls";

type ResourceResponse = IResourceResponse;
const ViewResources = () => {
  const { setAppLoading } = useLoading();
  const [pdfResources, setPDFResources] = useState<ResourceResponse[]>([]);
  const [videoResources, setVideoResources] = useState<ResourceResponse[]>([]);
  const [searchText, setSearchText] = useState<any>();

  useEffect(() => {
    setAppLoading(true);
    API.get(API_URLS.GetAllVideoResourcesList, {
      params: {
        SearchValue: searchText,
      },
    }).then((response: any) => {
      setVideoResources(response.data.data);
      API.get(API_URLS.GetAllPDFResourcesList, {
        params: {
          SearchValue: searchText,
        },
      }).then((res: any) => {
        setPDFResources(res.data.data);
        setAppLoading(false);
      });
    });
  }, [searchText]);

  return (
    <Grid container className="container-view">
      <Grid item xs={12} sm={1} md={1} />
      <Grid item xs={12} sm={10} md={10}>
        <Grid container>
          <Grid item xs={12} sm={8} md={8}>
            <div className="page-heading-container">
              <Controls.BackButton />
              <h2 className="page-heading">Help Resources</h2>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <SearchBar
              value={searchText}
              onChange={(e: any) => setSearchText(e)}
              onCancelSearch={() => setSearchText("")}
            />
          </Grid>
        </Grid>
        {(videoResources.filter(
          (x) => x.resourceGroupName === "Before The Rollout",
        ).length > 0 ||
          pdfResources.filter(
            (x) => x.resourceGroupName === "Before The Rollout",
          ).length > 0) && (
          <>
            <h1 className="resources-heading">Before the rollout</h1>
            <Grid container>
              {Object.values(pdfResources).map((value, index) => {
                if (value.resourceGroupName === "Before The Rollout") {
                  return (
                    <Grid item xs={12} sm={6} md={3} spacing={3}>
                      <div key={index}>
                        <Card className="custom-grid">
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              {value.resourceTitle}
                            </Typography>
                          </CardContent>
                          <CardActionArea className="custom-cardactionarea">
                            <a
                              href={value.resourceUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                className="custom-pdfimage"
                                src="https://andwiderstaging.blob.core.windows.net/pdf-resources/Pdf_Logo.png"
                                alt="PDF"
                              />
                            </a>
                          </CardActionArea>
                          <CardContent>
                            <div className="custom_resourcedescription">
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {value.resourceDescription}
                              </Typography>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    </Grid>
                  );
                }
                return null;
              })}
              {Object.values(videoResources).map((value, index) => {
                if (value.resourceGroupName === "Before The Rollout") {
                  return (
                    <Grid item xs={12} sm={6} md={3} spacing={3}>
                      <div key={index}>
                        <Card className="custom-card">
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              {value.resourceTitle}
                            </Typography>
                          </CardContent>
                          <CardActionArea className="custom-cardactionarea">
                            <Player
                              onPlay={() => {}}
                              onEnded={() => {}}
                              onLoadStart={() => {}}
                              onPause={() => {}}
                              videoId="id"
                              src={value.resourceUrl}
                              poster="https://andwiderstaging.blob.core.windows.net/videoresources/&Wider_grey_logo.jpg"
                            >
                              <BigPlayButton position="center" />
                            </Player>
                          </CardActionArea>
                          <CardContent>
                            <div className="custom_resourcedescription">
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {value.resourceDescription}
                              </Typography>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    </Grid>
                  );
                }
                return null;
              })}
            </Grid>
          </>
        )}
        {(videoResources.filter(
          (x) => x.resourceGroupName === "After The Rollout",
        ).length > 0 ||
          pdfResources.filter(
            (x) => x.resourceGroupName === "After The Rollout",
          ).length > 0) && (
          <>
            <h1 className="resources-heading">After the rollout</h1>
            <Grid container>
              {Object.values(pdfResources).map((value, index) => {
                if (value.resourceGroupName === "After The Rollout") {
                  return (
                    <Grid item xs={12} sm={6} md={3} spacing={3}>
                      <div key={index}>
                        <Card className="custom-grid">
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              {value.resourceTitle}
                            </Typography>
                          </CardContent>
                          <CardActionArea className="custom-cardactionarea">
                            <a
                              href={value.resourceUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                className="custom-pdfimage"
                                src="https://andwiderstaging.blob.core.windows.net/pdf-resources/Pdf_Logo.png"
                                alt="PDF"
                              />
                            </a>
                          </CardActionArea>
                          <CardContent>
                            <div className="custom_resourcedescription">
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {value.resourceDescription}
                              </Typography>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    </Grid>
                  );
                }
                return null;
              })}
              {Object.values(videoResources).map((value, index) => {
                if (value.resourceGroupName === "After The Rollout") {
                  return (
                    <Grid item xs={12} sm={6} md={3} spacing={3}>
                      <div key={index}>
                        <Card className="custom-card">
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              {value.resourceTitle}
                            </Typography>
                          </CardContent>
                          <CardActionArea className="custom-cardactionarea">
                            <Player
                              onPlay={() => {}}
                              onEnded={() => {}}
                              onLoadStart={() => {}}
                              onPause={() => {}}
                              videoId="id"
                              src={value.resourceUrl}
                              poster="https://andwiderstaging.blob.core.windows.net/videoresources/&Wider_grey_logo.jpg"
                            >
                              <BigPlayButton position="center" />
                            </Player>
                          </CardActionArea>
                          <CardContent>
                            <div className="custom_resourcedescription">
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {value.resourceDescription}
                              </Typography>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    </Grid>
                  );
                }
                return null;
              })}
            </Grid>
          </>
        )}
        {(videoResources.filter(
          (x) => x.resourceGroupName === "For The Workers",
        ).length > 0 ||
          pdfResources.filter((x) => x.resourceGroupName === "For The Workers")
            .length > 0) && (
          <>
            <h1 className="resources-heading">For the workers</h1>
            <Grid container>
              {Object.values(pdfResources).map((value, index) => {
                if (value.resourceGroupName === "For The Workers") {
                  return (
                    <Grid item xs={12} sm={6} md={3} spacing={3}>
                      <div key={index}>
                        <Card className="custom-grid">
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              {value.resourceTitle}
                            </Typography>
                          </CardContent>
                          <CardActionArea className="custom-cardactionarea">
                            <a
                              href={value.resourceUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                className="custom-pdfimage"
                                src="https://andwiderstaging.blob.core.windows.net/pdf-resources/Pdf_Logo.png"
                                alt="PDF"
                              />
                            </a>
                          </CardActionArea>
                          <CardContent>
                            <div className="custom_resourcedescription">
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {value.resourceDescription}
                              </Typography>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    </Grid>
                  );
                }
                return null;
              })}
              {Object.values(videoResources).map((value, index) => {
                if (value.resourceGroupName === "For The Workers") {
                  return (
                    <Grid item xs={12} sm={6} md={3} spacing={3}>
                      <div key={index}>
                        <Card className="custom-card">
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              {value.resourceTitle}
                            </Typography>
                          </CardContent>
                          <CardActionArea className="custom-cardactionarea">
                            <Player
                              onPlay={() => {}}
                              onEnded={() => {}}
                              onLoadStart={() => {}}
                              onPause={() => {}}
                              videoId="id"
                              src={value.resourceUrl}
                              poster="https://andwiderstaging.blob.core.windows.net/videoresources/&Wider_grey_logo.jpg"
                            >
                              <BigPlayButton position="center" />
                            </Player>
                          </CardActionArea>
                          <CardContent>
                            <div className="custom_resourcedescription">
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {value.resourceDescription}
                              </Typography>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    </Grid>
                  );
                }
                return null;
              })}
            </Grid>
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={1} md={1} />
    </Grid>
  );
};

export default ViewResources;
