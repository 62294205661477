import Grid from "@material-ui/core/Grid";
import { useNavigate } from "react-router-dom";
import Controls from "components/Controls";
import routePaths from "config/RoutePaths";
import SubSectorsTable from "../SubSectorsTable";

const SubSectors = () => {
  const navigate = useNavigate();

  const createSubSector = () => {
    navigate(`${routePaths.CREATESUBSECTOR}`);
  };

  return (
    <div className="container-view">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <div className="page-heading-container">
            <Controls.BackButton />
            <h2 className="page-heading">Manage SubSectors</h2>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="table-button-tray">
            <Controls.Button
              text="Add Sub Sector"
              className="button maroon"
              onClick={() => createSubSector()}
            />
          </div>
        </Grid>
      </Grid>
      <SubSectorsTable />
    </div>
  );
};

export default SubSectors;
