import { Grid } from "@material-ui/core";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth as useNewAuth } from "lib/auth";
import Controls from "components/Controls";
import { Form, useForm } from "hooks/useForm";
import { IVerificationCode } from "models/Interfaces/IAuth";
import API from "services/Api.Service";
import { API_URLS } from "config/ApiUrls";
import routePaths from "config/RoutePaths";
import { ErrorToast, SuccessToast } from "components/Toaster";
import "./Style.css";
import { UserResponse } from "lib/auth/helpers";
import { useLoading } from "providers/loading";

export default function TwoFactorCode() {
  const { setAppLoading } = useLoading();
  const { login }: any = useNewAuth();
  const { state }: any = useLocation();
  const navigate: any = useNavigate();
  const Email: string = state.email;
  const Password: any = state.password;

  const initialFValues: IVerificationCode = {
    verificationCode: "",
  };

  const obj = {} as IVerificationCode;

  const validate = (fieldValues = values) => {
    if ("verificationCode" in fieldValues)
      obj.verificationCode = fieldValues.verificationCode
        ? ""
        : "Security code is required.";
    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate,
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validate()) {
      API.post(API_URLS.VerifyTwoFactorCode, null, {
        params: {
          email: Email,
          VerificationCode: values.verificationCode,
        },
      })
        .then(async (response: any) => {
          if (response.data) {
            setAppLoading(true);
            try {
              if (validate()) {
                await login({ Email, Password }).then(
                  (loginResponse: UserResponse) => {
                    SuccessToast(loginResponse.message);
                    navigate(routePaths.DASHBOARD, { replace: true });
                    setAppLoading(false);
                  },
                );
              }
            } catch (error: any) {
              ErrorToast(error.response.data.message || error.response.data);
              setAppLoading(false);
            }
          }
        })
        .catch((error: any) => {
          ErrorToast(error.response.data.message || error.response.data);
          setAppLoading(false);
        })
        .finally(() => {
          setAppLoading(false);
        });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view">
        <div className="center">
          <Grid container>
            <Grid item xs={12} sm={3} md={4} />
            <Grid item xs={12} sm={6} md={4}>
              <Controls.Input
                id="verificationCode"
                name="verificationCode"
                label="Enter Security Code"
                value={values.verificationCode}
                onChange={handleInputChange}
                error={obj.verificationCode}
                cName="text-input"
                variant="outlined"
              />
              <br />
              <div className="form-buttons custom-floatRight">
                <br />
                <Controls.Button
                  className="button blue"
                  type="submit"
                  text="Verify"
                />
                <Controls.Button
                  text="Cancel"
                  className="cancel-button"
                  variant="outlined"
                  onClick={() => navigate(routePaths.SIGNIN, { replace: true })}
                />
              </div>
              <br />
            </Grid>
            <Grid item xs={12} sm={3} md={4} />
          </Grid>
        </div>
      </div>
    </Form>
  );
}
