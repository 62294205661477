import { API_URLS } from "config/ApiUrls";
import { axios } from "lib/axios";

export type GetUserDetailsDTO = {
  userId: string;
};

export const getUser = (data: GetUserDetailsDTO): Promise<any> => {
  return axios.post(API_URLS.GetUsersDetailsById, data);
};
