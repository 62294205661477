import { useEffect, useState } from "react";
import { colours } from "components/Charts/Colours";
import { SmallSpinner } from "components/Spinner";
import { API_URLS } from "config/ApiUrls";
import API from "services/Api.Service";
import "./Style.css";

function SegmentationKey({
  dataCycleId,
  selectedQuestion,
  dataCycleIds,
  showBenchmarks,
  groupDataCycleIds,
  showGroupBenchmarks,
}: any) {
  const [demographics, setDemographics] = useState<any>({});
  const [demographicsLoaded, setDemographicsLoaded] = useState(false);
  const [demographicBenchmarks, setDemographicBenchmarks] = useState<any>({});
  const [demographicBenchmarksLoaded, setDemographicBenchmarksLoaded] =
    useState(false);
  const [groupBenchmarks, setGroupBenchmarks] = useState<any>({});
  const [groupBenchmarksLoaded, setGroupBenchmarksLoaded] = useState(true);

  useEffect(() => {
    setDemographicsLoaded(false);
    if (dataCycleId && selectedQuestion) {
      const url = API_URLS.GetSegmentationResults;

      API.post(url, {
        dataCycleId,
        questionId: selectedQuestion.questionId,
      })
        .then((response: any) => {
          demographics.segmentationQuestion = response?.data?.chartTitle;
          demographics.responses = response?.data?.codedResponses?.map(
            (x: any) => {
              return {
                responseOption: x.keypadDesription,
                percentage: Math.round(x.participantsPercentage),
                participantsCount: x.participantsCount,
              };
            },
          );
          setDemographics(demographics);
          setDemographicsLoaded(true);
        })
        .finally(() => {});
    }
  }, [dataCycleId, selectedQuestion]);

  useEffect(() => {
    if (showGroupBenchmarks) {
      setGroupBenchmarksLoaded(false);
      API.post(API_URLS.GetSegmentationChartBenchmarks, {
        dataCycleId,
        questionId: selectedQuestion.questionId,
        dataCycleIds: groupDataCycleIds,
      })
        .then((response: any) => {
          groupBenchmarks.segmentationQuestion = response?.data?.chartTitle;
          groupBenchmarks.responses = response?.data?.codedResponses?.map(
            (x: any) => {
              return {
                responseOption: x.keypadDesription,
                percentage: Math.round(x.participantsPercentage),
                participantsCount: x.participantsCount,
              };
            },
          );
          setGroupBenchmarks(groupBenchmarks);
          setGroupBenchmarksLoaded(true);
        })
        .finally(() => {});
    }
    if (!showGroupBenchmarks) {
      setGroupBenchmarks({});
    }
  }, [showGroupBenchmarks, selectedQuestion]);

  useEffect(() => {
    if (showBenchmarks) {
      setDemographicBenchmarksLoaded(false);
      API.post(API_URLS.GetSegmentationChartBenchmarks, {
        dataCycleId,
        questionId: selectedQuestion.questionId,
        dataCycleIds,
      })
        .then((response: any) => {
          demographicBenchmarks.segmentationQuestion =
            response?.data?.chartTitle;
          demographicBenchmarks.responses = response?.data?.codedResponses?.map(
            (x: any) => {
              return {
                responseOption: x.keypadDesription,
                percentage: Math.round(x.participantsPercentage),
                participantsCount: x.participantsCount,
              };
            },
          );
          setDemographicBenchmarks(demographicBenchmarks);
          setDemographicBenchmarksLoaded(true);
        })
        .finally(() => {});
    }
    if (!showBenchmarks) {
      setDemographicBenchmarks({});
    }
  }, [showBenchmarks, selectedQuestion]);

  return (
    <>
      <div>
        {demographicsLoaded ? (
          <div className="segmentationKeyContainer">
            {demographics.responses.map((item: any, index: any) => {
              return (
                <div className="segmentationKeyLine">
                  <div
                    className="segmentationKeyColour"
                    style={{ backgroundColor: colours[index] }}
                  />
                  <div className="segmentationKeyText">{`${item.responseOption}: ${item.participantsCount} (${item.percentage}%)`}</div>
                </div>
              );
            })}
          </div>
        ) : (
          <div style={{ width: "150px", height: "150px" }}>
            <SmallSpinner />
          </div>
        )}
      </div>
      {showBenchmarks && demographicBenchmarksLoaded && (
        <div className="benchmarkKey">
          <h3>Your average</h3>
          {demographicBenchmarks.responses != null &&
          demographicBenchmarks.responses.length > 0 ? (
            <div className="segmentationKeyContainer">
              {demographicBenchmarks.responses.map((item: any, index: any) => {
                return (
                  <div className="segmentationKeyLine">
                    <div
                      className="segmentationKeyColour"
                      style={{ backgroundColor: colours[index] }}
                    />
                    <div className="segmentationKeyText">{`${item.responseOption}: ${item.participantsCount} (${item.percentage}%)`}</div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div style={{ width: "150px", height: "150px" }}>
              <SmallSpinner />
            </div>
          )}
        </div>
      )}
      {showGroupBenchmarks && groupBenchmarksLoaded && (
        <div className="benchmarkKey">
          <h3>Benchmark</h3>
          {groupBenchmarks.responses != null &&
          groupBenchmarks.responses.length > 0 ? (
            <div className="segmentationKeyContainer">
              {groupBenchmarks.responses.map((item: any, index: any) => {
                return (
                  <div className="segmentationKeyLine">
                    <div
                      className="segmentationKeyColour"
                      style={{ backgroundColor: colours[index] }}
                    />
                    <div className="segmentationKeyText">{`${item.responseOption}: ${item.participantsCount} (${item.percentage}%)`}</div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div style={{ width: "150px", height: "150px" }}>
              <SmallSpinner />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default SegmentationKey;
