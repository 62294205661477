import { Routes, Route, Navigate } from "react-router-dom";
import routePaths from "config/RoutePaths";
import UpdateResource from "./UpdateResource";
import CreateResource from "./CreateResource";
import Resources from ".";
import ViewResources from "./Resources";

export const ResourcesRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Resources />} />
      <Route path="/create" element={<CreateResource />} />
      <Route path={routePaths.EDITRESOURCE} element={<UpdateResource />} />
      <Route path="/view" element={<ViewResources />} />
      <Route path="*" element={<Navigate to={routePaths.RESOURCES} />} />
    </Routes>
  );
};
