/* eslint-disable jsx-a11y/media-has-caption */
import { Grid } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import ReactAudioPlayer from "react-audio-player";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import Controls from "components/Controls";
import { SuccessToast } from "components/Toaster";
import { API_URLS } from "config/ApiUrls";
import routePaths from "config/RoutePaths";
import { Form, useForm } from "hooks/useForm";
import API from "services/Api.Service";
import AlertModal from "util/AlertModal";
import { getDropDownOptions } from "util/CommonFunctions";
import { IQuestionLanguage } from "models/Interfaces/ILanguage";
import { useLoading } from "providers/loading";
import "./Style.css";

const initialFValues: IQuestionLanguage = {
  languageId: "",
  translation: "",
  audioFile: null,
  audioUrl: "",
  isAudio: false,
};

const obj = {} as IQuestionLanguage;
export default function AddQuestionLanguage() {
  const navigate = useNavigate();
  const { setAppLoading } = useLoading();
  const [open, setModelOpen] = useState(false);
  const [globalLanguage, setglobalLanguage] = useState<any>([]);
  const [dialectLanguage, setDialectLanguage] = useState<any>([]);
  const [question, setQuestion] = useState<any>([]);
  const [duration, setDuration] = useState("");

  const params = useParams();
  // console.log("params inadd langau", params);
  const { questionId, languageId } = params;

  // const [searchParams] = useSearchParams();
  // const questionId: string = searchParams.get("questionId") ?? "";
  // const languageId: any = searchParams.get("languageId");

  const validate = (fieldValues = values) => {
    if ("languageId" in fieldValues)
      obj.languageId =
        fieldValues.languageId.length !== 0 ? "" : "Language is required.";
    if ("audioFile" in fieldValues)
      obj.audioFile = fieldValues.audioFile ? "" : "Audio file is required.";
    if ("translation" in fieldValues)
      obj.translation = fieldValues.translation
        ? ""
        : "Translation is required.";

    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange, setDataValues } = useForm(
    initialFValues,
    true,
    validate,
  );

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (validate()) {
      setModelOpen(true);
    }
  };

  const handleClose = (isConfirmed: boolean) => {
    if (isConfirmed) {
      const bodyFormData = new FormData();
      bodyFormData.append("QuestionId", questionId as string);
      bodyFormData.append("LanguageId", values.languageId);
      bodyFormData.append("Dialect", values.Dialect ?? "");
      bodyFormData.append("Duration", duration);
      bodyFormData.append("Translation", values.translation);
      bodyFormData.append("AudioFile", values.audioFile ?? null);
      bodyFormData.append("IsAudio", values.isAudio);
      setModelOpen(false);
      setAppLoading(true);
      API.post(API_URLS.AddQuestionAudiosAndTranslations, bodyFormData)
        .then((response: any) => {
          navigate(`${routePaths.MANAGELANGUAGE}/${questionId}`);
          SuccessToast(response?.data?.message);
        })
        .finally(() => {
          setAppLoading(false);
        });
    } else {
      setModelOpen(false);
    }
  };

  const onDrageChange = async (file: any) => {
    const myVideos = [] as any;
    const files = file;
    if (files.length > 0) {
      myVideos.push(files[0]);
      const video = document.createElement("video");
      video.preload = "metadata";
      // eslint-disable-next-line func-names
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        const myNumber = video.duration;
        const minutes = Math.floor(myNumber / 60);
        const seconds = Math.floor(myNumber % 60);
        const secondsWithLeadingZero =
          seconds < 1 ? `0${seconds.toString}` : seconds;
        setDuration(
          `${minutes.toString()}:${secondsWithLeadingZero.toString()}`,
        );
        setDataValues({ ...values, audioFile: file[0], isAudio: true });
      };
      video.src = URL.createObjectURL(files[0]);
    }
  };

  useEffect(() => {
    if (questionId) {
      API.get(API_URLS.GetQuestionByQuestionId, {
        params: {
          QuestionId: questionId,
        },
      }).then((response: any) => {
        setQuestion(response?.data?.data);
      });
    }
  }, [questionId]);

  useEffect(() => {
    API.get(API_URLS.GetLanguages).then((response: any) => {
      setglobalLanguage(
        getDropDownOptions(response?.data?.data, "languageId", "languageLabel"),
      );
    });
  }, []);
  useEffect(() => {
    if (values.languageId) {
      API.get(API_URLS.DialectsLanguages, {
        params: {
          LanguageId: values.languageId,
        },
      }).then((response: any) => {
        setDialectLanguage(
          getDropDownOptions(
            response?.data?.data,
            "languageDialectId",
            "dialectName",
          ),
        );
      });
    }
  }, [values.languageId]);
  /// for edit language id
  useEffect(() => {
    if (languageId) {
      setAppLoading(true);
      API.get(API_URLS.GetAudiosAndTranslations, {
        params: {
          QuestionId: questionId,
          LanguageId: languageId,
        },
      })
        .then((response: any) => {
          const result = response?.data?.data;
          result[0].isAudio = false;
          setDataValues(result[0]);
        })
        .finally(() => {
          setAppLoading(false);
        });
    }
  }, [languageId]);

  const removeAudioUrl = () => {
    setDataValues({ ...values, audioUrl: "" });
  };

  const isEdit: boolean = languageId !== undefined;

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view">
        <Grid container>
          <Grid item xs={12} sm={12}>
            <div className="page-heading-container">
              <Controls.BackButton />
              <h2 className="page-heading">{`${
                languageId ? "Update " : "Add a"
              } Language`}</h2>
            </div>
            <div className="language-container">
              <p>
                Questions:<h4> {`${question.questionText}`}</h4>
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controls.Select
              id="languageId"
              name="languageId"
              label="please select language"
              options={globalLanguage}
              value={values.languageId}
              onChange={handleInputChange}
              error={obj.languageId}
              disabled={languageId ? isEdit : false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controls.Select
              id="languageDialectId"
              name="dialectName"
              label="Dialect"
              value={values.dialectName}
              onChange={handleInputChange}
              options={dialectLanguage}
              disabled={languageId ? isEdit : false}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Controls.Input
              id="translation"
              name="translation"
              label=""
              value={values.translation}
              onChange={handleInputChange}
              error={obj.translation}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div
              className="dropzone-area"
              style={{ width: "500px", margin: "45px auto" }}
            >
              {languageId && values.audioUrl ? (
                <div className="audio-file">
                  <ReactAudioPlayer src={values.audioUrl} controls />
                  <Tooltip title={<p>Remove Audio</p>} arrow>
                    <CloseIcon onClick={removeAudioUrl} />
                  </Tooltip>
                </div>
              ) : (
                <>
                  <DropzoneArea
                    acceptedFiles={["audio/*"]}
                    useChipsForPreview
                    previewGridProps={{
                      container: { spacing: 1, direction: "row" },
                    }}
                    filesLimit={1}
                    onChange={(file) => onDrageChange(file)}
                  />
                  {obj.audioFile && (
                    <span className="error-message">{obj.audioFile}</span>
                  )}
                </>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <AlertModal open={open} handleClose={handleClose} />
            <div className="form-buttons custom-floatRight">
              <Controls.Button
                className="button blue"
                type="submit"
                text={`${languageId ? "Update" : "Save"} Language`}
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={() =>
                  // navigate(
                  //   `${routePaths.MANAGELANGUAGE}?questionId=${questionId}`,
                  // )
                  navigate(-1)
                }
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Form>
  );
}
