import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import WarningIcon from "@material-ui/icons/Warning";
import Tooltip from "@material-ui/core/Tooltip";
import { useNavigate, useParams } from "react-router-dom";
import { API_URLS } from "config/ApiUrls";
import API from "services/Api.Service";
import "./Style.css";
import routePaths from "config/RoutePaths";
import { SuccessToast } from "components/Toaster";
import Controls from "components/Controls";
import { useLoading } from "providers/loading";
import { UseTable } from "hooks/useTable";

export default function QuestionLanguages() {
  const [languageTable, setlanguageTable] = useState<any>([]);
  const { setAppLoading } = useLoading();
  const params = useParams();
  const { questionId } = params;
  const [question, setQuestion] = useState<any>([]);

  const deleteLanguage = (languageId: string) => {
    setAppLoading(true);
    API.post(API_URLS.DeleteAudiosAndTranslations, {
      QuestionId: questionId,
      LanguageId: languageId,
    })
      .then((response: any) => {
        SuccessToast(response?.data?.message);

        getAudiosAndTranslations();
      })
      .finally(() => {
        setAppLoading(false);
      });
  };

  const navigateToEditLanguage = (languageId: string, rowData: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    rowData.languageLabel !== ""
      ? navigate(
          `/questions/${questionId}/${languageId}/edit-question-language`,
        )
      : null;
  };

  const columns = [
    {
      title: " LANGUAGE",
      field: "languageLabel",
      sorting: true,
      render: (rowData: any) => (
        <div>
          {rowData?.languageLabel}{" "}
          {!rowData.hasAudio && (
            <Tooltip
              title={
                <>
                  <p style={{ color: "white" }}>SETUP INCOMPLETE</p>
                  <p>This audio is missing</p>
                </>
              }
              arrow
            >
              <Button className="warning-icon">
                <WarningIcon />
              </Button>
            </Tooltip>
          )}
        </div>
      ),
    },
    { title: "TRANSLATION", field: "translation", sorting: true },
    {
      title: "PLAY AUDIO",
      field: "audioUrl",
      sorting: true,
      render: (rowData: any) => {
        return (
          <div className="actions-container">
            {rowData.audioUrl && (
              <p
                onClick={() => {
                  window.open(rowData.audioUrl, "_blank");
                }}
              >
                <PlayArrowIcon />
              </p>
            )}
          </div>
        );
      },
    },
    { title: "DURATION (sec)", field: "duration", sorting: true },
    {
      title: "MANAGE",
      field: "",
      sorting: false,
      render: (rowData: any) => {
        return (
          <div className="actions-container">
            <p
              onClick={() =>
                navigateToEditLanguage(rowData?.languageId, rowData)
              }
            >
              Edit
            </p>
            {rowData.languageLabel !== "English" && (
              <>
                |
                <p
                  className="actions-container-delete"
                  onClick={() => deleteLanguage(rowData?.languageId)}
                >
                  Delete
                </p>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const getAudiosAndTranslations = () => {
    API.get(API_URLS.GetAudiosAndTranslations, {
      params: {
        QuestionId: questionId,
      },
    }).then((response: any) => {
      setlanguageTable(response?.data?.data);
    });
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    getAudiosAndTranslations();
    API.get(API_URLS.GetQuestionByQuestionId, {
      params: {
        QuestionId: questionId,
      },
    }).then((response: any) => {
      setQuestion(response?.data?.data);
    });
    // eslint-disable-next-line
  }, []);

  const navigate = useNavigate();

  const navigateToAddLanguage = () => {
    navigate(`/questions/${questionId}/add-question-language`);
  };

  return (
    <div className="container-view">
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div className="page-heading-container">
              <Controls.BackButton />
              <h2 className="page-heading">Manage Language</h2>
            </div>
            <h4>Questions: {`${question?.questionText}`}</h4>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="table-button-tray">
              <Controls.Button
                text="Add Languages"
                className="button maroon"
                onClick={navigateToAddLanguage}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="table">
        <UseTable
          columns={columns}
          data={languageTable}
          title=""
          options={{
            paging: false,
            search: true,
          }}
        />
      </div>
      <Controls.Button
        text="Back"
        className="cancel-button"
        variant="outlined"
        onClick={() => navigate(routePaths.QUESTIONS)}
      />
    </div>
  );
}
