import "./Style.css";

export const Spinner = () => {
  return (
    <div className="spinner-overlay">
      <div className="cv-spinner">
        <div className="spinner" />
      </div>
    </div>
  );
};

export const SmallSpinner = () => {
  return (
    <div className="small-spinner-overlay">
      <div className="small-cv-spinner">
        <div className="small-spinner" />
      </div>
    </div>
  );
};

export const FullPageSpinner = () => {
  return <Spinner />;
};
