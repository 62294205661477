import { Routes, Route, Navigate } from "react-router-dom";
import routePaths from "config/RoutePaths";
import AddLanguage from "./AddLanguage";
import Languages from ".";

export const LanguageRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Languages />} />
      <Route path="/add-language" element={<AddLanguage />} />
      <Route path={routePaths.EDITLANGUAGE} element={<AddLanguage />} />
      <Route
        path="*"
        element={<Navigate to={routePaths.LANGUAGES} replace={true} />}
      />
    </Routes>
  );
};
