import { SyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useLoading } from "providers/loading";
import { useAuth } from "lib/auth";
import "./Style.css";
import { UserResponse } from "lib/auth/helpers";
import { Form, useForm } from "hooks/useForm";
import Controls from "components/Controls";
import { ISignUp } from "models/Interfaces/IAuth";
// Importing validation functions from util folder
import {
  phoneNumber,
  validateEmail,
  validatePassword,
  validatePasswords,
} from "util/validations";
import { ErrorToast, SuccessToast } from "components/Toaster";
import API from "services/Api.Service";
import { API_URLS } from "config/ApiUrls";
import routePaths from "config/RoutePaths";
import PasswordEye from "components/Controls/PaaswordEye";

export default function SignUp() {
  const { login }: any = useAuth();
  const { setAppLoading } = useLoading();
  const navigate = useNavigate();
  const [obj] = useState<any>({} as ISignUp);

  const initialFValues: ISignUp = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    password: "",
    organisationName: "",
    passwordConfirm: "",
  };

  // Validating required user inputs
  const validate = (fieldValues = values) => {
    if ("firstName" in fieldValues)
      obj.firstName = fieldValues.firstName ? "" : "First name is required.";
    if ("lastName" in fieldValues)
      obj.lastName = fieldValues.lastName ? "" : "Last name is required.";
    // if ("phoneNumber" in fieldValues)
    //   obj.phoneNumber = fieldValues.phoneNumber
    //     ? ""
    //     : "Phone number is required.";
    if ("email" in fieldValues)
      obj.email = fieldValues.email ? "" : "Email address is required.";
    if ("password" in fieldValues)
      obj.password = fieldValues.password ? "" : "Password is required.";
    if ("passwordConfirm" in fieldValues)
      obj.passwordConfirm = fieldValues.passwordConfirm
        ? ""
        : "Password confirmation is required.";
    if ("organisationName" in fieldValues)
      obj.organisationName = fieldValues.organisationName
        ? ""
        : "Business name is required.";
    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange, resetForm } = useForm(
    initialFValues,
    true,
    validate,
  );

  // Calling API to create organisation
  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    // Validating that all required fields are filled in,
    // the email, phone number and password are in the correct format
    // and that the two password fields match
    if (
      validate() &&
      (values.phoneNumber === "" || phoneNumber(values, obj, setErrors)) &&
      validateEmail(values, obj, setErrors) &&
      validatePassword(values, obj, setErrors) &&
      validatePasswords(values, obj, setErrors)
    ) {
      setAppLoading(true);
      API.post(API_URLS.SelfRegisterOrganisation, {
        OrganisationName: values.organisationName,
        Email: values.email,
        FirstNames: values.firstName,
        LastName: values.lastName,
        PhoneNumber: values.phoneNumber,
        Password: values.password,
      })
        .then((res: any) => {
          SuccessToast(res?.data.message);
          setAppLoading(false);
          resetForm();

          const { email } = values;
          const { password } = values;

          try {
            login({ email, password }).then((loginResponse: UserResponse) => {
              setAppLoading(false);
              SuccessToast(loginResponse.message);
              navigate(routePaths.DASHBOARD, { replace: true });
            });
          } catch (error: any) {
            ErrorToast(error.response.data.message || error.response.data);
            setAppLoading(false);
            navigate(routePaths.SIGNIN, { replace: true });
          }
        })
        .catch(() => {
          setAppLoading(false);
        });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view">
        <Grid container>
          <Grid item xs={12} sm={2} md={2} />
          <Grid item xs={12} sm={8} md={8}>
            <Grid container>
              <Grid item xs={12}>
                <div>
                  <div className="page-heading-container">
                    <Controls.BackButton />
                    <h2 className="page-heading">Register</h2>
                  </div>
                  <h3>
                    Fill in the below form to register your organisation and
                    create your account.
                  </h3>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Controls.Input
                  id="organisationName"
                  label="Business name*"
                  name="organisationName"
                  value={values.organisationName}
                  onChange={handleInputChange}
                  error={obj.organisationName}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} />
              <div>
                <br />
                <h4>
                  Create your admin login details to access your &Wider
                  dashboard.
                </h4>
              </div>
              <Grid item xs={12}>
                <Controls.Input
                  id="email"
                  name="email"
                  label="Email address*"
                  value={values.email}
                  onChange={handleInputChange}
                  error={obj.email}
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.Input
                  id="firstName"
                  name="firstName"
                  label="First name*"
                  value={values.firstName}
                  onChange={handleInputChange}
                  error={obj.firstName}
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.Input
                  id="lastName"
                  name="lastName"
                  label="Last name*"
                  value={values.lastName}
                  onChange={handleInputChange}
                  error={obj.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.Input
                  id="phoneNumber"
                  label="Phone number"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  onChange={handleInputChange}
                  error={obj.phoneNumber}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <PasswordEye
                  id="password"
                  label="Password*"
                  name="password"
                  value={values.password}
                  onChange={handleInputChange}
                  error={obj.password}
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordEye
                  id="passwordConfirm"
                  label="Confirm password*"
                  name="passwordConfirm"
                  value={values.passwordConfirm}
                  onChange={handleInputChange}
                  error={obj.passwordConfirm}
                  cName="text-input"
                />
              </Grid>
            </Grid>
            <div className="form-buttons custom-floatRight">
              <br />
              <Controls.Button
                className="button blue"
                type="submit"
                text="Register"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={() => navigate(-1)}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={4} />
        </Grid>
      </div>
    </Form>
  );
}
