import { useState, createRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import API from "services/Api.Service";
import { API_URLS } from "config/ApiUrls";
import { SuccessToast } from "components/Toaster";
import { useLoading } from "providers/loading";
import { UseTable } from "hooks/useTable";
import Controls from "components/Controls";
import "./Style.css";
import { useAuth } from "lib/auth";
import { RolesEnum } from "models/Enum/Roles";

const SiteTable = () => {
  const { setAppLoading } = useLoading();
  const navigate = useNavigate();
  const [sites, setSites] = useState<any[]>([]);
  const [modal, setModal] = useState(false);
  const [siteToBeDeleted, setSiteToBeDeleted] = useState({
    organisationSiteId: "",
    organisationSiteName: "",
    organisationName: "",
  });
  const tableRef = createRef<any>();
  const auth = useAuth();

  const isAdmin = auth?.user?.data?.roles?.includes(RolesEnum.Admin);

  useEffect(() => {
    setAppLoading(true);
    const url = isAdmin ? API_URLS.GetSites : API_URLS.GetOrganisationSites;
    API.get(url, {
      params: {
        OrderBy: "OrganisationSiteId",
        SearchValue: null,
      },
    })
      .then((response: any) => {
        setSites(response.data.data);
      })
      .finally(() => setAppLoading(false));
  }, []);

  const userphonenumber = (organisationSiteId: string) => {
    navigate(`${organisationSiteId}/phonenumbers`);
  };

  const viewPhoneNumbers = (organisationSiteId: string) => {
    navigate(`${organisationSiteId}/viewphonenumbers`);
  };

  const editsite = (organisationSiteId: string) => {
    navigate(`${organisationSiteId}/edit`);
  };

  const toggleModal = (rowData: any) => {
    setSiteToBeDeleted({
      organisationSiteId: rowData.organisationSiteId,
      organisationSiteName: rowData.organisationSiteName,
      organisationName: rowData.organisationName,
    });
    setModal(!modal);
  };

  const closeModal = () => {
    setModal(false);
  };

  const deleteSite = () => {
    setAppLoading(true);
    API.delete(API_URLS.DeleteSite, {
      params: {
        organisationSiteId: siteToBeDeleted.organisationSiteId,
      },
    })
      .then((res: any) => {
        SuccessToast(res?.data?.message);
        setSites(
          sites.filter(
            (u) => u.organisationSiteId !== siteToBeDeleted.organisationSiteId,
          ),
        );
        setModal(false);
      })
      .finally(() => setAppLoading(false));
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const columns: any = [
    { title: "WORKPLACE NAME", field: "organisationSiteName" },
    { title: "BUSINESS NAME", field: "organisationName" },
    { title: "SECTOR", field: "sectorName" },
    {
      title: "ACTIVE",
      field: "isActive",
      sorting: false,
      render: () => (
        <div>
          <Controls.CheckBox
            id="active"
            label=""
            name="active"
            value={true}
            onChange={() => {
              Function.prototype();
            }}
            checked={true}
          />
        </div>
      ),
    },
    {
      title: "PHONE NUMBERS",
      field: "uploadMedia",
      render: (rowData: any) => (
        <div className="actions-container">
          <p onClick={() => userphonenumber(rowData.organisationSiteId)}>
            Upload
          </p>
          |
          <p onClick={() => viewPhoneNumbers(rowData.organisationSiteId)}>
            View
          </p>
        </div>
      ),
    },
    {
      title: "MANAGE",
      field: "edit",
      render: (rowData: any) => (
        <div className="actions-container">
          <p onClick={() => editsite(rowData.organisationSiteId)}>Edit</p>|
          <p
            className="actions-container-delete"
            onClick={() => toggleModal(rowData)}
          >
            Delete
          </p>
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <UseTable
          refTable={tableRef}
          columns={columns}
          title="Sites"
          data={sites}
        />
      </div>
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay" />
          <div className="modal-content">
            <h2>Delete Site</h2>
            <p>
              You are about to delete the following site. Are you sure you want
              to proceed?
            </p>
            <p>Workplace name: {siteToBeDeleted.organisationSiteName}</p>
            <p>Organisation: {siteToBeDeleted.organisationName}</p>
            <br />
            <div className="form-buttons custom-floatRight">
              <Controls.Button
                className="button blue"
                onClick={deleteSite}
                text="Delete"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={closeModal}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SiteTable;
