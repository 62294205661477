// Status Badge Images
import Improving from "../assets/Images/improving.png";
import Responding from "../assets/Images/responding.png";
import Listening from "../assets/Images/listening.png";

export const StatusBadgeImagesEnum = {
  Improving,
  Responding,
  Listening,
};
