import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Controls from "components/Controls";
import { IOrganisationUser } from "models/Interfaces/IOrganisationUser";
import { Form, useForm } from "hooks/useForm";
import { API_URLS } from "config/ApiUrls";
import API from "services/Api.Service";
import { ErrorToast, SuccessToast } from "components/Toaster";
import "./Style.css";
import { UseTable } from "hooks/useTable";
import { useLoading } from "providers/loading";
import { useAuth } from "lib/auth";
import { RolesEnum } from "models/Enum/Roles";
import { phoneNumber, validateEmail } from "../../../util/validations";

const InviteOrgnisationUser = () => {
  const { setAppLoading } = useLoading();
  const navigate = useNavigate();
  const [organisationOptions, setOrganisationOptions] = useState<any>([]);
  const [regularUsers, setRegularUsers] = useState<any>([]);
  const [userOptions, setUserOptions] = useState<any>([]);
  const [allOrganisationSites, setAllOrganisationSites] = useState<any>([]);
  const [organisationSites, setOrganisationSites] = useState<any>([]);
  const [roles, setRoles] = useState<any>([]);
  const [obj, setObj] = useState<any>({} as IOrganisationUser);
  const [isOrgSelected, setIsOrgSelected] = useState(false);
  const { usersId, organisationUserId } = useParams();
  const [isAllWorkplaceVisibilityChecked, setAllWorkplaceVisibilityChecked] =
    useState(false);
  const [inviteUsers, setInviteUsers] = useState<any[]>([]);

  let initialFValues: IOrganisationUser = {
    organisationId: "",
    organisationIdTextSearch: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    physicalAddress: "",
    roleId: "",
    roleIdTextSearch: "",
    userId: "",
    userIdTextSearch: "",
    organisations: [],
    organisationOptions: [],
    organisationSites: [],
    organisationSiteIds: [],
    isUserExist: false,
    inviteUserDetails: [],
  };

  // Using Auth to determine the user role
  // and what should be rendered on the page
  const auth = useAuth();
  const isAdmin = auth?.user?.data?.roles?.includes(RolesEnum.Admin);

  const pageHeading = usersId ? "Edit Business User" : "Invite Business User";
  const columns = [
    { title: "WORKPLACE NAME", field: "organisationSiteName" },
    { title: "BUSINESS", field: "organisationName" },
    {
      title: (
        <div>
          <Controls.CheckBox
            id="workplaceVisibilityAll"
            label="WORKPLACE VISIBILITY"
            name="workplaceVisibilityAll"
            onChange={(e) => selectAllWorkplaceVisibility(e.target.value)}
            checked={isAllWorkplaceVisibilityChecked}
          />
        </div>
      ),
      field: "isOrganisationSiteIdChecked",
      sorting: false,
      render: (rowData: any) => (
        <div>
          <Controls.CheckBox
            id={rowData.organisationSiteId}
            label=""
            name="organisationSite"
            onChange={(e) => selectOrganisationSiteId(e, rowData)}
            checked={rowData.isOrganisationSiteIdChecked}
            value={rowData.isOrganisationSiteIdChecked}
          />
        </div>
      ),
    },
  ];

  const inviteUserColumns = [
    { title: "USER NAME", field: "email" },
    { title: "FIRST NAME", field: "firstName" },
    { title: "LAST NAME", field: "lastName" },
    { title: "PHONE NUMBER", field: "phoneNumber" },
    {
      title: "MANAGE",
      field: "manage",
      render: (rowData: any) => {
        return (
          <div className="actions-container">
            <p
              className="actions-container-delete"
              onClick={() => RemoveUserDetail(rowData)}
            >
              Remove
            </p>
          </div>
        );
      },
    },
  ];

  const validateUserDetails = (fieldValues = values) => {
    if ("phoneNumber" in fieldValues) obj.phoneNumber = "";
    if ("userIdTextSearch" in fieldValues) {
      obj.userIdTextSearch = fieldValues.userIdTextSearch
        ? ""
        : "Email address is required.";
    }
    if ("firstName" in fieldValues)
      obj.firstName = fieldValues.firstName ? "" : "First name is required.";
    if ("lastName" in fieldValues)
      obj.lastName = fieldValues.lastName ? "" : "Last name is required.";

    if (values.phoneNumber !== "") {
      phoneNumber(values, obj, setErrors);
    }
    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const validate = (fieldValues = values) => {
    if (pageHeading !== "Invite Business User") {
      if ("phoneNumber" in fieldValues) obj.phoneNumber = "";
      if ("organisationId" in fieldValues) {
        obj.organisationId = fieldValues.organisationId
          ? ""
          : "Business is required.";
      }
      if ("email" in fieldValues) {
        obj.email = fieldValues.email ? "" : "Email address is required.";
      }
      if ("firstName" in fieldValues)
        obj.firstName = fieldValues.firstName ? "" : "First name is required.";
      if ("lastName" in fieldValues)
        obj.lastName = fieldValues.lastName ? "" : "Last name is required.";
    }
    if ("roleId" in fieldValues)
      obj.roleId = fieldValues.roleId ? "" : "Role is required.";
    setErrors({
      ...obj,
    });
    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, setDataValues, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  // Retrieves organisations for selection
  useEffect(() => {
    API.get(API_URLS.GetSelectOrganisationOptions, {
      params: {},
    }).then((response: any) => {
      let orgOptions = response.data.map((x: any) => {
        return {
          id: x.organisationId,
          label: x.organisationName,
          isSedexClientCode: x.isSedexClientCode,
        };
      });
      if (!isAdmin) {
        const userOrgIds = auth?.user?.data?.userOrganisations.map((x: any) => {
          return (
            x.userRole === "Organisation Administrator" && x.organisationId
          );
        });
        orgOptions = orgOptions.filter((x: any) => {
          return userOrgIds?.includes(x.id);
        });
      }
      setOrganisationOptions(orgOptions);
      if (!isAdmin) {
        if (orgOptions.length === 1) {
          values.organisationId = orgOptions[0].id;
          values.organisationIdTextSearch = orgOptions[0].label;
          setDataValues(values);
          getOrgSites();
          setIsOrgSelected(true);
        }
      }
    });

    API.get(API_URLS.GetClientRoles, {}).then((response: any) => {
      const roleOptions = response.data.data.map((x: any) => {
        return {
          id: x.roleId,
          label: x.name,
        };
      });
      setRoles(roleOptions);
    });

    API.get(API_URLS.GetSites, {}).then((response: any) => {
      const siteData = response?.data?.data.map((site: any) => {
        let objValue = {};
        if (
          values.organisationSiteIds.includes(site.organisationSiteId) ||
          values.isAdministrator
        )
          objValue = { ...site, isOrganisationSiteIdChecked: true };
        else objValue = { ...site, isOrganisationSiteIdChecked: false };
        return objValue;
      });
      const setData = siteData;
      setAllOrganisationSites(setData);
    });

    // Retrieves regular users for selection
    API.get(API_URLS.GetAllRegularUsers, {
      params: {},
    }).then((response: any) => {
      const regularUserOptions = response?.data?.data.map((x: any) => {
        return {
          id: x.userId,
          label: x.userName,
        };
      });
      setRegularUsers(response?.data?.data);
      setUserOptions(regularUserOptions);
    });

    return () => {
      // Remove all the field errors and reset the form
      setObj({} as IOrganisationUser);
      resetForm();
    };
  }, []);

  // Get filterd organisation sites
  useEffect(() => {
    if (values.organisations && values.organisations.legnth !== 0) {
      // Assuming values.organisations is an array of objects with the mentioned structure
      const filteredOrganisationSites = values.organisations.map(
        (value: any) => {
          // Filter the allOrganisationSites array based on the organisationId
          return allOrganisationSites.filter(
            (site: any) => site.organisationId === value.organisationId,
          );
        },
      );
      // Flattening the array of arrays into a single array
      const flattenedOrganisationSites = filteredOrganisationSites.flat();
      // Set the state with the filtered and flattened array
      setOrganisationSites(flattenedOrganisationSites);
    }
  }, [values.organisations]);

  // Retrieves all sites for an organisation and sets the state
  // according to whether the user has access to the workplace or not
  useEffect(() => {
    if (values.organisationId !== undefined && values.organisationId !== "") {
      // Find the organization site based on the organisationId
      let filteredOrganisationSites = [];
      if (organisationSites.length === 0) {
        filteredOrganisationSites = allOrganisationSites.filter(
          (site: any) => site.organisationId === values.organisationId,
        );

        filteredOrganisationSites = filteredOrganisationSites.map(
          (site: any) => {
            let objValue = {};
            if (
              values.organisationSiteIds.includes(site.organisationSiteId) ||
              values.isAdministrator
            )
              objValue = { ...site, isOrganisationSiteIdChecked: true };
            else objValue = { ...site, isOrganisationSiteIdChecked: false };
            return objValue;
          },
        );

        // Check if any value in the filteredOrganisationSites array has isOrganisationSiteIdChecked set to false
        const isAnyUnchecked = filteredOrganisationSites.some(
          (site: any) => !site.isOrganisationSiteIdChecked,
        );

        // Update setAllWorkplaceVisibilityChecked based on the check result
        setAllWorkplaceVisibilityChecked(!isAnyUnchecked);
      } else {
        filteredOrganisationSites = organisationSites.filter(
          (site: any) => site.organisationId === values.organisationId,
        );
      }
      // Set the state with the filtered array
      setOrganisationSites(filteredOrganisationSites);
    }
  }, [
    values.organisationId,
    values.organisationSiteIds,
    values.isAdministrator,
  ]);

  // If the user is editing an existing user,
  // then get the user details and populate the form
  useEffect(() => {
    if (usersId) {
      setAppLoading(true);
      API.get(API_URLS.GetOrganisationUser, {
        params: {
          OrganisationId: organisationUserId,
          UserId: usersId,
        },
      })
        .then((response: any) => {
          const result = response?.data;
          values.organisationId = result.organisationId;
          values.organisationIdTextSearch = result.organisationName;
          values.firstName = result.firstName;
          values.lastName = result.lastName;
          values.userId = result.userId;
          values.userIdTextSearch = result.email;
          values.email = result.email;
          values.phoneNumber =
            result.phoneNumber === null ? "" : result.phoneNumber;
          values.physicalAddress = result.physicalAddress;
          values.roleId = result.roleId;
          values.roleIdTextSearch = result.roleName;
          values.organisationSiteIds = result.organisationSiteIds;
          values.isUserExist = true;
          setDataValues(values);
          setIsOrgSelected(true);
          if (result.roleName === RolesEnum.OrgAdmin) {
            setAllWorkplaceVisibilityChecked(true);
          }
        })
        .finally(() => {
          setAppLoading(false);
        });
    } else {
      initialFValues = {
        organisationId: "",
        organisationIdTextSearch: "",
        firstName: "",
        lastName: "",
        userId: "",
        userIdTextSearch: "",
        email: "",
        phoneNumber: "",
        physicalAddress: "",
        organisationSiteIds: [],
        roleId: "",
        roleIdTextSearch: "",
        organisations: [],
        organisationOptions: [],
        organisationSites: [],
        isUserExist: false,
        inviteUserDetails: [],
      };
      setDataValues(initialFValues);
    }
  }, [usersId]);

  //Function used to retrieve sites for the specified organisation
  const getOrgSites = () => {
    API.get(API_URLS.GetOrganisationSitesForReportsScreen, {
      params: {
        organisationId: values.organisationId,
        surveyTemplateTypeId: null,
      },
    }).then((response: any) => {
      setOrganisationSites(response?.data?.data);
    });
  };

  // This function runs when granting access to a workplace for a user
  const selectOrganisationSiteId = (event: any, rowData: any) => {
    if (!values.isAdministrator) {
      values.organisationSiteIds = [];
      setOrganisationSites((prevState: any[]) => {
        const newState = prevState.map((orgSite) => {
          if (orgSite.organisationSiteId === rowData.organisationSiteId) {
            return {
              ...orgSite,
              isOrganisationSiteIdChecked: event.target.value,
            };
          }
          return orgSite;
        });
        return newState;
      });
    }
  };

  // This function runs when selecting a role for the user
  // If the role is OrgAdmin, all the sites are selected by default
  const selectRole = (e: boolean) => {
    const neworganisationSites = organisationSites.map((item: any[]) => {
      return { ...item, isOrganisationSiteIdChecked: e };
    });
    setOrganisationSites(neworganisationSites);
  };

  const selectAllWorkplaceVisibility = (e: boolean) => {
    const neworganisationSites = organisationSites.map((item: any[]) => {
      return { ...item, isOrganisationSiteIdChecked: e };
    });
    setOrganisationSites(neworganisationSites);
    setAllWorkplaceVisibilityChecked(e);
  };

  // When selecting an object from any autocomplete form field,
  // this method runs to update the values to be submitted to the API
  const handleAutoCompleteChange = (selectedObject: any, fieldName: string) => {
    values[fieldName] = selectedObject ? selectedObject.id : "";
    if (obj) (obj as any)[fieldName] = "";
    setDataValues(values);
    setErrors(obj);

    if (fieldName === "roleId") {
      selectRole(selectedObject.label === RolesEnum.OrgAdmin);
      selectAllWorkplaceVisibility(selectedObject.label === RolesEnum.OrgAdmin);
    } else if (fieldName === "userId") {
      setAllWorkplaceVisibilityChecked(false);
    }
  };

  // This method is required to update the text search field in the
  // autocomplete components. It is also used to force the field to be blank.
  const handleAutoCompleteTextInputChange = (text: any, field: string) => {
    const fieldName = `${field}TextSearch`;
    values[fieldName] = text;
    setDataValues(values);
  };

  const handleMultipleSelectAutoCompleteChange = (
    selectedObject: any,
    fieldName: string,
  ) => {
    if (selectedObject.length === 0) {
      values[fieldName] = [];
    } else {
      values[fieldName] = selectedObject;
    }
    if (obj) (obj as any)[fieldName] = "";
    setDataValues(values);
    setErrors(obj);
    validate({ [fieldName]: selectedObject });
  };

  useEffect(() => {
    if (pageHeading === "Invite Business User") {
      if (
        values.userIdTextSearch !== undefined &&
        values.userIdTextSearch !== ""
      ) {
        obj.userIdTextSearch = "";
        const userDetails = regularUsers.filter(
          (user: any) => user.userName === values.userIdTextSearch,
        );

        if (userDetails.length !== 0) {
          values.userId = userDetails[0].userId;
          values.firstName = userDetails[0].firstNames;
          values.lastName = userDetails[0].lastName;
          values.phoneNumber =
            userDetails[0].phoneNumber === null
              ? ""
              : userDetails[0].phoneNumber;
          values.isUserExist = true;
          validateUserDetails();
        } else {
          values.userId = "";
          values.firstName = "";
          values.lastName = "";
          values.phoneNumber = "";
          values.isUserExist = false;
        }
      } else {
        values.userId = "";
        values.firstName = "";
        values.lastName = "";
        values.phoneNumber = "";
        values.isUserExist = false;
      }

      values.organisations = values.organisationOptions.map((x: any) => {
        return {
          organisationId: x.id,
          organisationName: x.label,
          isSedexClientCode: x.isSedexClientCode,
        };
      });
    }
    setDataValues(values);
    setErrors({
      ...obj,
    });
  }, [values.userIdTextSearch, values.organisationOptions]);

  const addUserDetails = () => {
    // Validate inputs except for phoneNumber

    values.email =
      values.userIdTextSearch !== undefined && values.userIdTextSearch !== ""
        ? values.userIdTextSearch
        : values.email;
    if (
      validateUserDetails() &&
      (values.phoneNumber === "" || phoneNumber(values, obj, setErrors)) &&
      validateEmail(values, obj, setErrors)
    ) {
      // Create a new user object with the validated details
      const newUser = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.userIdTextSearch,
        userId: values.userId,
        phoneNumber: values.phoneNumber,
        isUserExist: values.isUserExist,
      };

      // Add the new user to selectedAllUsers
      setInviteUsers((prevUsers) => [...prevUsers, newUser]);

      // Clear user details
      clearUserDetails();
    }
  };

  const RemoveUserDetail = (rowData: any) => {
    const updatedInviteUsers = inviteUsers.filter(
      (user) => user.email !== rowData.email,
    );

    setInviteUsers(updatedInviteUsers);
  };

  const clearUserDetails = () => {
    setDataValues({
      ...values,
      userId: "",
      userIdTextSearch: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      isUserExist: false,
    });
  };

  // Method to submit the form
  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    values.organisationSiteIds = [];
    values.organisationSites = [];
    if (values.roleName !== RolesEnum.OrgAdmin) {
      for (let i = 0; i < organisationSites.length; i += 1) {
        if (organisationSites[i].isOrganisationSiteIdChecked) {
          values.organisationSiteIds.push(
            organisationSites[i].organisationSiteId,
          );
          values.organisationSites.push(organisationSites[i]);
        }
      }
    }

    if (pageHeading !== "Invite Business User") {
      if (values.phoneNumber !== "") {
        phoneNumber(values, obj, setErrors);
      }
      validateEmail(values, obj, setErrors);
    }

    if (pageHeading === "Invite Business User") {
      values.inviteUserDetails = inviteUsers;
      if (values.inviteUserDetails.length === 0) {
        ErrorToast("Please add atleast one user.");
        return;
      }
    }

    if (validate()) {
      if (
        values.roleName !== RolesEnum.OrgAdmin &&
        values.organisationSiteIds.length < 1
      ) {
        ErrorToast("Please select atleast one workplace name.");
        return;
      }

      if (
        values.organisations.length > 1 &&
        values.roleIdTextSearch === RolesEnum.OrgAdmin
      ) {
        ErrorToast(
          "Cannot make this user an Administrator for multiple businesses.",
        );
        return;
      }

      const apiurl = usersId
        ? API_URLS.UpdateOrganisationUser
        : API_URLS.InviteUserToOrganisations;
      setAppLoading(true);
      API.post(apiurl, {
        ...values,
        userId: usersId,
        roleName: values.roleIdTextSearch,
      })
        .then((response: any) => {
          SuccessToast(response?.data?.message);
          setObj({} as IOrganisationUser);
          resetForm();
          navigate(`"${organisationUserId}/users/"`);
        })
        .catch(() => {
          setAppLoading(false);
        })
        .finally(() => {
          setAppLoading(false);
        });
    }
  };
  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view invie-org-form">
        <Grid container>
          <Grid item xs={12} sm={2} md={2} />
          <Grid item xs={12} sm={9}>
            <Grid container>
              <Grid item xs={12}>
                <div className="page-heading-container">
                  <Controls.BackButton />
                  <h2 className="page-heading">{pageHeading}</h2>
                </div>
              </Grid>
            </Grid>

            {pageHeading !== "Invite Business User" ? (
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Controls.AutoComplete
                    id="organisationId"
                    name="organisationId"
                    label="Business*"
                    value={values.organisationId}
                    options={organisationOptions}
                    inputValue={values.organisationIdTextSearch}
                    onChange={handleAutoCompleteChange}
                    onInputChange={handleAutoCompleteTextInputChange}
                    varient="standard"
                    error={obj.organisationId}
                    disabled={isOrgSelected}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Controls.Input
                    id="email"
                    name="email"
                    label="Email address*"
                    value={values.email}
                    onChange={handleInputChange}
                    error={obj.email}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Controls.Input
                    id="firstName"
                    name="firstName"
                    label="First name*"
                    value={values.firstName}
                    onChange={handleInputChange}
                    error={obj.firstName}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Controls.Input
                    id="lastName"
                    name="lastName"
                    label="Last name*"
                    value={values.lastName}
                    onChange={handleInputChange}
                    error={obj.lastName}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Input
                    id="phoneNumber"
                    label="Phone number"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={handleInputChange}
                    error={obj.phoneNumber}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Controls.AutoComplete
                    id="roleId"
                    name="roleId"
                    label="Role*"
                    inputValue={values.roleIdTextSearch}
                    onChange={handleAutoCompleteChange}
                    onInputChange={handleAutoCompleteTextInputChange}
                    options={roles}
                    error={obj.roleId}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <div className="site-table">
                    <br />
                    <UseTable
                      columns={columns}
                      data={organisationSites}
                      title="Organisation Sites"
                    />
                  </div>
                  <div className="form-buttons custom-floatRight">
                    <br />
                    <Controls.Button
                      className="button blue"
                      type="submit"
                      text="Save"
                    />
                    <Controls.Button
                      text="Cancel"
                      className="cancel-button"
                      variant="outlined"
                      // onClick={() => organisationUsersById()}
                      onClick={() => navigate(-1)}
                    />
                  </div>
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Accordion defaultExpanded className="custom-accordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    USER DETAILS
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <Controls.AutoComplete
                          id="userId"
                          name="userId"
                          label="Email address*"
                          value={values.userId}
                          options={userOptions}
                          inputValue={values.userIdTextSearch}
                          onChange={handleAutoCompleteChange}
                          onInputChange={handleAutoCompleteTextInputChange}
                          varient="standard"
                          error={obj.userIdTextSearch}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Controls.Input
                          id="firstName"
                          name="firstName"
                          label="First name*"
                          value={values.firstName}
                          onChange={handleInputChange}
                          error={obj.firstName}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Controls.Input
                          id="lastName"
                          name="lastName"
                          label="Last name*"
                          value={values.lastName}
                          onChange={handleInputChange}
                          error={obj.lastName}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Controls.Input
                          id="phoneNumber"
                          label="Phone number"
                          name="phoneNumber"
                          value={values.phoneNumber}
                          onChange={handleInputChange}
                          error={obj.phoneNumber}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <div className="form-buttons custom-floatRight">
                          <Controls.Button
                            className="button green"
                            type="button"
                            text="Add User"
                            onClick={() => addUserDetails()}
                          />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <div className="site-table">
                          <br />
                          <UseTable
                            columns={inviteUserColumns}
                            data={inviteUsers}
                            title="Invite Users"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded className="custom-accordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    ORGANISATION DETAILS
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <Controls.AutoComplete
                          id="organisationOptions"
                          name="organisationOptions"
                          label="Businesses*"
                          value={values.organisationOptions}
                          options={organisationOptions}
                          onChange={handleMultipleSelectAutoCompleteChange}
                          onInputChange={handleAutoCompleteTextInputChange}
                          error={obj.organisationOptions}
                          disabled={isOrgSelected}
                          multiple={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Controls.AutoComplete
                          id="roleId"
                          name="roleId"
                          label="Role*"
                          inputValue={values.roleIdTextSearch}
                          onChange={handleAutoCompleteChange}
                          onInputChange={handleAutoCompleteTextInputChange}
                          options={roles}
                          error={obj.roleId}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <div className="site-table">
                          <br />
                          <UseTable
                            columns={columns}
                            data={organisationSites}
                            title="Organisation Sites"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Grid item xs={12} sm={12}>
                  <div className="form-buttons custom-floatRight">
                    <br />
                    <Controls.Button
                      className="button blue"
                      type="submit"
                      text="Save"
                    />
                    <Controls.Button
                      text="Cancel"
                      className="cancel-button"
                      variant="outlined"
                      // onClick={() => organisationUsersById()}
                      onClick={() => navigate(-1)}
                    />
                  </div>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </Form>
  );
};

export default InviteOrgnisationUser;
