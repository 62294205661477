const routePaths = {
  // Auth paths
  SIGNIN: `/signin`, //✅
  SIGNUP: `/signup`, //✅
  SIGNOUT: `/signout`, //✅
  HOME: `/home`,
  RESETPASSWORD: `/reset-password`, //✅
  FORGOTPASSWORD: `/forgot-password`, //✅
  TWOFACTORCODE: `/two-factor-code`, //✅
  // Authorised paths
  DASHBOARD: `/`, //✅
  UNAUTHORISED: `/unauthorised`,
  //Profile paths
  PROFILE: `/profile`, //✅
  MANAGEPROFILE: `/profile/manage-profile`, //✅
  DEVELOPERAPICREDENTIALS: `/profile/developer-api-credentials`, //✅

  // workplace paths
  SITEMANAGEMENT: `/sites`, //✅
  CREATESITE: `/sites/create`, //✅
  EDITSITE: `/:id/edit`, //✅
  USERPHONENUMBER: `/:organisationSiteId/phonenumbers`, //✅
  VIEWPHONENUMBER: `/:organisationSiteId/viewphonenumbers`, //✅

  // Organisation paths
  ORGANISATIONS: `/organisations`, //✅
  CREATEORGANISATIONS: `/organisations/create`, //✅
  GETORGANISATIONUSERS: `/organisations/users`, //✅
  EDITORGANISATIONS: `/:id/edit`, //✅
  EDITORGANISATIONUSER: `/organisations/:userId/invite-user`, //✅
  INVITEORGANISATIONUSER: `/organisations/invite-user`, //✅
  UPDATEORGANISATIONUSER: `/organisations/:organisationId/:userId/edit`, //✅
  TAGS: `/organisations/tags`, //✅

  // Data cycle paths
  DATACYCLES: `/surveycycles`, //✅
  EDITDATACYCLE: `/:id/edit`, //✅
  VIEWDATACYCLE: `/:id/view`, //✅
  DATACYCLENOTES: `/:id/notes`, //✅
  CREATEDATACYCLENOTE: `/:dataCycleId/createnote`, //✅
  EDITDATACYCLENOTE: `/:dataCycleNoteId/editnote`, //✅
  CREATEDATACYCLE: `/surveycycles/create`, //✅
  CREATEMULTIPLEDATACYCLES: `/surveycycles/create-multiple-datacycles`, //✅
  TRANSLATIONS: `/:id/translations`, //✅

  // Question paths
  QUESTIONS: `/questions`, //✅
  CREATEQUESTION: `/questions/create`, //✅
  MANAGELANGUAGE: `/questions/manage-language`, //✅
  EDITQUESTIONS: `/:questionId/edit`, //✅
  ADDQUESTIONLANGUAGE: `/:questionId/add-question-language`, //✅
  EDITQUESTIONLANGUAGE: `/:questionId/:languageId/edit-question-language`, //✅
  QUESTIONLANGUAGES: `/:questionId/question-languages`, //✅

  //LANGUAGES
  LANGUAGES: `/languages`, //✅
  ADDLANGUAGE: `/languages/add-language`, //✅
  EDITLANGUAGE: `/:languageId/edit-language`, //✅

  // Report paths
  REPORTS: `/reports`, //✅
  REPORTINGTABS: `/tabs/:organisationId/:siteId/:sttId/:defaultTab/:clientId/:selectedOrgId`,
  MAPS: `/map`,
  LISTVIEW: `/list`,

  // Survey template paths
  SURVEYTEMPLATES: `/survey-templates`, //✅
  CREATETEMPLATE: `/survey-templates/create`, //✅
  VIEWTEMPLATE: `/:surveyTemplateId/view`, //✅
  EDITTEMPLATE: `/:surveyTemplateId/edit`, //✅
  CREATETEMPLATETYPE: `/survey-templates/create-templatetype`, //✅

  // Resource paths
  RESOURCES: `/resources`, //✅
  CREATERESOURCE: `/resources/create`, //✅
  EDITRESOURCE: `/:resourceId/edit`, //✅
  VIEWRESOURCES: `/resources/view`, //✅

  // User paths
  USERS: `/users`, //✅
  CLIENTUSERS: `/users/clients`, //✅
  REGISTERADMINISTRATOR: `/register-administrator`, //✅
  EDITADMIN: `/:userId/edit`, //✅
  VIEWUSER: `/:userId/view-user`,

  // Organisation admin
  ORGANISATIONADMIN: `/admin`,
  ORGANISATIONINVITEUSER: `/invite-user`,
  ORGANISATIONEDITUSER: `/edit-user/:usersId`,

  // Sectors
  SECTORS: `/sectors`,
  SUBSECTORS: `/sectors/subsectors`,
  CREATESECTOR: `/sectors/create`,
  CREATESUBSECTOR: `/sectors/create-sub-sector`,
};

export default routePaths;
