import { useState } from "react";
import { Link } from "react-router-dom";
import { IAdminNavItem } from "../../models/Interfaces/INavBar";
import Dropdown from "./Dropdown";
import "./MenuItem.css";

function MenuItem({ title, id, path, submenu, icon, iconPath }: IAdminNavItem) {
  const [dropdown, setDropdown] = useState(false);

  const onMouseEnter = (val: any) => {
    if (window.innerWidth < 960) {
      setDropdown(val);
    } else {
      setDropdown(val);
    }
  };

  const onMouseLeave = (val: any) => {
    if (window.innerWidth < 960) {
      setDropdown(val);
    } else {
      setDropdown(val);
    }
  };

  const renderItem = () => {
    if (submenu != null) {
      return (
        <li
          className="nav-item"
          id={`${id}-desktop`}
          onMouseEnter={(keyId: any) => onMouseEnter(keyId)}
          onMouseLeave={() => onMouseLeave(false)}
        >
          <div className="dropdown-menu-item">
            {icon && (
              <i style={{ fontSize: "xx-large" }} className={iconPath} />
            )}
            {!icon && <p className="menu-title">{title}</p>}
          </div>
          {dropdown && <Dropdown submenu={submenu} title="" path="" />}
        </li>
      );
    }
    return (
      <li className="nav-item" id={`${id}-desktop`}>
        <Link to={path} className="menu-item">
          {title}
        </Link>
      </li>
    );
  };

  return <>{renderItem()}</>;
}

export default MenuItem;
