import { Navigate, Route, Routes } from "react-router-dom";
import routePaths from "config/RoutePaths";
import ForgotPassword from "pages/Auth/ForgotPassword";
import SignIn from "pages/Auth/SignIn";
import SignUp from "pages/Auth/SignUp";
import Unauthorised from "pages/DashBoard/Unauthorised";
import ResetPassword from "pages/Auth/ResetPassword";
import TwoFactorCode from "pages/Auth/TwoFactorCode";

export const UnauthenticatedApp = () => {
  return (
    <Routes>
      <Route path={routePaths.SIGNIN} element={<SignIn />} />
      <Route path={routePaths.SIGNUP} element={<SignUp />} />
      <Route path={routePaths.FORGOTPASSWORD} element={<ForgotPassword />} />
      <Route path={routePaths.UNAUTHORISED} element={<Unauthorised />} />
      <Route path={routePaths.RESETPASSWORD} element={<ResetPassword />} />
      <Route path={routePaths.TWOFACTORCODE} element={<TwoFactorCode />} />

      <Route path="*" element={<Navigate to={routePaths.SIGNIN} />} />
    </Routes>
  );
};
