import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useLoading } from "providers/loading";
import moment from "moment";
import Controls from "../../../components/Controls";
import API from "../../../services/Api.Service";
import { API_URLS } from "../../../config/ApiUrls";
import "./Style.css";

export default function ViewDataCycle(this: any) {
  // Pull ID of data cycle from URL parameters
  // if no ID in parameters, it is a create data cycle screen
  const { id } = useParams();
  const { setAppLoading } = useLoading();
  const [surveyCycle, setSurveyCycle] = useState<any>({});

  // This useEffect checks whether ID is present in parameters
  // if so the form is prepopulated with the details of the data cycle to be edited
  useEffect(() => {
    if (id) {
      setAppLoading(true);
      API.get(API_URLS.GetDataCycleById, {
        params: {
          dataCycleId: id,
        },
      })
        .then((response: any) => {
          const result = response?.data;

          result.languages = result.languages
            .map((x: any) => {
              return `${x.languageLabel}, `;
            })
            .toString()
            .slice(0, -2);

          result.dataCollectionMethods = result.dataCollectionMethods
            .map((x: any) => {
              return `${x.method}, `;
            })
            .toString()
            .slice(0, -2);

          setSurveyCycle(result);
        })
        .finally(() => {
          setAppLoading(false);
        });
    }
  }, [id]);

  return (
    <div className="container-view">
      <Grid container>
        <Grid item xs={12} sm={2} md={2} />
        <Grid item xs={12} sm={8} md={8}>
          <Grid container>
            <Grid item xs={12} className="grid-custom-spacing">
              <div className="page-heading-container">
                <Controls.BackButton />
                <h2 className="page-heading">VIEW DATA CYCLE</h2>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="grid-custom-spacing">
              <h3>Survey Cycle Name</h3>
              <p>{surveyCycle.dataCycleName}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="grid-custom-spacing"
            />
            <Grid item xs={12} sm={12} md={12} className="grid-custom-spacing">
              <h3>Business Name</h3>
              <p>{surveyCycle.organisationName}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="grid-custom-spacing">
              <h3>Workplace</h3>
              <p>{surveyCycle.organisationSiteName}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="grid-custom-spacing">
              <h3>Survey Template</h3>
              <p>{surveyCycle.surveyTemplateName}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="grid-custom-spacing">
              <h3>Season</h3>
              <p>{surveyCycle.dataCycleSeasonLabel}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="grid-custom-spacing">
              <h3>Languages</h3>
              <p>{surveyCycle.languages}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="grid-custom-spacing">
              <h3>Number of workers</h3>
              <p>{surveyCycle.numberOfWorker}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="grid-custom-spacing">
              <h3>Collection methods</h3>
              <p>{surveyCycle.dataCollectionMethods}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="grid-custom-spacing">
              <h3>Start date</h3>
              <p>{moment(surveyCycle.startDate).format("DD/MM/YYYY")}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="grid-custom-spacing">
              <h3>
                End date (bear in mind this is the date it was marked as
                complete)
              </h3>
              <p>{moment(surveyCycle.completedDate).format("DD/MM/YYYY")}</p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
