import { useEffect, useState } from "react";
import Controls from "components/Controls";
import { IDownloadModal } from "models/Interfaces/IReports";
import { useLoading } from "providers/loading";
import { ReportingTypeEnum } from "models/Enum/EnumTypes";
import { API_URLS } from "config/ApiUrls";
import API from "services/Api.Service";
import "./Style.css";

const DownloadModal = ({
  setModal,
  items,
  reportType,
  selectedQuestion,
}: IDownloadModal) => {
  const { setAppLoading } = useLoading();
  const [options, setOptions] = useState<any>([]);
  const [consentGiven, setConsentGiven] = useState(false);
  const [heading, setHeading] = useState("Data");
  const [url, setUrl] = useState("");

  useEffect(() => {
    const optionArray = items.map((cycle) => {
      return {
        checked: false,
        description: cycle.label,
        option: cycle.key,
      };
    });

    optionArray.unshift({
      checked: false,
      description: "All survey cycles",
      option: "ALL",
    });

    setOptions(optionArray);
  }, [items]);

  useEffect(() => {
    let text = "Data";
    let downloadLink = "";

    switch (reportType) {
      case ReportingTypeEnum.Priorities:
        downloadLink = API_URLS.DownloadPriorities;
        text = "Priorities";
        break;
      case ReportingTypeEnum.Result:
        downloadLink = API_URLS.DownloadResults;
        text = "Results";
        break;
      case ReportingTypeEnum.Progress:
        downloadLink = "";
        text = "Progress Data";
        break;
      case ReportingTypeEnum.Participation:
        downloadLink = selectedQuestion?.questionText
          ? API_URLS.DownloadSegmentationCharts
          : API_URLS.DownloadResponseRates;
        text = "Participation Data";
        break;
      default:
        text = "Data";
        break;
    }

    setUrl(downloadLink);
    setHeading(text);
  }, [reportType]);

  const handleCheckBoxChange = (e: any, optionIndex: number) => {
    if (optionIndex === 0) {
      options.forEach((option: any) => (option.checked = !e.target.checked));
      setOptions([...options]);
    } else {
      options[optionIndex].checked = !e.target.checked;
      setOptions([...options]);
    }
  };

  const downloadData = () => {
    const IDs: string[] = [];

    options.forEach((option: any) => {
      if (option.option && option.checked && option.option !== "ALL") {
        IDs.push(option.option);
      }
    });

    if (IDs.length > 0) {
      setAppLoading(true);
      API.post(url, {
        DataCycleIds: IDs,
        QuestionId: selectedQuestion.questionId,
      })
        .then((res: any) => {
          const mineType = res.headers["content-type"];
          let name = res.headers["content-disposition"].replace(
            "attachment; filename=",
            "",
          );
          name = name.replace(/"/g, "").substring(0, name.indexOf(".csv"));
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          const blob = new Blob([res.data], { type: mineType });
          const dlurl = window.URL.createObjectURL(blob);
          a.href = dlurl;
          a.download = name;
          a.click();
          window.URL.revokeObjectURL(dlurl);
        })
        .finally(() => {
          setAppLoading(false);
          setModal();
        });
    }
  };

  return (
    <div className="modal">
      <div onClick={setModal} className="overlay" />
      <div className="modal-content">
        <h2>Download {heading}</h2>
        {heading === "Participation Data" ? (
          selectedQuestion?.questionText ? (
            <h3>Segmentation Chart</h3>
          ) : (
            <h3>Response Rates</h3>
          )
        ) : null}
        {!consentGiven ? (
          <div className="consent-checkbox">
            <Controls.CheckBox
              checked={consentGiven}
              label="Responses provided by survey participants should not be altered. By downloading this data you agree to using it ethically and in ways that contribute to improving the wellbeing of the participants. Please check the box to proceed."
              key="consent"
              value={consentGiven}
              onChange={() => setConsentGiven(!consentGiven)}
            />
          </div>
        ) : (
          <div className="callCycle-options">
            {options.length > 0 &&
              options.map((dataCycle: any, optionIndex: any) => {
                return (
                  <Controls.CheckBox
                    checked={dataCycle.checked}
                    label={dataCycle.description}
                    key={dataCycle.option}
                    value={dataCycle.checked}
                    onChange={(e: any) => handleCheckBoxChange(e, optionIndex)}
                  />
                );
              })}
          </div>
        )}
        <div className="form-buttons custom-floatRight">
          <Controls.Button
            className="button blue"
            onClick={downloadData}
            text="Download"
          />
          <Controls.Button
            text="Cancel"
            className="cancel-button"
            variant="outlined"
            onClick={setModal}
          />
        </div>
      </div>
    </div>
  );
};

export default DownloadModal;
