import { useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import InfoIcon from "@mui/icons-material/Info";
import { ReportingTypeEnum } from "../../../../models/Enum/EnumTypes";
import "./Style.css";

function WhatToLookFor({ tabName }: any) {
  const [expanded, setExpanded] = useState(false);

  let content = null;

  switch (tabName) {
    case ReportingTypeEnum.Priorities:
      content = (
        <div>
          Here, look for the longest red bar - this will be the top priority
          voiced by workers. However, every issue on the priorities chart
          demands attention, because it's been flagged by over half the
          participating workers. Navigate to previous data cycle results to
          compare current priorities to past cycles for insights. We advise
          using the comparison function from data cycle 2 onwards to track the
          impact of your improvement efforts and identify patterns. Explore the
          filters to address issues affecting specific worker subgroups. This
          approach ensures targeted action.
        </div>
      );
      break;
    case ReportingTypeEnum.Result:
      content = (
        <div>
          This tab will give you more detail on the questions. Again, you are
          looking for the longest red bars, as these will be the top priorities
          voiced by workers.
        </div>
      );
      break;
    case ReportingTypeEnum.Progress:
      content = (
        <div>
          Your goal here is to shrink your reds and grow your greens. If you
          spot an uptick in yellow, check to see if your reds have decreased
          too. Yellows replacing reds signals an improvement—a positive shift in
          working conditions. Embrace the journey of progress.
        </div>
      );
      break;
    case ReportingTypeEnum.Participation:
      content = (
        <div>
          Here, you will find more information about the workers who answered
          the survey. On the left you can select other charts to find
          demographic information about the workers who participated. This
          information enables you to compare the experiences of categories or
          subgroups of workers on site. Please always check your filters for
          targeted interventions that resonate with specific worker subgroups.
          Your attention to these details ensures impactful strategies tailored
          to the unique needs of your workforce.
        </div>
      );
      break;
    default:
      content = (
        <div>
          We love celebrating improvement! A supplier gets a status badge when a
          certain level of improvement has been reported by workers. To grow
          your badge collection, regularly check your dashboard results and
          implement the changes requested by workers. You can share this
          positive journey with clients and on social media, showcasing your
          unwavering commitment to continuous improvement to the world.
        </div>
      );
  }

  return (
    <div className="infoBox">
      <div className={`infoBoxBanner ${expanded ? "infoBoxBannerBorder" : ""}`}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <InfoIcon style={{ display: "inline-block" }} />
          <p style={{ display: "inline-block", paddingLeft: "5px" }}>
            What to look for
          </p>
        </div>
        <div
          className="contentExpansion"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <div className={expanded ? "hiddenIcon" : "displayedIcon"}>
            <FaPlus />
          </div>
          <div className={expanded ? "displayedIcon" : "hiddenIcon"}>
            <FaMinus />
          </div>
        </div>
      </div>
      <div className={`infoBoxContent ${expanded ? "visible" : "invisible"}`}>
        {content}
      </div>
    </div>
  );
}

export default WhatToLookFor;
