import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLoading } from "providers/loading";
import Controls from "components/Controls";
import { SuccessToast } from "components/Toaster";
import { API_URLS } from "config/ApiUrls";
import { UseTable } from "hooks/useTable";
import API from "services/Api.Service";

import "./Style.css";

const SurveyTemplateTable = () => {
  const { setAppLoading } = useLoading();
  const navigate = useNavigate();
  const [surveyTemplates, setSurveyTemplates] = React.useState<any[]>([]);
  const [modal, setModal] = React.useState(false);
  const [modalHeading, setModalHeading] = React.useState("");
  const [tempInContext, setTempInContext] = React.useState({
    surveyTemplateId: "",
    templateName: "",
    templateType: "",
  });

  useEffect(() => {
    setAppLoading(true);
    API.get(API_URLS.GetSurveyTemplates, {
      params: {},
    })
      .then((response: any) => {
        setSurveyTemplates(response.data.data);
      })
      .finally(() => {
        setAppLoading(false);
      });
  }, []);

  const navigateToEdit = (surveyTemplateId: string) => {
    navigate(`/survey-templates/${surveyTemplateId}/edit`);
  };

  const navigateToView = (surveyTemplateId: string) => {
    navigate(`/survey-templates/${surveyTemplateId}/view`);
  };

  const columns = [
    { title: "NAME", field: "surveyTemplateName" },
    { title: "TYPE", field: "surveyTemplateTypeName" },
    { title: "NUMBER OF QUESTIONS", field: "numberOfQuestions" },
    {
      title: "MANAGE",
      field: "edit",
      render: (rowData: any) => (
        <div className="actions-container">
          <p onClick={() => toggleModal(rowData, "Duplicate")}>Duplicate</p>|
          <p onClick={() => navigateToEdit(rowData?.surveyTemplateId)}>Edit</p>|
          <p onClick={() => navigateToView(rowData?.surveyTemplateId)}>View</p>|
          <p
            className="actions-container-delete"
            onClick={() => toggleModal(rowData, "Delete")}
          >
            Delete
          </p>
        </div>
      ),
    },
  ];

  const toggleModal = (rowData: any, type: string) => {
    setModalHeading(type);
    setTempInContext({
      surveyTemplateId: rowData.surveyTemplateId,
      templateName: rowData.surveyTemplateName,
      templateType: rowData.surveyTemplateTypeName,
    });
    setModal(!modal);
  };

  const closeModal = () => {
    setModal(false);
  };

  const deleteSurveyTemplate = () => {
    setAppLoading(true);
    API.delete(API_URLS.DeleteSurveyTemplate, {
      params: {
        surveyTemplateId: tempInContext.surveyTemplateId,
      },
    })
      .then((res: any) => {
        SuccessToast(res?.data.message);
        setModal(false);
        API.get(API_URLS.GetSurveyTemplates, {
          params: {},
        }).then((response: any) => {
          setSurveyTemplates(response.data.data);
        });
      })
      .finally(() => {
        setAppLoading(false);
      });
  };

  const duplicateSurveyTemplate = () => {
    setAppLoading(true);
    API.post(API_URLS.DuplicateSurveyTemplate, {
      surveyTemplateId: tempInContext.surveyTemplateId,
    })
      .then((res: any) => {
        SuccessToast(res?.data.message);
        setModal(false);
        API.get(API_URLS.GetSurveyTemplates, {
          params: {},
        }).then((response: any) => {
          setSurveyTemplates(response.data.data);
        });
      })
      .finally(() => {
        setAppLoading(false);
      });
  };

  return (
    <>
      <div className="tableDiv">
        <UseTable
          columns={columns}
          data={surveyTemplates}
          title="Survey Templates"
        />
      </div>
      {modal && (
        <div className="modal">
          <div onClick={closeModal} className="overlay" />
          <div className="modal-content">
            <h2>{modalHeading} Survey Template</h2>
            <p>
              You are about to {modalHeading.toLowerCase()} the following survey
              template. Are you sure you want to proceed?
            </p>
            <p>Template name: {tempInContext.templateName}</p>
            <p>Type: {tempInContext.templateType}</p>
            <br />
            <div className="form-buttons custom-floatRight">
              <Controls.Button
                className="button blue"
                onClick={
                  modalHeading === "Delete"
                    ? deleteSurveyTemplate
                    : duplicateSurveyTemplate
                }
                text={modalHeading.toUpperCase()}
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={closeModal}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SurveyTemplateTable;
