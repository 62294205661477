import { useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./Style.css";
import { IAdminForgotPassword } from "models/Interfaces/IAdmin";
import { useLoading } from "providers/loading";
import { Form, useForm } from "hooks/useForm";
import Controls from "components/Controls";
import API from "services/Api.Service";
import { API_URLS } from "config/ApiUrls";
import { SuccessToast } from "components/Toaster";
import routePaths from "config/RoutePaths";

const initialFValues: IAdminForgotPassword = {
  email: "",
};

type ForgotPassError = {
  email?: string;
};

const obj: ForgotPassError = {};

export default function ForgotPassword() {
  const { setAppLoading } = useLoading();
  const navigate = useNavigate();
  const Url: any = window.location.origin + routePaths.RESETPASSWORD;

  const validate = (fieldValues = values) => {
    if ("email" in fieldValues)
      obj.email = fieldValues.email ? "" : "Email address is required.";
    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const validateEmail = (fieldValues = values) => {
    const re = /\S+@\S+\.\S+/;
    if ("email" in fieldValues)
      if (!re.test(fieldValues.email)) {
        obj.email = "Please enter a valid email address.";
      }

    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange, resetForm } = useForm(
    initialFValues,
    true,
    validate,
  );
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validate() && validateEmail()) {
      setAppLoading(true);
      API.post(API_URLS.ForgotPassword, null, {
        params: {
          Email: values.email,
          url: Url,
        },
      })
        .then((response: any) => {
          SuccessToast(response?.data?.message);
          navigate(routePaths.SIGNIN);
          resetForm();
        })
        .finally(() => {
          setAppLoading(false);
        });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view">
        <Grid container>
          <Grid item xs={12} sm={3} md={3} />
          <Grid item xs={12} sm={6} md={6}>
            <div>
              <div className="page-heading-container">
                <Controls.BackButton />
                <h2 className="page-heading">Forgot Password</h2>
              </div>
              <p>
                Please enter your email address below and we will email you a
                password reset link.
              </p>
            </div>
            <Controls.Input
              id="email"
              name="email"
              label="Email address*"
              value={values.email}
              onChange={handleInputChange}
              error={obj.email}
              cName="text-input"
            />
            <br />
            <br />
            <div className="form-buttons custom-floatRight">
              <Controls.Button
                className="button blue"
                type="submit"
                text="Email link"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={() => navigate(-1)}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3} />
        </Grid>
      </div>
    </Form>
  );
}
