import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import MultiSelect from "./MultiSelect";
import Button from "./Button";
import DatePicker from "./DatePicker";
import PasswordInput from "./PasswordInput/Index";
import CheckBox from "./CheckBox";
import Address from "./Address";
import AutoComplete from "./AutoComplete";
import BackButton from "./BackButton";
import { Rating } from "../Rating";
import PasswordEye from "./PaaswordEye";

const Controls = {
  Input,
  RadioGroup,
  Select,
  MultiSelect,
  CheckBox,
  DatePicker,
  Button,
  PasswordInput,
  Address,
  AutoComplete,
  Rating,
  PasswordEye,
  BackButton,
};

export default Controls;
