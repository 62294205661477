import { IQuestionComponents } from "models/Interfaces/IQuestion";
import AntiCoaching from "./AntiCoaching";
import ConsentQuestions from "./ConsentQuestions";
import CoreQuestions from "./CoreQuestions";
import SegmentationQuestion from "./SegmentationQuestion";

const QuestionComponents = ({
  questionTypeId,
  questionTypes,
  values,
  errors,
  onChange,
  isYesPositive,
  setIsYesPositive,
}: IQuestionComponents) => {
  const questionType = questionTypes.filter(
    (x: any) => x.id === questionTypeId,
  );

  if (questionType.length > 0) {
    switch (questionType[0].label) {
      case "Consent":
        return <ConsentQuestions />;
      case "Core":
        return (
          <CoreQuestions
            isYesPositive={isYesPositive}
            setIsYesPositive={setIsYesPositive}
          />
        );
      case "Segmentation":
        return (
          <SegmentationQuestion
            values={values}
            errors={errors}
            onChange={onChange}
          />
        );
      case "Anti-coaching":
        return <AntiCoaching />;
      default:
        return null;
    }
  } else {
    return null;
  }
};

export default QuestionComponents;
