import { API_URLS } from "config/ApiUrls";
import { axios } from "lib/axios";

import { UserResponse } from "./types";

export type RegisterCredentialsDTO = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export const registerWithEmailAndPassword = (
  data: RegisterCredentialsDTO,
): Promise<UserResponse> => {
  return axios.post(API_URLS.RegisterAndWiderAdmin, data);
};
