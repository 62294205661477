import { useEffect, useState } from "react";
import Charts from "components/Charts";
import { API_URLS } from "config/ApiUrls";
import { IProgressChart } from "models/Interfaces/ICharts";
import API from "services/Api.Service";
import { SmallSpinner } from "components/Spinner";
//import "./Style.css";

function Progress({ siteId, surveyTemplateTypeId }: IProgressChart) {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState<any>([]);

  useEffect(() => {
    setLoading(true);
    if (siteId && surveyTemplateTypeId) {
      API.post(API_URLS.GetProgressChart, {
        organisationSiteId: siteId,
        surveyCycleTemplateTypeId: surveyTemplateTypeId,
      })
        .then((response: any) => {
          setResults(response?.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [siteId, surveyTemplateTypeId]);

  return loading ? (
    <div className="chartSpinner">
      <SmallSpinner />
    </div>
  ) : (
    <Charts.ProgressChart items={results} />
  );
}

export default Progress;
