import { Grid } from "@material-ui/core";
import Controls from "components/Controls";

export interface ICoreQuestions {
  isYesPositive: boolean;
  setIsYesPositive: (value: any) => void;
}

const CoreQuestions = ({ isYesPositive, setIsYesPositive }: ICoreQuestions) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <Controls.CheckBox
          id="IsYesAPositiveResponse"
          name="IsYesAPositiveResponse"
          label="Is yes a positive response?*"
          value={isYesPositive}
          checked={isYesPositive}
          onChange={(e) => {
            setIsYesPositive(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controls.Input
          id="coreYes"
          name="coreYes"
          label="Yes"
          value={isYesPositive ? "Positive" : "Negative"}
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <Controls.Input
          id="coreNo"
          name="coreNo"
          label="No"
          value={isYesPositive ? "Negative" : "Positive"}
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <Controls.Input
          id="coreDontKnow"
          name="coreDontKnow"
          label="Don't know"
          value="Neutral"
          disabled
        />
      </Grid>
    </Grid>
  );
};

export default CoreQuestions;
