import React from "react";
import { FullPageSpinner } from "components/Spinner";
import { useAuth } from "lib/auth";
import Layout from "pages/DashBoard/Layout";
import { isEmpty } from "util/CommonFunctions";
import storage from "util/storage";
import { useLoading } from "providers/loading";
import { AuthenticatedApp } from "./Authenticated";
import { UnauthenticatedApp } from "./Unauthenticated";

export const AppRoutes = () => {
  const auth = useAuth();
  const user = isEmpty(auth.user) ? null : auth.user;
  const userToken = storage.getUser();
  const { appLoading } = useLoading();
  return (
    <Layout>
      <React.Suspense fallback={<FullPageSpinner />}>
        {userToken || user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
        {appLoading && <FullPageSpinner />}
      </React.Suspense>
    </Layout>
  );
};
