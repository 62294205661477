import { Grid } from "@material-ui/core";
import Controls from "components/Controls";

const ConsentQuestions = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Controls.Input
          id="consentQuestionYes"
          name="consentQuestionYes"
          label="Yes"
          value="Consented"
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <Controls.Input
          id="consentQuestionNo"
          name="consentQuestionNo"
          label="No"
          value="No Consent"
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <Controls.Input
          id="consentQuestionLater"
          name="consentQuestionLater"
          label="Call back later"
          value="Call back later"
          disabled
        />
      </Grid>
    </Grid>
  );
};

export default ConsentQuestions;
