import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import API from "services/Api.Service";
import { API_URLS } from "config/ApiUrls";
import { ErrorToast, SuccessToast } from "components/Toaster";
import routePaths from "config/RoutePaths";
import { useLoading } from "providers/loading";
import { Form, useForm } from "hooks/useForm";
import Controls from "components/Controls";
import { ISurveyTemplate } from "models/Interfaces/ISurveyTemplate";
import PotentialQuestionTable from "../PotentialQuestionTable";
import SurveyTemplateQuestionTable from "../SurveyTemplateQuestionTable";
import "./Style.css";

export default function CreateSurveyTemplate() {
  const { setAppLoading } = useLoading();
  const [surveyTemplateTypes, setSurveyTemplateTypes] = useState<any[]>([]);
  const [allQuestions, setAllQuestions] = useState<any[]>([]);
  const [introductionQuestions, setIntroductionQuestions] = useState<any[]>([]);
  const [callbackIntroQuestions, setCallbackIntroQuestions] = useState<any[]>(
    [],
  );
  const [errorQuestions, setErrorQuestions] = useState<any[]>([]);
  const [almostDoneQuestions, setAlmostDoneQuestions] = useState<any[]>([]);
  const [noConsentOutros, setNoConsentOutros] = useState<any[]>([]);
  const [contactLaterOutros, setContactLaterOutros] = useState<any[]>([]);
  const [completedOutros, setCompletedOutros] = useState<any[]>([]);
  const [surveyTemplateQuestions, setSurveyTemplateQuestions] = useState<any[]>(
    [],
  );
  const [answerableQuestions, setAnswerableQuestions] = useState<any[]>([]);
  const [consentQuestions, setConsentQuestions] = useState<any[]>([]);
  const [questionOptionsLoaded, setQuestionOptionsLoaded] =
    useState<any>(false);
  const [obj, setObj] = useState<any>({} as ISurveyTemplate);

  const params = useParams();
  const { surveyTemplateId } = params;

  const navigate = useNavigate();

  const initialFValues: ISurveyTemplate = {
    surveyTemplateName: "",
    surveyTemplateTypeId: "",
    surveyTemplateTypeIdTextSearch: "",
    introductionQuestionId: "",
    introductionQuestionIdTextSearch: "",
    consentQuestionId: "",
    consentQuestionIdTextSearch: "",
    puwloQuestionId: "",
    puwloQuestionIdTextSearch: "",
    errorQuestionId: "",
    errorQuestionIdTextSearch: "",
    almostDoneQuestionId: "",
    almostDoneQuestionIdTextSearch: "",
    contactLaterOutroQuestionId: "",
    contactLaterOutroQuestionIdTextSearch: "",
    noConsentOutroQuestionId: "",
    noConsentOutroQuestionIdTextSearch: "",
    completedOutroQuestionId: "",
    completedOutroQuestionIdTextSearch: "",
    questionIds: [],
  };

  useEffect(() => {
    setAppLoading(true);
    API.get(API_URLS.GetSurveyTemplateTypes, {
      params: {},
    }).then((response: any) => {
      const templateTypeOptions = response.data.data.map((x: any) => {
        return { id: x.surveyTemplateTypeId, label: x.surveyTemplateTypeName };
      });
      setSurveyTemplateTypes(templateTypeOptions);
    });

    API.get(API_URLS.GetQuestionsForSurveyTemplateSetUp, {
      params: {},
    })
      .then((response: any) => {
        const allQuestionOptions = response.data.data.map((x: any) => {
          return {
            id: x.questionId,
            key: x.questionId,
            title: x.questionText,
            internalId: x.internalId,
            label: x.label,
            questionText: x.questionText,
            type: x.quetionTypeName,
          };
        });
        setAllQuestions(allQuestionOptions);

        const consentOptions = response.data.data
          .filter((question: any) => question.quetionTypeName === "Consent")
          .map((x: any) => {
            return {
              id: x.questionId,
              key: x.questionId,
              title: x.questionText,
              internalId: x.internalId,
              label: x.label,
              questionText: x.questionText,
            };
          });
        setConsentQuestions(consentOptions);

        const introductionOptions = response.data.data
          .filter(
            (question: any) => question.quetionTypeName === "Introduction",
          )
          .map((x: any) => {
            return {
              id: x.questionId,
              key: x.questionId,
              title: x.questionText,
              internalId: x.internalId,
              label: x.label,
              questionText: x.questionText,
            };
          });
        setIntroductionQuestions(introductionOptions);

        const callbackIntroOptions = response.data.data
          .filter(
            (question: any) => question.quetionTypeName === "Callback intro",
          )
          .map((x: any) => {
            return {
              id: x.questionId,
              key: x.questionId,
              title: x.questionText,
              internalId: x.internalId,
              label: x.label,
              questionText: x.questionText,
            };
          });
        setCallbackIntroQuestions(callbackIntroOptions);

        const errorOptions = response.data.data
          .filter((question: any) => question.quetionTypeName === "Error")
          .map((x: any) => {
            return {
              id: x.questionId,
              key: x.questionId,
              title: x.questionText,
              internalId: x.internalId,
              label: x.label,
              questionText: x.questionText,
            };
          });
        setErrorQuestions(errorOptions);

        const almostDoneOptions = response.data.data
          .filter((question: any) => question.quetionTypeName === "Almost Done")
          .map((x: any) => {
            return {
              id: x.questionId,
              key: x.questionId,
              title: x.questionText,
              internalId: x.internalId,
              label: x.label,
              questionText: x.questionText,
            };
          });
        setAlmostDoneQuestions(almostDoneOptions);

        const noConsentOptions = response.data.data
          .filter(
            (question: any) => question.quetionTypeName === "No Consent Outro",
          )
          .map((x: any) => {
            return {
              id: x.questionId,
              key: x.questionId,
              title: x.questionText,
              internalId: x.internalId,
              label: x.label,
              questionText: x.questionText,
            };
          });
        setNoConsentOutros(noConsentOptions);

        const contactLaterOptions = response.data.data
          .filter(
            (question: any) =>
              question.quetionTypeName === "Contact Later Outro",
          )
          .map((x: any) => {
            return {
              id: x.questionId,
              key: x.questionId,
              title: x.questionText,
              internalId: x.internalId,
              label: x.label,
              questionText: x.questionText,
            };
          });
        setContactLaterOutros(contactLaterOptions);

        const completedOptions = response.data.data
          .filter(
            (question: any) => question.quetionTypeName === "Completed Outro",
          )
          .map((x: any) => {
            return {
              id: x.questionId,
              key: x.questionId,
              title: x.questionText,
              internalId: x.internalId,
              label: x.label,
              questionText: x.questionText,
            };
          });
        setCompletedOutros(completedOptions);

        const coreQuestionOptions = response.data.data
          .filter(
            (question: any) =>
              question.quetionTypeName === "Segmentation" ||
              question.quetionTypeName === "Core" ||
              question.quetionTypeName === "Anti-coaching",
          )
          .map((x: any) => {
            return {
              id: x.questionId,
              key: x.questionId,
              internalId: x.internalId,
              questionText: x.questionText,
              label: x.label,
              indicator: x.indicator,
              indicatorFamily: x.indicatorFamily,
              isIncluded: false,
              type: x.quetionTypeName,
            };
          });
        setAnswerableQuestions(coreQuestionOptions);
        setQuestionOptionsLoaded(true);
      })
      .finally(() => {
        setAppLoading(false);
      });

    return () => {
      // Remove all the field errors and reset the form
      setObj({} as ISurveyTemplate);
      resetForm();
    };
  }, []);

  useEffect(() => {
    if (surveyTemplateId && questionOptionsLoaded) {
      setAppLoading(true);
      API.get(API_URLS.GetSurveyTemplate, {
        params: {
          surveyTemplateId,
        },
      })
        .then((response: any) => {
          const data = response?.data;
          values.surveyTemplateName = data.surveyTemplateName;
          values.surveyTemplateTypeId = data.surveyTemplateTypeId;
          values.surveyTemplateTypeIdTextSearch = data.surveyTemplateTypeName;

          const introQ = data.questions.find(
            (x: any) => x.questionTypeName === "Introduction",
          );
          if (introQ) {
            values.introductionQuestionId = introQ.questionId;
            values.introductionQuestionIdTextSearch = introQ.questionText;
          }

          const consentQ = data.questions.find(
            (x: any) => x.questionTypeName === "Consent",
          );
          if (consentQ) {
            values.consentQuestionId = consentQ.questionId;
            values.consentQuestionIdTextSearch = consentQ.questionText;
          }

          const puwloQ = data.questions.find(
            (x: any) => x.questionTypeName === "Callback intro",
          );
          if (puwloQ) {
            values.puwloQuestionId = puwloQ.questionId;
            values.puwloQuestionIdTextSearch = puwloQ.questionText;
          }

          const errorQ = data.questions.find(
            (x: any) => x.questionTypeName === "Error",
          );
          if (errorQ) {
            values.errorQuestionId = errorQ.questionId;
            values.errorQuestionIdTextSearch = errorQ.questionText;
          }

          const almostDoneQ = data.questions.find(
            (x: any) => x.questionTypeName === "Almost Done",
          );
          if (almostDoneQ) {
            values.almostDoneQuestionId = almostDoneQ.questionId;
            values.almostDoneQuestionIdTextSearch = almostDoneQ.questionText;
          }

          const contactLaterOutroQ = data.questions.find(
            (x: any) => x.questionTypeName === "Contact Later Outro",
          );
          if (contactLaterOutroQ) {
            values.contactLaterOutroQuestionId = contactLaterOutroQ.questionId;
            values.contactLaterOutroQuestionIdTextSearch =
              contactLaterOutroQ.questionText;
          }

          const noConsentOutroQ = data.questions.find(
            (x: any) => x.questionTypeName === "No Consent Outro",
          );
          if (noConsentOutroQ) {
            values.noConsentOutroQuestionId = noConsentOutroQ.questionId;
            values.noConsentOutroQuestionIdTextSearch =
              noConsentOutroQ.questionText;
          }

          const completedOutroQ = data.questions.find(
            (x: any) => x.questionTypeName === "Completed Outro",
          );
          if (completedOutroQ) {
            values.completedOutroQuestionId = completedOutroQ.questionId;
            values.completedOutroQuestionIdTextSearch =
              completedOutroQ.questionText;
          }

          const newValues = { ...values };
          setDataValues(newValues);

          const newQs = answerableQuestions.map((x: any) => {
            const isIncluded =
              data.questions.findIndex((y: any) => y.questionId === x.id) > -1;

            return {
              id: x.id,
              key: x.key,
              internalId: x.internalId,
              label: x.label,
              questionText: x.questionText,
              indicator: x.indicator,
              indicatorFamily: x.indicatorFamily,
              isIncluded,
              type: x.type,
            };
          });
          setAnswerableQuestions(newQs);

          const surveyTemplateQuestionsFromDB = data.questions.map((x: any) => {
            const type = x.questionTypeName;
            let isSurveySetup = 0;

            if (
              x.questionTypeName !== "Anti-coaching" &&
              x.questionTypeName !== "Segmentation" &&
              x.questionTypeName !== "Core"
            ) {
              isSurveySetup = 1;
            }

            return {
              order: x.orderNumber,
              id: x.questionId,
              internalId: x.internalId,
              questionText: x.questionText,
              label: x.label,
              type,
              isSurveySetup,
            };
          });

          setSurveyTemplateQuestions(
            surveyTemplateQuestionsFromDB.sort(
              (a: any, b: any) => a.order - b.order,
            ),
          );
        })
        .finally(() => {
          setAppLoading(false);
        });
    } else {
      // If no ID in parameters, reset the form
      resetForm();
      setObj({} as ISurveyTemplate);
      // Remove questions from the list
      setSurveyTemplateQuestions([]);
      const questions = answerableQuestions.map((x) => {
        x.isIncluded = false;
        return x;
      });
      setAnswerableQuestions(questions);
    }
  }, [surveyTemplateId, questionOptionsLoaded]);

  const validate = (fieldValues = values) => {
    if ("surveyTemplateName" in fieldValues)
      obj.surveyTemplateName = fieldValues.surveyTemplateName
        ? ""
        : "Survey template name is required.";
    if ("surveyTemplateTypeId" in fieldValues)
      obj.surveyTemplateTypeId = fieldValues.surveyTemplateTypeId
        ? ""
        : "Survey template type is required.";
    if ("introductionQuestionId" in fieldValues)
      obj.introductionQuestionId = fieldValues.introductionQuestionId
        ? ""
        : "Introduction is required.";
    if ("consentQuestionId" in fieldValues)
      obj.consentQuestionId = fieldValues.consentQuestionId
        ? ""
        : "Consent question is required.";
    if ("puwloQuestionId" in fieldValues)
      obj.puwloQuestionId = fieldValues.puwloQuestionId
        ? ""
        : "Callback intro is required.";
    if ("errorQuestionId" in fieldValues)
      obj.errorQuestionId = fieldValues.errorQuestionId
        ? ""
        : "Error is required.";
    if ("almostDoneQuestionId" in fieldValues)
      obj.almostDoneQuestionId = fieldValues.almostDoneQuestionId
        ? ""
        : "Almost done is required.";
    if ("contactLaterOutroQuestionId" in fieldValues)
      obj.contactLaterOutroQuestionId = fieldValues.contactLaterOutroQuestionId
        ? ""
        : "Contact later outro is required.";
    if ("noConsentOutroQuestionId" in fieldValues)
      obj.noConsentOutroQuestionId = fieldValues.noConsentOutroQuestionId
        ? ""
        : "No consent outro is required.";
    if ("completedOutroQuestionId" in fieldValues)
      obj.completedOutroQuestionId = fieldValues.completedOutroQuestionId
        ? ""
        : "Completed outro is required.";
    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange, resetForm, setDataValues } =
    useForm(initialFValues, true, validate);

  const validateNumberOfQuestions = () => {
    if (surveyTemplateQuestions.filter((x) => x.order !== 0).length < 1) {
      ErrorToast(
        "You  must include at least one question in the survey template.",
      );
      return false;
    }

    return true;
  };

  const includeQuestion = (e: SyntheticEvent, id: string) => {
    const question = answerableQuestions.find((x) => x.id === id);
    question.isIncluded = true;
    addQuestion(id);
    handleInputChange(e);
    setAnswerableQuestions(answerableQuestions);
  };

  const addQuestion = (id: string) => {
    let newQuestion = {};
    const question = allQuestions.find((x) => x.id === id);
    let orderNumber = 0;

    if (
      question.type === "Core" ||
      question.type === "Anti-coaching" ||
      question.type === "Segmentation"
    ) {
      orderNumber =
        surveyTemplateQuestions.filter((x) => x.order !== 0).length + 1;
    }

    newQuestion = {
      order: orderNumber,
      id,
      internalId: question.internalId,
      questionText: question.questionText,
      label: question.label,
    };

    surveyTemplateQuestions.push(newQuestion);
    surveyTemplateQuestions.sort((a, b) => a.order - b.order);
    setSurveyTemplateQuestions(surveyTemplateQuestions);
  };

  const excludeQuestion = (e: SyntheticEvent, id: string) => {
    const question = answerableQuestions.find((x) => x.id === id);
    question.isIncluded = false;
    removeQuestionWithFormEvent(e, id);
    setAnswerableQuestions(answerableQuestions);
  };

  const removeQuestionWithFormEvent = (e: SyntheticEvent, id: string) => {
    removeQuestion(id);
    (e.target as HTMLInputElement).name = "removeQuestion";
    handleInputChange(e);
  };

  const removeQuestion = (id: string) => {
    const questionIndex = surveyTemplateQuestions.findIndex((x) => x.id === id);
    for (
      let i = questionIndex + 1;
      i < surveyTemplateQuestions.length;
      i += 1
    ) {
      surveyTemplateQuestions[i].order -= 1;
    }
    surveyTemplateQuestions.splice(questionIndex, 1);
    setSurveyTemplateQuestions(surveyTemplateQuestions);
    const question = answerableQuestions.find((x) => x.id === id);
    question.isIncluded = false;
    setAnswerableQuestions(answerableQuestions);
  };

  const reorderQuestion = (newPosition: any, id: any) => {
    const question = surveyTemplateQuestions.find((x) => x.id === id);

    if (question.order > newPosition) {
      surveyTemplateQuestions
        .filter(
          (x) =>
            x.order >= Number(newPosition) &&
            x.order < question.order &&
            x.id !== id,
        )
        .map((x) => x.order++);
    } else if (question.order < newPosition) {
      surveyTemplateQuestions
        .filter(
          (x) =>
            x.order <= Number(newPosition) &&
            x.order > question.order &&
            x.id !== id,
        )
        .map((x) => x.order--);
    }

    surveyTemplateQuestions
      .filter((x) => x.id === id)
      .map((x) => (x.order = Number(newPosition)));

    surveyTemplateQuestions.sort((a, b) => a.order - b.order);
    setSurveyTemplateQuestions(surveyTemplateQuestions);
  };

  // When selecting an object from any autocomplete form field,
  // this method runs to update the values to be submitted to the API
  const handleAutoCompleteChange = (selectedObject: any, fieldName: string) => {
    values[fieldName] = selectedObject ? selectedObject.id : "";
    if (obj) (obj as any)[fieldName] = "";

    setDataValues(values);
    setErrors(obj);
  };

  // This method is required to update the text search field in the
  // autocomplete components. It is also used to force the field to be blank.
  const handleAutoCompleteTextInputChange = (text: any, field: string) => {
    const fieldName = `${field}TextSearch`;
    values[fieldName] = text;
    setDataValues(values);
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAppLoading(true);
    e.preventDefault();

    if (validate() && validateNumberOfQuestions()) {
      //Clear Survey Setup Elements
      if (surveyTemplateId) {
        surveyTemplateQuestions.forEach((arrayItem: any) => {
          const question = allQuestions.find((x) => x.id === arrayItem.id);
          if (
            question.type !== "Core" &&
            question.type !== "Anti-coaching" &&
            question.type !== "Segmentation"
          ) {
            const index = surveyTemplateQuestions.findIndex(
              (x: any) => x.id === arrayItem.id,
            );
            if (index > -1) {
              surveyTemplateQuestions.splice(index, 1);
            }
          }
        });
      }

      // Add all the survey setup elements to the survey template
      addQuestion(values.introductionQuestionId);
      addQuestion(values.consentQuestionId);
      addQuestion(values.puwloQuestionId);
      addQuestion(values.errorQuestionId);
      addQuestion(values.almostDoneQuestionId);
      addQuestion(values.contactLaterOutroQuestionId);
      addQuestion(values.noConsentOutroQuestionId);
      addQuestion(values.completedOutroQuestionId);

      let apiurl = API_URLS.CreateSurveyTemplate;
      let data = {
        surveyTemplateName: values.surveyTemplateName,
        surveyTemplateTypeId: values.surveyTemplateTypeId,
        questions: surveyTemplateQuestions.map((x: any) => {
          return { questionId: x.id, orderNumber: x.order };
        }),
      };
      if (surveyTemplateId) {
        apiurl = API_URLS.UpdateSurveyTemplate;
        const id = { surveyTemplateId };
        data = { ...data, ...id };
      }
      API.post(apiurl, { ...data })
        .then((res: any) => {
          SuccessToast(res?.data?.message);
          navigate(routePaths.SURVEYTEMPLATES);
          resetForm();
        })
        .finally(() => {
          setAppLoading(false);
        });
    }

    setAppLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view">
        <Grid container>
          <Grid item xs={12} sm={2} md={2} />
          <Grid item xs={10} sm={8} md={8}>
            <Grid container>
              <Grid item xs={12} className="grid-custom-spacing">
                <div className="page-heading-container">
                  <Controls.BackButton />
                  <h2 className="page-heading">{`${
                    surveyTemplateId ? "EDIT" : "ADD "
                  } SURVEY TEMPLATE`}</h2>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.Input
                  id="surveyTemplateName"
                  name="surveyTemplateName"
                  label="Survey Template Name*"
                  value={values.surveyTemplateName}
                  onChange={handleInputChange}
                  error={obj.surveyTemplateName}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.AutoComplete
                  id="surveyTemplateTypeId"
                  name="surveyTemplateTypeId"
                  label="Please select the survey template type*"
                  value={values.surveyTemplateTypeId}
                  inputValue={values.surveyTemplateTypeIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  options={surveyTemplateTypes}
                  error={obj.surveyTemplateTypeId}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.AutoComplete
                  id="introductionQuestionId"
                  name="introductionQuestionId"
                  label="Please select the introduction*"
                  value={values.introductionQuestionId}
                  inputValue={values.introductionQuestionIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  options={introductionQuestions}
                  error={obj.introductionQuestionId}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.AutoComplete
                  id="consentQuestionId"
                  name="consentQuestionId"
                  label="Please select the consent question*"
                  value={values.consentQuestionId}
                  inputValue={values.consentQuestionIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  options={consentQuestions}
                  error={obj.consentQuestionId}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.AutoComplete
                  id="puwloQuestionId"
                  name="puwloQuestionId"
                  label="Please select the callback (PUWLO) introduction*"
                  value={values.puwloQuestionId}
                  inputValue={values.puwloQuestionIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  options={callbackIntroQuestions}
                  error={obj.puwloQuestionId}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.AutoComplete
                  id="errorQuestionId"
                  name="errorQuestionId"
                  label="Please select the error notification*"
                  value={values.errorQuestionId}
                  inputValue={values.errorQuestionIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  options={errorQuestions}
                  error={obj.errorQuestionId}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.AutoComplete
                  id="almostDoneQuestionId"
                  name="almostDoneQuestionId"
                  label="Please select the almost done notification*"
                  value={values.almostDoneQuestionId}
                  inputValue={values.almostDoneQuestionIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  options={almostDoneQuestions}
                  error={obj.almostDoneQuestionId}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.AutoComplete
                  id="noConsentOutroQuestionId"
                  name="noConsentOutroQuestionId"
                  label="Please select the no consent outro*"
                  value={values.noConsentOutroQuestionId}
                  inputValue={values.noConsentOutroQuestionIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  options={noConsentOutros}
                  error={obj.noConsentOutroQuestionId}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.AutoComplete
                  id="contactLaterOutroQuestionId"
                  name="contactLaterOutroQuestionId"
                  label="Please select the contact later outro*"
                  value={values.contactLaterOutroQuestionId}
                  inputValue={values.contactLaterOutroQuestionIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  options={contactLaterOutros}
                  error={obj.contactLaterOutroQuestionId}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.AutoComplete
                  id="completedOutroQuestionId"
                  name="completedOutroQuestionId"
                  label="Please select the completed outro*"
                  value={values.completedOutroQuestionId}
                  inputValue={values.completedOutroQuestionIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  options={completedOutros}
                  error={obj.completedOutroQuestionId}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} className="grid-custom-spacing">
                <br />
                <h3>Question Bank</h3>
                <br />
                <PotentialQuestionTable
                  items={answerableQuestions}
                  includeQuestion={includeQuestion}
                  excludeQuestion={excludeQuestion}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} className="grid-custom-spacing">
                <br />
                <h3>Survey Template</h3>
                <br />
                <SurveyTemplateQuestionTable
                  items={surveyTemplateQuestions}
                  removeQuestion={removeQuestionWithFormEvent}
                  reorderQuestion={reorderQuestion}
                />
              </Grid>
            </Grid>

            <div className="form-buttons custom-floatRight">
              <br />
              <Controls.Button
                className="button blue"
                type="submit"
                text="Save"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={() => {
                  navigate(routePaths.SURVEYTEMPLATES);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={2} md={2} />
        </Grid>
      </div>
    </Form>
  );
}
