import { useEffect, useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { SmallSpinner } from "components/Spinner";
import Controls from "components/Controls";
import { API_URLS } from "config/ApiUrls";
import API from "services/Api.Service";
import { IChartProps } from "models/Interfaces/IMapSite";
import { FaMinus, FaPlus } from "react-icons/fa";
import "./Style.css";

function Filters({
  dataCycleId,
  compareDataCycleId,
  setSegmentationFiltersForReports,
  setFiltersLoadedForReports,
  hideFilter,
}: IChartProps) {
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [segmentationFilters, setSegmentationFilters] = useState<any>([]);

  useEffect(() => {
    setLoading(true);
    if (dataCycleId && compareDataCycleId) {
      API.get(API_URLS.GetSegmentationQuestionsComparison, {
        params: {
          dataCycleId,
          compareDataCycleId,
        },
      })
        .then((response: any) => {
          const sementationQuestionFilters: any = [];
          response?.data?.data.map((segFilter: any) => {
            const segQuestion: any = { options: [] };
            segQuestion.questionId = segFilter.questionId;
            segQuestion.segmentationQuestionId =
              segFilter.segmentationQuestionId;
            segQuestion.chartTitle = segFilter.chartTitle;

            let filterIndex: any = 0;
            Object.keys(segFilter).forEach((key: any) => {
              if (
                key !== "chartTitle" &&
                key !== "questionId" &&
                key !== "segmentationQuestionId" &&
                key !== "questionText"
              ) {
                filterIndex += 1;
                if (segFilter[key]) {
                  const filter = {
                    option: filterIndex,
                    description: segFilter[key],
                    checked: true,
                  };
                  segQuestion.options.push(filter);
                }
              }
            });
            sementationQuestionFilters.push(segQuestion);
            return segFilter;
          });
          setSegmentationFilters(sementationQuestionFilters);
          if (setFiltersLoadedForReports) setFiltersLoadedForReports(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (dataCycleId) {
      API.get(API_URLS.GetSegmentationQuestions, {
        params: {
          dataCycleId,
        },
      })
        .then((response: any) => {
          const sementationQuestionFilters: any = [];

          response?.data?.data.map((segFilter: any) => {
            const segQuestion: any = { options: [] };
            segQuestion.questionId = segFilter.questionId;
            segQuestion.segmentationQuestionId =
              segFilter.segmentationQuestionId;
            segQuestion.chartTitle = segFilter.chartTitle;

            let filterIndex: any = 0;
            Object.keys(segFilter).forEach((key: any) => {
              if (
                key !== "chartTitle" &&
                key !== "questionId" &&
                key !== "segmentationQuestionId" &&
                key !== "questionText"
              ) {
                filterIndex += 1;
                if (segFilter[key]) {
                  const filter = {
                    option: filterIndex,
                    description: segFilter[key],
                    checked: true,
                  };
                  segQuestion.options.push(filter);
                }
              }
            });
            sementationQuestionFilters.push(segQuestion);
            return segFilter;
          });
          setSegmentationFilters(sementationQuestionFilters);
          if (setFiltersLoadedForReports) setFiltersLoadedForReports(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [dataCycleId, compareDataCycleId]);

  useEffect(() => {
    if (segmentationFilters) {
      const segFiltersForReports: any = [];

      segmentationFilters.forEach((filter: any) => {
        const filterForReport: any = {};

        filterForReport.chartTitle = filter.chartTitle;
        filterForReport.questionId = filter.questionId;
        filterForReport.segmentationQuestionId = filter.segmentationQuestionId;

        filter.options.forEach((option: any) => {
          filterForReport[`Keypad${option.option}Description`] = option.checked;
        });

        segFiltersForReports.push(filterForReport);
      });

      if (setSegmentationFiltersForReports)
        setSegmentationFiltersForReports(segFiltersForReports);
    }
  }, [JSON.stringify(segmentationFilters)]);

  const handleCheckBoxChange = (
    e: any,
    parentIndex: number,
    optionIndex: number,
  ) => {
    segmentationFilters[parentIndex].options[optionIndex].checked =
      !e.target.checked;
    setSegmentationFilters([...segmentationFilters]);
  };

  return loading ? (
    <div className="loadingBox">
      <SmallSpinner />
    </div>
  ) : (
    <div className={`filterBox ${hideFilter ? "hidden" : ""}`}>
      <div
        className={`filterBoxBanner ${expanded ? "filterBoxBannerBorder" : ""}`}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <FilterAltIcon style={{ display: "inline-block" }} />
          <p style={{ display: "inline-block" }}>Filters</p>
        </div>
        <div className="siteSummaryBoxIcons">
          <div
            className="contentExpansion"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <div className={expanded ? "hiddenIcon" : "displayedIcon"}>
              <FaPlus />
            </div>
            <div className={expanded ? "displayedIcon" : "hiddenIcon"}>
              <FaMinus />
            </div>
          </div>
        </div>
      </div>
      <div className={`filterBoxContent ${expanded ? "visible" : "invisible"}`}>
        {segmentationFilters.map((segFilter: any, parentIndex: any) => {
          return (
            <>
              <h3>{segFilter.chartTitle}</h3>
              {segFilter.options.length > 0 &&
                segFilter.options.map((filter: any, optionIndex: any) => {
                  return (
                    <Controls.CheckBox
                      className="filterBoxCheckBox"
                      checked={filter.checked}
                      label={filter.description}
                      key={filter.option}
                      value={filter.checked}
                      onChange={(e: any) =>
                        handleCheckBoxChange(e, parentIndex, optionIndex)
                      }
                    />
                  );
                })}
              <br />
            </>
          );
        })}
      </div>
    </div>
  );
}

export default Filters;
