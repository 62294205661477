import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLoading } from "providers/loading";
import { API_URLS } from "config/ApiUrls";
import { UseTable } from "hooks/useTable";
import API from "services/Api.Service";

import "./Style.css";

const LanguagesTable = () => {
  const { setAppLoading } = useLoading();
  const navigate = useNavigate();
  const [languages, setLanguages] = React.useState<any[]>([]);

  useEffect(() => {
    setAppLoading(true);
    API.get(API_URLS.GetLanguages, {
      params: {},
    })
      .then((response: any) => {
        setLanguages(response.data.data);
      })
      .finally(() => {
        setAppLoading(false);
      });
  }, []);

  const navigateToEdit = (languageId: string) => {
    navigate(`/languages/${languageId}/edit-language`);
  };

  const columns = [
    { title: "LANGUAGE NAME", field: "languageLabel" },
    { title: "ISO", field: "iso" },
    {
      title: "MANAGE",
      field: "edit",
      render: (rowData: any) => (
        <div className="actions-container">
          <p onClick={() => navigateToEdit(rowData?.languageId)}>Edit</p>
        </div>
      ),
    },
  ];

  return (
    <div className="tableDiv">
      <UseTable columns={columns} data={languages} title="Languages" />
    </div>
  );
};

export default LanguagesTable;
