import "./Style.css";

function PrioritiesAndDetailedKey() {
  return (
    <div className="resultsKeyContainer">
      <div className="resultsKeyLine">
        <div className="resultsKeyColour green" />
        <div className="resultsKeyText">Positive</div>
      </div>
      <div className="resultsKeyLine">
        <div className="resultsKeyColour yellow" />
        <div className="resultsKeyText">Neutral</div>
      </div>
      <div className="resultsKeyLine">
        <div className="resultsKeyColour red" />
        <div className="resultsKeyText">Negative</div>
      </div>
    </div>
  );
}

export default PrioritiesAndDetailedKey;
