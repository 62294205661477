import { useNavigate } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import "./Style.css";

export default function BackButton() {
  const navigate = useNavigate();

  return (
    <div onClick={() => navigate(-1)} className="back-button">
      <ChevronLeft />
    </div>
  );
}
