import { Grid } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import { Form, useForm } from "hooks/useForm";
import { ILanguage } from "models/Interfaces/ILanguage";
import { useLoading } from "providers/loading";

import "./Style.css";
import { API_URLS } from "config/ApiUrls";
import API from "services/Api.Service";
import { useEffect, useState } from "react";
import Controls from "components/Controls";
import routePaths from "config/RoutePaths";
import { SuccessToast } from "components/Toaster";

let initialFValues: ILanguage = {
  languageId: "",
  languageLabel: "",
  iso: "",
};

export default function AddLanguage() {
  const navigate = useNavigate();
  const { setAppLoading } = useLoading();
  const [obj, setObj] = useState<any>({} as ILanguage);

  const params = useParams();
  const { languageId } = params;
  const pageHeading = languageId ? "Update Language" : "Add Language";
  const validate = (fieldValues = values) => {
    if ("languageLabel" in fieldValues) {
      obj.languageLabel = fieldValues.languageLabel
        ? ""
        : "Language label is required.";
    }

    if ("iso" in fieldValues) {
      if (fieldValues.iso !== "") {
        const isoLength = fieldValues.iso.trim().length;
        if (isoLength !== 3) {
          obj.iso = "Iso must be exactly 3 characters long.";
        } else {
          obj.iso = "";
        }
      } else {
        obj.iso = "";
      }
    }

    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange, setDataValues, resetForm } =
    useForm(initialFValues, true, validate);

  useEffect(() => {
    if (languageId) {
      setAppLoading(true);
      API.get(API_URLS.GetLanguageById, {
        params: {
          LanguageId: languageId,
        },
      })
        .then((response: any) => {
          const result = response?.data;
          setObj({} as ILanguage);
          resetForm();
          values.languageId = result.languageId;
          values.languageLabel = result.languageLabel;
          values.iso = result.iso === null ? "" : result.iso;
          setDataValues(values);
        })
        .finally(() => {
          setAppLoading(false);
        });
    } else {
      initialFValues = {
        languageId: "",
        languageLabel: "",
        iso: "",
      };
      setDataValues(initialFValues);
    }
  }, []);

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (validate()) {
      const apiurl = languageId
        ? API_URLS.UpdateLanguage
        : API_URLS.CreateLanguage;
      setAppLoading(true);
      API.post(apiurl, {
        ...values,
        languageId,
      })
        .then((response: any) => {
          SuccessToast(response?.data?.message);
          setObj({} as ILanguage);
          resetForm();
          navigate(routePaths.LANGUAGES);
        })
        .catch((error: any) => {
          setAppLoading(false);
        })
        .finally(() => {
          setAppLoading(false);
        });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view">
        <Grid container>
          <Grid item xs={12} sm={2} md={2} />
          <Grid item xs={10} sm={8} md={8}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <div className="page-heading-container">
                  <Controls.BackButton />
                  <h2 className="page-heading">{pageHeading}</h2>
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controls.Input
                  id="languageLabel"
                  name="languageLabel"
                  label="Language Label*"
                  value={values.languageLabel}
                  onChange={handleInputChange}
                  error={obj.languageLabel}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controls.Input
                  id="iso"
                  name="iso"
                  label="Iso"
                  value={values.iso}
                  onChange={handleInputChange}
                  error={obj.iso}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className="form-buttons custom-floatRight">
                  <br />
                  <Controls.Button
                    className="button blue"
                    type="submit"
                    text="Save"
                  />
                  <Controls.Button
                    text="Cancel"
                    className="cancel-button"
                    variant="outlined"
                    onClick={() => navigate(routePaths.LANGUAGES)}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={2} md={2} />
        </Grid>
      </div>
    </Form>
  );
}
