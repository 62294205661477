import React from "react";
import Controls from "../../../components/Controls";
import { IMarkDataCycleComplete } from "../../../models/Interfaces/IDataCycle";
import "./Style.css";

type HideFiltersProps = {
  data: IMarkDataCycleComplete;
  toggleHideFiltersModal: (rowData: any) => void;
  setHideFiltersModal: React.Dispatch<React.SetStateAction<boolean>>;
  toggleFilters: (values: IMarkDataCycleComplete) => void;
};

// New modal used when togglings filters for a survey cycle
export const ToggleFilters = (props: HideFiltersProps) => {
  const { toggleHideFiltersModal, setHideFiltersModal, toggleFilters, data } =
    props;

  return (
    <div className="modal">
      <div onClick={toggleHideFiltersModal} className="overlay" />
      <div className="modal-content">
        <p>
          {`You are going to ${
            data.hideFilters ? "unhide" : "hide"
          } filters from the end user. Are you sure?`}
        </p>
        <br />
        <div className="form-buttons custom-floatRight">
          <Controls.Button
            className="button blue"
            text={data.hideFilters ? "Unhide" : "Hide"}
            onClick={() => toggleFilters(data)}
          />
          <Controls.Button
            text="Cancel"
            className="cancel-button"
            variant="outlined"
            onClick={() => setHideFiltersModal(false)}
          />
        </div>
      </div>
    </div>
  );
};
