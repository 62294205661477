import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Controls from "components/Controls";
import { API_URLS } from "config/ApiUrls";
import { useLoading } from "providers/loading";
import API from "services/Api.Service";
import "./Style.css";
import { RolesEnum } from "models/Enum/Roles";
import { useAuth } from "lib/auth";
import SiteList from "./SiteList";
import AvgSiteScore from "../Map/AvgSiteScore";

function ListView() {
  const location: any = useLocation();
  const organisationId = location.state.organisationId
    ? location.state.organisationId
    : "";
  const clientId = location.state.clientId ? location.state.clientId : "";
  const orgName = location.state.orgName ? location.state.orgName : "";
  const clientName = location.state.clientName ? location.state.clientName : "";
  const clientOrgIds =
    location.state.clientOrgIds.length > 0 ? location.state.clientOrgIds : [];
  const [selectedUserId, setSelectedUserId] = useState(
    clientId === undefined || clientId === ""
      ? "00000000-0000-0000-0000-000000000000"
      : clientId,
  );
  const [selectedUserName, setSelectedUserName] = useState(clientName);
  const [selectedOrgId, setSelectedOrgId] = useState(organisationId);
  const [selectedOrgName, setSelectedOrgName] = useState(orgName);
  const [users, setUsers] = useState<any>([]);
  const [organisations, setOrganisations] = useState<any>([]);
  const [surveyTemplateTypes, setSurveyTemplateTypes] = useState<any>([]);
  const [filteredOrganisations, setFilteredOrganisations] = useState<any>([]);
  const [organisationSites, setOrganisationSites] = useState<any>([]);
  const [groups, setGroups] = useState<any>([]);
  const [avgSiteScore, setAvgSiteScore] = useState<any>(null);
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [selectedGroupName, setSelectedGroupName] = useState("");
  const [groupScore, setGroupScore] = useState(-1);
  const [selectedSurveyTemplateTypeId, setSelectedSurveyTemplateTypeId] =
    useState("");
  const [surveyTemplateTypeName, setSurveyTemplateTypeName] = useState("");
  const { setAppLoading } = useLoading();

  const auth = useAuth();

  const isAdmin = auth?.user?.data?.roles?.includes(RolesEnum.Admin);

  useEffect(() => {
    if (isAdmin) {
      API.get(API_URLS.GetTags, {
        params: {},
      }).then((response: any) => {
        const groupOptions = response.data.data.map((x: any) => {
          return { id: x.tagId, label: x.tagName };
        });
        setGroups(groupOptions);
      });
    } else {
      const groupOptions = auth?.user?.data?.userTags.map((x: any) => {
        return {
          id: x.tagId,
          label: x.tagName,
        };
      });
      setGroups(groupOptions);
    }
  }, [isAdmin]);

  useEffect(() => {
    setAppLoading(true);

    const fetchData = async () => {
      try {
        const getTypes = API.get(API_URLS.GetSurveyTemplateTypes, {
          params: {
            organisationId,
            userId: clientId != null ? clientId : "",
          },
        }).then((response: any) => {
          const templateTypeOptions = response.data.data.map((x: any) => {
            return {
              id: x.surveyTemplateTypeId,
              label: x.surveyTemplateTypeName,
              code: x.surveyTemplateTypeCode,
            };
          });
          setSurveyTemplateTypes(templateTypeOptions);
          const currentTemplateType = templateTypeOptions[0];
          if (currentTemplateType) {
            setSelectedSurveyTemplateTypeId(currentTemplateType.id);
            setSurveyTemplateTypeName(currentTemplateType.label);
          }
        });

        const getOrgs = API.get(API_URLS.GetOrganisationsWithSites, {
          params: {
            OrderBy: "OrganisationId",
            SearchValue: null,
          },
        }).then((response: any) => {
          const orgs = response.data.data.map((x: any) => {
            return { id: x.organisationId, label: x.organisationName };
          });

          let organisationOptions = [];

          if (!isAdmin) {
            if (auth?.user?.data?.userOrganisations) {
              if (auth?.user?.data?.userOrganisations?.length > 1) {
                const userOrgIds = auth?.user?.data?.userOrganisations.map(
                  (x: any) => {
                    return x.organisationId;
                  },
                );
                organisationOptions = orgs.filter((x: any) => {
                  return userOrgIds.includes(x.id);
                });
              } else {
                const userOrgIds = auth?.user?.data?.userOrganisations.map(
                  (x: any) => {
                    return x.organisationId;
                  },
                );
                organisationOptions = orgs.filter((x: any) => {
                  return userOrgIds.includes(x.id);
                });
              }
            }

            organisationOptions = [
              {
                id: "all",
                label: "All Businesses",
              },
              ...organisationOptions,
            ];
          } else {
            if (selectedUserId !== "") {
              organisationOptions = orgs.filter((x: any) => {
                return clientOrgIds.includes(x.id);
              });
            }

            organisationOptions = [
              {
                id: "all",
                label: "All Businesses",
              },
              ...organisationOptions,
            ];
          }
          setFilteredOrganisations(organisationOptions);
          setOrganisations(orgs);
        });

        const getUsers = API.get(API_URLS.GetUserList, {
          params: {},
        }).then((response: any) => {
          const userList = response.data.data
            .filter((x: any) => {
              return x.userOrganisationDetails.length > 0;
            })
            .map((x: any) => {
              return {
                id: x.userId,
                label: x.userName,
                orgs: x.userOrganisationDetails.map((y: any) => {
                  return y.organisationId;
                }),
              };
            });
          setUsers(userList);
        });

        // Wait for all requests to complete
        await Promise.all([getOrgs, getUsers, getTypes]);
      } finally {
        // All requests completed, set isLoading to false
        // setAppLoading(false);
      }
    };

    fetchData();

    return () => {
      setAppLoading(false);
    };
  }, []);

  useEffect(() => {
    if (selectedOrgId && selectedOrgId !== "") {
      setAppLoading(true);
      API.get(API_URLS.GetSitesForList, {
        params: {
          organisationId: selectedOrgId,
          clientId: selectedUserId,
        },
      })
        .then((response: any) => {
          setOrganisationSites(response.data.data);
          setAvgSiteScore(response.data.averageSiteScore);
        })
        .finally(() => {
          setAppLoading(false);
        });
    }

    return () => {
      setAppLoading(false);
    };
  }, [selectedUserId, selectedOrgId]);

  useEffect(() => {
    if (
      selectedGroupId !== "" &&
      selectedGroupName !== "" &&
      selectedSurveyTemplateTypeId !== "" &&
      surveyTemplateTypeName !== ""
    ) {
      setAppLoading(true);
      API.get(API_URLS.GetTaggedSites, {
        params: {
          tagId: selectedGroupId,
          surveyTemplateTypeId: selectedSurveyTemplateTypeId,
        },
      })
        .then((response: any) => {
          if (response.data.averageSiteScore > 0) {
            setGroupScore(response.data.averageSiteScore);
          }
        })
        .finally(() => {
          setAppLoading(false);
        });
    } else {
      setGroupScore(-1);
    }
  }, [
    selectedGroupId,
    selectedGroupName,
    selectedSurveyTemplateTypeId,
    surveyTemplateTypeName,
  ]);

  const handleSelectUser = (selectedObject: any) => {
    setSelectedUserId(selectedObject.id);
    setSelectedUserName(selectedObject.label);
    setSelectedOrgId("");
    setSelectedOrgName("");
    updateFilteredOrganisations(selectedObject);
  };

  const handleSelectUserTextInputChange = (text: any) => {
    setSelectedUserName(text);
  };

  const handleSelectOrg = (selectedObject: any) => {
    setSelectedOrgId(selectedObject.id);
    setSelectedOrgName(selectedObject.label);
  };

  const handleSelectOrgTextInputChange = (text: any) => {
    setSelectedOrgName(text);
  };

  // Filter the organisations when the user is selected
  const updateFilteredOrganisations = (user: any) => {
    if (!user) {
      setFilteredOrganisations(organisations);
      return;
    }

    let organisationOptions = organisations.filter((x: any) => {
      return user.orgs.includes(x.id);
    });

    organisationOptions = [
      {
        id: "all",
        label: "All Businesses",
      },
      ...organisationOptions,
    ];

    setFilteredOrganisations(organisationOptions);
  };

  const handleSelectGroup = (selectedObject: any) => {
    setSelectedGroupId(selectedObject.id);
    setSelectedGroupName(selectedObject.label);
  };

  const handleSelectGroupTextInputChange = (text: any) => {
    setSelectedGroupName(text);
  };

  const handleSelectSurveyTemplate = (selectedObject: any) => {
    setSelectedSurveyTemplateTypeId(selectedObject.id);
    setSurveyTemplateTypeName(selectedObject.label);
  };

  const handleSelectSurveyTemplateTextInputChange = (text: any) => {
    setSurveyTemplateTypeName(text);
  };

  return (
    <div className="customPannel">
      <Grid container>
        <Grid item xs={12} sm={2} md={2} />
        <Grid item xs={12} sm={8} md={8}>
          <Grid container>
            <Grid item xs={12}>
              <div className="page-heading-container">
                <Controls.BackButton />
                <h2 className="page-heading">Your workplaces</h2>
              </div>
            </Grid>
            <Grid item xs={12}>
              {isAdmin && (
                <Controls.AutoComplete
                  id="clientId"
                  name="clientId"
                  label="Select a user"
                  value={selectedUserId}
                  inputValue={selectedUserName}
                  onChange={handleSelectUser}
                  onInputChange={handleSelectUserTextInputChange}
                  varient="standard"
                  options={users}
                />
              )}
              <Controls.AutoComplete
                id="organisationId"
                name="organisationId"
                label="Select a business*"
                value={selectedOrgId}
                inputValue={selectedOrgName}
                onChange={handleSelectOrg}
                onInputChange={handleSelectOrgTextInputChange}
                varient="standard"
                options={filteredOrganisations}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <AvgSiteScore
              avgSiteScore={avgSiteScore}
              groups={groups}
              handleSelectGroup={handleSelectGroup}
              handleSelectGroupTextInputChange={
                handleSelectGroupTextInputChange
              }
              selectedGroupId={selectedGroupId}
              selectedGroupName={selectedGroupName}
              handleSelectSurveyTemplate={handleSelectSurveyTemplate}
              handleSelectSurveyTemplateTextInputChange={
                handleSelectSurveyTemplateTextInputChange
              }
              selectedSurveyTemplateTypeId={selectedSurveyTemplateTypeId}
              surveyTemplateTypeName={surveyTemplateTypeName}
              surveyTemplateTypes={surveyTemplateTypes}
              groupScore={groupScore}
              listView={true}
            />
          </Grid>
          <br />
          <Grid item xs={12}>
            <SiteList
              sites={organisationSites}
              clientId={selectedUserId}
              selectedOrgId={selectedOrgId}
              avgSiteScore={avgSiteScore}
              benchmarksEnabled={
                auth?.user?.data?.benchmarksEnabled
                  ? auth?.user?.data?.benchmarksEnabled
                  : false
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={2} md={2} />
      </Grid>
    </div>
  );
}

export default ListView;
