import { Routes, Route } from "react-router-dom";
import routePaths from "config/RoutePaths";
import ListView from "./ListView";
import SiteMaps from "./Map";
import OrganisationSelect from "./OrganisationSelect";
import ReportingTabs from "./ReportingTabs";

export const ReportsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<OrganisationSelect />} />
      <Route path={routePaths.REPORTINGTABS} element={<ReportingTabs />} />
      <Route path={routePaths.MAPS} element={<SiteMaps />} />
      <Route path={routePaths.LISTVIEW} element={<ListView />} />
    </Routes>
  );
};
