import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { Form, useForm } from "hooks/useForm";
import Controls from "components/Controls";
import { ISubSector } from "models/Interfaces/IOrganisation";
import API from "services/Api.Service";
import routePaths from "config/RoutePaths";
import { API_URLS } from "config/ApiUrls";
import { SuccessToast } from "components/Toaster";
import { useLoading } from "providers/loading";
import "./Style.css";

export default function CreateSubSector() {
  const { setAppLoading } = useLoading();
  const navigate = useNavigate();
  const [sectors, setSectors] = useState([]);
  const [obj, setObj] = useState<any>({} as ISubSector);

  const initialFValues: ISubSector = {
    sectorId: "",
    section: "",
    sectionLabel: "",
    division: "",
    divisionLabel: "",
    group: "",
    groupLabel: "",
    class: "",
    fullCode: "",
    description: "",
  };

  // useEffect loads all the options into the stateful variables declared above
  useEffect(() => {
    API.get(API_URLS.GetSectors, {
      params: {},
    }).then((response: any) => {
      const sectorOptions = response.data.data.map((x: any) => {
        return { id: x.sectorId, label: x.sectorSection };
      });
      setSectors(sectorOptions);
    });

    return () => {
      // Remove all the field errors and reset the form
      setObj({} as ISubSector);
      resetForm();
    };
  }, []);

  const validate = (fieldValues = values) => {
    if ("sectorId" in fieldValues)
      obj.sectorId = fieldValues.sectorId ? "" : "Sector is required.";
    if ("section" in fieldValues)
      obj.section = fieldValues.section ? "" : "Section is required.";
    if ("sectionLabel" in fieldValues)
      obj.sectionLabel = fieldValues.sectionLabel
        ? ""
        : "Section label is required.";
    if ("division" in fieldValues)
      obj.division = fieldValues.division ? "" : "Division is required.";
    if ("divisionLabel" in fieldValues)
      obj.divisionLabel = fieldValues.divisionLabel
        ? ""
        : "Division label is required.";
    if ("group" in fieldValues)
      obj.group = fieldValues.group ? "" : "Group is required.";
    if ("groupLabel" in fieldValues)
      obj.groupLabel = fieldValues.groupLabel ? "" : "Group label is required.";
    if ("class" in fieldValues)
      obj.class = fieldValues.class ? "" : "Class is required.";
    if ("fullCode" in fieldValues)
      obj.fullCode = fieldValues.fullCode ? "" : "Full code is required.";
    if ("description" in fieldValues)
      obj.description = fieldValues.description ? "" : "Full code is required.";
    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange, resetForm, setDataValues } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (validate()) {
      setAppLoading(true);
      const url = API_URLS.CreateSubSector;
      API.post(url, {
        sectorId: values.sectorId,
        section: values.section,
        sectionLabel: values.sectionLabel,
        division: values.division,
        divisionLabel: values.divisionLabel,
        group: values.group,
        groupLabel: values.groupLabel,
        class: values.class,
        fullCode: values.fullCode,
        description: values.description,
      })
        .then((res: any) => {
          SuccessToast(res?.data.message);
          // Remove all the field errors and reset the form
          setObj({} as ISubSector);
          resetForm();
          // Navigate to subsectors screen
          navigate(routePaths.SUBSECTORS);
        })
        .finally(() => {
          setAppLoading(false);
        });
    }
  };

  // This method is required to update the text search field in the
  // autocomplete components. It is also used to force the field to be blank.
  const handleAutoCompleteTextInputChange = (text: any, field: string) => {
    const fieldName = `${field}TextSearch`;
    values[fieldName] = text;
    setDataValues(values);
  };

  // When selecting an object from any autocomplete form field,
  // this method runs to update the values to be submitted to the API
  const handleAutoCompleteChange = (selectedObject: any, fieldName: string) => {
    values[fieldName] = selectedObject ? selectedObject.id : "";
    if (obj) (obj as any)[fieldName] = "";
    setDataValues(values);
    setErrors(obj);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view">
        <Grid container>
          <Grid item xs={12} sm={2} md={2} />
          <Grid item xs={10} sm={8} md={8}>
            <Grid container>
              <Grid item xs={12} className="grid-custom-spacing">
                <div className="page-heading-container">
                  <Controls.BackButton />
                  <h2 className="page-heading">Add Sub Sector</h2>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Controls.AutoComplete
                  id="sectorId"
                  name="sectorId"
                  label="Sector*"
                  inputValue={values.sectorIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  varient="standard"
                  options={sectors}
                  error={obj.sectorId}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.Input
                  id="section"
                  name="section"
                  label="Section*"
                  value={values.section}
                  onChange={handleInputChange}
                  error={obj.section}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.Input
                  id="sectionLabel"
                  name="sectionLabel"
                  label="Section Label*"
                  value={values.sectionLabel}
                  onChange={handleInputChange}
                  error={obj.sectionLabel}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.Input
                  id="division"
                  name="division"
                  label="Division*"
                  value={values.division}
                  onChange={handleInputChange}
                  error={obj.division}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.Input
                  id="divisionLabel"
                  name="divisionLabel"
                  label="Division Label*"
                  value={values.divisionLabel}
                  onChange={handleInputChange}
                  error={obj.divisionLabel}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.Input
                  id="group"
                  name="group"
                  label="Group*"
                  value={values.group}
                  onChange={handleInputChange}
                  error={obj.group}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.Input
                  id="groupLabel"
                  name="groupLabel"
                  label="Group Label*"
                  value={values.groupLabel}
                  onChange={handleInputChange}
                  error={obj.groupLabel}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.Input
                  id="class"
                  name="class"
                  label="Class*"
                  value={values.class}
                  onChange={handleInputChange}
                  error={obj.class}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.Input
                  id="fullCode"
                  name="fullCode"
                  label="Full Code*"
                  value={values.fullCode}
                  onChange={handleInputChange}
                  error={obj.fullCode}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="grid-custom-spacing">
                <Controls.Input
                  id="description"
                  name="description"
                  label="Description*"
                  value={values.description}
                  onChange={handleInputChange}
                  error={obj.description}
                />
              </Grid>
            </Grid>

            <div className="form-buttons custom-floatRight">
              <br />
              <Controls.Button
                className="button blue"
                type="submit"
                text="Save"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={() => {
                  navigate(routePaths.DASHBOARD);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={2} md={2} />
        </Grid>
      </div>
    </Form>
  );
}
