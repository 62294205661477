import Controls from "components/Controls";
import "./Style.css";

function AvgSiteScore(params: any) {
  return (
    <div className="benchmarkBox">
      <div className={`benchmarkBoxBanner`}>
        <div>
          <p style={{ display: "inline-block", paddingLeft: "5px" }}>
            Your average site score
          </p>
        </div>
        <div
          className={`avgSiteScoreCircle ${
            params.avgSiteScore < 0
              ? "scoreZero"
              : params.avgSiteScore >= 0 && params.avgSiteScore <= 5
              ? "scoreLow"
              : params.avgSiteScore > 5 && params.avgSiteScore <= 7.5
              ? "scoreMedium"
              : "scoreHigh"
          }`}
        >
          {params.avgSiteScore < 0 ? "N/A" : params.avgSiteScore}
        </div>
      </div>
      {params.listView && params.groups && params.groups.length > 0 && (
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <Controls.AutoComplete
            id="groupId"
            name="groupId"
            label="Select a group to see benchmarks*"
            value={params.selectedGroupId}
            inputValue={params.selectedGroupName}
            onChange={params.handleSelectGroup}
            onInputChange={params.handleSelectGroupTextInputChange}
            options={params.groups}
          />
        </div>
      )}
      {params.listView && (
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <Controls.AutoComplete
            id="surveyTemplateTypeId"
            name="surveyTemplateTypeId"
            label="Select a survey template type*"
            value={params.selectedSurveyTemplateTypeId}
            inputValue={params.surveyTemplateTypeName}
            onChange={params.handleSelectSurveyTemplate}
            onInputChange={params.handleSelectSurveyTemplateTextInputChange}
            options={params.surveyTemplateTypes}
          />
        </div>
      )}
      {params.groupScore && params.groupScore > 0 && (
        <div
          className={`benchmarkBoxBanner`}
          style={{ borderTop: params.listView ? "" : "1px solid black" }}
        >
          <div>
            <p style={{ display: "inline-block", paddingLeft: "5px" }}>
              Group average site score
            </p>
          </div>
          <div
            className={`avgSiteScoreCircle ${
              params.groupScore < 0
                ? "scoreZero"
                : params.groupScore >= 0 && params.groupScore <= 5
                ? "scoreLow"
                : params.groupScore > 5 && params.groupScore <= 7.5
                ? "scoreMedium"
                : "scoreHigh"
            }`}
          >
            {params.groupScore < 0 ? "N/A" : params.groupScore}
          </div>
        </div>
      )}
      {params.selectedGroupId !== null &&
        params.selectedGroupId !== "" &&
        params.groupScore < 0 && (
          <div
            className={`benchmarkBoxBanner`}
            style={{ borderTop: params.listView ? "" : "1px solid black" }}
          >
            <div>
              <p style={{ display: "inline-block", paddingLeft: "5px" }}>
                There are no averages for the currently selected group and
                survey template type.
              </p>
            </div>
          </div>
        )}
    </div>
  );
}

export default AvgSiteScore;
