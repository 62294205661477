import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Controls from "components/Controls";
import { useAuth } from "lib/auth";
import { useLoading } from "providers/loading";
import { ErrorToast, SuccessToast } from "components/Toaster";
import API from "../../services/Api.Service";
import { API_URLS } from "../../config/ApiUrls";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { ChangeEmailForm } from "./ChangeEmailForm";

export const ManageProfile = () => {
  const { user } = useAuth();
  const [Is2FAEnabled, setIs2FAEnabled] = useState(false);
  const username = user?.data?.userName || "";
  const { setAppLoading } = useLoading();

  useEffect(() => {
    if (username) {
      API.get(API_URLS.Is2FAEnabled, {
        params: {
          email: username,
        },
      }).then((response: any) => {
        setIs2FAEnabled(response.data.isEnabled);
      });
    }
  }, [username]);

  const disableTwoFactor = () => {
    setAppLoading(true);
    try {
      API.get(API_URLS.DisableTwoFactor, {
        params: {
          email: username,
        },
      })
        .then((res: any) => {
          SuccessToast(res?.data?.message);
          setIs2FAEnabled(false);
        })
        .finally(() => {
          setAppLoading(false);
        });
    } catch (error) {
      setAppLoading(false);
      ErrorToast("Something went wrong!!");
    }
  };

  const enableTwoFactor = () => {
    setAppLoading(true);
    try {
      API.get(API_URLS.EnableTwoFactor, {
        params: {
          email: username,
        },
      })
        .then((res: any) => {
          SuccessToast(res?.data?.message);
          setIs2FAEnabled(true);
        })
        .finally(() => {
          setAppLoading(false);
        });
    } catch (error) {
      setAppLoading(false);
      ErrorToast("Something went wrong!!");
    }
  };

  return (
    <div className="container-view">
      <Grid container>
        <Grid item xs={12} sm={3} md={3} />
        <Grid item xs={12} sm={6} md={6}>
          <div>
            <div className="page-heading-container">
              <Controls.BackButton />
              <h2 className="page-heading">Manage Profile</h2>
            </div>
          </div>
          <div>
            <h2>Change Email</h2>
          </div>
          <ChangeEmailForm />
          <br />
          <br />
          <div>
            <h2>Change Password</h2>
          </div>
          <ChangePasswordForm />
          <br />
          <div style={{ display: "inline-block" }}>
            <br />
            <div className="form-buttons custom-floatRight">
              <h2 style={{ marginRight: "20px" }}>Two Factor Authentication</h2>
              {!Is2FAEnabled && (
                <Controls.Button
                  className="button blue"
                  text="Enable 2FA"
                  variant="outlined"
                  onClick={() => enableTwoFactor()}
                />
              )}
              {Is2FAEnabled && (
                <Controls.Button
                  text="Disable 2FA"
                  className="button blue"
                  variant="outlined"
                  onClick={() => disableTwoFactor()}
                />
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={3} md={3} />
      </Grid>
    </div>
  );
};
