import { Routes, Route, Navigate } from "react-router-dom";
import routePaths from "config/RoutePaths";
import CreateSurveyTemplateType from "./CreateSurveyTemplateType";
import SurveyTemplates from "./SurveyTemplates";
import ViewSurveyTemplate from "./ViewSurveyTemplate";
import CreateSurveyTemplate from "./CreateSurveyTemplate";

export const SurveyRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<SurveyTemplates />} />
      <Route path="/create" element={<CreateSurveyTemplate />} />
      <Route path={routePaths.VIEWTEMPLATE} element={<ViewSurveyTemplate />} />
      <Route
        path={routePaths.EDITTEMPLATE}
        element={<CreateSurveyTemplate />}
      />
      <Route
        path="/create-templatetype"
        element={<CreateSurveyTemplateType />}
      />
      <Route path="*" element={<Navigate to={routePaths.SURVEYTEMPLATES} />} />
    </Routes>
  );
};
