import { Routes, Route } from "react-router-dom";
import Sectors from ".";
import SubSectors from "./SubSectors";
import CreateSector from "./CreateSector";
import CreateSubSector from "./CreateSubSector";

export const SectorsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Sectors />} />
      <Route path="/subsectors" element={<SubSectors />} />
      <Route path="/create" element={<CreateSector />} />
      <Route path="/create-sub-sector" element={<CreateSubSector />} />
    </Routes>
  );
};
