import Controls from "components/Controls";
import { IFiltersModal } from "models/Interfaces/IReports";
import API from "services/Api.Service";
import { API_URLS } from "config/ApiUrls";
import { useLoading } from "providers/loading";
import { useEffect, useState } from "react";
import { useForm } from "hooks/useForm";
import { Grid } from "@material-ui/core";
import "./Style.css";

const FiltersModal = ({ setModal, setFilters, filters }: IFiltersModal) => {
  const [organisations, setOrganisations] = useState([]);
  const [sites, setSites] = useState([]);
  const [tags, setTags] = useState([]);
  const [obj, setObj] = useState<any>({});
  const { setAppLoading } = useLoading();

  const initialValues = {
    organisations: [],
    organisationsTextSearch: "",
    sites: [],
    sitesTextSearch: "",
    tags: [],
    tagsTextSearch: "",
    date: null,
    filtersHidden: false,
    chartsHidden: false,
    isCompleted: false,
    statuses: true,
  };

  useEffect(() => {
    setAppLoading(true);

    const fetchData = async () => {
      try {
        // Start all your GET requests asynchronously
        const getOrgs = API.get(API_URLS.GetOrganisationsWithSites, {
          params: {},
        }).then((response: any) => {
          const organisationOptions = response.data.data.map((x: any) => {
            return { id: x.organisationId, label: x.organisationName };
          });

          setOrganisations(organisationOptions);
        });

        // Start all your GET requests asynchronously
        const getSites = API.get(API_URLS.GetSites, {
          params: {},
        }).then((response: any) => {
          const siteOptions = response.data.data.map((x: any) => {
            return { id: x.organisationSiteId, label: x.organisationSiteName };
          });

          setSites(siteOptions);
        });

        // Start all your GET requests asynchronously
        const getTags = API.get(API_URLS.GetTags, {
          params: {},
        }).then((response: any) => {
          const tagOptions = response.data.data.map((x: any) => {
            return { id: x.tagId, label: x.tagName };
          });

          setTags(tagOptions);
        });

        // Wait for all requests to complete
        await Promise.all([getOrgs, getSites, getTags]);
      } finally {
        // All requests completed, set isLoading to false
        values.organisations = [];

        if (filters.organisations) {
          values.organisations = filters.organisations;
        }

        values.organisationsTextSearch = "";

        values.sites = [];

        if (filters.sites) {
          values.sites = filters.sites;
        }

        values.sitesTextSearch = "";

        values.tags = [];

        if (filters.tags) {
          values.tags = filters.tags;
        }

        values.tagsTextSearch = "";

        values.date = filters.date;
        values.isCompleted = filters.isCompleted;
        values.filtersHidden = filters.filtersHidden;
        values.chartsHidden = filters.chartsHidden;
        values.statuses = filters.statuses;

        setDataValues(values);
        setAppLoading(false);
      }
    };

    fetchData();

    return () => {
      // Remove all the field errors and reset the form
      setObj({});
      // setDataValues(initialValues);
      // resetForm();
    };
  }, []);

  const validate = (fieldValues = values) => {
    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, setDataValues, resetForm } = useForm(
    initialValues,
    true,
    validate,
  );

  // Method to update the site options depending on the organisations
  // the user has selected.
  const updateSites = (selectedOrganisations: any) => {};

  // Method to update the organisation options depending on the sites
  // the user has selected.
  const updateOrganisations = (selectedSites: any) => {};

  // When selecting an object from any multi-select autocomplete form field,
  // this method runs to update the values to be submitted to the API
  const handleMultipleSelectAutoCompleteChange = (
    selectedObject: any,
    fieldName: string,
  ) => {
    if (selectedObject.length === 0) {
      values[fieldName] = [];
    } else {
      values[fieldName] = selectedObject;
    }
    if (obj) (obj as any)[fieldName] = "";
    setDataValues(values);
    setErrors(obj);

    if (fieldName === "organisations") updateSites(selectedObject);

    if (fieldName === "sites") updateOrganisations(selectedObject);
  };

  // This method is required to update the text search field in the
  // autocomplete components. It is also used to force the field to be blank.
  const handleAutoCompleteTextInputChange = (text: any, field: string) => {
    const fieldName = `${field}TextSearch`;
    values[fieldName] = text;
    setDataValues(values);
  };

  const handleDateChange = (date: any) => {
    values.date = date;
    setDataValues(values);
    validate({ startDate: date });
  };

  // Method to apply filters
  const applyFilters = () => {
    if (validate()) {
      setFilters(values);
      setModal();
    }
  };

  // Method to reset all filters
  const resetFilters = () => {
    setFilters({
      organisations: [],
      sites: [],
      tags: [],
      date: null,
      filtersHidden: false,
      chartsHidden: false,
      isCompleted: false,
      statuses: true,
    });
    setModal();
  };

  const handleInputChange = (event: any) => {
    values[event.target.name] = event.target.value;
    setDataValues(values);

    if (event.target.name === "statuses" && event.target.value) {
      values.isCompleted = false;
      values.filtersHidden = false;
      values.chartsHidden = false;
      setDataValues(values);
    } else {
      values.statuses = false;
      setDataValues(values);
    }
  };

  return (
    <div className="modal">
      <div onClick={setModal} className="overlay" />
      <div className="modal-content surveyCycleFilters">
        <h2>Filter survey cycles</h2>
        <Grid container>
          <Grid item xs={12}>
            <Controls.AutoComplete
              id="organistions"
              name="organisations"
              label="Organisations"
              multiple={true}
              values={values.organisations}
              onChange={handleMultipleSelectAutoCompleteChange}
              onInputChange={handleAutoCompleteTextInputChange}
              options={organisations}
              error={obj.organisationsError}
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.AutoComplete
              id="sites"
              name="sites"
              label="Sites"
              multiple={true}
              values={values.sites}
              onChange={handleMultipleSelectAutoCompleteChange}
              onInputChange={handleAutoCompleteTextInputChange}
              options={sites}
              error={obj.sitesError}
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.AutoComplete
              id="tags"
              name="tags"
              label="Tags"
              multiple={true}
              values={values.tags}
              onChange={handleMultipleSelectAutoCompleteChange}
              onInputChange={handleAutoCompleteTextInputChange}
              options={tags}
              error={obj.tagsError}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} className="grid-custom-spacing">
            <Controls.DatePicker
              id="date"
              name="date"
              variant="inline"
              value={values.date}
              inputVariant="standard"
              label="From Date"
              placeholder="dd/MM/yyyy"
              format="dd/MM/yyyy"
              orientation="landscape"
              onChange={handleDateChange}
              error={obj.date}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} className="grid-custom-spacing">
            <Controls.CheckBox
              id="isCompleted"
              label="Marked as complete?"
              name="isCompleted"
              value={values.isCompleted}
              onChange={handleInputChange}
              error={obj.isCompleted}
              checked={values.isCompleted}
            />
            <Controls.CheckBox
              id="filtersHidden"
              label="Report filters hidden?"
              name="filtersHidden"
              value={values.filtersHidden}
              onChange={handleInputChange}
              error={obj.filtersHidden}
              checked={values.filtersHidden}
            />
            <Controls.CheckBox
              id="chartsHidden"
              label="Segmentation charts hidden?"
              name="chartsHidden"
              value={values.chartsHidden}
              onChange={handleInputChange}
              error={obj.chartsHidden}
              checked={values.chartsHidden}
            />
            <Controls.CheckBox
              id="statuses"
              label="All statuses"
              name="statuses"
              value={values.statuses}
              onChange={handleInputChange}
              error={obj.statuses}
              checked={values.statuses}
            />
          </Grid>
        </Grid>
        <br />
        <div className="form-buttons custom-floatRight">
          <Controls.Button
            className="button red"
            onClick={resetFilters}
            text="Reset Filters"
          />
          <Controls.Button
            className="button blue"
            onClick={applyFilters}
            text="Apply Filters"
          />
          <Controls.Button
            text="Cancel"
            className="cancel-button"
            variant="outlined"
            onClick={setModal}
          />
        </div>
      </div>
    </div>
  );
};

export default FiltersModal;
