import { useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { FaMinus, FaPlus } from "react-icons/fa";
import Controls from "../../../../components/Controls";
import "./Style.css";

function MapFilters({
  isAdmin,
  selectedUserId,
  selectedUserName,
  selectedOrgId,
  selectedOrgName,
  selectedSurveyTemplateTypeId,
  surveyTemplateTypeName,
  handleSelectUser,
  handleSelectUserTextInputChange,
  handleSelectOrg,
  handleSelectOrgTextInputChange,
  handleSelectSurveyTemplate,
  handleSelectSurveyTemplateTextInputChange,
  filteredOrganisations,
  surveyTemplateTypes,
  users,
  handleSelectGroup,
  handleSelectGroupTextInputChange,
  selectedGroupId,
  selectedGroupName,
  groups,
}: any) {
  const [expanded, setExpanded] = useState(true);
  return (
    <div className="mapFilterBox">
      <div
        className={`mapFilterBoxBanner ${
          expanded ? "mapFilterBoxBannerBorder" : ""
        }`}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <FilterAltIcon style={{ display: "inline-block" }} />
          <p style={{ display: "inline-block" }}>Filters map by</p>
        </div>
        <div className="mapFilterBoxIcons">
          <div
            className="contentExpansion"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <div className={expanded ? "hiddenIcon" : "displayedIcon"}>
              <FaPlus />
            </div>
            <div className={expanded ? "displayedIcon" : "hiddenIcon"}>
              <FaMinus />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`mapFilterBoxContent ${expanded ? "visible" : "invisible"}`}
      >
        <div className="surveyTemplateSelectMap">
          {isAdmin && (
            <Controls.AutoComplete
              id="clientId"
              name="clientId"
              label="Select a user"
              value={selectedUserId}
              inputValue={selectedUserName}
              onChange={handleSelectUser}
              onInputChange={handleSelectUserTextInputChange}
              varient="standard"
              options={users}
            />
          )}
          <Controls.AutoComplete
            id="organisationId"
            name="organisationId"
            label="Select a business*"
            value={selectedOrgId}
            inputValue={selectedOrgName}
            onChange={handleSelectOrg}
            onInputChange={handleSelectOrgTextInputChange}
            varient="standard"
            options={filteredOrganisations}
          />
          <Controls.AutoComplete
            id="surveyTemplateTypeId"
            name="surveyTemplateTypeId"
            label="Select a survey template type*"
            value={selectedSurveyTemplateTypeId}
            inputValue={surveyTemplateTypeName}
            onChange={handleSelectSurveyTemplate}
            onInputChange={handleSelectSurveyTemplateTextInputChange}
            options={surveyTemplateTypes}
          />
          {groups && groups.length > 0 && (
            <Controls.AutoComplete
              id="groupId"
              name="groupId"
              label="Select a benchmarks group*"
              value={selectedGroupId}
              inputValue={selectedGroupName}
              onChange={handleSelectGroup}
              onInputChange={handleSelectGroupTextInputChange}
              options={groups}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MapFilters;
